import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Typography,
  TableHead,
  TablePagination,
} from '@mui/material';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import campaigns from 'modules/campaigns';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import phishingCampaign from 'modules/phishingCampaign';

const formatDateTime = dateTimeString => {
  const date = new Date(dateTimeString);
  const formattedDateTime =
    date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  return formattedDateTime;
};

const PhishingSentSendEmail = props => {
  const Swal = useSwalWrapper();
  const [isLoading, setIsLoading] = React.useState(true);
  const [sentData, setSentData] = React.useState([]);

 

  useEffect(() => {
    loadSentData(); 
  }, [props.phishing]);

  const loadSentData = () => {
    phishingCampaign.PhishingEmailHistory(props.phishing, response => {
      setIsLoading(false);
      if (response.status === 'success') {
        // Format the date fields
        const formattedData = response.data.map(rowData => ({
          ...rowData,
          sent_at: formatDateTime(rowData.sent_at),
          createdAt: formatDateTime(rowData.createdAt),
          updatedAt: formatDateTime(rowData.updatedAt),
        }));
        setSentData(formattedData);
      } else {
        setSentData([]);
      }
    }); 
  };

  const[smsHistory, setEmailRetry]= useState([]);

  const EmailHistoryRetry= (id)=>{
   phishingCampaign.getPhisingEmailRetry(id, response => {
      if (response.status === 'success') {
        setEmailRetry(response.data)
        loadSentData();
      } else {
        setEmailRetry([])
      }
    });
  }
  return (
    <React.Fragment>
      {isLoading ? (
        <div className='loader'>
          <svg className='circular-loader' viewBox='25 25 50 50'>
            <circle className='loader-path' cx='50' cy='50' r='20' />
          </svg>
        </div>
      ) : sentData?.length <= 0 ? (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  padding: '0px !important',
                  boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                  borderRadius: '12px',
                  backgroundColor: 'rgb(255, 255, 255)',
                  whiteSpace: 'nowrap',
                }}
              >
                {' '}
                {/* <TableCell>Id</TableCell> */}
                <TableCell>Email </TableCell>
                <TableCell>Is sent</TableCell>
                <TableCell>Sent At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sentData.map(rowData => (
                <TableRow>
                  {/* <TableCell>{rowData.id}</TableCell> */}
                  <TableCell>{rowData.email_id}</TableCell>
                  <TableCell>{rowData.is_sent.toString()}</TableCell>
                  <TableCell>{rowData.sent_at}</TableCell>
                  <TableCell>
                    {rowData.is_sent ? (
                       <Button disabled>Retry</Button>
                        ) : (
                          <Button onClick={() => EmailHistoryRetry(rowData.id)}>Retry</Button>
                         )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

export default PhishingSentSendEmail;
