import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';

function GridWithHeader({
  data,
  isLoading,
  headers,
  viewHeaders,
  rowsPerPageOptions,
  rowsPerPage,
  page,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
  onRowClick,
  actionsColumn,
  openDialog,
  // handleEdit,
}) {
  return (
    <React.Fragment>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'} mt={1}>
          {headers.main}
        </Typography>
        <Box>
          {openDialog && (
            <Button variant='contained' onClick={openDialog}>
              {headers.addButton}
            </Button>
          )}
        </Box>
      </Box>

      {isLoading ? (
        <div className='loader' style={{ marginTop: '110px' }}>
          <svg className='circular-loader' viewBox='25 25 50 50'>
            <circle className='loader-path' cx='50' cy='50' r='20' />
          </svg>
        </div>
      ) : data?.length <= 0 && !isLoading ? (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headers.main === 'LDAP Servers'
                  ? // Render headers for LDAP Servers
                    viewHeaders.columns.map((header, index) => (
                      <TableCell key={index}>{header.label}</TableCell>
                    ))
                  : // Render headers for other cases
                    headers.columns.map((header, index) => (
                      <TableCell key={index}>{header.label}</TableCell>
                    ))}
                {actionsColumn && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index, set) => (
                <TableRow
                  key={index}
                  onClick={() => {
                    // handleEdit(item, set);
                    onRowClick && onRowClick(item);
                  }}
                >
                  {headers.main === 'LDAP Servers'
                    ? // First map for LDAP Servers
                      viewHeaders.columns.map((key, columnIndex) => (
                        <TableCell key={columnIndex}>
                          {item[key?.name] ? item[key?.name] : '_'}
                        </TableCell>
                      ))
                    : // Second map for other cases
                      headers.columns.map((key, columnIndex) => (
                        <TableCell key={columnIndex}>
                          {item[key?.name] ? item[key?.name] : '_'}
                        </TableCell>
                      ))}

                  {actionsColumn && (
                    <TableCell>{actionsColumn(item, index)}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component='div'
            count={count ? count : data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </React.Fragment>
  );
}

GridWithHeader.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  headers: PropTypes.shape({
    main: PropTypes.string.isRequired,
    addButton: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  actionsColumn: PropTypes.func,
  openDialog: PropTypes.func,
};

export default GridWithHeader;
