import React from "react";
import campaigns from "modules/campaigns";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GridWithHeader from "app/CommonComponents/GridWithHeader";
import AddServer from "./AddServer";
import AddCampaign from "./AddCampaign";
import emailCampaign from "modules/emailCampaign";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
function EmailCampaigns() {
  const Swal = useSwalWrapper();
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [emailCampaignList, setEmailCampaignList] = React.useState([]);
  const { showDialog, hideDialog } = useJumboDialog();
  const [serverDialog, setServerDialog] = React.useState({
    open: false,
  });
  const setOpenServerDialog = (data) => {
    setServerDialog({ open: true, data: data });
  };

  const handleCloseDialog = () => {
    setServerDialog({ open: false });
  };

  React.useEffect(() => {
    getAllEmailCampaigns();
  }, []);
  const headers = {
    main: "Email Campaigns",
    addButton: "Add Email Campaign",
    columns: [
      { name: "title", label: "Title" },
      { name: "context", label: "Context" },
      { name: "name", label: "Sender Name" },
    ],
  };
  const getAllEmailCampaigns = () => {
    emailCampaign.getAllEmailCampaigns((response) => {
      if (response.status === "success") {
        setEmailCampaignList(response.data);
      } else {
        setEmailCampaignList([]);
      }
    });
  };

  const addEmailCampaign = (data) => {
    serverDialog.data
      ? emailCampaign.updateEmailCampaign(
          serverDialog.data.id,
          data,
          (response) => {
            if (response.status === "success") {
              getAllEmailCampaigns();

              Swal.fire({
                icon: "success",
                title: "Email Campaign has been updated successfully.",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "There is an Error while updating email campaign.",
              });
            }
          }
        )
      : emailCampaign.addEmailCampaign(data, (response) => {
          if (response.status === "success") {
            getAllEmailCampaigns();

            Swal.fire({
              icon: "success",
              title: "Email Camapign has been added successfully.",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "There is an Error while adding email campaign.",
            });
          }
        });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllEmailCampaigns();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleItemAction = (actionType, item) => {
    switch (actionType.action) {
      case "delete":
        showDialog({
          variant: "confirm",
          title: "Are you sure about deleting this Lesson?",
          content: "You won't be able to recover this Lesson later",
          onYes: () => handleDelete(item.id),
          onNo: hideDialog,
        });

        break;
      case "edit":
        handleEdit(item);
        break;
    }
  };
  const handleDelete = (id) => {
    emailCampaign.deleteEmailCampaign(id, (response) => {
      if (response.status === "success") {
        getAllEmailCampaigns();
        Swal.fire({
          icon: "success",
          title: "Email Campaign has been deleted successfully.",
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: "error",
          title: "There is an Error while deleting Email Campaign.",
        });
      }
    });
  };
  const handleEdit = (data) => {
    setServerDialog({ ...serverDialog, open: true, data: data });
  };

  return (
    <>
      <GridWithHeader
        data={emailCampaignList}
        isLoading={isLoading}
        headers={headers}
        rowsPerPageOptions={[10]}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        actionsColumn={(item, index) => (
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={(actionType) => handleItemAction(actionType, item)}
          />
        )}
        openDialog={() =>
          setServerDialog({ ...serverDialog, open: true, data: "" })
        }
      />
      {serverDialog.open ? (
        <AddCampaign
          open={serverDialog.open}
          close={handleCloseDialog}
          addEmailCampaign={addEmailCampaign}
        />
      ) : null}
      {serverDialog.data && serverDialog.open ? (
        <AddCampaign
          open={serverDialog.open}
          close={handleCloseDialog}
          addEmailCampaign={addEmailCampaign}
          edit={true}
          data={serverDialog.data}
        />
      ) : null}
    </>
  );
}

export default EmailCampaigns;
const menuItems = [
  { icon: <EditIcon />, title: "Edit", action: "edit" },
  { icon: <DeleteIcon />, title: "Delete", action: "delete" },
];
