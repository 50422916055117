import React from "react";
import Div from "@jumbo/shared/Div";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography } from "@mui/material";

import Lesson from "./Lesson";
import Videos from "../videos/Videos";
import Questions from "../questions/Questions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Div sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Div>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LessonDetails = (props) => {
  const [value, setValue] = React.useState(0);
  return (
    <Div sx={{ width: "100%" }}>
      <Div sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
          <Tab label="Lesson Details" {...a11yProps(0)} />
          <Tab label="Videos" {...a11yProps(1)} />
          <Tab label="Questions" {...a11yProps(2)} />
        </Tabs>
      </Div>
      <TabPanel value={value} index={0}>
        <Lesson
          lesson={props.lesson}
          setExpanded={props.setExpanded}
          examId={props.examId}
          getAllLessons={props.getAllLessons}
          getAllLessonsByExam={props.getAllLessonsByExam}
          getAllExamLessonsByExam={props.getAllExamLessonsByExam}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Videos id={props.lesson.id} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Questions id={props.lesson.id} />
      </TabPanel>
    </Div>
  );
};

export default LessonDetails;
