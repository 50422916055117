import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import CachedIcon from "@mui/icons-material/Cached";
import license from "modules/license";

const AddLicense = (props) => {
  const Swal = useSwalWrapper();

  const handleAddEditLiscence = (data) => {
    if (props?.contact?.id) {
      license.updateLicense(props.contact.id, data, (response) => {
        if (response.status === "success") {
          props.close();
          props?.getAllLicenses();
          Swal.fire({
            icon: "success",
            title: "License has been updated successfully.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "There is an Error while adding license.",
          });
        }
      });
    } else {
      license.addLicense(data, (response) => {
        if (response.status === "success") {
          props.close();
          props?.getAllLicenses();
          Swal.fire({
            icon: "success",
            title: "license has been added successfully.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "There is an Error while adding license.",
          });
        }
      });
    }
    props.close();
  };

//   const expired = [True, False];
  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close} maxWidth='lg'>
        <DialogTitle id='alert-dialog-title' textAlign='center'>
          {props.data.id ? "Edit Licenses" : "Add Licenses"}
        </DialogTitle>
        <Formik
          validateOnChange={true}
          initialValues={{
            ...props.contact,
          }}
          enableReinitialize={true}
         
          onSubmit={(data) => {
            handleAddEditLiscence(data);
          }}
        >
          <Form style={{ textAlign: "left" }} noValidate autoComplete='off'>
            <DialogContent>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name='licenseName'
                    label='LicenseName'
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField fullWidth name='assignedLisences' label='AssignedLicense' type='number' />
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField fullWidth name='purchasedQuantity' label='PurchasedQuantity' type='number' />
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField fullWidth name='purchasedOn' label='PurchasedOn' type='date'/>
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField fullWidth name='plan' label='Plan' type='number'/>
                </Grid>
                
                <Grid item xs={12} md={6} sm={6} sx={{ mt: 1 }}>
                  <JumboTextField fullWidth name='billingFrequency' label='Billing Frequency' type='text' />
                </Grid>
                <Grid item xs={12} md={6} sm={6} sx={{ mt: 1 }}>
                  <JumboTextField
                    type='date'
                    fullWidth
                    name='renewsOn'
                    label='renewsOn'
                  />
                </Grid>
              

              
                <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                  <JumboTextField fullWidth select name='expired' label='Expired'>
                    <MenuItem key='True' value='true'>
                      true
                    </MenuItem>
                    <MenuItem key='false' value='false'>
                    false
                    </MenuItem>
                  </JumboTextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' type='submit' autoFocus>
                {props.data.id ? "Update" : "Save"}
              </Button>
              <Button variant='contained' onClick={props.close}>
                {" "}
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddLicense;
