import appApi from './appApi';
class phishingUsers {
  addOrUpdatePhishingUsers = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/phishing/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  Phishing users', error);
      });
  };

  getAllPassedUsers = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/phishing/getPassedUsers',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting passed users', error);
      });
  };
  getAllFailedUsers = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/phishing/getFailedUsers',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting failed users', error);
      });
  };

  getAllClickedUsers = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/phishing/getClickedPhishingUsers',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting clicked users', error);
      });
  };

  getAllUsersJoinedCampaigns = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/phishing/usersJoinedCampaigns',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting users joined campaigns', error);
      });
  };
  getAllUsersJoinedPhishing = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/phishing/usersJoinedphishing',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(
          'error while getting users joined phishingcampaigns',
          error
        );
      });
  };

  getAllUsersByVideoStatus = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/users/all/videostatus',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users by videoStatus', error);
      });
  };

  getAllUsersByVideoStatusAndQuizStatus = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/users/all/videostatusandquizstatus',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(
          'error while getting  users by videoStatus and quizStatus',
          error
        );
      });
  };
  getAllUsersWithQuizScore = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/users/all/quizscore',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users quizScore', error);
      });
  };
}
export default new phishingUsers();
