import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GridWithHeader from 'app/CommonComponents/GridWithHeader';
import AddServer from './AddServer';
import ssoServer from 'modules/ssoServer';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
function SsoServers() {
  const Swal = useSwalWrapper();
  const [isLoading, setIsLoading] = React.useState(true);
  const [serverDialog, setServerDialog] = React.useState({
    open: false,
  });
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [ssoServerList, setSsoServerList] = React.useState([]);
  const { showDialog, hideDialog } = useJumboDialog();
  const setOpenServerDialog = data => {
    setServerDialog({ open: true, data: data });
  };

  const handleCloseDialog = () => {
    setServerDialog({ open: false });
  };
  const headers = {
    main: 'Single Sign On',
    addButton: 'Add Server',
    columns: [
      { name: 'title', label: 'Title' },
      { name: 'type', label: 'Type' },
      { name: 'discoverable', label: 'Discoverable' },
    ],
  };

  React.useEffect(() => {
    getAllSsoServers(page);
    getCount();
  }, []);
  const getCount = () => {
    ssoServer.getCount(response => {
      if (response.status === 'success') {
        setCount(response.data);
      } else {
        setCount();
      }
    });
  };

  const getAllSsoServers = newPage => {
    let p = page;
    if (newPage) {
      p = newPage;
    }
    // const offset = page * rowsPerPage;
    ssoServer.getAllSsoServers(p, rowsPerPage, response => {
      if (response.status === 'success') {
        setSsoServerList(response.data);
        setIsLoading(false);
      } else {
        setSsoServerList([]);
        setIsLoading(false);
      }
    });
  };
  const addSsoServer = data => {
    serverDialog.data
      ? ssoServer.updateSsoServer(serverDialog.data.id, data, response => {
          if (response.status === 'success') {
            getAllSsoServers();
            Swal.fire({
              icon: 'success',
              title: 'Sso Server has been updated successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while updating sso server.',
            });
          }
        })
      : ssoServer.addSsoServer(data, response => {
          if (response.status === 'success') {
            getAllSsoServers();

            Swal.fire({
              icon: 'success',
              title: 'Sso Server has been added successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while adding sso server.',
            });
          }
        });
  };
  const handleChangePage = (event, newPage) => {
    debugger;
    setPage(newPage);
    getAllSsoServers(newPage);
  };

  const handleChangeRowsPerPage = event => {
    debugger;
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getAllSsoServers();
  };
  const handleItemAction = (actionType, item) => {
    switch (actionType.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this SSO Server?',
          content: "You won't be able to recover this SSO Server",
          onYes: () => handleDelete(item.id),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit(item);
        break;
    }
  };
  const handleDelete = id => {
    ssoServer.deleteSsoServer(id, response => {
      if (response.status === 'success') {
        getAllSsoServers();
        Swal.fire({
          icon: 'success',
          title: 'SSo server has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting sso server.',
        });
      }
    });
  };
  const handleEdit = data => {
    setServerDialog({ ...serverDialog, open: true, data: data });
  };

  return (
    <>
      <GridWithHeader
        data={ssoServerList}
        isLoading={isLoading}
        headers={headers}
        rowsPerPageOptions={[10]}
        rowsPerPage={rowsPerPage}
        page={page}
        count={count}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        actionsColumn={(item, index) => (
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={actionType => handleItemAction(actionType, item)}
          />
        )}
        openDialog={() =>
          setServerDialog({ ...serverDialog, open: true, data: '' })
        }
      />
      {serverDialog.open ? (
        <AddServer
          open={serverDialog.open}
          close={handleCloseDialog}
          addSsoServer={addSsoServer}
        />
      ) : null}
      {serverDialog.data && serverDialog.open ? (
        <AddServer
          open={serverDialog.open}
          close={handleCloseDialog}
          addSsoServer={addSsoServer}
          edit={true}
          data={serverDialog.data}
        />
      ) : null}
    </>
  );
}

export default SsoServers;
const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
