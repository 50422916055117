import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import Assignment from 'app/mmEsoft/module/Assignment';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

export default function DeleteAssignment(props) {

    const Swal = useSwalWrapper();

    const handleDelete = () =>{
      let id = props.deleteAssignmentData?.id;
    Assignment.deleteAssignment(id, (response) => {
        if (response.status === "success") {
          Swal.fire({
            icon: "success",
            title: "Data deleted Successfully.",
          });
          props.handleAllAssignment();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error Occured While deleting Data!",
          });
        }
      });
      props.handleCloseDelete();
    }
    
  

  return (
    <Box>
      <Dialog
        open={props.openDelete}
        onClose={props.handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseDelete}>Close</Button>
          <Button onClick={handleDelete}>
             Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}