import { GET_CAMPAIGNS, GET_CAMPAIGNS_FAILURE } from '../actions/userCampaigns';

const initialCampaignState = {
  campaignList: [],
  error: null,
};

const campaignsReducer = (state = initialCampaignState, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS:
      return {
        ...state,
        campaignList: action.payload,
        error: null,
      };
    case GET_CAMPAIGNS_FAILURE:
      return {
        ...state,
        campaignList: [],
        error: 'Failed to fetch campaigns',
      };
    default:
      return state;
  }
};

export default campaignsReducer;
