import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { Form, Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import questions from 'modules/questions';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import MenuItem from '@mui/material/MenuItem';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import options from 'modules/options';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // Import the CSS for styling

import './ckEditor.css';

import * as yup from 'yup';
let Editor = () => <React.Fragment />;
const AddQuestion = props => {
  const Swal = useSwalWrapper();
  const validationSchema = yup.object().shape({
    text: props?.data.id
      ? yup.string()
      : yup.string().required('Text is required'),
    option1: props?.data.id
      ? yup.string()
      : yup.string().required('Option is required'),
    option2: props?.data.id
      ? yup.string()
      : yup.string().required('Option is required'),
    option3: props?.data.id
      ? yup.string()
      : yup.string().required('Option is required'),
    option4: props?.data.id
      ? yup.string()
      : yup.string().required('Option is required'),
  });
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [mcq, setMcq] = useState(true);
  const contentRef = useRef(null);
  const [multiselect, setMultiSelect] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionData, setOptionData] = useState([]);
  let optionss = [
    {
      name: 'option1',
      label: 'Option 1',
    },
    {
      name: 'option2',
      label: 'Option 2',
    },
    {
      name: 'option3',
      label: 'Option 3',
    },
    {
      name: 'option4',
      label: 'option 4',
    },
  ];
  const [optionIcons, setOptionIcons] = useState(
    optionss?.map(() => ({
      displayCancelIcon: true,
      selected: false,
    }))
  );
  React.useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
    Editor = require('react-draft-wysiwyg').Editor;
    if (props.data?.text) {
      const blocksFromHTML = convertFromHTML(props.data?.text);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(content));
    } else {
      setEditorState(EditorState.createEmpty());
    }
    getAllOptionsByQuestion(props.data?.id);
  }, []);
  React.useEffect(() => {
    setOptionIcons(prevOptionIcons =>
      prevOptionIcons?.map(icon => ({
        ...icon,
        displayCancelIcon: true,
      }))
    );
  }, []);
  const toggleOption1 = index => {
    setOptionIcons(prevOptionIcons => {
      const updatedOptionIcons = [...prevOptionIcons];
      updatedOptionIcons[index] = {
        displayCancelIcon: !prevOptionIcons[index]?.displayCancelIcon,
        selected: !prevOptionIcons[index]?.selected,
      };
      return updatedOptionIcons;
    });
  };
  const toggleOption = index => {
    setOptionIcons(prevOptionIcons => {
      const updatedOptionIcons = prevOptionIcons.map((icon, i) => {
        if (i === index) {
          // Toggle the selected state and cancel icon display for the clicked option
          return {
            displayCancelIcon: !icon.displayCancelIcon,
            selected: !icon.selected,
          };
        } else {
          // For other options, set them as not selected and display the cancel icon
          return {
            displayCancelIcon: true,
            selected: false,
          };
        }
      });
      return updatedOptionIcons;
    });
  };

  useEffect(() => {
    if (props?.data?.id) {
      setOptionIcons(optionData.map((option, index) => ({
        displayCancelIcon: !option.isAnswer,
        selected: option.isAnswer,
      })));
    }
  }, [optionData, props?.data?.id]);
  
 
  const handleIconClick = index => {
    if (multiselect) {
      // If multiselect is true, toggle the selected state of the clicked option
      setSelectedOptions(prevSelectedOptions => {
        if (prevSelectedOptions.includes(index)) {
          // If the option is already selected, remove it
          return prevSelectedOptions.filter(item => item !== index);
        } else {
          // If the option is not selected, add it
          return [...prevSelectedOptions, index];
        }
      });
      toggleOption1(index);
    } else {
      // If multiselect is false, select only the clicked option
      setSelectedOptions([index]);

      toggleOption(index);
    }

    // Toggle the display of the cancel icon and selected state of the clicked option
  };

  // Function to handle the CancelPresentationIcon click
  const handleCancelClick = index => {
    // Toggle the option state when CancelPresentationIcon is clicked
    toggleOption(index);
  };

  const getAllQuestionsByLesson = id => {
    questions.getAllQuestionsByLesson(id, response => {
      if (response.status === 'success') {
        props?.setQuestionData(response.data);
        getAllOptionsByQuestion(props.data?.id);
      } else {
        props?.setQuestionData([]);
      }
    });
  };

  const getAllOptionsByQuestion = id => {
    options.getAllOptionsByQuestion(id, response => {
      if (response.status === 'success') {
        let data = response.data;
        setOptionData(data);
      } else {
        setOptionData([]);
      }
    });
  };
  const handleAddEditQuestion = data => {
    let optionsdata;
    if (mcq) {
      optionsdata = {
        option0: {
          text: data.option1 ? data.option1 : optionData[0],
          questionId: props?.data.id ? props?.data.id : '',
          isAnswer: selectedOptions.includes(0) ? true : false,
        },
        option1: {
          text: data.option2 ? data.option2 : optionData[1],
          questionId: props?.data.id ? props?.data.id : '',
          isAnswer: selectedOptions.includes(1) ? true : false,
        },
        option2: {
          text: data.option3 ? data.option3 : optionData[2],
          questionId: props?.data.id ? props?.data.id : '',
          isAnswer: selectedOptions.includes(2) ? true : false,
        },
        option3: {
          text: data.option4 ? data.option4 : optionData[3],
          questionId: props?.data.id ? props?.data.id : '',
          isAnswer: selectedOptions.includes(3) ? true : false,
        },
      };
    }
    if (props?.data?.id) {
      if (mcq) {
        const data1 = {
          lessonId: data.lessonId,
          text: data.text ? data.text : props?.data?.text,
          multipleChoice: data.multipleChoice || false,
          multipleSelect: data.multipleSelect || false,
        };
        getAllOptionsByQuestion(props.data?.id);
        if (optionData.length > 0) {
          for (let option in optionData) {
            let option1 = 'option' + option;
            if (optionsdata.hasOwnProperty(option1)) {
              const optionn = optionsdata[option1];
             

              updateOption(optionData[option].id, optionn);
            }
          }
        } else {
          for (const option in optionsdata) {
            if (optionsdata.hasOwnProperty(option)) {
              const optionn = optionsdata[option];
             
              optionn.questionId = props?.data?.id;
              if (optionn.text == '') {
                console.log('Option Empty');
              } else {
                addOption(optionn);
              }
            }
          }
        }
        questions.updateQuestion(props.data.id, data1, response => {
          if (response.status === 'success') {
            Swal.fire({
              icon: 'success',
              title: 'Question has been Updated successfully.',
            });

            getAllQuestionsByLesson(props?.lessonId);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while updating question.',
            });
          }
        });
      } else {
        const data1 = {
          lessonId: data.lessonId,
          text: data.text ? data.text : props?.data?.text,
          multipleChoice: data.multipleChoice || false,
          multipleSelect: data.multipleSelect || false,
        };
        questions.updateQuestion(props.data.id, data1, response => {
          if (response.status === 'success') {
            Swal.fire({
              icon: 'success',
              title: 'Question has been Updated successfully.',
            });

            getAllQuestionsByLesson(props?.lessonId);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while updating question.',
            });
          }
        });
      }
    } else {
      if (mcq) {
        const data1 = {
          lessonId: data.lessonId,
          text: data.text,
          multipleChoice: data.multipleChoice || false,
          multipleSelect: data.multipleSelect || false,
        };
        questions.addQuestion(data1, response => {
          if (response.status === 'success') {
            for (const option in optionsdata) {
              if (optionsdata.hasOwnProperty(option)) {
                const optionn = optionsdata[option];
               
                optionn.questionId = response?.data?.id;

                if (optionn.text == '') {
                  console.log('Option Empty');
                } else {
                  addOption(optionn);
                }
              }
            }
            getAllQuestionsByLesson(props.lessonId);
            Swal.fire({
              icon: 'success',
              title: 'Question has been added successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while adding Question.',
            });
          }
        });
      } else {
        const data1 = {
          lessonId: data.lessonId,
          text: data.text,
          multipleChoice: data.multipleChoice || false,
          multipleSelect: data.multipleSelect || false,
        };
        questions.addQuestion(data1, response => {
          if (response.status === 'success') {
            //  for (const option in optionsdata) {
            //    if (optionsdata.hasOwnProperty(option)) {
            //      const optionn = optionsdata[option];
            //      console.log(
            //        `Person: ${optionn.text} ${optionn.questionId}${optionn.isAnswer}`
            //      );
            //      optionn.questionId = response.data?.id;
            //      if (optionn.text == '') {
            //        console.log('Option Empty');
            //      } else {
            //        addOption(optionn);
            //      }
            //    }
            //  }
            getAllQuestionsByLesson(props.lessonId);
            Swal.fire({
              icon: 'success',
              title: 'Question has been added successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while adding Question.',
            });
          }
        });
      }
    }
  };

  const updateOption = (id, data) => {
    options.updateOption(id, data, response => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Option has been updated successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while updating option.',
        });
      }
    });
  };
  const addOption = data => {
    options.addOption(data, response => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Option has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while adding option.',
        });
      }
    });
  };


  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close} maxWidth="md" fullWidth>
        <Formik
          validateOnChange={true}
          initialValues={{
            text: '',
            option1: optionData[0]?.text ? optionData[0]?.text : '',
            option2: optionData[1]?.text ? optionData[1]?.text : '',
            option3: optionData[2]?.text ? optionData[2]?.text : '',
            option4: optionData[3]?.text ? optionData[3]?.text : '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            data.lessonId = props.lessonId;
            data.multipleChoice = mcq;
            data.multipleSelect = multiselect;
            handleAddEditQuestion(data);

            props.close();
          }}
        >
          {({ setFieldValue }) => (
            <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
              <DialogContent
                ref={contentRef}
                sx={{ overflowY: 'auto', maxHeight: '80vh' }}
              >
                <DialogTitle id="alert-dialog-title" textAlign="center">
                  {props?.data?.id ? 'Edit Question' : 'Add Question'}
                </DialogTitle>
                <Box
                  sx={{
                    width: '700px',
                  }}
                >
                  <label>
                    {' '}
                    <b>Question:</b>{' '}
                  </label>
                  <Div sx={{ flex: 1 }}>
                    <Editor
                      editorStyle={{
                        width: '100%',
                        minHeight: 100,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'lightgray',
                      }}
                      toolbarClassName="horizontal-toolbar"
                      editorState={editorState}
                      name="text"
                      onEditorStateChange={editorState => {
                        setEditorState(editorState);
                        let text = draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                        );
                        setFieldValue('text', text);
                      }}
                    />
                  </Div>
                  <label> Multiple Choice</label>
                  <Switch defaultChecked onClick={() => setMcq(!mcq)} />
                  <label> MultiSelect</label>
                  <Switch onClick={() => setMultiSelect(!multiselect)} />
                  <br />
                  {!mcq ? (
                    <Div>
                      <label>
                        <b>Answer:</b>
                      </label>
                      <textarea style={{ width: '100%', height: 80 }} />
                    </Div>
                  ) : (
                    <Div>
                      <div>
                        <label style={{ fontSize: ' 16px' }}>
                          <b>Options</b>
                        </label>
                        <label
                          style={{ marginLeft: '580px', fontSize: ' 16px' }}
                        >
                          <b>Answer</b>
                        </label>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '2px',
                          marginTop: '3px',
                        }}
                      >
                        <JumboTextField
                          sx={{ mt: 2, width: '650px' }}
                          name="option1"
                          label="Option 1"
                        />
                        &nbsp;&nbsp;&nbsp;
                        {optionIcons[0]?.displayCancelIcon ? (
                          <CancelPresentationIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleIconClick(0)} // Handle CancelPresentationIcon click
                          />
                        ) : (
                          <CheckBoxIcon
                            id={`checkbox-icon-0`} // Add a unique ID to each checkbox icon
                            style={{
                              color: optionIcons[0]?.selected
                                ? '#5D3FD3'
                                : 'grey',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleIconClick(0)} // Handle checkbox icon click
                          />
                        )}
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '2px',
                          marginTop: '3px',
                        }}
                      >
                        <JumboTextField
                          fullWidth
                          sx={{ mt: 2, width: '650px' }}
                          name="option2"
                          label="Option 2"
                        />
                        &nbsp;&nbsp;&nbsp;
                        {optionIcons[1]?.displayCancelIcon ? (
                          <CancelPresentationIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleIconClick(1)} // Handle CancelPresentationIcon click
                          />
                        ) : (
                          <CheckBoxIcon
                            id={`checkbox-icon-1`} // Add a unique ID to each checkbox icon
                            style={{
                              color: optionIcons[1]?.selected
                                ? '#5D3FD3'
                                : 'grey',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleIconClick(1)} // Handle checkbox icon click
                          />
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '2px',
                          marginTop: '3px',
                        }}
                      >
                        <JumboTextField
                          fullWidth
                          sx={{ mt: 2, width: '650px' }}
                          name="option3"
                          label="Option 3"
                        />
                        &nbsp;&nbsp;&nbsp;
                        {optionIcons[2]?.displayCancelIcon ? (
                          <CancelPresentationIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleIconClick(2)} // Handle CancelPresentationIcon click
                          />
                        ) : (
                          <CheckBoxIcon
                            id={`checkbox-icon-2`} // Add a unique ID to each checkbox icon
                            style={{
                              color: optionIcons[2]?.selected
                                ? '#5D3FD3'
                                : 'grey',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleIconClick(2)} // Handle checkbox icon click
                          />
                        )}
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '2px',
                          marginTop: '3px',
                        }}
                      >
                        <JumboTextField
                          fullWidth
                          sx={{ mt: 2, width: '650px' }}
                          name="option4"
                          label="Option 4"
                        />
                        &nbsp;&nbsp;&nbsp;
                        {optionIcons[3]?.displayCancelIcon ? (
                          <CancelPresentationIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleIconClick(3)} // Handle CancelPresentationIcon click
                          />
                        ) : (
                          <CheckBoxIcon
                            id={`checkbox-icon-3`} // Add a unique ID to each checkbox icon
                            style={{
                              color: optionIcons[3]?.selected
                                ? '#5D3FD3'
                                : 'grey',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleIconClick(3)} // Handle checkbox icon click
                          />
                        )}
                      </div>
             </Div>
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" type="submit" autoFocus>
                  {props?.data?.id ? 'Update' : 'Save '}
                </Button>
                <Button variant="contained" onClick={props.close}>
                  {' '}
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddQuestion;
