import MarvelMindsApi, { appServerURLJava } from './MarvelMindsApi';
import appApi from '../../../modules/appApi';
import axios from 'axios';
class Experience {
    addEmploy = (data, callBack) => {
        return appApi({
          method: 'POST',
          url: `/api/experience/add`,
          data: data,
        })
          .then(response => {
            callBack({ status: 'success', data: response.data });
          })
          .catch(error => {
            callBack({ status: 'error' });
            console.log(error, "Error Occurred In Getting Add Employ");
          });
      };
      editEmploy = (data, id,callBack) => {
        return appApi({
          method: 'PUT',
          url: `/api/experience/${id}`,
          data: data,
        })
          .then(response => {
            callBack({ status: 'success', data: response.data });
          })
          .catch(error => {
            callBack({ status: 'error' });
            console.log(error, "Error Occurred while updating Employ");
          });
      };
      getAllExperienceByCandidateId  = (id,callBack) => {       
        return appApi({
          method: 'GET',
          url: `/api/experience/candidateId/${id}`,
        })
            .then(response => {
              callBack({ status: 'success', data: response.data });
            })
            .catch(error => {
              callBack({ status: 'error' });
              console.log(error, 'error occurred in getting Experience');
            });
        };
        deleteEmploy = (id, callBack) => {
          return appApi({
            method: 'DELETE',
            url: `/api/experience/${id}`,
          })
            .then(response => {
              callBack({ status: 'success', data: response.data });
            })
            .catch(error => {
              callBack({ status: 'error' });
              console.log(error, 'Error Occurred while deleting Employ');
            });
        };
      }
    
    export default new Experience();