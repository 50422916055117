import Page from '@jumbo/shared/Page';
import EmailConfig from 'app/pages/settings/Email/EmailConfig';
import LdapConfig from 'app/pages/settings/LDAP/LdapConfig';
import SmsConfig from 'app/pages/settings/SMS/SmsConfig';
import SsoConfig from 'app/pages/settings/SSO/SsoConfig';
import Domain from 'app/pages/settings/Domain/Domain';

const settingRoutes = [
  {
    path: '/settings/email',
    element: <Page component={EmailConfig} />,
  },
  {
    path: '/settings/sms',
    element: <Page component={SmsConfig} />,
  },
  {
    path: '/settings/ldap',
    element: <Page component={LdapConfig} />,
  },
  {
    path: '/settings/sso',
    element: <Page component={SsoConfig} />,
  },
  {
    path: '/settings/others',
    element: <Page component={Domain} />,
  },
];
export default settingRoutes;
