import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Tabs,
  Tab,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Table,
  Skeleton,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import CachedIcon from "@mui/icons-material/Cached";
import license from "modules/license";
import users from "modules/users";
import { is } from "date-fns/locale";

const AssignedLicense = (props) => {
    const [isLoading, setIsLoading] = useState(true);
  const Swal = useSwalWrapper();
  const [userList, setUserList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    getAllUsersByLicenseId();
  }, []);

  const getAllUsersByLicenseId = () => {
    users.getAllUsersByLicenseId(props.contact.id,(response) => {
      if (response.status === "success") {
        setUserList(response.data?.Users);
        setIsLoading(false);
      } else {
        setUserList([]);
        setIsLoading(false);
      }
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    // Fetch data when the "Licensed User" tab is selected
    if (newValue === 0) {
      getAllUsersByLicenseId();
    }
  };
  return (
<Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close} maxWidth="sm" fullWidth>
        <DialogTitle id="alert-dialog-title" textAlign="center">
          Choose User Type
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Licensed User" />
          </Tabs>
          {tabValue === 0 && (
            <>
              <TableContainer>
                <Table>
                  {isLoading ? (
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Skeleton variant="rectangular" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rectangular" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="rectangular" height={40} />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  ) : (
                    <TableHead>
                      <TableRow
                        sx={{
                          padding: "0px !important",
                          boxShadow:
                            "rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem",
                          borderRadius: "12px",
                          backgroundColor: "rgb(255, 255, 255)",
                        }}
                      >
                        <TableCell style={{ width: "34%" }}>UserId</TableCell>
                        <TableCell style={{ width: "34%" }}>UserName</TableCell>
                        <TableCell style={{ width: "34%" }}>Email</TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Skeleton
                            variant="rectangular"
                            height={40}
                            style={{ width: "100%" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : userList.length > 0 ? (
                      userList.map((user) => (
                        <TableRow
                          sx={{
                            padding: "2px 2px 2px 10px",
                            boxShadow:
                              "rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem",
                            borderRadius: "12px",
                            backgroundColor: "rgb(255, 255, 255)",
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "2px 2px 2px 20px !important",
                            }}
                          >
                            <Typography variant={"h6"} mb={0}>
                              {user.id}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "2px 2px 2px 20px !important",
                            }}
                          >
                            <Typography variant={"h6"} mb={0}>
                              {user.firstName}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{
                              padding: "2px 2px 2px 20px !important",
                            }}
                          >
                            <Typography variant={"h6"} mb={0}>
                              {user.email}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography variant="body1">
                            No licensed users found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Div>
  
  );
};

export default AssignedLicense;
