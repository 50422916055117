import MarvelMindsApi, { appServerURLJava } from './MarvelMindsApi';
import appApi from '../../../modules/appApi';
import axios from 'axios';

class Skills {
	Addskills = (data, callBack) => {
		return appApi({
			method: 'POST',
			url: `api/skill_list/add`,
			data: data,
		})
			.then(response => {
				callBack({ status: 'success', data: response.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log(error, 'Error Occurred In Adding Skills');
			});
	};

	getSkillsByCandidateId = (id, callBack) => {
		return appApi({
			method: 'GET',
			url: `/api/skill_list/${id}`,
		})
			.then(response => {
				callBack({ status: 'success', data: response?.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log(error, 'Error Occurred In Getting Skills');
			});
	};

	editSkill = (id, data, callBack) => {
		return appApi({
			method: 'PUT',
			url: `/api/skill_list/${id}`,
			data,
		})
			.then(response => {
				callBack({ status: 'success' });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log(error, 'Error Occurred In Getting Skills');
			});
	};

	deleteSkillsByCandidateId = (id, callBack) => {
		return appApi({
			method: 'DELETE',
			url: `/api/skill_list/${id}`,
		})
			.then(response => {
				callBack({ status: 'success', data: response?.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log(error, 'Error Occurred In Getting Skills');
			});
	};
}
export default new Skills();
