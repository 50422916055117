import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Stack,
	Grid,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import userCertificates from 'modules/userCertificates';
import { useSelector } from 'react-redux';
import ViewUserCertificates from './viewUserCertificates';
import { Margin, usePDF } from 'react-to-pdf';

import Slide from '@mui/material/Slide';
import certificates from 'modules/certificates';

const Transition = React.forwardRef(function Transition(props, ref) {
	return (
		<Slide
			direction='up'
			ref={ref}
			{...props}
		/>
	);
});

const UserCertificates = () => {
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [viewingTemplate, setViewingTemplate] = useState(false);
	const [certificateData, setCertificateData] = useState([]);
	const [isLoading, setIsLoading] = React.useState(false);
	const [selectedCertificate, setSelectedCertificate] = useState(null); // State to store the selected certificate
	const navigate = useNavigate();
	const { user } = useSelector(({ login }) => login);

	const Swal = useSwalWrapper();
	useEffect(() => {
		getAllCertificatesForUserId();
	}, []);

	const { toPDF, targetRef } = usePDF({
		filename: 'Certicate.pdf',
		page: { margin: Margin.MEDIUM },
	});

	const getAllCertificatesForUserId = () => {
		setIsLoading(false);
		userCertificates.getUserCertificatesByUserId(user.id, response => {
			if (response.status === 'success') {
				setIsLoading(false);
				setCertificateData(response.data);
			} else {
				setCertificateData([]);
				setIsLoading(false);
			}
		});
	};

	const handleViewTemplate = certificate => {
		setSelectedCertificate(certificate);
		setViewingTemplate(true);
	};

	return (
		<div>
			<Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant={'h2'}>My Certificates</Typography>
			</Box>

			{!isLoading ? (
				<Grid
					container
					spacing={1}
				>
					{certificateData.length > 0 ? (
						certificateData.map((template, index) => (
							<Grid
								item
								xs={3}
							>
								<Card
									style={{
										width: '100%',
										display: viewingTemplate && selectedTemplate === index ? 'none' : '',
									}}
								>
									<Typography style={{ display: 'flex', justifyContent: 'center' }}>
										<WorkspacePremiumIcon
											style={{
												color: '#173f5b',
												width: '100px',
												height: '100px',
											}}
										/>
									</Typography>

									<b
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										{template.certificateName}
									</b>
									<Button
										onClick={() => {
											handleViewTemplate(template); // Set the selected certificate
										}}
										style={{ display: 'block', margin: 'auto' }}
									>
										View
									</Button>
								</Card>
							</Grid>
						))
					) : (
						<Stack
							direction='row'
							justifyContent='center'
							alignItems='center'
							spacing={2}
						>
							<Typography
								variant='h1'
								style={{ textAlign: 'center' }}
							>
								{' '}
								No certificates available{' '}
							</Typography>
						</Stack>
					)}
				</Grid>
			) : (
				<div className='loader'>
					<svg
						className='circular-loader'
						viewBox='25 25 50 50'
					>
						<circle
							className='loader-path'
							cx='50'
							cy='50'
							r='20'
						/>
					</svg>
				</div>
			)}

			{/* ViewUserCertificates modal */}
			<Dialog
				maxWidth
				TransitionComponent={Transition}
				keepMounted
				open={viewingTemplate}
				onClose={() => setViewingTemplate(false)}
			>
				<DialogTitle>
					<Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant={'h3'}>View Certificate</Typography>
						<Button
							startIcon={<DownloadIcon />}
							variant='contained'
							// onClick={handleDownloadCertificate}
							onClick={toPDF}
							style={{ width: '200px', height: '40px' }}
						>
							Download
						</Button>
					</Box>
				</DialogTitle>
				<DialogContent>
					{selectedCertificate && (
						<div ref={targetRef}>
							<ViewUserCertificates certificateData={selectedCertificate} />
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setViewingTemplate(false);
							setSelectedCertificate(null);
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default UserCertificates;
