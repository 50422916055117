import MarvelMindsApi, { appServerURLJava } from './MarvelMindsApi';
import appApi from '../../../../src/modules/appApi';
import axios from 'axios';

class Candidate {
	getCandidateByUserId = (id, callBack) => {
		return appApi({
			method: 'GET',
			url: `/api/candidate/user_id/${id}`,
		})
			.then(response => {
				callBack({ status: 'success', data: response.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log(error, 'error occurred in getting Candidate');
			});
	};
}

export default new Candidate();
