import Batches from 'app/mmEsoft/module/Batches';
import { useEffect, useState } from 'react';

const useGetAllCandidates = (batchId) => {
  const [studentList, setStudentList] = useState([]);
  const [studentLoading, setStudentLoading] = useState(false);

  const fetchCandidates = (batchId) => {
    setStudentLoading(true);
    Batches.getCandidates(batchId, (response) => {
      if (response.status === 'success') {
        const res = response.data.reverse();
        setStudentList(res);
        setStudentLoading(false);
      } else {
        setStudentLoading(false);
        console.error('Error while getting students by batch id');
      }
    });
  };

  useEffect(() => {
    if (batchId) {
      fetchCandidates(batchId);
    }
  }, [batchId]);

  return { studentList, studentLoading, fetchCandidates };
};

export default useGetAllCandidates;
