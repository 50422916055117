import React, { useState } from 'react';
import { TableCell, Card, TableRow, Typography } from '@mui/material';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

import questions from 'modules/questions';
import options from 'modules/options';
import HTMLRenderer from 'react-html-renderer';

const Question = props => {
  const Swal = useSwalWrapper();
  const { showDialog, hideDialog } = useJumboDialog();
  // React.useEffect(() => {
  //   getAllOptionsByQuestion(props.question.id);
  // }, [props.id]);

  const [optionData, setOptionData] = useState([{}]);
  const getAllQuestionsByLesson = id => {
    questions.getAllQuestionsByLesson(id, response => {
      if (response.status === 'success') {
        props.setQuestionData(response.data);
      } else {
        props.setQuestionData([]);
      }
    });
  };

  const handleDelete = () => {
    questions.deleteQuestion(props.question.id, response => {
      if (response.status === 'success') {
        getAllQuestionsByLesson(props.lessonId);

        Swal.fire({
          icon: 'success',
          title: 'Question has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting .',
        });
      }
    });
  };
  const getAllOptionsByQuestion = id => {
    options.getAllOptionsByQuestion(id, response => {
      if (response.status === 'success') {
        setOptionData(response.data);
      } else {
        setOptionData([]);
      }
    });
  };

  const handleEdit = () => {
    props?.openQuestionDialog(props?.question);
  };

  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this Question?',
          content: "You won't be able to recover this question later",
          onYes: () => handleDelete(),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit();
        break;
    }
  };

  return (
    <>
      <TableRow
        sx={{
          boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
          borderRadius: '12px',
          backgroundColor: 'rgb(255, 255, 255)',
          width: '100%',
          display: 'table',
        }}
      >
        <TableCell colSpan={2}>
          <Typography variant={'h6'} width="700px">
            <HTMLRenderer html={props.question.text} />
          </Typography>
        </TableCell>

        <TableCell>
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={handleItemAction}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default Question;

const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
