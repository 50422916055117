import React, { useState } from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import license from 'modules/license';
import AddLicense from "./AddLicense";
import AssignedLicense from './AssignedLicense';
const LicenseCell = props => {
  const Swal = useSwalWrapper();
 const { showDialog, hideDialog } = useJumboDialog();
  const [activeTab, setActiveTab] = useState(1);
  const [licenseDialog, setLicenseDialog] = React.useState({
    open: false,
    data: {
      licenseName: '',
      assignedLisences:'',
      purchasedQuantity:'',
      billingFrequency:'',
      renewsOn:'',
    //   expired:,
      plan:'',
    },
  });

 const { contact, getAllLicenses} = props;
 const { id,licenseName,assignedLisences , purchasedQuantity, billingFrequency, renewsOn, plan,expired } = contact;

 const [isDialogOpen, setIsDialogOpen] = React.useState({
    open: false,
    data: {
        username: '',
        userId:'',
    
    },
  });
  const deleteLicense = (deleteFunction, fetchParams) => {
    deleteFunction(fetchParams, response => {
      if (response.status === 'success') {
        props.getAllLicenses();
        Swal.fire({
          icon: 'success',
          title: 'License has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting license.',
        });
      }
    });
  };


  const handleDelete = () => {
  deleteLicense(license.deleteLicense,id);
    }
  

  const handleEdit = () => {
    setLicenseDialog({ ...licenseDialog, open: true });
  };

  const closeDialog = () => {
    setLicenseDialog({ ...licenseDialog, open: false });
  };
  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this License?',
          content: "You won't be able to recover this License later",
          onYes: () => handleDelete(),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit();
        break;
    }
  };
  const openDialogs = () => {
    setIsDialogOpen({...isDialogOpen,open:true});
  };

  const closeDialogs = () => {
    setIsDialogOpen(false);
  };
  return (
    <TableRow
      sx={{
        padding: '2px 2px 2px 10px',
        boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
        borderRadius: '12px',
        backgroundColor: ' rgb(255, 255, 255)',
      }}
    >
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
         <Typography variant={'h6'} mb={0}>
          {`${id? id:'_'}`}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
        <Typography variant={'h6'} mb={0}>
          {`${licenseName? licenseName:'_'}`}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
    
       <Typography variant={'h6'} mb={0}>
       {expired !== true ? (
          <a href="#" onClick={openDialogs}>
            {assignedLisences}
          </a>
        ) : (
        <>  
        {assignedLisences}
        </>
         )}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >

      <Typography variant={'h6'} mb={0}>
          {purchasedQuantity ? purchasedQuantity : '-'}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
        <Typography variant={'h6'} mb={0}>
          {billingFrequency ? billingFrequency : '-'}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
        <Typography variant={'h6'} mb={0}>
          {renewsOn ? renewsOn : '-'}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
        <Typography variant={'h6'} mb={0}>
          {plan ? plan : '-'}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
        <JumboDdMenu menuItems={menuItems} onClickCallback={handleItemAction} />
      </TableCell>

      {licenseDialog.open && (
        <AddLicense
          open={licenseDialog.open}
          close={closeDialog}
          data={licenseDialog.data}
          getAllLicenses={props.getAllLicenses}
          contact={props.contact}
        />
      )}
        {isDialogOpen.open && (
        <AssignedLicense
          open={isDialogOpen.open}
          close={closeDialogs}
          contact={props.contact}
        />
      )}
    </TableRow>
  );
};

export default LicenseCell;

const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
