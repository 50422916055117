import campaigns from "modules/campaigns";
import { useEffect, useState } from "react";

export const useGetAllCampaigns = () => {
  const [campaignList, setCampaignList] = useState([]);

  useEffect(() => {
    campaigns.getAllCampaigns((response) => {
      if (response.status === "success") setCampaignList(response.data);
      else console.log("error while getting all campaigns");
    });
  }, []);

  return { campaignList };
};
