import Lessons from "app/pages/lessons/Lessons";
import Page from "@jumbo/shared/Page";
import Exams from "app/pages/exams/Exams";
const myTrainingRoutes = [
  {
    path: "/lessons/lessons",
    element: <Page component={Lessons} />,
  },
  {
    path: "/exams/exams",
    element: <Page component={Exams} />,
  },
];
export default myTrainingRoutes;
