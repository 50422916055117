import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import code from '@mui/icons-material/Code';
import Div from '@jumbo/shared/Div';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import AddSkillsApi from 'app/mmEsoft/module/AddSkillsApi';
import Candidate from 'app/mmEsoft/module/Candidate';
import { MenuItem } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import EditSkills from './EditSkills';
import SettingItem from 'app/shared/MessagesDropdown/MessagesSetting/SettingItem';
const AddSkills = props => {
	const [open, setOpen] = React.useState(false);
	const [skills, setSkills] = useState([]);
	const { showDialog, hideDialog } = useJumboDialog();
	const [editOpen, setEditOpen] = useState(false);
	const [item, setItem] = useState();
	const [skillsData, setSkillsData] = React.useState({
		skill: '',
		level: '',
		experience: null,
		candidateId: props?.candidate?.id,
	});

	useEffect(() => {
		getAllSkills();
	}, []);

	function getAllSkills() {
		AddSkillsApi.getSkillsByCandidateId(props?.candidate?.id, response => {
			if (response.status === 'success') setSkills(response.data);
			else console.log('error while get all skills by cand. id');
		});
	}

	function handleDelete(id) {
		AddSkillsApi.deleteSkillsByCandidateId(id, response => {
			if (response.status === 'success') {
				Swal.fire({
					icon: 'success',
					title: 'Skill has been deleted successfully',
				});
				getAllSkills();
				hideDialog();
			} else {
				console.log('error at delete skills');
				hideDialog();
			}
		});
	}

	const handleConfirmationDelete = id => {
		showDialog({
			variant: 'confirm',
			title: 'Are you sure about delet skills?',
			content: "You won't be able to recover this project later",
			onYes: () => handleDelete(id),
			onNo: hideDialog,
		});
	};
	const handleAddSkills = () => {
		AddSkillsApi.Addskills(skillsData, response => {
			if (response.status === 'success') {
				getAllSkills();
			} else {
				console.error('Error saving skills');
				// Handle error cases
			}
			setOpen(false); // Close the dialog after saving
		});
	};

	const handleChange = event => {
		setSkillsData({
			...skillsData,
			[event.target.name]: event.target.value,
		});
	};

	const handleEdit = item => {
		setItem(item);
		setEditOpen(!editOpen);
	};

	return (
		<JumboCardQuick
			demoCode={code}
			wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
			style={{ marginTop: '10px', padding: '10px' }}
		>
			<Div>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					sx={{ marginTop: '10px', padding: '10px' }}
				>
					<Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
						Skill Details
					</Typography>
					<Button
						variant='contained'
						onClick={() => setOpen(true)}
					>
						Add Skills
					</Button>
				</Box>
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
				>
					<DialogTitle>Add Skills</DialogTitle>
					<DialogContent>
						<TextField
							autoFocus
							margin='dense'
							id='skill'
							name='skill'
							label='Skill'
							onChange={handleChange}
							type='text'
							fullWidth
						/>
						<FormControl
							sx={{ marginTop: '10px' }}
							fullWidth
						>
							<InputLabel id='skillLevelLabel'>Skill Level</InputLabel>
							<Select
								id='skillLevel'
								name='level'
								value={skillsData.level}
								onChange={handleChange}
								labelId='skillLevelLabel'
								label='Skill Level'
							>
								<MenuItem value='beginner'>Beginner</MenuItem>
								<MenuItem value='intermediate'>Intermediate</MenuItem>
								<MenuItem value='advanced'>Advanced</MenuItem>
							</Select>
						</FormControl>
						<TextField
							autoFocus
							margin='dense'
							id='experience'
							name='experience'
							label='Experience'
							onChange={handleChange}
							type='number'
							fullWidth
						/>
					</DialogContent>
					<DialogActions>
						<Button
							variant='outlined'
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
						<Button
							variant='outlined'
							onClick={handleAddSkills}
						>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			</Div>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Skills</TableCell>
						<TableCell>Skill Level</TableCell>
						<TableCell>Experience </TableCell>
						<TableCell>Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{skills?.map(item => (
						<TableRow key={item?.id}>
							<TableCell>{item.skill}</TableCell>
							<TableCell>{item.level}</TableCell>
							<TableCell>{item.experience}</TableCell>
							<TableCell>
								<Edit
									style={{
										cursor: 'pointer',
										marginRight: '10px',
										marginTop: '10px',
									}}
									onClick={() => handleEdit(item)}
								/>
								<Delete
									style={{ cursor: 'pointer' }}
									onClick={() => handleConfirmationDelete(item?.id)}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				<EditSkills
					openEdit={editOpen}
					setEditOpen={setEditOpen}
					item={item}
					getAllSkills={getAllSkills}
				/>
			</Table>
		</JumboCardQuick>
	);
};

export default AddSkills;
