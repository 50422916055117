import appApi from './appApi';

class Company {
  getcompanyById = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/company/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  company', error);
      });
  };
  addOrUpdateCompanyTheme = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/company/theme',
      headers: {
        'content-type': 'application/json', // Correct content type for JSON data
      },
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while adding theme', error);
        callBack({ status: 'error' });
      });
  };
  updateCompany = (id, data, callBack) => {
    appApi({
      method: 'PUT',
      url: '/api/company/' + id,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating company  ', error.message);
        callBack({ status: 'error' });
      });
  };
  getLogo = callBack => {
    return appApi({
      method: 'GET',
      url: `/public/get-logo`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  logo', error);
      });
  };
  addOrUpdateLogo = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/company/logo',
      headers: {
        'content-type': 'image/png',
      },
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while adding logo', error);
        callBack({ status: 'error' });
      });
  };
}
export default new Company();
