import { Box, Typography, Button } from "@mui/material";
import { useState , useEffect} from "react";
import campaigns from 'modules/campaigns';
import AddCampaignDialog from "./AddCampaignDialog";
import CampaignTable from "./CampaignTable";

const BatchCampaign = ({batchId}) => {
  const [campaignTableData, setCampaignTableData] = useState([]);
  const [open, setOpen] = useState(false);

  const getAllCampaigns = () => {
    campaigns.getAllAddedCampaignOfBatch(batchId, response => {
      if (response.status == 'success') {
        setCampaignTableData(response?.data);
      } else {
        console.log('Error While Fetching the Campagin List ');
      }
    });
  };

  useEffect(() => {
    getAllCampaigns();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h1" component="h2">
          Course
        </Typography>
        <Button onClick={() => setOpen(!open)} variant="contained" autoFocus>
          Add Course
        </Button>
        <AddCampaignDialog open={open} setOpen={setOpen} getAllCampaigns={getAllCampaigns} batchId={batchId} />
      </Box>
      <CampaignTable campaignTableData={campaignTableData} getAllCampaigns={getAllCampaigns} batchId={batchId}/>
    </>
  );
};

export default BatchCampaign;
