import React from 'react';
import Grid from '@mui/material/Grid';
import Contacts from './components/Contacts';
import Friends from './components/Friends';
import Photos from './components/Photos';
import { useSelector } from 'react-redux';

const UserProfileSidebar = ({ user ,userData}) => {

  return (
    // <Grid container spacing={3.75}>
    //   <Grid item xs={12} md={6} lg={12}>
        <Contacts userData={userData} user={user} />
    
    //   </Grid>
    // </Grid>
  );
};

export default UserProfileSidebar;
