import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select, TextField, Typography } from '@mui/material';

import { useState } from 'react';
import ContactModule from 'app/mmEsoft/module/ContactModule';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

function AddLabelContact() {
  const Swal = useSwalWrapper();
  const [open, setOpen] = useState(false);
  const [labelData, setLabelData] = useState({
    code: '',
    description: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setLabelData({ ...labelData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await ContactModule.createLabel(labelData, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Label created Successfully.',
        });
        handleClose();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Create Label failed',
        });
      }
    });
  };

  return (
    <>
      <Button
        variant='contained'
        size='large'
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      ></Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'>
          <Typography variant='h3'> Create Label </Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography>Label</Typography>
            <TextField
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              name='code'
              value={labelData.label}
              onChange={handleChange}
              fullWidth
            />
            <Typography>Description</Typography>
            <TextField
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              name='description'
              value={labelData.description}
              onChange={handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='outlined'>
              Cancel
            </Button>
            <Button type='submit' variant='contained'>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
export default AddLabelContact;
