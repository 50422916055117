import auth from '../../../modules/auth';
import Swal from 'sweetalert2';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const UPDATE_USER = 'UPDATE_USER';

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});
export const loginFailure = () => ({
  type: LOGIN_FAILURE,
});
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});
export const onEditUser = (user) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER, payload: user });
  };
};
export const authenticateUser = (user) => (dispatch) => {
  dispatch(loginRequest());
  auth.authenticate(user, (response) => {
    if (response.status === 'success') {
      dispatch({ type: LOGIN_SUCCESS, payload: response.data });
    } else if (response.status === 'error') {
      dispatch({ type: LOGIN_FAILURE });
      Swal.fire({
        toast: true,
        icon: 'error',
        title: 'username , email or passowrd is incorrect',
        width: '20rem',
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  });
};

export const authenticateSSOUser = (tokenData) => (dispatch) => {
  debugger;
  dispatch(loginRequest());
  dispatch(loginSuccess(tokenData));
};

export const Logout = () => (dispatch) => {
  dispatch({ type: LOGIN_FAILURE });
};
