import React from 'react';
import { Navigate } from 'react-router-dom';
import dashboardRoutes from './dashboardRoutes';
import listViewRoutes from './listViewRoutes';
import gridViewRoutes from './gridViewRoutes';
import Login2 from '../pages/auth-pages/login2';
import Signup2 from '../pages/auth-pages/signup2';
import Page from '@jumbo/shared/Page';
import myTrainingRoutes from './myTrainingRoute';
import campianRoutes from './campainRoutes';
import certificatesRoutes from './certificatesRoutes';
import clientRoutes from './clientRoutes';
import ForgotPassword from 'app/pages/auth-pages/forgot-password/ForgotPassword';
import ResetPassword from 'app/pages/auth-pages/reset-password/ResetPassword';
import settingRoutes from './settingRoutes';
import UserProfileRouts from './userProfileRouts';
import viewCertificatesRoutes from './viewCertificatesRoutes';
import termsAndConditions from './termsAndConditionsRoutes';
import phishingCampaignsRoutes from './phishingCampaignsRoutes';
import phishingTemplateRoutes from './phishingTemplateRoutes';
import MasterAdminLogin from 'app/pages/auth-pages/MasterAdminLogin';
import smsTemplateRoutes from './smsTemplateRoutes';
import MasterAdminDashboard from 'app/pages/auth-pages/MasterAdminDashboard';
import userCertificatesRoutes from './userCertificatesRoutes';
import assignmentRoutes from './assignmentRoutes';
import attendenceRoutes from './attendenceRoutes';
import contactsRoutes from './contactsRoutes';
import meetsRoutes from './meetsRoutes';
// import userBatchesRoutes from 'app/mmEsoft/routes/userBatchesRoutes';

import reportsRoutes from './reportsRouter';
import resultRoute from './resultRoute';
/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated admin users
 **/
const routesForAdmin = [
  {
    path: '/',
    element: <Navigate to={'/dashboards/dashboard'} />,
  },
  ...dashboardRoutes,
  ...listViewRoutes,
  ...gridViewRoutes,
  ...myTrainingRoutes,
  ...campianRoutes,
  ...certificatesRoutes,
  ...reportsRoutes,
  ...settingRoutes,
  ...assignmentRoutes,
  ...attendenceRoutes,
  ...contactsRoutes,
  ...meetsRoutes,
  ...UserProfileRouts,
  ...viewCertificatesRoutes,
  ...termsAndConditions,
  ...phishingCampaignsRoutes,
  ...phishingTemplateRoutes,
  ...resultRoute,
  ...smsTemplateRoutes,
];

/**
 routes only accessible to authenticated non-admin users
 **/
const routesForUser = [
  {
    path: '/',
    element: <Navigate to={'/dashboards/user'} />,
  },
  ...clientRoutes,
  ...userCertificatesRoutes,
];

const masterAdminRoutes = [
  {
    path: '/master-admin/otp',
    element: (
      <Page
        component={MasterAdminLogin}
        layout={'solo-page'}
        disableSmLogin={true}
      />
    ),
  },

  {
    path: '/master-admin/dashboard',
    element: <Page component={MasterAdminDashboard} />,
  },
];

const routesForMasterAdmin = [
  {
    path: '/',
    element: <Navigate to={'/master-admin/otp'} />,
  },
  {
    path: '/',
    element: <Navigate to={'/master-admin/dashboard'} />,
  },
  ...masterAdminRoutes,
];

const routesForNotAuthenticatedOnly = [
  {
    path: '/user/login',
    element: (
      <Page component={Login2} layout={'solo-page'} disableSmLogin={true} />
    ),
  },
  {
    path: '/user/signup',
    element: (
      <Page component={Signup2} layout={'solo-page'} disableSmLogin={true} />
    ),
  },
  {
    path: '/user/forgot-password',
    element: (
      <Page
        component={ForgotPassword}
        layout={'solo-page'}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: '/user/reset-password',
    element: (
      <Page
        component={ResetPassword}
        layout={'solo-page'}
        disableSmLogin={true}
      />
    ),
  },
];

const routes = [
  ...routesForPublic,
  ...routesForAdmin,
  ...routesForUser,
  ...routesForMasterAdmin,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  masterAdminRoutes,
  routesForAdmin,
  routesForUser,
};
