import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableRow,
  Typography,
  Dialog,
  DialogContent,
  Button,
} from '@mui/material';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import videos from 'modules/videos';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import '../../pages/videos/Video.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReactPlayer from 'react-player';
import userVideos from 'modules/userVideos';
import userProgress from 'modules/userProgress';
import { useSelector } from 'react-redux';
import auth from 'modules/auth';
import { appServerURL } from 'modules/appApi';
import { object } from 'prop-types';

const UserVideos = (props) => {
  let videoRef = React.useRef();

  let prevPlayed = React.useRef(null);
  const Swal = useSwalWrapper();
  const { user } = useSelector(({ login }) => login);

  const { showDialog, hideDialog } = useJumboDialog();
  const [newQuestions, setNewQuestions] = useState([]);
  const [showMcq, setShowMcq] = useState({ show: false, index: null });
  const [answer, setAnswer] = useState(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [watchVideoAgain, setWatchVideoAgain] = useState(false);
  const [userVideoId, setUserVideoId] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);

  const [videoDialog, setVideoDialog] = React.useState({
    open: false,
    data: {
      title: '',
      title1: '',
    },
  });
  const [multiSelect, setMultiSelect] = useState(false);
  const [checkedStates, setCheckedStates] = useState([
    false,
    false,
    false,
    false,
  ]);

  let [playing, setPlaying] = useState(false);
  let [playedSeconds, setPlayedSeconds] = useState(0);

  const [duration, setDuration] = React.useState(0);
  const [durationn, setDurationn] = React.useState(0);

  const { myBatch } = useSelector(({ batchesReducer }) => batchesReducer);
  let pip = false;
  let controls = true;
  let light = false;
  let volume = 0.8;
  let muted = false;

  let playbackRate = 1.0;
  let loop = false;
  const [videoSrc, setVideoSrc] = useState();

  useEffect(() => {
    // if (props.video?.url) {
    //   setVideoSrc(
    //     appServerURL +
    //       'api/video/' +
    //       props?.video?.url +
    //       '?auth=' +
    //       auth.getToken().token
    //   );
    // }
    if (props.video?.url) {
      getVideoURL(props?.video?.url);
    }
    setIsVideoWatched(props?.video?.userVideoData?.isWatched);
    let data = props?.video?.interaction
      ? JSON.parse(props.video.interaction)
      : null;
    if (data && data.layers) {
      const newQuestionss = data.layers.map((l) => ({
        timeStamp: l.timeStamp.split(':')[1],
        question: l.config.question,
        options: l.config.answers,
        correctAnswer: l.config.correctAnswer,
        skippable: l.skippable,
        type: l.type,
      }));
      setNewQuestions(newQuestionss);
    } else {
      setNewQuestions([]);
    }

    getVideoDuration(props.userId, props?.lesson?.lessonId);

    return () => {
      videoRef = null;
    };
  }, [props?.video]);

  function getVideoDuration(userId, lessonId) {
    videos.getVideoDuration(userId, lessonId, (response) => {
      if (response.status === 'success') {
        const duration = response.data[0]?.duration;
        console.log({ response: duration })
        setUserVideoId(response.data[0]?.id);
        setDurationn(duration);
        setDuration(duration);

        if (!isNaN(duration)) {
          videoRef.current?.seekTo(Number(duration), 'seconds');
        }
      } else {
        console.log('ERROR AT GET VIDEO DURATION');
      }
    });
  }

  React.useEffect(() => {
    updateVideoWatchedStatus();
  }, [durationn, props]);

  const updateVideoWatchedStatus = async () => {
    if (durationn > 0 && props?.lesson && !isVideoWatched) {
      let data = {
        videoId: props?.video?.id,
        userId: props?.userId,
        duration: durationn,
        lessonId: props?.lesson?.lessonId,
        isWatched: durationn >= duration ? true : false,
      };
      if (data.isWatched) {
        setIsVideoWatched(true);
      }

      await AddUserVideos(data);
    }
  };

  const updateProgress = (_userVideoData) => {
    let count = 0;
    _userVideoData?.map((vid) => {
      if (vid.isWatched) {
        count = 1;
      }
    });
    if (count == 1) {
      let upData = props?.userProgressData?.find(
        (u) => u.lessonId == props?.lesson?.lessonId
      );
      if (!upData?.videoStatus) {
        upData.videoStatus = true;
        upData.batchId = myBatch;
        updateUserProgress(upData?.id, upData);
      }
    }
  };

  const handlePlay = () => {
    setPlaying(true);
    let upData = props?.userProgressData?.find(
      (u) => u.lessonId == props?.lesson?.lessonId
    );
    if (!upData?.videoStatus) {
      upData.videoStatus = true;
      upData.batchId = myBatch;
      updateUserProgress(upData?.id, upData);
    }
  };

  const handleSeek = (e) => {
    if (props?.lesson?.seek == false) {
      let delta =
        videoRef?.current?.getCurrentTime() - prevPlayed?.current?.playedSeconds;
      if (delta > 0.01) {
        videoRef?.current?.seekTo(prevPlayed.current.playedSeconds);
      }
    }
  };

  const AddUserVideos = (data) => {
    userVideos.addUserVideos(data, (response) => {
      if (response.status === 'success') {
        getUserVideoByLessonId(props?.lesson?.lessonId);
      }
    });
  };
  const getVideoURL = (videoName, response) => {
    videos.getVideoURL(videoName, (response) => {
      if (response.status === 'success') {
        setVideoSrc(response.data.url);
      } else {
        setVideoSrc([]);
      }
    });
  };

  const updateUserProgress = (id, data) => {
    userProgress.updateUserProgressById(id, data, (response) => {
      if (response.status === 'success') {
      } else {
        console.log('error');
      }
    });
  };

  const getUserVideoByLessonId = (lessonId, _videoData) => {
    userVideos.getUserVideoByLessonIdAndUserId(
      lessonId,
      user.id,
      (response) => {
        if (response.status === 'success') {
          updateProgress(response.data);
          handleVideoChange(_videoData);
        }
      }
    );
  };

  const handleVideoChange = (_videoData) => {
    // if (props?.userVideoData?.length == 0) {
    //   if (_videoData?.length > 0) {
    //     props?.handleListItemClickCallBack(1, props?.lesson, _videoData[0]);
    //     props?.setVideo(_videoData[0]);
    //   }
    // } else {
    //   for (let i = 0; i < props.userVideoData?.length; i++) {.j




    //     const uvData = props.userVideoData[i];
    //     let video = _videoData?.filter(vData => vData.id == uvData.videoId);
    //     if (video?.length > 0) {
    //       props?.setVideo(_videoData[props?.video?.id]);
    //       props?.handleListItemClickCallBack(
    //         1,
    //         props?.lesson,
    //         _videoData[props?.video?.id]
    //       );
    //     } else {
    //       let indexx = props.videoData?.indexOf(_videoData[props?.video?.id]);
    //       props?.setVideo(_videoData[indexx + 1]);
    //       props?.handleListItemClickCallBack(
    //         1,
    //         props?.lesson,
    //         _videoData[indexx + 1]
    //       );
    //     }
    //   }
    // }
  };

  const handleWatchVideoAgain = () => {
    setWatchVideoAgain(true);
    let upData = props?.userProgressData?.find(
      (u) => u.lessonId === props?.lesson?.lessonId
    );
    if (upData && upData?.videoStatus) {
      upData.videoStatus = true;
      updateUserProgress(upData?.id, upData);
    }
  };


  const handleDuration = (e) => {
    setDuration(e);
  };

  function dialogClick(e) {
    if (e.target.classList[0] != 'MuiDialogContent-root') {
      return;
    }
  }

  let newQuestionss = [];

  const onVideoTimeUpdate = (state) => {
    prevPlayed.current = state;

    // newQuestions?.map((nq, index) => {
    //   let timeStamp = +nq?.timeStamp;
    //   if (
    //     state.playedSeconds >= timeStamp &&
    //     state.playedSeconds <= timeStamp + 1 &&
    //     !nq.isViewed
    //   ) {
    //     newQuestions[index].isViewed = true;
    //     setPlaying(false);
    //     pauseVid(index);
    //     if (nq.type != 'single choice question') {
    //       setMultiSelect(true);
    //     } else {
    //       setMultiSelect(false);
    //     }
    //   }
    // });

    // Update userVideo duration after every 5 seconds or when video is watched as much as loaded
    if (
      parseInt(state.playedSeconds) % 60 === 0 &&
      parseInt(state.playedSeconds) >= 60
    ) {
      setPlayedSeconds(state.playedSeconds);
      setDurationn(state.playedSeconds);
      userVideos.updateUserVideosById(
        userVideoId,
        { duration: state.playedSeconds },
        (response) => { }
      );
    }

    prevPlayed.current = state;
    let duration = playedSeconds != 0 ? playedSeconds : state.playedSeconds;

    newQuestions?.map((nq, index) => {
      let timeStamp = +nq?.timeStamp;
      if (duration >= timeStamp && duration <= timeStamp + 1 && !nq.isViewed) {
        newQuestions[index].isViewed = true;
        setPlaying(false);
        pauseVid(index);
        if (nq.type != 'single choice question') {
          setMultiSelect(true);
        } else {
          setMultiSelect(false);
        }
      }
    });
  };

  const onVideoSeeking = (time) => {
    // if (time > newTime) playerr.currentTime(0);
  };
  const onVideoSeeked = (from, to) => {
    // newTime = from;
  };
  function onVideoEnd() { }

  const handleButtonClick = (index) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = !newCheckedStates[index];
    setCheckedStates(newCheckedStates);

    if (!multiSelect) {
      // Deselect all other options except the clicked one
      for (let i = 0; i < newCheckedStates.length; i++) {
        if (i !== index) {
          newCheckedStates[i] = false;
        }
      }
    }
  };
  const handleSubmit = () => {
    let ans = null;
    let count = 0;
    if (
      newQuestions[showMcq.index]?.correctAnswer ===
      checkedStates.indexOf(true) + 1
    ) {
      ans = true;
    } else if (newQuestions[showMcq.index]?.correctAnswer.length > 0) {
      newQuestions[showMcq.index]?.correctAnswer.map((c) => {
        let newVal = checkedStates.indexOf(true) + 1;
        if (c === newVal) {
          count = count + 1;
          setCheckedStates(
            ...checkedStates,
            (checkedStates[newVal - 1] = false)
          );
        }
      });
      if (newQuestions[showMcq.index]?.correctAnswer.length === count) {
        ans = true;
      } else {
        ans = false;

        newQuestions[showMcq.index].isViewed = false;
      }
    }

    if (ans == true) {
      setTimeout(() => {
        setShowMcq({ show: false });
        setPlaying(true);
        setCheckedStates([false, false, false, false]);
        setAnswer(null);
      }, 1500);
    }
    setAnswer(ans);
  };

  function seeVideoAgain() {
    videoRef.current.seekTo(0);
    setShowMcq({ show: false });
    setPlaying(true);
    setCheckedStates([false, false, false, false]);
    setAnswer(null);
  }
  const buttonStyles = {
    marginRight: '1rem',
    width: '150px',
  };
  function pauseVid(index) {
    setShowMcq({ index: index, show: true });
  }
  const handleCloseMcq = () => {
    setShowMcq({ show: false });
  };

  const handleDelete = () => {
    // Remove this function
    videos.deleteVideo(props.video.id, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Video has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting video.',
        });
      }
    });
  };

  const handleEdit = () => {
    setVideoDialog({ ...videoDialog, data: props?.video, open: true });
  };

  const closeDialog = () => {
    setVideoDialog({ ...videoDialog, open: false });
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this Video?',
          content: "You won't be able to recover this video later",
          onYes: () => handleDelete(),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit();
        break;
    }
  };

  const handleStart = () => {
    videoRef?.current?.seekTo(duration, 'seconds');
    // videoRef.current?.onProgress.playedSeconds = duration
  };

  // const onPlayerReady = () => {
  //   console.log('Video play duration ' + duration);
  //   videoRef.current?.seekTo(duration, 'seconds');
  //   // videoRef.current?.onProgress.playedSeconds = duration
  // };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     console.log(props, 'propsUser');
  
  //     let upData;
  
  //     if (props) {
  //       upData = props?.userProgressData?.find(
  //         (u) => u?.lessonId === props?.lesson?.lessonId
  //       );
  //       console.log(upData, 'updata');
  //     }
  
  //     if (upData !== undefined) {
  //       if (!upData?.videoStatus) {
  //         upData.videoStatus = true;
  //         upData.batchId = myBatch; // Ensure `myBatch` is defined in scope
  //         try {
  //           await updateUserProgress(upData?.id, upData); // Await the promise if `updateUserProgress` is async
  //         } catch (error) {
  //           console.error('Failed to update user progress:', error);
  //         }
  //       }
  //     }
  //   };
  
  //   fetchData();
  // }, [props, myBatch, updateUserProgress]);
  
  useEffect(() => {
    // console.log(props,'propsUser');
    
    let upData
    //  console.log(typeof(props),"pepepep");
     
      if(typeof(props)=="object") {
        upData = props?.userProgressData?.find(
        (u) => u?.lessonId == props?.lesson?.lessonId
      );
      // console.log(upData,"updata");
      
    }

  
    if(upData!=undefined){
        if (!upData?.videoStatus) {
          upData.videoStatus = true;
          upData.batchId = myBatch;
          updateUserProgress(upData.id, upData);
        }
      
    } else {
      console.error("userProgressData is not an array or is undefined.");
    }
  }, [props]);
  
  return (
    <>
      {isVideoWatched && !watchVideoAgain ? (
        <div style={{ minHeight: '250px', maxHeight: '350px' }}>
          <h3 style={{ marginTop: '10%' }}>
            <center>You have successfully watched the video</center>
          </h3>
          <center>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleWatchVideoAgain(props?.videoData)}
              sx={buttonStyles}
              fullWidth
            >
              Watch Video Again
            </Button>
          </center>
        </div>
      ) : (
        <div>
          <div>
            <TableRow
              sx={{
                boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                borderRadius: '12px',
                backgroundColor: 'rgb(255, 255, 255)',
                width: '100%',
                display: 'table',
              }}
            >
              <TableCell colSpan={2}>
                <div>
                  <Typography variant='h3' sx={{ ml: 1 }}>
                    {props?.video?.title
                      ? props?.video?.title
                      : props?.video?.title1}
                  </Typography>
                  {/* <span>isWatched :</span>{' '}
                  {props?.video?.userVideoData?.isWatched ? 'TRUE' : 'FALSE'}
                  <br />
                  <span>lessonId :</span>{' '}
                  {props?.video?.userVideoData?.lessonId}
                  <br /> */}
                  <ReactPlayer
                    ref={videoRef}
                    // Disable download button
                    config={{
                      file: { attributes: { controlsList: 'nodownload' } },
                    }}
                    className='react-player'
                    width='100%'
                    height={props.flag ? '300px' : '300px'}
                    url={videoSrc}
                    // pip={pip}
                    // playing={playing}
                    controls={controls}
                    // // controls={props.controls ? true : false}
                    // light={light}
                    // loop={loop}
                    // playbackRate={playbackRate}
                    // volume={volume}
                    // muted={muted}
                    // onReady={onPlayerReady}
                    onStart={handleStart}
                    onSeek={handleSeek}
                    onProgress={onVideoTimeUpdate}
                    // onDuration={(e) => handleDuration(e)}
                    onPlay={handlePlay}
                  // onPause={(e) => setPlaying(false)}
                  // onEnded={(e) => onVideoEnd(e)}
                  />
                </div>
              </TableCell>
              {props.controls && !props.dataVideo ? (
                <JumboDdMenu
                  icon={<MoreVertIcon />}
                  menuItems={menuItems}
                  onClickCallback={handleItemAction}
                />
              ) : (
                ''
              )}
            </TableRow>
          </div>
        </div>
      )}

      <Dialog
        open={showMcq.show}
        onClose={handleCloseMcq}
        onClick={dialogClick}
        maxWidth='sm'
        fullWidth
      >
        <DialogContent>
          <Typography variant='h3'>
            {newQuestions[showMcq.index]?.question}
          </Typography>
          <br />

          {newQuestions[showMcq.index]?.options?.map((label, index) => (
            <div>
              <Button
                key={index}
                sx={{
                  border: '2px solid #ccc',
                  borderRadius: '7px',
                  padding: '5px 10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: checkedStates[index]
                    ? 'lightgrey'
                    : 'transparent',
                  cursor: 'pointer',
                }}
                fullWidth
                onClick={() => handleButtonClick(index)}
              >
                {newQuestions[showMcq.index]?.type == 'single choice question'
                  ? label
                  : label.name}
                {multiSelect ? (
                  <>
                    <input
                      type='checkbox'
                      checked={checkedStates[index]}
                      readOnly
                    />
                  </>
                ) : (
                  <>
                    <input
                      type='radio'
                      name={`radio_${index}`} // Use a unique name for each radio button group
                      checked={checkedStates[index]}
                      readOnly
                    />
                  </>
                )}
              </Button>
              <br />
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              justifyContent:
                answer == false || answer ? 'space-between' : 'flex-end',
              alignItems: 'center',
            }}
          >
            {answer == true && <h3 style={{ color: 'green' }}>CORRECT</h3>}
            {answer == false && (
              <div>
                <h3 style={{ color: 'red' }}>INCORRECT</h3>
              </div>
            )}
            {newQuestions[showMcq.index]?.skippable == true &&
              answer == null && (
                <div>
                  <Button
                    variant='contained'
                    sx={{
                      border: '2px solid #ccc',
                      borderRadius: '7px',
                      display: 'flex',
                      width: '110px',
                    }}
                    onClick={() => {
                      setShowMcq({ show: false });
                      setPlaying(true);
                      setCheckedStates([false, false, false, false]);
                      setAnswer(null);
                    }}
                  >
                    Skip
                  </Button>
                </div>
              )}
            &nbsp; &nbsp;
            {answer == null && (
              <Button
                sx={{
                  border: '2px solid #ccc',
                  borderRadius: '7px',
                  display: 'flex',
                  width: '130px',
                }}
                // onClick={handleSubmit}
                onClick={() => {
                  if (props.flag) {
                    setShowMcq({ show: false });
                    setPlaying(true);
                    setCheckedStates([false, false, false, false]);
                    setAnswer(null);
                  } else {
                    // Call the handleSubmit logic when props.flag is false
                    handleSubmit();
                  }
                }}
              >
                Submit
              </Button>
            )}
            &nbsp; &nbsp;
            {answer == false && (
              <Button
                variant='contained'
                sx={{
                  border: '2px solid #ccc',
                  borderRadius: '7px',
                  width: '180px',
                  marginLeft: '20px',
                }}
                onClick={(e) => seeVideoAgain()}
              >
                See Video Again
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserVideos;

// TODO: Remove this
const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
