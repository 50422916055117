import appApi from './appApi';

class Options {
  getAllOptionsByQuestion = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/options/question/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Option', error);
      });
  };
  addOption = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/options/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  Option', error);
      });
  };
  updateOption = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/options/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating  Option', error);
      });
  };
  deleteOption = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: '/api/options/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while deleting  question', error);
      });
  };
}
export default new Options();
