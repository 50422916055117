import appApi from './appApi';

class SmsTemplate {
  addSmsTemplate = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/sms_template/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  sms Template', error);
      });
  };
  getAllSmsTemplates = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/sms_template/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  sms template', error);
      });
  };

  updateSmsTemplate = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/sms_template/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating sms template', error);
      });
  };
  deleteSmsTemplate = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: '/api/sms_template/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while deleting sms template', error);
      });
  };
}

export default new SmsTemplate();
