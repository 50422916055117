import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import campaigns from 'modules/campaigns';
import lessons from 'modules/lessons';
import { Autocomplete, Box, TextField } from '@mui/material';

const AddLessons = props => {
  const Swal = useSwalWrapper();
  const [lessonData, setLessonData] = React.useState([]);
  let id;
  const validationSchema = yup.object().shape({
    lessonName: yup.string().required('lesson is required'),
  });
  React.useEffect(() => {
    getAllLessons();
  }, []);
  const getAllLessons = () => {
    lessons.getAllLessons(response => {
      if (response.status === 'success') {
        setLessonData(response.data);
      } else {
        setLessonData([]);
      }
    });
  };

  const campaignLesson = data => {
    let campaignData = { ...data, campaignId: props.campaignId, lessonId: id };
    if (props.data.id) {
      campaigns.updateCampignByLessonId(
        props.data.id,
        campaignData,
        response => {
          if (response.status === 'success') {
            props.getAllCampaignsLessons();
            props.close();
            Swal.fire({
              icon: 'success',
              title: 'Lesson has been Updated successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'there is an issue While updating Lesson',
            });
          }
        }
      );
    } else {
      campaigns.addLessonsByCampaignId(campaignData, response => {
        if (response.status === 'success') {
          props.getAllCampaignsLessons();
          props.close();
          Swal.fire({
            icon: 'success',
            title: 'Lesson has been added successfully.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'thsere is an issue While adding Lesson',
          });
        }
      });
    }
  };

  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close} maxWidth='lg'>
        <DialogTitle id='alert-dialog-title' textAlign='center'>
          Add Lesson
        </DialogTitle>
        <Formik
          validateOnChange={true}
          initialValues={{ ...props.data }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            campaignLesson(data);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
              <DialogContent>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} md={6} sm={6}>
                    <Autocomplete
                      id='lessonName'
                      name='lessonName'
                      options={lessonData}
                      onChange={(event, newValue) => {
                        setFieldValue('lessonName', newValue?.title);
                        id = newValue?.id;
                      }}
                      getOptionLabel={option =>
                        typeof option.title == 'string' && option
                          ? `${option.title} `
                          : option
                      }
                      value={values.lessonName}
                      renderOption={(props, option) => {
                        return (
                          <>
                            <div {...props} key={option.id}>
                              {`${option.title}`}
                            </div>
                          </>
                        );
                      }}
                      renderInput={params => (
                        <JumboTextField
                          name='lessonName'
                          label='Lesson*'
                          {...params}
                        />
                      )}
                    />

                    {/* <JumboTextField
                      fullWidth
                      select
                      name='lessonName'
                      label='Lesson'
                      value={`${values.lessonId}-${values.lessonName}`}
                    >
                      {lessonData.map(option => (
                        <MenuItem
                          key={option.id}
                          value={`${option.id}-${option.title}`}
                        >
                          {option.title}
                        </MenuItem>
                      ))}
                    </JumboTextField> */}
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <JumboTextField
                      fullWidth
                      name='maxQuestions'
                      label='Max Questions'
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event =>
                            setFieldValue('seek', event.target.checked)
                          }
                          checked={values.seek}
                        />
                      }
                      label='Seek'
                      name='seek'
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event =>
                            setFieldValue('policy', event.target.checked)
                          }
                          checked={values.policy}
                        />
                      }
                      label='Policy'
                      name='policy'
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event =>
                            setFieldValue('quiz', event.target.checked)
                          }
                          checked={values.quiz}
                        />
                      }
                      label='Quiz'
                      name='quiz'
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant='contained' type='submit' autoFocus>
                  {props.data.id ? 'Update' : 'Add'}
                </Button>
                <Button variant='contained' onClick={props.close}>
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddLessons;
