import React from 'react';
import {
  Box,
  Button,
  Typography,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import AddCampaignDialog from './AddCampaignDialog';
import campaigns from 'modules/campaigns';
import Div from '@jumbo/shared/Div';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CampaignDetails from './CampaignDetails';
import exams from 'modules/exams';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder/NoDataPlaceholder';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import dataAndTimeFormatter from 'modules/dataAndTimeFormatter';
import { useTranslation } from 'react-i18next';
const MyCampains = props => {
  const Swal = useSwalWrapper();
  const { t } = useTranslation();
  const [userDialog, setUserDialog] = React.useState({
    open: false,
    data: {
      title1: '',
      title2: '',
      successPercentage: 0,
      startDate: new Date().toLocaleDateString('en-CA'),
      dueDate: new Date().toLocaleDateString('en-CA'),
      // examId: null,
      // quizType: '',
      // quizStyle: '',
      // seek: '',
      // player: '',
      // randomQuestions: false,
    },
  });
  const [userList, setUserList] = React.useState([]);
  const [isLoading, setIsloading] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [examsData, setExamsData] = React.useState([]);
  const { searchReslts } = useJumboAuth();
  const { searchTerm } = useJumboAuth();
  React.useEffect(() => {
    if (searchReslts.length > 0) {
      setUserList(searchReslts);
    } else if (searchTerm) {
      // If searchReslts is empty and searchTerm is provided, show "No results found"
      setUserList([]);
    } else {
      getAllCampaigns();
      getAllExams();
    }
  }, [searchReslts]);

  const closeDialog = () => {
    setUserDialog({ ...userDialog, open: false });
  };
  const getAllCampaigns = () => {
    campaigns.getAllCampaigns(response => {
      if (response.status === 'success') {
        setUserList(response.data);
        setIsloading(false);
      } else {
        setUserList([]);
        setIsloading(false);
      }
    });
  };
  const getAllExams = () => {
    exams.getAllExams(response => {
      if (response.status === 'success') {
        setExamsData(response.data);
      } else {
        setExamsData([]);
      }
    });
  };
  const openDialog = () => {
    setUserDialog({ ...userDialog, open: true });
  };
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'} mt={1}>
          {/* Campaigns */}
          {t('pages.title.campaigns')}
        </Typography>
        <Box>
          <Button variant="contained" onClick={() => openDialog()}>
            Add Course
          </Button>
        </Box>
      </Box>

      {isLoading ? (
        <div className="loader" style={{ marginBottom: '20%' }}>
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : userList?.length <= 0 && !isLoading ? (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      ) : (
        userList.map((item, index) => (
          <Card sx={{ mb: 1, boxShadow: 1 }}>
            <Accordion
              expanded={expanded === 'panel' + index}
              onChange={handleChange('panel' + index)}
              square
              sx={{ borderRadius: 2 }}
              key={index}
            >
              <AccordionSummary
                expandIcon={
                  expanded === 'panel' + index ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Div
                  sx={{
                    width: { xs: 'auto', lg: '26%' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}
                >
                  <Typography>{item?.title ? item?.title : '-'}</Typography>
                </Div>
                <Div
                  sx={{
                    width: { xs: 'auto', lg: '26%' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}
                >
                  <Typography sx={{ color: 'text.secondary' }}>
                    {item?.description ? item?.description : '-'}
                  </Typography>
                </Div>
                <Div
                  sx={{
                    width: { xs: 'auto', lg: '26%' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}
                >
                  <Typography sx={{ color: 'text.secondary' }}>
                    {dataAndTimeFormatter.formatDateToCustomFormat(
                      item?.startDate
                    )}
                  </Typography>
                </Div>
                <Div
                  sx={{
                    width: { xs: 'auto', lg: '26%' },
                    flexShrink: 0,
                    px: 1,
                    flex: { xs: '1', lg: '0 1 auto' },
                  }}
                >
                  <Typography sx={{ color: 'text.secondary' }}>
                    {dataAndTimeFormatter.formatDateToCustomFormat(
                      item?.dueDate
                    )}
                  </Typography>
                </Div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  borderTop: 1,
                  borderColor: 'divider',
                  p: theme => theme.spacing(2, 2, 2, 8.25),
                }}
              >
                <CampaignDetails
                  campaign={item}
                  getAllCampaigns={getAllCampaigns}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  Swal={Swal}
                  examsData={examsData}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
        ))
      )}
      {userDialog.open && (
        <AddCampaignDialog
          open={userDialog.open}
          close={closeDialog}
          getAllCampaigns={getAllCampaigns}
          data={userDialog.data}
          // examsData={examsData}
        />
      )}
    </React.Fragment>
  );
};

export default MyCampains;