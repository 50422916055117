import Cookies from 'js-cookie';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  UPDATE_USER,
} from '../actions/login';

const initialAuthData = Cookies.get('authData');
const authData = initialAuthData
  ? JSON.parse(initialAuthData)
  : JSON.parse(localStorage.getItem('authData'));
const initialState = {
  isAuthenticated: !!authData?.token,
  token: authData?.token,
  user: authData?.user,
  isLoading: false,
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      Cookies.set('authData', JSON.stringify(action.payload), {
        expires: 1,
      });
      return {
        ...state,
        isAuthenticated: true,
        user: action?.payload?.user,
        token: action?.payload?.token,
        isLoading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action?.payload,
      };
    case LOGIN_FAILURE:
      Cookies.remove('authData');
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default login;
