import React, { useEffect, useState } from 'react';
import { alpha, Grid, List, ListItemText, Paper, Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Div from '@jumbo/shared/Div';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import videos from 'modules/videos';
import questions from 'modules/questions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCampaigns } from 'app/redux/actions/userCampaigns';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Video from 'app/pages/videos/Video';
import styled from '@emotion/styled';
const VideoItem1 = ({ item ,onVideoSelect}) => {
  const { theme } = useJumboTheme();
  const images = item?.images;
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide({ currentSlide: index });
    }
  }; 
  const dispatch = useDispatch();
  const [libraryList, setLibraryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [video, setVideo] = useState(null);
  const { user } = useSelector(({ login }) => login);
  const { campaignList } = useSelector(
    ({ campaignsReducer }) => campaignsReducer
  );
  useEffect(() => {
    // When campaignList changes, fetch videos for all campaigns
    if (campaignList.length > 0) {
      getVideosByCampaignIds();
    }
  }, [campaignList]);
  const getVideosByCampaignIds = () => {
    const campaignIds = campaignList.map(campaign => campaign.id);
    const data = {
      campaignIds: campaignIds,
    };
    videos.getVideosByCampaignIds(data, response => {
      if (response.status === 'success') {
        setLibraryList(response.data.result);
        setVideo(response.data.result[0]);
        setIsLoading(false);
      } else {
        setLibraryList([]);
        setIsLoading(false);
      }
    });

  };
 
  const handleClick = video => {
    setVideo(video);
    onVideoSelect(video);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  return (

      <ListItemButton
        // key={index}
        disableRipple
        alignItems="flex-start"
        sx={{
          p: 3,
          transition: 'all 0.2s',
          borderBottom: 1,
          borderBottomColor: 'divider',
          [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
          },
          '&:hover': {
            boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
            transform: 'translateY(-4px)',
            borderBottomColor: 'transparent',
          },
        }}
        onClick={() => handleClick(item)}
    
      >
         <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={5}>
            <PlayCircleOutlineIcon
              style={{ marginTop: '15px', color: '#340' }}
            />
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant={'h4'}
              style={{ color: '#331', fontFamily: 'inherit',textAlign: 'right' }}
            >
              {item.title}
            </Typography>
          </Grid>
        </Grid>
      </ListItemButton>
  );
};

export default VideoItem1;
