import React, { useState } from 'react';
import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import code from '../TextFields/demo-code/basic-text-fields.txt';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { Button } from '@mui/material';
import Candidate from '../../../../../modules/candiate';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const BasicTextFields = ({ candidate }) => {
	const [details, setDetails] = useState({
		personal_website: candidate?.personalWebsite || '',
		github: candidate?.github || '',
		linkedIn: candidate?.linkedIn || '',
		otherLink: candidate?.otherLink || '',
	});

	const Swal = useSwalWrapper();

	function handleSubmit(e) {
		e.preventDefault();
		Candidate.updateCandidate(candidate?.id, details, response => {
			if (response.status === 'success') {
				Swal.fire({
					icon: 'success',
					title: 'Other info added successfully',
				});
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Other info not added',
				});
			}
		});
	}

	function handleChange(e) {
		const { name, value } = e.target;
		setDetails(prev => ({ ...prev, [name]: value }));
	}

	return (
		<JumboCardQuick
			title={'Enter Other Details'}
			demoCode={code}
			wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
			style={{ marginTop: '10px' }}
		>
			<form onSubmit={handleSubmit}>
				<Grid
					container
					spacing={2}
					justifyContent='center'
					alignItems='center'
				>
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							id='website-url'
							label='Personal Website URL'
							variant='standard'
							value={details?.personal_website || ''}
							fullWidth
							onChange={handleChange}
							name='personal_website'
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							id='github-url'
							label='GitHub URL'
							variant='standard'
							value={details?.github || ''}
							fullWidth
							onChange={handleChange}
							name='github'
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							id='linkedin-url'
							label='LinkedIn URL'
							variant='standard'
							value={details?.linkedIn || ''}
							fullWidth
							onChange={handleChange}
							name='linkedIn'
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
					>
						<TextField
							id='other-url'
							label='Other URL'
							variant='standard'
							value={details?.otherLink || ''}
							fullWidth
							onChange={handleChange}
							name='otherLink'
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<Button
							type='submit'
							variant='outlined'
							fullWidth
							style={{ color: '#3f51b5', fontWeight: '600' }}
						>
							SAVE
						</Button>
					</Grid>
				</Grid>
			</form>
		</JumboCardQuick>
	);
};

export default BasicTextFields;
