import appApi from '../../../../src/modules/appApi';

class ContactModule {
  addContact = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: `/api/contacts/add`,
      data: data,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        const msg = error.response.data.error || 'Something went wrong';
        callBack({ status: 'error', msg });
      });
  };
  countContact = (callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/contacts/count-contacts`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        const msg = error.response.data.error || 'Something went wrong';
        callBack({ status: 'error', msg });
      });
  };

  getAllContacts = (pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/contacts/all/?page=${pageNum}&perPage=${pageSize}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getting Contact List ');
      });
  };

  deleteContact = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: `/api/contacts/${id}`,
    })
      .then((response) => {
        callBack({ status: 'success' });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred While Deleting Contact');
      });
  };

  editContact = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/contacts/' + id,
      data: data,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred While Editing Contact');
      });
  };

  uploadBulkContact = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: `/api/contacts/addContacts`,
      data: data,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred while Adding The Contact');
      });
  };

  filterContact = (data, callBack) => {
    const {
      name,
      email,
      phoneNumber,
      labels,
      semester,
      college,
      courseRelation,
    } = data; // Destructure values from data object
    appApi({
      method: 'GET',
      url: `/api/contacts/searchContact?name=${name}&email=${email}&phoneNumber=${phoneNumber}&labels=${labels}&semester=${semester}&college=${college}&courseRelation=${courseRelation}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred while filtering The Contact');
      });
  };

  getContactsByLabel = (labelId, callBack) => {
    appApi({
      method: 'GET',
      url: `api/contacts/label/${labelId}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred while Adding The Contact');
      });
  };

  // @GET api/contacts/all-colleges
  getAllCollage = (callBack) => {
    appApi({
      method: 'GET',
      url: `api/contacts/all-colleges`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred while getting all collages');
      });
  };

  //@GET api/contacts/all-course-relations
  getAllCourseRelations = (callBack) => {
    appApi({
      method: 'GET',
      url: `api/contacts/all-course-relations`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred while getting all course');
      });
  };

  //TODO GET ALL EMAIL CAMPAGIN

  getAllEmailCampagin = (callBack) => {
    appApi({
      method: 'GET',
      url: `api/email_campaigns/all`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred while getting all Email Cmapgin ');
      });
  };
  //TODO ADD EMAIL CAMPAGIN

  sendEmailCampaign = (data, callBack) => {
    appApi({
      method: 'POST',
      url: `api/email_campaign_user/bulk_add`,
      data: data,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred While sending Email');
      });
  };

  //TODO ADD LABEL

  sendAddLabel = (data, callBack) => {
    appApi({
      method: 'PUT',
      url: `api/contacts/add-labels-to-filtered-contacts`,
      data: data,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred While sending Email Campaign');
      });
  };

  searchContactTerm = (searchTerm, callback) => {
    appApi({
      method: 'Get',
      url: `/api/contacts/search/${searchTerm}`,
    })
      .then((response) => {
        callback({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callback({ status: 'error' });
        console.log(error, 'Error at search Term on contacts ');
      });
  };
  postEmail = (id, callBack) => {
    return appApi({
      method: 'POST',
      url: `/api/contacts/post-email/${id}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error occured while posting email');
      });
  };
  sendEmail = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: `/api/contacts/sendEmail`,
      data: data,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error occured while posting email');
      });
  };

  createLabel = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: `/api/label/add`,
      data: data,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log(error, 'Error occured while creating label');
      });
  };
}

export default new ContactModule();
