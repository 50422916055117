import appApi from './appApi';

class UserVideos {
  addUserVideos = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/user_video/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  user videos', error);
      });
  };

  getAllUserVideosById = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/user_video/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting user videos', error);
      });
  };
  getUserVideosById = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/user_video/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting user videos', error);
      });
  };
  getUserVideoByLessonIdAndUserId = async (lessonId, userId, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/user_video/lesson/' + lessonId + '/user/' + userId,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting user videos', error);
      });
  };

  getVideosByUserId = async (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/user_video/user' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting user videos', error);
      });
  };
  updateUserVideosById = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/user_video/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating user videos', error);
      });
  };
}

export default new UserVideos();
