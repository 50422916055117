import React, { useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

const AddGateway = props => {
  const contentRef = useRef(null);

  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title1: yup.string().when('title', {
        is: title => !title || title.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
    },
    ['title', 'title1']
  );
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, []);
  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close}>
        <Formik
          validateOnChange={true}
          initialValues={{
            title: props?.data?.title ? props?.data?.title : '',
            title1: props?.data?.title1 ? props?.data?.title1 : '',
            from: props?.data?.from ? props?.data?.from : '',
            key: props?.data?.key ? props?.data?.key : '',
            secret: props?.data?.secret ? props?.data?.secret : '',
            provider: props?.data?.provider ? props?.data?.provider : '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            data.examId = props.id;
            props.addSmsGateway(data);
            props.close();
          }}
        >
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <DialogContent
              ref={contentRef}
              sx={{ overflowY: 'auto', maxHeight: '80vh' }}
            >
              <DialogTitle id="alert-dialog-title" textAlign="center">
                {props?.data?.id ? 'Edit SMS Gateway' : 'Add SMS Gateway'}
              </DialogTitle>
              <Box
                sx={{
                  width: '500px',
                }}
              >
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="title"
                    label="Title(English)*"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="title1"
                    label="Title ( العربيه )"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField fullWidth name="from" label="From" />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField fullWidth name="key" label="Key" />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField fullWidth name="secret" label="Secret" />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="provider"
                    select
                    label="Provider"
                  >
                    <MenuItem value="Nexmo">Nexmo</MenuItem>
                  </JumboTextField>
                </Div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type="submit" autoFocus>
                Save
              </Button>

              <Button variant="contained" onClick={props.close}>
                {' '}
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddGateway;
