import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // Import the CSS for styling
// import './ckEditor.css';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';

let Editor = () => <React.Fragment />;
const AddPhishingTemplate = (props, { addEmailTemplate }) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const editorDataa = () => {
    Editor = require('react-draft-wysiwyg').Editor;
    if (props.data?.text) {
      const blocksFromHTML = convertFromHTML(props.data?.text);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(content));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  };

  React.useEffect(() => {
    editorDataa();
  }, []);
  const validationSchema = yup.object().shape({
    title: yup.string().required('Please Enter the Title First'),
  });

  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close}>
        <DialogTitle id="alert-dialog-title" textAlign="center">
          {/* Add Phishing Template */}
          {props?.data?.id ? 'Edit Phishing Template' : 'Add Phishing Template'}
        </DialogTitle>
        <Formik
          validateOnChange={true}
          initialValues={{
            title: props?.data?.title ? props?.data?.title : '',
            url: props?.data?.url ? props?.data?.url : '',
            text: props?.data?.text ? props?.data?.text : '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            props?.addTemplate(data);
            props.close();
          }}
        >
          {({ setFieldValue }) => (
            <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
              <DialogContent>
                <Box
                  sx={{
                    width: '500px',
                  }}
                >
                  <Div sx={{ mb: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="title"
                      label="Title(English)*"
                    />
                  </Div>
                  <Div sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField fullWidth name="url" label="Url" />
                  </Div>
                  <Div sx={{ flex: 1 }}>
                    <Editor
                      editorStyle={{
                        width: '100%',
                        minHeight: 80,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'lightgray',
                      }}
                      editorState={editorState}
                      name="text"
                      onEditorStateChange={editorState => {
                        setEditorState(editorState);
                        let text = draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                        );
                        setFieldValue('text', text);
                      }}
                    />
                  </Div>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" type="submit" autoFocus>
                  {props?.data?.id ? 'Update' : 'Save'}
                </Button>
                <Button variant="contained" onClick={props.close}>
                  {' '}
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddPhishingTemplate;
