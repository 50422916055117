//  import MarvelmindsAPI from "../../mmEsoft/module/MarvelMindsApi"
import axios from 'axios';
import appApi from '../../../../src/modules/appApi';
import MarvelMindsApi, { appServerURLJava } from './MarvelMindsApi';

class SessionApi {
  getSessionsByBatchIdAndCampaignsId = (id, data, callBack) => {
    return appApi({
      method: 'POST',
      data: data,
      url: `/api/meeting/get-meeting/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getting meetngs');
      });
  };
  updateMeeting = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      data: data,
      url: `/api/meeting/update/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getting meetngs');
      });
  };
  getSessionViewUrlByBatchIdAndCampaignId = (batchId, campaignId, callback) => {
    return appApi({
      method: 'GET',
      url: `/api/sessions/get-recordings/${batchId}/${campaignId}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        callback({ status: 'error' });
        console.log(
          error,
          'error occurred in getSessionViewUrlByBatchIdAndCampaignId'
        );
      });
  };
  getSessionAdminByBatchId = (batchId, callback) => {
    return appApi({
      method: 'GET',
      url: `/api/meeting/all/${batchId}`,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(error => {
        callback({ status: 'error' });
        console.log(error, 'error occurred in getting meeting');
      });
  };
}
export default new SessionApi();
