import Page from '@jumbo/shared/Page';
import PhishingTemplate from 'app/pages/phishing-template/PhishingTemplate';
import React from 'react';

const phishingTemplateRoutes = [
  {
    path: '/phishingtemplate',
    element: <Page component={PhishingTemplate} />,
  },
];
export default phishingTemplateRoutes;
