import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import { useTranslation } from 'react-i18next';
import projectIcon from './projectIcon.svg';
import taskIcon from './tasksIcon.svg';
import teamsIcon from './teamsIcon.svg';
import fileIcon from './filesIcon.svg';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
const VisitCount = props => {
  const { t } = useTranslation();
  const { theme } = useJumboTheme();
  return (
    <JumboCardQuick
      style={{
        backgroundImage: `linear-gradient(to left top, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        height: '120px',
      }}
    >
      <Div sx={{ display: 'flex', alignItems: 'center' }}>
        <Div
          sx={{ display: 'flex', flexDirection: 'column', minHeight: '80px' }}
        >
          {props?.type == 'all' ? (
            <img alt={'projectIcon Icon'} src={projectIcon} />
          ) : props?.type == 'active' ? (
            <img alt={'taskIcon Icon'} src={taskIcon} />
          ) : props?.type == 'completed' ? (
            <img alt={'teamsIcon Icon'} src={teamsIcon} />
          ) : props?.type == 'upcoming' ? (
            <img alt={'fileIcon Icon'} src={fileIcon} />
          ) : props?.type == 'phishingactive' ? (
            <img alt={'taskIcon Icon'} src={taskIcon} />
          ) : props?.type == 'allCounts' ? (
            <img alt={'projectIcon Icon'} src={projectIcon} />
          ) : (
            ''
          )}
        </Div>
        {/* {props?.type == 'all'
              ? props?.count?.all
              : props?.type == 'active'
              ? props?.count?.active
              : props?.type == 'completed'
              ? <LibraryAddCheckOutlinedIcon htmlColor='#FFF' />
              : props?.type == 'upcoming'
              ? props?.count?.upcoming
              : ''} */}

        <Div sx={{ ml: 2.5, minHeight: '80px' }}>
          <Typography color={'common.white'} variant={'h2'} mb={0.5}>
            {props?.type == 'all'
              ? props?.count?.all ?? 0
              : props?.type == 'active'
              ? props?.count?.active ?? 0
              : props?.type == 'completed'
              ? props?.count?.completed ?? 0
              : props?.type == 'upcoming'
              ? props?.count?.upcoming ?? 0
              : props?.type == 'phishingactive'
              ? props?.count?.phishingactive ?? 0
              : props?.type == 'allCounts'
              ? props?.count?.allCounts ?? 0
              : ''}
          </Typography>
          <Typography
            color={'common.white'}
            variant={'h5'}
            mb={0.8}
            sx={{ minHeight: '35px' }}
          >
            {t(props?.cardHeaderTitle)}
          </Typography>
          {/* <Typography variant={'h6'} color={'common.white'} mb={0}>
            {props?.content}
          </Typography> */}
        </Div>
      </Div>
    </JumboCardQuick>
  );
};

export default VisitCount;
