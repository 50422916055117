import { appServerURL } from './appApi';

class ThemeModule {
  constructor() {
    this.color = {
      default: true,
      primary: {
        main: '#FFFEF9',
        light: '#FFFEFA',
        dark: '#FFFFFE',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#CCCBC6',
        light: '#B3B2AD',
        dark: '#4D4C4A',
        contrastText: '#FFF',
      },
      fontStyle: {
        fontSize: '14px',
        fontColor: { r: 104, g: 226, b: 31 },
        fontFamily: 'NoirPro, Arial',
      },
    };
  }

  loadTheme = async () => {
    let _currentColor = localStorage.getItem('themeSettings');
    if (_currentColor) {
      _currentColor = JSON.parse(_currentColor);
      this.setColor(_currentColor);
      return;
    }
    if (this.color.default) {
      let response = await fetch(appServerURL + 'public/fetch-theme');
      if (response && response.status == 200) {
        let currentColor = await response.json();
        if (currentColor) {
          localStorage.setItem('themeSettings', JSON.stringify(currentColor));
          this.setColor(currentColor);
        }
      }
    }
  };

  setColor = currentColor => {
    let currentPrimaryColor = currentColor.primary;
    let currentSecondaryColor = currentColor.secondary;
    let currentFontStyle = currentColor.fontStyle;
    this.color = {
      default: false,
      primary: {
        main: this.adjustColor(currentPrimaryColor, 0),
        light: this.adjustColor(currentPrimaryColor, 10, false),
        dark: this.adjustColor(currentPrimaryColor, 10, true),
        contrastText: '#FFF',
      },
      secondary: {
        main: this.adjustColor(currentSecondaryColor, 0),
        light: this.adjustColor(currentSecondaryColor, 10, false),
        dark: this.adjustColor(currentSecondaryColor, 10, true),
        contrastText: '#FFF',
      },
      fontStyle: {
        fontSize: currentFontStyle?.fontSize || '14px',
        fontColor: currentFontStyle?.fontColor || { r: 104, g: 226, b: 31 },
        fontFamily: currentFontStyle?.fontFamily || 'NoirPro, Arial',
      },
    };
  };

  adjustColor(rgb, percentage, darken = true) {
    const factor = darken ? 1 - percentage / 100 : percentage / 100;
    let newRgb = {
      r: Math.round(rgb.r * factor),
      g: Math.round(rgb.g * factor),
      b: Math.round(rgb.b * factor),
    };
    return `rgb(${newRgb.r}, ${newRgb.g}, ${newRgb.b})`;
  }
}

export default new ThemeModule();
