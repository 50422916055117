import appApi from './appApi';

class PhishingTemplate {
  addPhishingTemplate = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/phishing_template/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  phishing template', error);
      });
  };
  getAllPhishingTemplate = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/phishing_template/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  phishing Template', error);
      });
  };
  deletePhishingTemplate = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: '/api/phishing_template/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while deleting phishing template', error);
      });
  };
  updatePhishingTemplate = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/phishing_template/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating phishing template', error);
      });
  };
}

export default new PhishingTemplate();
