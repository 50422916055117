import appApi from './appApi';

class Departments {
  addDepartments = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/department/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  department', error);
      });
  };
  getAllDepartments = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/department/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  department', error);
      });
  };
  updateDepartments = (id, data, callBack) => {
    appApi({
      method: 'PUT',
      url: '/api/department/' + id,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating department  ', error.message);
        callBack({ status: 'error' });
      });
  };
  deleteDeptByDeptId = (id, callBack) => {
    appApi({
      method: 'DELETE',
      url: '/api/department/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting department  ', error.message);
        callBack({ status: 'error' });
      });
  };
}
export default new Departments();
