import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';
import { useState} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteCampaign from './DeleteCampaign';
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
const CampaignTable = ({ campaignTableData , getAllCampaigns }) => {
  const [openDeleteDiolog, setOpenDeleteDiolog] = useState(false);
  const [deleteData, setDeleteData] = useState();


  const handleClickOpenDeleteDiolog = data => {
    setDeleteData(data);
    setOpenDeleteDiolog(true);
  };

  const handleCloseDeleteDiolog = () => {
    setOpenDeleteDiolog(false);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {/* TODO - 0 : SHOW ADDED TO BATCH CAMPAIGN */}

          <TableBody>
            {campaignTableData.length > 0 ? (
              campaignTableData.map(data => (
                <TableRow key={data.id}>
                  <TableCell>{data?.title}</TableCell>
                  <TableCell>
                    {data?.description}
                  </TableCell>
                  <TableCell>
                    {data?.startDate }
                  </TableCell>
                  <TableCell>{data?.endDate}</TableCell>
                  <TableCell>
                    <Button
                      variant="filled"
                      onClick={() => handleClickOpenDeleteDiolog(data)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <JumboListNoDataPlaceHolder />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteCampaign
        openDeleteDiolog={openDeleteDiolog}
        handleClickOpenDeleteDiolog={handleClickOpenDeleteDiolog}
        handleCloseDeleteDiolog={handleCloseDeleteDiolog}
        deleteData={deleteData}
        getAllCampaigns={getAllCampaigns}
      />
    </>
  );
};

export default CampaignTable;
