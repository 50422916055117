import {
  Grid,
  Typography,
  Box,
  CircularProgress,
  Checkbox,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { useEffect, useState } from 'react';
import attendanceModule from 'app/mmEsoft/module/attendanceModule';

const AttendenceTable = ({ attendances, loading }) => {
  const [presentStates, setPresentStates] = useState({});

  useEffect(() => {
    const updatedPresentStates = {};
    attendances?.data?.forEach((activity) => {
      activity.forEach((student) => {
        updatedPresentStates[student.id] = student.present || false;
      });
    });
    // Update the present states
    setPresentStates(updatedPresentStates);
  }, [attendances]);

  const handlePresent = (id) => {
    const newPresentStates = {
      ...presentStates,
      [id]: !presentStates[id],
    };

    setPresentStates(newPresentStates);
    attendanceModule.setPresent(
      id,
      { present: newPresentStates[id] },
      (response) => {
        if (response.status === 'error') console.log('error at set present');
      }
    );
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {attendances?.data?.map((activity, index) => {
            return (
              <Grid
                key={index}
                container
                sx={{
                  width: '300px',
                  border: 3,
                  borderColor: '#c5cae9',
                  borderRadius: '10px',
                }}
                direction={'column'}
              >
                <Grid
                  component={'div'}
                  item
                  sx={12}
                  style={{
                    padding: '12px',
                    borderBottom: 'solid 2px #c5cae9',
                    height: '70px',
                    width: '200px',
                  }}
                >
                  <Typography
                    component={'p'}
                    textAlign={'center'}
                    fontSize={'15px'}
                  >
                    {activity[0]?.activityName}
                  </Typography>
                  <Typography
                    component={'p'}
                    textAlign={'center'}
                    fontSize={'14px'}
                    style={{
                      textAlign: 'center',
                      padding: '3px',
                      backgroundColor: '#2196f3',
                      color: '#ffff',
                      borderRadius: '6px',
                    }}
                  >
                    {activity[0]?.activityDate}
                  </Typography>
                </Grid>

                {activity?.map((student) => {
                  return (
                    <Grid
                      key={student.id}
                      component={'div'}
                      sx={12}
                      style={{
                        height: '50px',
                        borderBottom: 'solid 1px #c5cae9',
                      }}
                    >
                      <Typography fontSize={'15px'} textAlign={'center'}>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={presentStates[student.id]}
                                onChange={() =>
                                  handlePresent(
                                    student.id,
                                    !presentStates[student.id]
                                  )
                                }
                                icon={
                                  <span
                                    style={{
                                      borderRadius: '50%',
                                      backgroundColor: 'transparent',
                                      width: '20px',
                                      height: '20px',
                                      border: '1px solid #ccc',
                                    }}
                                  />
                                }
                                checkedIcon={
                                  <span
                                    style={{
                                      borderRadius: '50%',
                                      backgroundColor: '#2196f3',
                                      width: '20px',
                                      height: '20px',
                                      display: 'inline-block',
                                      border: '1px solid #ccc',
                                    }}
                                  />
                                }
                              />
                            }
                            label='Present'
                          />
                        </FormControl>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
};

export default AttendenceTable;
