import Page from '@jumbo/shared/Page';
import MyCampains from 'app/pages/mycampains/MyCampains';

const campianRoutes = [
  {
    path: '/mycampaigns',
    element: <Page component={MyCampains} />,
  },
];
export default campianRoutes;
