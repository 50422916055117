import React from 'react';
import Avatar from '@mui/material/Avatar';
import ContentHeader from '../../../layouts/shared/headers/ContentHeader';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ASSET_AVATARS } from '../../../utils/constants/paths';
import { getAssetPath } from '../../../utils/appHelpers';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(0, 1),

  '&:hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiTouchRipple-root': {
    display: 'none',
  },
}));

const Item = styled('div')({
  textAlign: 'center',
});

const Header = ({ user }) => {

  return (
    
    <ContentHeader
      avatar={
        <Avatar
          sx={{ width: 72, height: 72 }}
          alt={`${user?.firstName} ${user?.lastName}`}
          src={getAssetPath(`${ASSET_AVATARS}/avatar3.jpg`, '72x72')}
        />
        
      }
      // title={`${user?.firstName} ${ user?.lastName ? user.lastName:""}`}
      title={user?.name}
      // subheader={
      //   // <Typography fontSize={12} variant={'body1'} color={'inherit'} mt={0.5}>
      //   //   {/* {user?.name} */}
      //   // </Typography>
      // }
    
      children={
        <Stack
          direction={'row'}
          justifyContent={'space-evenly'}
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          sx={{
            mx: 1,
          }}
        ></Stack>
      }
      tabs={
        <List
          disablePadding
          sx={{
            display: 'flex',
            minWidth: 0,
          }}
        >
          {/* <StyledMenuItem>Timeline</StyledMenuItem>
          <StyledMenuItem>About</StyledMenuItem>
          <StyledMenuItem>Photos</StyledMenuItem>
          <StyledMenuItem>Friends</StyledMenuItem>
          <StyledMenuItem>More</StyledMenuItem> */}
        </List>
      }
      //   action={
      //     <Button
      //       disableRipple
      //       variant="text"
      //       startIcon={<SettingsIcon />}
      //       sx={{
      //         color: "inherit",
      //         textTransform: "none",
      //         "&:hover": {
      //           backgroundColor: "transparent",
      //         },
      //       }}
      //     >
      //       Settings
      //     </Button>
      //   }
      sx={{
        position: 'relative',
        zIndex: 1,

        '& .MuiCardHeader-action': {
          alignSelf: 'center',
        },
      }}
      
    />
   
  );
};

export default Header;
