import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

const AddSmsTemplate = props => {
  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title1: yup.string().when('title', {
        is: title => !title || title.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
    },
    ['title', 'title1']
  );
  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close}>
        <DialogTitle id="alert-dialog-title" textAlign="center">
          {props?.data?.id ? 'View/Edit SMS Template' : 'Add SMS Template'}
        </DialogTitle>
        <Formik
          validateOnChange={true}
          initialValues={{
            title: props?.data?.title ? props?.data?.title : '',
            title1: props?.data?.title1 ? props?.data?.title1 : '',
            content: props?.data?.content ? props?.data?.content : '',
            content1: props?.data?.content1 ? props?.data?.content1 : '',
            type: props?.data?.type ? props?.data?.type : '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            props.addSmsTemplate(data);
            props.close();
          }}
        >
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <DialogContent>
              <Box
                sx={{
                  width: '500px',
                }}
              >
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="title"
                    label="Title(English)*"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="title1"
                    label="Title ( العربيه )"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="content"
                    label="Content ( English )"
                    multiline
                    rows={4}
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="content1"
                    label="Content ( العربيه )"
                    multiline
                    rows={4}
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    select
                    fullWidth
                    name="type"
                    label="Choose Type"
                  >
                    <MenuItem key="OTP/SMS" value="otp_sms">
                      OTP SMS
                    </MenuItem>
                    <MenuItem
                      key="Training/Campaign/Sms"
                      value="training_campaign_sms"
                    >
                      Training Campaign SMS
                    </MenuItem>
                  </JumboTextField>
                </Div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type="submit" autoFocus>
                Save
              </Button>

              <Button variant="contained" onClick={props.close}>
                {' '}
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddSmsTemplate;
