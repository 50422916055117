import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import lessons from 'modules/lessons';
import examLessons from 'modules/examLessons';
import { useTranslation } from 'react-i18next';

const Lesson = ({
  lesson,
  setExpanded,
  examId,
  getAllLessons,
  getAllLessonsByExam,
  getAllExamLessonsByExam,
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const Swal = useSwalWrapper();
  const { t } = useTranslation();
  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title1: yup.string().when('title', {
        is: title => !title || title.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
    },
    ['title', 'title1']
  );
  const handleDelete = () => {
    setOpenConfirmation(false);
    setExpanded(false);
    deleteLesson(lesson.id);
  };

  const updateLesson = (id, data) => {
    lessons.updateLesson(id, data, response => {
      if (response.status === 'success') {
        if (examId !== undefined) {
          getAllLessonsByExam(examId);
        } else getAllLessons();
        Swal.fire({
          icon: 'success',
          title: 'Lesson has been updated successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while updating lesson.',
        });
      }
    });
  };
  const deleteLesson = (id, data) => {
    lessons.deleteLesson(id, response => {
      if (response.status === 'success') {
        if (response.data == 'Already exists in campaign lesson') {
          Swal.fire({
            icon: 'error',
            title: 'Cant delete! Lesson still exists in campaigns.',
          });
        } else {
          if (examId !== undefined) {
            getAllLessonsByExam(examId);
          } else getAllLessons();
          Swal.fire({
            icon: 'success',
            title: 'Lesson has been deleted successfully.',
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting lesson.',
        });
      }
    });
  };
  const deleteExamLesson = id => {
    examLessons.deleteExamLesson(id, response => {
      if (response.status === 'success') {
        getAllExamLessonsByExam(examId);
        setExpanded(false);

        Swal.fire({
          icon: 'success',
          title: ' Exam Lesson has been deleted successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting exam lesson.',
        });
      }
    });
  };

  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Formik
        validateOnChange={true}
        initialValues={lesson}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={data => {
          updateLesson(lesson.id, data);
        }}
      >
        <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
          <Div sx={{ mb: 2, mt: 1 }}>
            <JumboTextField fullWidth name="title" label="Title(English)" />
          </Div>
          <Div sx={{ mb: 2, mt: 1 }}>
            <JumboTextField fullWidth name="title1" label="Title ( العربيه )" />
          </Div>
          <Div sx={{ mb: 2, mt: 1 }}>
            <JumboTextField
              fullWidth
              name="description"
              label="Description (English)"
            />
          </Div>
          <Div sx={{ mb: 2, mt: 1 }}>
            <JumboTextField
              fullWidth
              name="description1"
              label="Description ( العربيه )"
            />
          </Div>

          <Div
            sx={{ mb: 2, mt: 1, display: 'flex', justifyContent: 'flex-end' }}
          >
            {examId ? (
              <Button
                variant="contained"
                onClick={() => deleteExamLesson(lesson.id)}
              >
                {t('pages.title.remove')}
              </Button>
            ) : (
              <div>
                <Button variant="contained" type="submit" autoFocus>
                  {t('widgets.title.update')}
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button
                  variant="contained"
                  onClick={() => setOpenConfirmation(true)}
                >
                  {t('widgets.title.delete')}
                </Button>
              </div>
            )}
            <Dialog
              open={openConfirmation}
              onClose={() => setOpenConfirmation(false)}
            >
              <DialogTitle> {t('pages.title.deleteConfirmation')}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this lesson?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenConfirmation(false)}
                  color="primary"
                >
                  {t('pages.title.cancel')}
                </Button>
                <Button onClick={handleDelete} color="primary">
                  {t('widgets.title.delete')}
                </Button>
              </DialogActions>
            </Dialog>
            &nbsp; &nbsp; &nbsp;
            <Button variant="contained" onClick={() => setExpanded(false)}>
              {' '}
              {t('pages.title.cancel')}
            </Button>
          </Div>
        </Form>
      </Formik>
    </Div>
  );
};

export default Lesson;
