import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';
import Div from '@jumbo/shared/Div/Div';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { useEffect, useState } from 'react';
import UserLessonDetailss from './UserLessonDetailss';

import UserVideos from './UserVideos';
import Quiz from './Quiz';
import campaigns from 'modules/campaigns';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import userProgress from 'modules/userProgress';
import './UserCampaigns.css';
import { getUserCampaigns } from 'app/redux/actions/userCampaigns';
import videos from 'modules/videos';

const UserCampaigns = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { campaignList } = useSelector(
    ({ campaignsReducer }) => campaignsReducer
  );

  const CurrentCampaign = campaignList.find((cam) => cam.id == id);
  const { myBatch } = useSelector(({ batchesReducer }) => batchesReducer);
  const { user } = useSelector(({ login }) => login);
  const [userId, setUserId] = React.useState();
  const [video, setVideo] = React.useState();
  const [userProgressData, setUserProgressData] = React.useState([]);
  const [lessonData, setLessonData] = React.useState([]);
  const [displayArray, setDisplayArray] = React.useState([]);
  const [displayEl, setDisplayEl] = React.useState();
  const [policyy, setPolicy] = useState([]);
  const delay = (ms) =>
    new Promise((res) => {
      setTimeout(() => {
        res();
      }, ms);
    });
  React.useEffect(() => {
    (async function () {
      for (let el of lessonData) {
        await delay(500);
        setDisplayEl(el);
      }
      setDisplayEl(undefined);
    })();
  }, [lessonData]);

  React.useEffect(() => {
    displayEl && setDisplayArray((prev) => [...prev, displayEl]);
  }, [displayEl]);

  const [expanded, setExpanded] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [selectedListItem, setSelectedListItem] = React.useState('start');
  const [lesson, setLesson] = React.useState();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setDisplayArray([]);
      setLessonData([]);
      await dispatch(getUserCampaigns(user?.id));
      setUserId(user?.id);
      await getCampaignLessons(id);
    };

    fetchData();
  }, [id]);

  React.useEffect(() => {
    // This effect runs whenever lessonData changes
    if (userProgressData.length > 0 && lessonData.length > 0) {
      addNewLessonsToUserProgress(userProgressData, lessonData);
    }
  }, [userProgressData, lessonData]);

  // if campaign is already started and we add new lesson to this campaign add newLesson entry to userProgressTable
  const addNewLessonsToUserProgress = async (
    _userProgressData,
    _lessonData
  ) => {
    for (let i = 0; i < _lessonData.length; i++) {
      const lesson = _lessonData[i];
      const existingLesson = _userProgressData.find(
        (upData) => upData.lessonId === lesson.lessonId
      );

      if (!existingLesson) {
        await addUserProgress(lesson.lessonId, i);
      }
    }
  };

  const startUserCampaign = async (_userProgressData, _lessonData) => {
    if (_userProgressData?.length == 0) {
      setLessonData(_lessonData);
      setSelectedIndex(0);
    } else {
      for (let i = 0; i < _userProgressData.length; i++) {
        const upData = _userProgressData[i];
        for (let j = 0; j < _lessonData.length; j++) {
          const lesson = _lessonData[j];
          if (lesson.lessonId == upData.lessonId) {
            lesson.showVideoWatched = upData.videoStatus;
            lesson.showQuizScore = upData.quizStatus;
            if ((!upData.videoStatus || !upData.quizStatus) && !expanded) {
              setExpanded('panel' + j);
              setLesson(lesson);
            }
          }
        }
      }
      // Add new lessons to userProgress table when campaign is already started
      await addNewLessonsToUserProgress(_userProgressData, _lessonData);
      setSelectedListItem('');
      setLessonData(_lessonData);
    }
  };

  const handleStartCampaign = () => {
    lessonData.forEach((lesson, index) => {
      addUserProgress(lesson?.lessonId, index);
    });

    videos.getAllVideosByLesson(lessonData[0]?.lessonId, (response) => {
      if (response.status === 'success') {
        let allVideoDataa = response.data;
        if (allVideoDataa.length > 0) {
          handleListItemClickCallBack(1, lessonData[0], allVideoDataa[0]);
        } else {
          handleListItemClickCallBack(2, lessonData[0]);
        }
      }
    });
  };
  // React.useEffect(() => {
  //   if (
  //     userProgressData?.every(
  //       lesson =>
  //         (lesson.videoStatus && lesson.quizStatus) || lesson.policyStatus
  //     )
  //   ) {
  //     setSelectedIndex(5);
  //   }
  // }, [userProgressData]);
  React.useEffect(() => {
    if (
      Array.isArray(userProgressData) &&
      userProgressData.every(
        (lesson) =>
          (lesson.videoStatus && lesson.quizStatus) || lesson.policyStatus
      )
    ) {
      setSelectedIndex(5);
    }
  }, [userProgressData]);

  const addUserProgress = (lsnId, lessonCount) => {
    let data = {
      lessonId: lsnId,
      userId: userId,
      campaignId: id,
      videoStatus: false,
      quizStatus: false,
      policyStatus: false,
      startDate: new Date(),
      endDate: new Date(),
      batchId: myBatch,
    };

    userProgress.addUserProgress(data, (response) => {
      if (response.status === 'success') {
        setUserProgressData(response.data);
        if (lessonCount == 0) {
          getUserProgressByCampaignId(id, user?.id, lessonData);
        }
      } else {
        setUserProgressData([]);
      }
    });
  };

  const getCampaignLessons = async (id) => {
    campaigns.getLessonsByCampaignId(id, (response) => {
      if (response.status === 'success') {
        getUserProgressByCampaignId(id, user?.id, response.data);
        // console.log(response.data,"lesnbycmpnid");
        
      } else {
        setLessonData([]);
      }
    });
  };

  const getUserProgressByCampaignId = async (id, userId, lessonData) => {
    userProgress.getAllUserProgressByCampaignId(
      id,
      userId,
      async (response) => {
        if (response.status === 'success') {
          setUserProgressData(response.data);
          startUserCampaign(response.data, lessonData);
          // console.log(response.data,"uscmpn");
          // console.log( lessonData,"ld");
          
        } else {
          setUserProgressData([]);
        }
      }
    );
  };

  const handleListItemClickCallBack = (index, lesson, video) => {
    setSelectedIndex(index);
    setLesson(lesson);
    
    if (video) {
      setVideo(video);
    }
  };

  const containerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  };
  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} sm={8} md={8} lg={8}>
        <JumboCardQuick
          title={
            <Typography
              variant='h3'
              textAlign={'center'}
              style={{ textTransform: 'uppercase' }}
            >
              {CurrentCampaign?.title}
            </Typography>
          }
          wrapperSx={{ pt: 1 }}
        >
          {selectedIndex === 1 ? (
            <UserVideos
              userId={user?.id}
              lesson={lesson}
              setLesson={setLesson}
              video={video}
              setVideo={setVideo}
              handleListItemClickCallBack={handleListItemClickCallBack}
              userProgressData={userProgressData}
              setUserProgressData={setUserProgressData}
              // videoData={videoData}
              // userVideoData={userVideoData}
              // setUserVideoData={setUserVideoData}
            />
          ) : selectedIndex === 2 ? (
            <Quiz
              lesson={lesson}
              userId={user?.id}
              userProgressData={userProgressData}
              setUserProgressData={setUserProgressData}
              lessonData={lessonData}
              campaign={CurrentCampaign}
            />
          ) : selectedIndex === 0 ? (
            <Grid container sx={containerStyles}>
              <Grid item md={12} textAlign='center'>
                <Typography variant='h4'>Welcome to the campaigns</Typography>
                <br />
                <Button
                  variant='contained'
                  color='primary'
                  onClick={(e) => handleStartCampaign()}
                  sx={{ marginRight: '1rem', width: '150px' }}
                >
                  Start
                </Button>
              </Grid>
            </Grid>
          ) : (
            <div className='loader' style={{ marginTop: '20%' }}>
              <svg className='circular-loader' viewBox='25 25 50 50'>
                <circle className='loader-path' cx='50' cy='50' r='20' />
              </svg>
            </div>
          )}
        </JumboCardQuick>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        width={300}
        className='videoContainer'
      >
        <JumboCardQuick title={'lessons'} wrapperSx={{ pt: 1 }}>
          <Grid className='videoItem' sx={{ position: 'sticky', top: 0 }}>
            {displayArray?.map((item, index) => (
              <Accordion
                key={index}
                sx={{ mb: 1, boxShadow: 1 }}
                expanded={expanded === 'panel' + index}
                onChange={handleChange('panel' + index)}
                className='video'
                width='250px'
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel' + index ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Div
                    sx={{
                      width: '400',
                      flexShrink: 0,
                      flex: { xs: '1', lg: '0 1 auto' },
                      display: 'flex',
                    }}
                  >
                    <Typography>
                      {item?.lessonName ? item?.lessonName : '-'}
                    </Typography>
                  </Div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    borderTop: 1,
                    borderColor: 'divider',
                  }}
                >
                  <UserLessonDetailss
                    key={item.lessonId}
                    handleListItemClickCallBack={handleListItemClickCallBack}
                    selectedIndex={selectedIndex}
                    selectedListItem={selectedListItem}
                    setSelectedListItem={setSelectedListItem}
                    lesson={item}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default UserCampaigns;
