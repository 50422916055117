import React, { useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  Typography,
  TableHead,
  TablePagination,
  Grid,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import users from "modules/users";
import AddUser from "./AddUser";
import UserCell from "./userCell";
import AddMultipleUsers from "./AddMultipleUsers";
import JumboListNoDataPlaceholder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder";
import NoDataPlaceholder from "app/shared/NoDataPlaceholder/NoDataPlaceholder";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import AddBulkUsers from "./AddBulkUsers";

import { useSelector } from "react-redux";
import license from "modules/license";
import Div from "@jumbo/shared/Div";
import { useTranslation } from "react-i18next";

const UsersList = (props) => {
  const Swal = useSwalWrapper();
  const { t } = useTranslation();

  const { isAuthenticated, user } = useSelector(({ login }) => login);

  const { searchReslts } = useJumboAuth();
  const { searchTerm } = useJumboAuth();
  const [userDialog, setUserDialog] = React.useState({
    open: false,
    data: {
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      department: "",
      role: "",
      status: "",
      language: "",
      dob: "",
    },
  });
  const [userList, setUserList] = React.useState([]);
  const [searchText, setSearchText] = React.useState([]);
  const [isLoading, setIsloading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [openAddMultiple, setOpenAddMultiple] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);

  const [sizee, setSizee] = useState(0);
  React.useEffect(() => {
    getAllLicenses();
    if (searchReslts.length > 0) {
      setUserList(searchReslts);
    } else if (searchTerm) {
      // If searchReslts is empty and searchTerm is provided, show "No results found"
      setUserList([]);
    } else {
      getUsers();
    }
    // const dataToParentForDeleteCheck = () => {
    // props.recData(userList);
    // };
  }, [page, props.open, searchReslts]);
  const fetchUsers = (fetchFunction, fetchParams) => {
    fetchFunction(...fetchParams, (response) => {
      if (response.status === "success") {
        setUserList(response.data);
        setTotalCount(page * 15 + 15);
        setSizee(response.data?.length);
      } else {
        setUserList([]);
        setTotalCount(0);
      }
      setIsloading(false);
    });
  };

  const getUsers = () => {
    if (props?.groupId) {
      fetchUsers(users.getUsersByGroupId, [props.groupId, page, rowsPerPage]);
    } else if (props?.departmentId) {
      fetchUsers(users.getUsersByDepartmentId, [
        props.departmentId,
        page,
        rowsPerPage,
      ]);
    } else if (props?.campaignId) {
      fetchUsers(users.getUsersByCampaignId, [
        props.campaignId,
        page,
        rowsPerPage,
      ]);
    } else if (props?.phishing) {
      fetchUsers(users.getUsersByPhishingCampaignId, [
        props.phishing,
        page,
        rowsPerPage,
      ]);
    } else {
      fetchUsers(users.getAllUsers, [page, rowsPerPage]);
    }
  };

  const closeDialog = () => {
    setUserDialog({ ...userDialog, open: false });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const openDialog = () => {
    if (
      props?.departmentId ||
      props?.campaignId ||
      props.groupId ||
      props?.phishing
    ) {
      setOpenAddMultiple(true);
    } else {
      setUserDialog({ ...userDialog, open: true });
    }
  };
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const handleUserIdsChange = (userIds) => {
    setSelectedUserIds(userIds);
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((contact) => contact);
      setSelectedRows(newSelecteds);
    } else {
      setSelectedRows([]);
    }
    setIsDialogOpen(selectedRows.length > 0);
  };

  // const licenseToUser = data => {
  const licenseToUser = (licenseId, users) => {
    const data = {
      licenseId: licenseId,
      users: users,
    };
    license.LicenseToUser(data, (response) => {
      if (response.status === "success") {
        getAllLicensesById(licenseId);
        getUsers();
        Swal.fire({
          icon: "success",
          title: "license to user has been added successfully.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "There is an Error while adding license to user.",
        });
      }
    });
  };
  const handleConfirmClick = () => {
    if (licenseId && selectedRows.length > 0) {
      // Call licenseToUser with licenseId and selectedUserIds
      let users = [];
      selectedRows?.map((sr) => {
        users.push({ id: sr.id });
      });
      licenseToUser(licenseId, users);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please select a license and at least one user to confirm.",
      });
    }
    setIsDialogOpen(false);
  };
  const [licenseId, setLicenseId] = useState(1);

  const handleLicenseIdChange = (event) => {
    const selectedLicenseId = event.target.value;
    setLicenseId(selectedLicenseId);
    // Fetch license data when license ID changes
    getAllLicensesById(selectedLicenseId);
  };
  const [licenseList, setLicenseList] = React.useState([]);
  const getAllLicenses = () => {
    license.getAllLicenses((response) => {
      if (response.status === "success") {
        setLicenseList(response.data);
      } else {
        setLicenseList([]);
      }
    });
  };
  const handleLicenseDropdownClick = () => {
    // When the dropdown is clicked, fetch all licenses.
    getAllLicenses();
    getAllLicensesById(licenseId);
  };

  const [licenseLists, setLicenseLists] = React.useState([]);

  const [assignedLisences, setAssignedLisences] = useState("");
  const [purchasedQuantity, setPurchasedQuantity] = useState("");

  const getAllLicensesById = (id) => {
    license.getLicenseById(id, (response) => {
      if (response.status === "success") {
        const licenseData = response.data;
        setAssignedLisences(licenseData.assignedLisences);
        setPurchasedQuantity(licenseData.purchasedQuantity);
      } else {
        // setLicenseLists([]);
        setAssignedLisences("");
        setPurchasedQuantity("");
      }
    });
  };

  const [isDialogOpen, setIsDialogOpen] = React.useState({
    open: false,
    data: {
      licenseId: "",
      purchasedQuantity: "",
      assignedLisences: "",
    },
  });

  const openDialogs = () => {
    setIsDialogOpen({ ...isDialogOpen, open: selectedRows.length > 0 });
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    let departmentId = props.departmentId && props?.departmentId;
    let groupId = props.groupId && props.groupId;
    let campaignId = props.campaignId && props.campaignId;
    let phishingId = props.phishing && props.phishing;
    if (departmentId) {
      users.searchUsersByDepartmentId(
        departmentId,
        e.target.value,
        (response) => {
          if (response.status === "success") {
            setUserList(response.data);
          } else {
            users.getUsersByDepartmentId(
              props.departmentId,
              page,
              rowsPerPage,
              (response) => {
                if (response.status === "success") {
                  setUserList(response.data);
                } else {
                  setUserList();
                }
              }
            );
          }
        }
      );
    } else if (groupId) {
      users.searchUsersByGroupId(groupId, e.target.value, (response) => {
        if (response.status === "success") {
          setUserList(response.data);
        } else {
          users.getUsersByGroupId(groupId, page, rowsPerPage, (response) => {
            if (response.status === "success") {
              setUserList(response.data);
            } else {
              setUserList();
            }
          });
        }
      });
    } else if (campaignId) {
      users.searchUsersByCampaignId(campaignId, e.target.value, (response) => {
        if (response.status === "success") {
          setUserList(response.data);
        } else {
          users.getUsersByCampaignId(
            props.campaignId,
            page,
            rowsPerPage,
            (response) => {
              if (response.status === "success") {
                setUserList(response.data);
              } else {
                setUserList();
              }
            }
          );
        }
      });
    } else if (phishingId) {
      users.searchUsersByPhishingCampaignId(
        phishingId,
        e.target.value,
        (response) => {
          if (response.status === "success") {
            setUserList(response.data);
          } else {
            users.getUsersByPhishingCampaignId(
              phishingId,
              page,
              rowsPerPage,
              (response) => {
                if (response.status === "success") {
                  setUserList(response.data);
                } else {
                  setUserList();
                }
              }
            );
          }
        }
      );
    }
  };

  const closeDialogs = () => {
    setIsDialogOpen(false);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };
  return (
    <React.Fragment>
      <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"h2"} mt={1}>
          {t("pages.title.users")}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {user?.roleId === 0 && (
            <Button variant="contained" onClick={() => openDialog()}>
              {t("pages.title.addUser")}
            </Button>
          )}

          {user?.roleId === 0 && (
            <Button
              variant="contained"
              onClick={openDialogs}
              sx={{ marginLeft: "8px" }}
            >
              AssignLicenses
            </Button>
          )}
        </Box>
        {user?.roleId !== 0 && (
          <Box sx={{ columnGap: 2, display: "flex", mr: -2 }}>
            {props?.departmentId ||
            props?.campaignId ||
            props.groupId ||
            props?.phishing ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  size="small"
                  style={{
                    width: "250px",
                    borderRadius: "20%",
                    margin: "0 auto",
                    marginBottom: "2rem",
                  }}
                  label="Search Users"
                  value={searchText}
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  InputProps={{
                    endAdornment: <SearchIcon />,
                  }}
                />
              </Box>
            ) : (
              <Grid>
                <AddBulkUsers />
              </Grid>
            )}
            <Button
              sx={{ height: "36px" }}
              variant="contained"
              onClick={() => openDialog()}
            >
              {t("pages.title.addUser")}
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Dialog
          open={isDialogOpen.open}
          onClose={closeDialog}
          aria-labelledby="dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="dialog-title">Assign License </DialogTitle>
          <DialogContent>
            <Div sx={{ display: "flex" }}>
              {/* <InputLabel htmlFor="licenseId"> */}
              <Typography>Licensed Id</Typography>
              {/* </InputLabel> */}
              <Select
                labelId="licenseIdLabel"
                id="licenseId"
                value={licenseId}
                onChange={handleLicenseIdChange}
                onClick={handleLicenseDropdownClick}
                sx={{ marginLeft: 1, maxHeight: "30px" }}
              >
                {licenseList.map((license) => (
                  <MenuItem key={license.id} value={license.id}>
                    {license.id}
                  </MenuItem>
                ))}
              </Select>
            </Div>
            <Typography>Assigned License: {assignedLisences}</Typography> <br />
            <Typography>Purchased Quantity: {purchasedQuantity}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeDialogs}>
              Discard
            </Button>
            <Button variant="contained" onClick={handleConfirmClick}>
              confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      {isLoading ? (
        <div className="loader" style={{ marginBottom: "15%" }}>
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : userList?.length <= 0 && !isLoading && page === 0 ? (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  padding: "0px !important",
                  boxShadow: "rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem",
                  borderRadius: "12px",
                  backgroundColor: " rgb(255, 255, 255)",
                }}
              >
                {user?.roleId === 0 && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedRows.length === userList.length &&
                        userList.length > 0
                      }
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < userList.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                <TableCell>{t("pages.title.name")}</TableCell>
                <TableCell>{t("pages.title.username")}</TableCell>
                <TableCell>{t("pages.title.email")}</TableCell>
                <TableCell>{t("pages.title.phoneNumber")}</TableCell>
                <TableCell>{t("pages.title.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.map((contact, index) => (
                <UserCell
                  padding={"0px !important"}
                  key={index}
                  index={index}
                  contact={contact}
                  getAllUsers={getUsers}
                  groupId={props?.groupId}
                  phishingId={props.phishing}
                  campaignId={props?.campaignId}
                  departmentId={props?.departmentId}
                  handleSelectAllClick={handleSelectAllClick}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  onUserIdsChange={handleUserIdsChange}
                />
              ))}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPageOptions={[15]}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            backIconButtonProps={{
              disabled: page === 0,
            }}
            nextIconButtonProps={{
              disabled: sizee < 15,
            }}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${from + 15 - 1}`;
            }}
          />
        </TableContainer>
      )}
      {userDialog.open && (
        <AddUser
          open={userDialog.open}
          close={closeDialog}
          getAllUsers={getUsers}
          data={userDialog.data}
        />
      )}

      <AddMultipleUsers
        close={() => setOpenAddMultiple(false)}
        open={openAddMultiple}
        groupId={props?.groupId}
        getAllUsers={getUsers}
        campaignId={props?.campaignId}
        phishingId={props.phishing}
        departmentId={props?.departmentId}
        Swal={Swal}
      />
    </React.Fragment>
  );
};

export default UsersList;
