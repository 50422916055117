import React from 'react';
import Div from '@jumbo/shared/Div';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button, Typography } from '@mui/material';
import UsersList from '../list-views/UsersList/UsersList';
import EditGroupDetails from './EditGroupDetails';
import { useTranslation } from 'react-i18next';


function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Div sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Div>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GroupDetails = ({ group, getAllGroup, expanded, setExpanded }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  return (
    <Div sx={{ width: '100%' }}>
      <Div sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
          <Tab label={t("widgets.title.groupDetails")} {...a11yProps(0)} />
          <Tab label={t("widgets.title.users")} {...a11yProps(1)} />
        </Tabs>
      </Div>
      <TabPanel value={value} index={0}>
        <EditGroupDetails
          groupData={group}
          getAllGroup={getAllGroup}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UsersList groupId={group.id} />
      </TabPanel>
    </Div>
  );
};

export default GroupDetails;
