import React, { useEffect, useState } from 'react';
import { Button, Grid, Input, TextField } from '@mui/material';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Div from '@jumbo/shared/Div';
import company from 'modules/company';
const Domain = () => {
  const Swal = useSwalWrapper();
  const [companyData, setCompanyData] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = () => {
    let Companyid = 1;
    company.getcompanyById(Companyid, response => {
      if (response.status === 'success') {
        setData(response.data);
      } else {
      }
    });
  };
  const hanldeSubmit = () => {
    let id = 1;
    company.updateCompany(id, data, response => {
      if (response.status === 'success') {
        setCompanyData(response.data);
        Swal.fire({
          icon: 'success',
          title: 'Domain has been updated successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while adding Domain.',
        });
      }
    });
  };
  const handleChange = event => {
    setData({
      ...companyData,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Div>
      <Grid container>
        <Grid item xs={6} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
          <TextField
            fullWidth
            name="allowedDomain"
            label="Domain"
            onChange={handleChange}
            value={data?.allowedDomain || ''}
          />
        </Grid>
        <Grid item xs={6} md={6} sm={6} sx={{ mb: 1, mt: 2 }}>
          <Button
            sx={{ height: '40px', ml: 2 }}
            variant="contained"
            onClick={hanldeSubmit}
            autoFocus
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Div>
  );
};
export default Domain;
