import React from 'react';
import campaigns from 'modules/campaigns';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddLessons from './AddLessons';
import GridWithHeader from 'app/CommonComponents/GridWithHeader';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
function LessonDetails({ campaign, Swal }) {
  const [isLoading, setIsloading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [lessonList, setLessonList] = React.useState([]);
  const { showDialog, hideDialog } = useJumboDialog();
  const [addLesson, setAddLesson] = React.useState({
    open: false,
    data: {
      lessonName: '',
      maxQuestions: '',
      seek: false,
      policy: false,
      quiz: false,
    },
  });

  React.useEffect(() => {
    getAllCampaignsLessons();
  }, []);
  const headers = {
    main: 'Lessons',
    addButton: 'Add Lessons',
    columns: [
      { name: 'lessonName', label: 'Lesson Name' },
      { name: 'maxQuestions', label: 'Max Questions' },
    ],
  };
  const getAllCampaignsLessons = () => {
    campaigns.getLessonsByCampaignId(campaign.id, response => {
      if (response.status === 'success') {
        setLessonList(response.data);
        setIsloading(false);
      } else {
        setIsloading(false);

        setLessonList([]);
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllCampaignsLessons();
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleItemAction = (actionType, item) => {
    switch (actionType.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this Lesson?',
          content: "You won't be able to recover this Lesson later",
          onYes: () => handleDelete(item.id),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit(item);
        break;
    }
  };
  const handleDelete = id => {
    campaigns.deleteCampaignLessons(id, response => {
      if (response.status === 'success') {
        getAllCampaignsLessons();
        Swal.fire({
          icon: 'success',
          title: 'Lesson has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting Lesson.',
        });
      }
    });
  };
  const handleEdit = data => {
    setAddLesson({ ...addLesson, open: true, data: data });
  };

  return (
    <>
      <GridWithHeader
        data={lessonList}
        isLoading={isLoading}
        headers={headers}
        rowsPerPageOptions={[10]}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        actionsColumn={(item, index) => (
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={actionType => handleItemAction(actionType, item)}
          />
        )}
        openDialog={() =>
          setAddLesson({ ...addLesson, open: true, data: addLesson.data })
        }
      />

      {addLesson.open && (
        <AddLessons
          open={addLesson.open}
          close={() =>
            setAddLesson({
              ...addLesson,
              open: false,
              data: {
                lessonName: '',
                maxQuestions: '',
                seek: false,
                policy: false,
                quiz: false,
              },
            })
          }
          data={addLesson.data}
          getAllCampaignsLessons={getAllCampaignsLessons}
          campaignId={campaign.id}
        />
      )}
    </>
  );
}

export default LessonDetails;
const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
