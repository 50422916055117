import Page from '@jumbo/shared/Page';
import AddAssignment from "app/mmEsoft/components/Assignments/addAssignment";
import ViewAssignment from "app/mmEsoft/components/Assignments/viewAssignment";

const assignmentRoutes = [
    {
        path: '/assignment/addAssignment',
        element: <Page component={AddAssignment} />,
      },
      {
        path: '/assignment/viewAssignment',
        element: <Page component={ViewAssignment} />,
      },
]
export default assignmentRoutes;