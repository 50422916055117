import React from 'react';
import campaigns from 'modules/campaigns';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GridWithHeader from 'app/CommonComponents/GridWithHeader';
import AddServer from './AddServer';
import ldapServer from 'modules/lpadServers';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

function LdapServers() {
  const Swal = useSwalWrapper();
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { showDialog, hideDialog } = useJumboDialog();
  const [ldapDerverList, setLdapServerList] = React.useState([]);
  const [serverDialog, setServerDialog] = React.useState({
    open: false,
  });
  const setOpenServerDialog = data => {
    setServerDialog({ open: true, data: data });
  };

  const handleCloseDialog = () => {
    setServerDialog({ open: false });
  };
  // const [addLesson, setAddLesson] = React.useState({
  //   open: false,
  //   data: {
  //     lessonName: "",
  //     maxQuestions: "",
  //     seek: false,
  //     policy: false,
  //     quiz: false,
  //   },
  // });

  React.useEffect(() => {
    getAllLdapServers();
  }, []);
  const viewHeaders={
    main: 'LDAP Servers',
    addButton: 'Add LDAP Server',
    columns: [
      { name: 'title', label: 'Title' },
      { name: 'host', label: 'Host' },
      { name: 'port', label: 'Port' },
      { name: 'securityType',label:'Security Type'},
    ]

  }
  const headers = {
    main: 'LDAP Servers',
    addButton: 'Add LDAP Server',
    columns: [
      { name: 'title', label: 'Title' },
      { name: 'host', label: 'Host' },
      { name: 'port', label: 'Port' },
      { name: 'password',label:'Password'},
      { name: 'bindDn',label:'Bind DN'},
      { name: 'securityType',label:'Security Type'},
      { name: 'base',label:'Base'},
      { name: 'filter',label:'user Filter'},
      { name: 'mapUsername',label:'Map Username'},
      { name: 'mapFirstname',label:'Map FirstName'},
      { name: 'mapLastname',label:'Map LastName'},
      { name: 'mapEmail',label:'Map Email'},
      { name: 'mapDepartment',label:'Map Department'},
      { name: 'mapPhonenumber',label:'Map PhoneNumber'},
      { name: 'mapGroup',label:'Map Group'},
      { name: 'mapGroupFilter',label:'Map GroupFilter'},
      { name: 'mapUserPassword',label:'Map GroupFilter'},
      
    ],
  };
  const addLpadServer = data => {
    serverDialog.data
      ? ldapServer.updateLdapServer(serverDialog.data.id, data, response => {
          if (response.status === 'success') {
            getAllLdapServers();

            Swal.fire({
              icon: 'success',
              title: 'Ldap Server has been updated successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while updating ldap server.',
            });
          }
        })
      : ldapServer.addLdapServer(data, response => {
          if (response.status === 'success') {
            getAllLdapServers();

            Swal.fire({
              icon: 'success',
              title: 'Ldap Server has been added successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while adding ldap server.',
            });
          }
        });
  };
  // const getAllCampaignsLessons = () => {
  //   campaigns.getLessonsByCampaignId(campaign.id, (response) => {
  //     if (response.status === "success") {
  //       setLessonList(response.data);
  //     } else {
  //       setLessonList([]);
  //     }
  //   });
  // };
  const getAllLdapServers = () => {
    ldapServer.getAllLdapServers(response => {
      if (response.status === 'success') {
        setLdapServerList(response.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setLdapServerList([]);
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllLdapServers();
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleItemAction = (actionType, item) => {
    switch (actionType.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this Lesson?',
          content: "You won't be able to recover this Lesson later",
          onYes: () => handleDelete(item.id),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit(item);
        break;
    }
  };
  const handleDelete = id => {
    ldapServer.deleteLdapServer(id, response => {
      if (response.status === 'success') {
        getAllLdapServers();
        Swal.fire({
          icon: 'success',
          title: 'Ldap Server has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting Ldap Server.',
        });
      }
    });
  };
  const handleEdit = data => {
    setServerDialog({ ...serverDialog, open: true, data: data });
  };

  return (
    <>
      <GridWithHeader
        data={ldapDerverList}
        isLoading={isLoading}
        headers={headers}
        viewHeaders={viewHeaders}
        rowsPerPageOptions={[10]}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        actionsColumn={(item, index) => (
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={actionType => handleItemAction(actionType, item)}
          />
        )}
        openDialog={() =>
          setServerDialog({ ...serverDialog, open: true, data: '' })
        }
        // onRowClick={user => console.log(user)}
      />
      {serverDialog.open ? (
        <AddServer
          open={serverDialog.open}
          close={handleCloseDialog}
          addLdapServer={addLpadServer}
        />
      ) : null}
      {serverDialog.data && serverDialog.open ? (
        <AddServer
          open={serverDialog.open}
          close={handleCloseDialog}
          addLdapServer={addLpadServer}
          edit={true}
          data={serverDialog.data}
        />
      ) : null}
    </>
  );
}

export default LdapServers;
const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
