import UserProfile from "app/pages/users/user-profile/UserProfile";
import Page from "@jumbo/shared/Page";

const UserProfileRouts = [
  {
    path: "/profile",
    element: <Page component={UserProfile} />,
  },
];
export default UserProfileRouts;
