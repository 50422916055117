import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import code from '../Menus/demo-code/context-menu.txt';
import { Box, IconButton } from '@mui/material';
import candiate from 'modules/candiate';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const ContextMenu = ({ candidate }) => {
	const [contextMenu, setContextMenu] = React.useState(null);
	const [editDialogOpen, setEditDialogOpen] = useState(false);

	const Swal = useSwalWrapper();

	const [summary, setsummary] = useState({
		summary: candidate?.summary || '',
	});

	const handleContextMenu = event => {
		event.preventDefault();
		setContextMenu(
			contextMenu === null
				? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
				: null,
		);
	};

	const handleClose = () => {
		setContextMenu(null);
	};

	const handleEditClick = () => {
		setEditDialogOpen(true);
	};

	const handleEditDialogClose = () => {
		setEditDialogOpen(false);
	};

	const handleSave = () => {
		candiate.updateCandidate(candidate?.id, summary, response => {
			if (response.status === 'success') {
				Swal.fire({
					icon: 'success',
					title: 'summary added successfully',
				});
			} else {
				Swal.fire({
					icon: 'error',
					title: 'summary not added',
				});
			}
		});
		setEditDialogOpen(false);
	};

	return (
		<JumboCardQuick
			demoCode={code}
			wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
			style={{ marginTop: '10px' }}
		>
			<Box sx={{ display: 'flex', columnGap: '20px', alignItems: 'center' }}>
				<Typography fontSize={'20px'}>Profile Summary</Typography>
				<IconButton onClick={handleEditClick}>
					<EditIcon />
				</IconButton>
			</Box>
			<Typography>{summary?.summary || 'Write about yourself...'}</Typography>

			{/* Context Menu */}
			<div
				onContextMenu={handleContextMenu}
				style={{ cursor: 'context-menu' }}
			/>

			{/* Edit Dialog */}
			<Dialog
				open={editDialogOpen}
				onClose={handleEditDialogClose}
				PaperProps={{ style: { borderRadius: 10, minWidth: '500px' } }}
			>
				<DialogTitle>Edit Profile Summary</DialogTitle>
				<DialogContent>
					<TextareaAutosize
						value={summary.summary || ''}
						onChange={e => setsummary({ summary: e.target.value })}
						style={{
							width: '100%', // Make width 100%
							height: '100px', // Set fixed height
							padding: '12px', // Add padding
							borderRadius: '4px', // Add border radius
							border: '1px solid #ced4da', // Add border
							resize: 'none', // Disable resizing
							fontSize: '16px', // Set font size
							fontFamily: 'Arial, sans-serif', // Set font family
							color: '#333', // Set text color
							backgroundColor: '#f8f9fa', // Set background color
							lineHeight: '1.5', // Set line height
							boxShadow: 'none', // Remove any box shadow
							outline: 'none', // Remove outline
						}}
						placeholder='Write about yourself...'
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleEditDialogClose}>Cancel</Button>
					<Button onClick={handleSave}>Save</Button>
				</DialogActions>
			</Dialog>
		</JumboCardQuick>
	);
};

export default ContextMenu;
