import appApi from './appApi';

class UserProgress {
  addUserProgress = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/user-progress/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  user progress', error);
      });
  };

  getAllUserProgressByCampaignId = (campaignId, userId, callBack) => {
    appApi({
      method: 'GET',
      url: '/api/user-progress/campaign/' + campaignId + '/user/' + userId,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting user progress', error);
      });
  };
  getAllUserProgressByUserId = (id, callBack) => {
    appApi({
      method: 'GET',
      url: '/api/user-progress/user/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting user progress', error);
      });
  };

  getUserProgressById = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/user_progress/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting user progress', error);
      });
  };

  updateUserProgressById = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/user-progress/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating user progress', error);
      });
  };
}

export default new UserProgress();
