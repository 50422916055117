import {
  TextField,
  Stack,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
} from '@mui/material';
import { useState } from 'react';
import ContactModule from 'app/mmEsoft/module/ContactModule';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useGetAllLabels } from './filterContacts/hooks/useGetAllLabels';
import { useGetAllCollages } from './filterContacts/hooks/useGetAllCollages';
import { useGetAllCourseRtln } from './filterContacts/hooks/useGetAllCourseRtln';
import { InputLabel } from '@material-ui/core';
function AddContact({ getAllContacts }) {
  const Swal = useSwalWrapper();

  const [addOpenModal, setAddOpenModal] = useState(false);

  const handleOpenModal = () => {
    setAddOpenModal(true);
  };
  const handleCloseModal = () => {
    setAddOpenModal(false);
  };
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    addressId: null,
    leadDate: null,
    labels: '',
    semester: '',
    college: '',
    courseRelation: '',
    remarks: '',
    // "alumni": false,
  });

  const handleFormDataOnChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    setFormData((formData) => {
      return {
        ...formData,
        [name]: value,
      };
    });
  };
  const handleSubmitFormData = () => {
    ContactModule.addContact(formData, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Contact is Added Successfully',
        });
        getAllContacts();
        handleCloseModal();
        setFormData({
          name: '',
          email: '',
          phoneNumber: '',
          addressId: null,
          leadDate: null,
          labels: '',
          semester: '',
          college: '',
          courseRelation: '',
          remarks: '',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: response.msg,
        });
      }
    });
  };

  const { labelsList, loader } = useGetAllLabels();
  // GET CONTACTS
  const { collageList, collageLoader } = useGetAllCollages();
  // GET COURSES
  const { courseList, courseLoder } = useGetAllCourseRtln();

  const semesterList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <>
      <Button variant='contained' onClick={handleOpenModal}>
        Add Contact
      </Button>
      <Dialog
        open={addOpenModal}
        onClose={handleCloseModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'>
          <Typography variant='h3'>Add Contact</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Grid container spacing={2} columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  id='name'
                  label='Name'
                  variant='outlined'
                  fullWidth
                  name='name'
                  value={formData.name}
                  onChange={handleFormDataOnChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id='email'
                  label='Email'
                  fullWidth
                  variant='outlined'
                  name='email'
                  value={formData.email}
                  onChange={handleFormDataOnChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id='phoneNumber'
                  label='Phone Number'
                  fullWidth
                  variant='outlined'
                  name='phoneNumber'
                  value={formData.phoneNumber}
                  onChange={handleFormDataOnChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id='addressId'
                  label='Address'
                  fullWidth
                  variant='outlined'
                  name='addressId'
                  value={formData.addressId}
                  onChange={handleFormDataOnChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel htmlFor='leadDate'>Lead Date</InputLabel>
                <TextField
                  fullWidth
                  id='leadDate'
                  type='date'
                  variant='outlined'
                  name='leadDate'
                  value={formData.leadDate}
                  onChange={handleFormDataOnChange}
                  InputLabelProps={{ shrink: true }} // Ensures the label floats when there is a value
                />
              </Grid>

              <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                <Autocomplete
                  id='labels'
                  fullWidth
                  freeSolo
                  options={labelsList.map((option) => option.code)}
                  name='Labels'
                  value={formData.labels}
                  onChange={(event, value) =>
                    setFormData({ ...formData, labels: value })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='filled'
                      label='Labels'
                      placeholder='Label'
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id='semester'
                  fullWidth
                  label='Semester'
                  name='semester'
                  value={formData.semester}
                  options={semesterList}
                  onChange={(event, value) =>
                    setFormData({ ...formData, semester: value })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Semester'
                      variant='filled'
                      name='semester'
                      onChange={handleFormDataOnChange}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id='College'
                  freeSolo
                  options={collageList.map((option) => option.college)}
                  fullWidth
                  name='college'
                  value={formData.college}
                  onChange={(event, value) =>
                    setFormData({ ...formData, college: value })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='filled'
                      label='College' // Set the label here
                      placeholder='College'
                      onChange={handleFormDataOnChange}
                      name='college'
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id='courseRelation'
                  freeSolo
                  options={courseList.map((option) => option.course_relation)}
                  fullWidth
                  name='courseRelation'
                  value={formData.courseRelation}
                  // onChange={handleFormDataOnChange}
                  onChange={(event, value) =>
                    setFormData({ ...formData, courseRelation: value })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='filled'
                      label='Course Relation'
                      placeholder='Course Relation'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  id='remarks'
                  label='Remarks'
                  name='remarks'
                  value={formData.remarks}
                  onChange={handleFormDataOnChange}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleSubmitFormData} autoFocus variant='contained'>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default AddContact;
