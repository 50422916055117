import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Div from '@jumbo/shared/Div';
import GroupDetails from './GroupDetails';
import AddGroup from './AddGroup';
import groups from 'modules/groups';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import dataAndTimeFormatter from 'modules/dataAndTimeFormatter';
import DateAndTimePickers from '../components/mui/Pickers/DateAndTimePickers';
import { useTranslation } from 'react-i18next';
const Groups = () => {
  const Swal = useSwalWrapper();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const [openGroupDialog, setOpenGroupDialog] = React.useState(false);
  const [groupData, setGroupData] = React.useState([]);
  const [isLoading, setIsloading] = React.useState(true);

  React.useEffect(() => {
    getAllGroup();
  }, []);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseDialog = () => {
    setOpenGroupDialog(false);
  };
  const addGroup = data => {
    groups.addGroup(data, response => {
      if (response.status === 'success') {
        getAllGroup();
        Swal.fire({
          icon: 'success',
          title: 'Group has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while adding group.',
        });
      }
    });
  };
  const getAllGroup = () => {
    groups.getAllGroups(response => {
      if (response.status === 'success') {
        setGroupData(response.data);
        setIsloading(false);
      } else {
        setGroupData([]);
        setIsloading(false);
      }
    });
  };

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'} mt={1}>
          {t("widgets.title.groups")}
        </Typography>
        <Box>
          <Button variant="contained" onClick={() => setOpenGroupDialog(true)}>
            {t("widgets.title.addGroup")}
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <div className="loader" style={{ marginTop: '15%' }}>
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : (
        <>
          {groupData.map((item, index) => (
            <Card sx={{ mb: 1, boxShadow: 1 }}>
              <Accordion
                expanded={expanded === 'panel' + index}
                onChange={handleChange('panel' + index)}
                square
                key={index}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel' + index ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    <Typography>{item?.title1 ? item?.title1 : '-'}</Typography>
                  </Div>
                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '76%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    <Typography sx={{ color: 'text.secondary' }}>
                      {item?.description ? item?.description : '-'}
                    </Typography>
                  </Div>
                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '76%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    <Typography sx={{ color: 'text.secondary' }}>
                      {dataAndTimeFormatter.formatDateToCustomFormat(
                        item?.createdAt
                      )}{' '}
                    </Typography>
                  </Div>
                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '76%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    <Typography sx={{ color: 'text.secondary' }}>
                      {dataAndTimeFormatter.formatDateToCustomFormat(
                        item?.updatedAt
                      )}{' '}
                    </Typography>
                  </Div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    borderTop: 1,
                    borderColor: 'divider',
                    p: theme => theme.spacing(2, 2, 2, 8.25),
                    marginLeft: -8,
                  }}
                >
                  <GroupDetails
                    group={item}
                    getAllGroup={getAllGroup}
                    expanded={expanded}
                    setExpanded={setExpanded}
                  />
                </AccordionDetails>
              </Accordion>
            </Card>
          ))}
        </>
      )}

      <AddGroup
        open={openGroupDialog}
        close={handleCloseDialog}
        addGroup={addGroup}
      />
    </Box>
  );
};

export default Groups;
