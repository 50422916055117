import ContactModule from "app/mmEsoft/module/ContactModule";
import { useEffect, useState } from "react";

export const useGetAllCollages = () => {
  const [collageList, setCollages] = useState([]);
  const [collageLoader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    ContactModule.getAllCollage((response) => {
      if (response.status === "success") {
        setCollages(response.data);
      } else console.log("error while getting collages");
    });
    setLoader(false);
  }, []);

  return { collageList, collageLoader };
};
