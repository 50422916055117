import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GridWithHeader from 'app/CommonComponents/GridWithHeader';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import smsTemplate from 'modules/smsTemplate';
import AddSmsTemplate from './addSmsTemplate';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';

function SmsTemplate() {
  const Swal = useSwalWrapper();
  const [isLoading, setIsLoading] = React.useState(true);
  const [templateDialog, setTemplateDialog] = React.useState({
    open: false,
  });
  const [smsTemplateList, setSmsTemplateList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { showDialog, hideDialog } = useJumboDialog();

  React.useEffect(() => {
    getSmsTemplates();
  }, []);

  const headers = {
    main: 'Sms Template',
    addButton: 'Add SMS Template',
    columns: [
      { name: 'title', label: 'Title' },
      { name: 'type', label: 'Type' },
      { name: 'content', label: 'English Content' },
      { name: 'content1', label: 'Arabic Content' },
    ],
  };

  const getSmsTemplates = () => {
    smsTemplate.getAllSmsTemplates(response => {
      if (response.status === 'success') {
        setSmsTemplateList(response.data);
        setIsLoading(false);
      } else {
        setSmsTemplateList([]);
        setIsLoading(false);
      }
    });
  };
  const addSmsTemplate = data => {
    templateDialog.data
      ? smsTemplate.updateSmsTemplate(
          templateDialog.data.id,
          data,
          response => {
            if (response.status === 'success') {
              getSmsTemplates();

              Swal.fire({
                icon: 'success',
                title: 'SMS Template has been updated successfully.',
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'There is an Error while updating SMS Template.',
              });
            }
          }
        )
      : smsTemplate.addSmsTemplate(data, response => {
          if (response.status === 'success') {
            getSmsTemplates();

            Swal.fire({
              icon: 'success',
              title: 'SMS Template has been added successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while adding SMS Template.',
            });
          }
        });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getSmsTemplates();
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const setOpenServerDialog = data => {
    setTemplateDialog({ open: true, data: data });
  };

  const handleCloseDialog = () => {
    setTemplateDialog({ open: false });
  };
  const handleItemAction = (actionType, item) => {
    switch (actionType.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this SMS Template?',
          content: "You won't be able to recover this SMS Template later",
          onYes: () => handleDelete(item.id),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit(item);
        break;
    }
  };
  const handleDelete = id => {
    smsTemplate.deleteSmsTemplate(id, response => {
      if (response.status === 'success') {
        getSmsTemplates();
        Swal.fire({
          icon: 'success',
          title: 'SMS Template has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting Sms Template.',
        });
      }
    });
  };
  const handleEdit = data => {
    setTemplateDialog({ ...templateDialog, open: true, data: data });
  };

  return (
    <>
      <GridWithHeader
        data={smsTemplateList}
        isLoading={isLoading}
        headers={headers}
        rowsPerPageOptions={[10]}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        // handleEdit={handleEdit}
        actionsColumn={(item, index) => (
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={actionType => handleItemAction(actionType, item)}
          />
        )}
        openDialog={() =>
          setTemplateDialog({ ...templateDialog, open: true, data: '' })
        }
      />

      {templateDialog.open ? (
        <AddSmsTemplate
          open={templateDialog.open}
          close={handleCloseDialog}
          addSmsTemplate={addSmsTemplate}
        />
      ) : null}
      {templateDialog.data && templateDialog.open ? (
        <AddSmsTemplate
          open={templateDialog.open}
          close={handleCloseDialog}
          addSmsTemplate={addSmsTemplate}
          edit={true}
          data={templateDialog.data}
        />
      ) : null}
    </>
  );
}

export default SmsTemplate;
const menuItems = [
  { icon: <EditIcon />, title: 'View/Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
