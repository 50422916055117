import appApi from "./appApi";

class Search {
  getBySearch = (source, term, callBack) => {
    return appApi({
      method: "GET",
      url: "/api/search/" + source + "/" + term,
      source: source,
      term: term,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
        console.log(response.data);
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while searching", error);
      });
  };
}
export default new Search();
