import React, { useContext, useEffect, useState } from 'react';
import { Search, SearchIconWrapper, StyledInputBase } from './style';
import { IconButton, Box, Typography, Button, Grow } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import search from 'modules/search';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useIdleTimer } from 'react-idle-timer';
import auth from 'modules/auth';
import { useSelector, useDispatch } from 'react-redux';
import { Logout } from 'app/redux/actions/login';
import { refreshActive } from 'app/constant';

const SearchGlobal = ({ sx }) => {
  const location = useLocation();
  const pathnameSegments = location.pathname.split('/');
  const source = pathnameSegments[pathnameSegments.length - 1];
  const { setSearchReslts } = useJumboAuth();
  const { searchTerm, setSearchTerm } = useJumboAuth();
  // const [event, setEvent] = useState('Event');
  // const [elapsed, setElapsed] = useState(0);
  // const [refreshTokenTime, setRefreshTokenTime] = useState(0);
  const [waitTime, setWaitTime] = useState({
    m: 2,
    s: 58,
  });
  const [popUp, setPopUp] = useState(false);
  const { isAuthenticated, user } = useSelector(({ login }) => login);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  setSearchTerm(searchTerm);
  const handleSearch = (source, searchTerm) => {
    search.getBySearch(source, searchTerm, response => {
      if (response.status === 'success') {
        setSearchReslts(response?.data?.results);
      } else {
        console.log('Error while searching');
      }
    });
  };
  useEffect(() => {
    if (
      source === 'users' ||
      source === 'lessons' ||
      source === 'exams' ||
      source === 'mycampaigns' ||
      source === 'contacts' ||
      source === 'batches'
    )
      handleSearch(source, searchTerm);
  }, [searchTerm]);

  // const onAction = (event) => {
  //   setEvent(event ? event.type : 'Event');
  //   reset();
  // };

  // const { getElapsedTime, reset } = useIdleTimer({
  //   onAction,
  //   timeout: 1000 * 60 * 25,
  //   throttle: 500,
  // });

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setElapxsed(Math.ceil(getElapsedTime() / 1000));
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [getElapsedTime]);

  // useEffect(() => {
  //   if (elapsed >= 1500) {
  //     setPopUp(true);
  //   }
  //   if (popUp) {
  //     if (!user.id === 348) {
  //       setWaitTime((prev) => {
  //         return { m: prev.m, s: prev.s - 2 };
  //       });
  //       if (waitTime.s <= 1) {
  //         if (waitTime.m > 0) {
  //           setWaitTime((prev) => {
  //             return { m: prev.m - 1, s: 58 };
  //           });
  //         } else {
  //           const logoutData = {
  //             userId: user?.id,
  //             userAgent: navigator.userAgent,
  //           };
  //           auth.logoutUser(logoutData, (response) => {
  //             if (response.status === 'success') {
  //             } else {
  //               console.log('Error while logout user');
  //             }
  //           });
  //           dispatch(Logout());
  //           navigate('/user/login');
  //         }
  //       }
  //     }
  //   }
  // }, [elapsed]);

  // call refresh token end point
  useEffect(() => {
    let refreshInterval;
    if (refreshActive) {
      const data = {
        refToken: localStorage.getItem('refreshToken'),
      };
      refreshInterval = setInterval(() => {
        auth.refreshToken(data, response => {});
      }, 10 * 60 * 1000);
    }

    return () => clearInterval(refreshInterval);
  }, []);

  // console.log(user);

  const handleLogout = () => {
    const logoutData = {
      userId: user?.id,
      userAgent: navigator.userAgent,
    };
    auth.logoutUser(logoutData, response => {
      dispatch(Logout());
      navigate('/user/login');
    });
  };

  return (
    <>
      {popUp ? (
        <Box
          sx={{
            position: 'absolute',
            padding: '20px',
            display: 'flex',
            backgroundColor: '#ffcdd2',
            gap: '10px',
            left: '50%',
            top: '90%',
            width: '350px',
            border: '2px solid #c62828',
            boxShadow: '20px 20px 20px #0000',
            flexDirection: 'column',
            borderRadius: '5px',
          }}
        >
          <Typography
            style={{
              fontSize: '18px',
              color: '#c62828',
            }}
          >
            Your Session will be abort in {waitTime.m} :{' '}
            {waitTime.s >= 9 ? waitTime.s : '0' + waitTime.s}
          </Typography>
          <small
            style={{
              color: '#c62828',
            }}
          >
            Please click on continue if u want to continue your session
          </small>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'end',
            }}
          >
            <Button onClick={handleLogout} variant="outlined" size="small">
              logout
            </Button>
            <Button
              onClick={() => {
                // reset();
                setPopUp(false);
                setWaitTime({ m: 2, s: 58 });
              }}
              variant="contained"
              size="small"
            >
              continue
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <Search sx={sx}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>

        <StyledInputBase
          placeholder="Search anything"
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </Search>
    </>
  );
};

export default SearchGlobal;
