import { useState, useEffect } from 'react';
import AttendanceModule from '../../../module/attendanceModule';

const useGetAttancence = (batchId) => {
	const [attendances, setAttandence] = useState([]);
	const [loading, setLoading] = useState(false);

	const getAttendance = (batchId) => {
		setLoading(true);
		try {
			AttendanceModule.getAttendance(batchId, (response) => {
				if (response.status === 'success') {
					setAttandence(response.data);
					setLoading(false);
				} else {
					setAttandence(response.data);
					setLoading(false);
				}
			});
		} catch (error) {}
	};

	useEffect(() => {
		getAttendance(batchId);
	}, [batchId]);

	return { attendances, loading, getAttendance };
};

export default useGetAttancence;
