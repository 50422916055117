import {
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  TableHead,
  CircularProgress
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import UserEmail from 'app/mmEsoft/module/UserEmail';


export default function SendEmail() {
  const [emailHistory, setEmailHistory] = useState([])
  const [loading, setLoading] = useState(true);

  let getEmailHistory=()=>{
    
      UserEmail.getEmailHIstory( response => {
        console.log(response.data.length);
        
        if (response.status == 'success') {
          setEmailHistory(response.data);
          setLoading(false);
        } else {
          setEmailHistory([]);
          setLoading(false);
        }
      });
    };
  
 

  const handleRetry=(id)=>{
    console.log(id);
  }
  
  useEffect(()=>{
    getEmailHistory();
  },[])
  return (
    <Box>
      <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  padding: '0px !important',
                  boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
                  borderRadius: '12px',
                  backgroundColor: 'rgb(255, 255, 255)',
                  whiteSpace: 'nowrap',
                }}
              >
                <TableCell>Email</TableCell>
                <TableCell>sent</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
           { loading ? 
             <TableRow>
             <TableCell colSpan={4} sx={{ textAlign: 'center', padding: '20px' }}>
               <CircularProgress />
             </TableCell>
           </TableRow>
         
         : 
          emailHistory.length==0?
          <TableCell colSpan={4} sx={{ textAlign: 'center', padding: '20px' }}>
               <h2 style={{color:"gray"}}>NO DATA AVAILABLE</h2>
             </TableCell>
          :emailHistory.map((v,i)=>{
            return(
              <TableRow key={i}>
                  <TableCell>{v.email_id}</TableCell>
                  <TableCell>{v.is_sent?"Yes":"No"}</TableCell>
                  <TableCell>{v.name}</TableCell>
                  <TableCell>
                    <Button onClick={()=>handleRetry(v.id)}>
                      <Tooltip title="retry">
                        <ReplayIcon/> 
                      </Tooltip>
                    </Button>
                     
                  </TableCell>
                </TableRow>
            )
          })
                }
            </TableBody>
          </Table>
        </TableContainer>
    </Box>
  )
}
  
