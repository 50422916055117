class Utils {
  constructor() {}
  convertToISTDate = timestamp => {
    let utc = new Date(timestamp);
    var dateUTC = new Date(utc); // format should be like '2023-11-20T10:30:00'
    var dateUTC = dateUTC.getTime();
    var dateIST = new Date(dateUTC);
    //date shifting for IST timezone (+5 hours and 30 minutes)
    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);

    let dateTimeIST = new Date(dateIST);

    const year = dateTimeIST.getFullYear();
    const month = String(dateTimeIST.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(dateTimeIST.getDate()).padStart(2, '0');
    const hours = String(dateTimeIST.getHours()).padStart(2, '0');
    const minutes = String(dateTimeIST.getMinutes()).padStart(2, '0');
    const seconds = String(dateTimeIST.getSeconds()).padStart(2, '0');

    const formattedString = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedString;
  };
  // Converting Time To Indiand Standerd Strat
  //TODO: Use moment.js formatting.
  // convertToISTDate = timestamp => {
  //   let [datePart, timePart] = timestamp.split('T');
  //   let [yearr, monthh, dayy] = datePart.split('-');
  //   let [hourss, minutess, secondss] = timePart?.split(':');

  //   // Create a new Date object with the swapped day and month
  //   let utc = new Date(
  //     `${yearr}-${monthh}-${dayy}T${hourss}:${minutess}:${secondss}Z`
  //   );
  //   // let utc = new Date(timestamp);
  //   var dateUTC = new Date(utc); // format should be like '2023-11-20T10:30:00'
  //   var dateUTC = dateUTC.getTime();
  //   var dateIST = new Date(dateUTC);
  //   //date shifting for IST timezone (+5 hours and 30 minutes)
  //   dateIST.setHours(dateIST.getHours() + 5);
  //   dateIST.setMinutes(dateIST.getMinutes() + 30);

  //   let dateTimeIST = new Date(dateIST);

  //   const year = dateTimeIST.getFullYear();
  //   const month = String(dateTimeIST.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
  //   const day = String(dateTimeIST.getDate()).padStart(2, '0');
  //   const hours = String(dateTimeIST.getHours()).padStart(2, '0');
  //   const minutes = String(dateTimeIST.getMinutes()).padStart(2, '0');
  //   const seconds = String(dateTimeIST.getSeconds()).padStart(2, '0');

  //   const formattedString = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  //   return formattedString;
  // };

  calculateGradeLetter = grade => {
    let gradeLetter;
    if (grade === null || grade === undefined) {
      gradeLetter = '--';
    } else if (grade >= 85 && grade <= 100) {
      gradeLetter = 'A';
    } else if (grade >= 70 && grade <= 84) {
      gradeLetter = 'B';
    } else if (grade >= 55 && grade <= 69) {
      gradeLetter = 'C';
    } else {
      gradeLetter = 'F';
    }
    return gradeLetter;
  };
}

export default new Utils();
