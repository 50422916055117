import {
  GET_BATCHES,
  GET_BATCHES_FAILURE,
  SELECT_BATCH,
} from '../actions/userBatches';
const initialBacthesState = {
  myBatch: null,
  batchesList: [],
  error: null,
};

const batchesReducer = (state = initialBacthesState, action) => {
  switch (action.type) {
    case GET_BATCHES:
      return {
        ...state,
        batchesList: action.payload,
        // myBatch: action.payload[0].batchId,
        error: null,
      };
    case SELECT_BATCH:
      return {
        ...state,
        myBatch: action.payload,
        error: null,
      };
    case GET_BATCHES_FAILURE:
      return {
        ...state,
        batchesList: [],
        error: 'Failed to fetch batches',
      };
    default:
      return state;
  }
};

export default batchesReducer;
