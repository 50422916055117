import Page from '@jumbo/shared/Page';
import UserCertificates from 'app/clientSide/certificates/userCertificates';

const userCertificatesRoutes = [
  {
    path: '/user-certificates',
    element: <Page component={UserCertificates} />,
  },
];
export default userCertificatesRoutes;
