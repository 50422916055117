import React from 'react';
import {
  alpha,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
const RecentActivityItem = ({
  content,
  cardHeaderTitle,
  phishingCount,
  pcType,
}) => {
  const { theme } = useJumboTheme();
  return (
    <React.Fragment>
      <Typography
        variant={'body1'}
        color={'text.secondary'}
        sx={{ px: 3, fontSize: 12, my: 1 }}
      >
        {/* {moment(recentItem.createdAt).format('MMMM DD, YYYY')} */}
      </Typography>
      <List
        sx={{
          p: theme => theme.spacing(0, 0, 1),
        }}
      >
        <ListItemButton
          component={'li'}
          // key={index}
          alignItems={'flex-start'}
          sx={{
            px: 3,
            transition: 'all 0.2s',

            '&:hover': {
              boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
              transform: 'translateY(-4px)',
            },
          }}
        >
          <ListItemAvatar style={{ marginLeft: '-2vw' }}>
            <PrivacyTipIcon
              alt={cardHeaderTitle}
              style={{
                width: '40px',
                height: '40px',
                color: theme.palette.primary.main,
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                component={'div'}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography
                  variant={'body1'}
                  color={'text.primary'}
                  sx={{ fontSize: 16, my: 1 }}
                >
                  {cardHeaderTitle}
                </Typography>
                <Typography
                  variant={'body1'}
                  color={'text.primary'}
                  sx={{ px: 1, fontSize: 16, my: 1 }}
                >
                  {pcType == 'pcAll'
                    ? phishingCount?.all
                    : pcType == 'pcActive'
                    ? phishingCount?.active
                    : pcType == 'pcCompleted'
                    ? phishingCount?.completed
                    : pcType == 'pcUpcoming'
                    ? phishingCount?.upcoming
                    : ''}
                </Typography>
              </Typography>
            }
            secondary={
              <Typography
                variant={'body1'}
                color={'text.secondary'}
                sx={{ fontSize: 16, my: 1 }}
              >
                {content}
              </Typography>
            }
          />
        </ListItemButton>
      </List>
    </React.Fragment>
  );
};
/* Todo :- recent activity item props */
export default RecentActivityItem;
