import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Autocomplete,
  InputAdornment,
  Stack,
  SwipeableDrawer,
  Chip,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useGetAllLabels } from "../Contacts/filterContacts/hooks/useGetAllLabels";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ContactModule from "app/mmEsoft/module/ContactModule";

function AddLabel({ openLabel, handleCloseAddlabel, filterData }) {
  const { labelsList } = useGetAllLabels();
  const [chips, setChips] = useState([]);
  const Swal = useSwalWrapper();
  //LABEL PROPS

  //TODO : Add label to all searched contacts Api intergration  pending
  const [label, setLabel] = useState('');

  //? i dont need this peice of code for now
  // Selected labels state
  // const handleInputlabel = (event, newValue) => {
  //   const chip = newValue?.code;
  //   if (!chip) {
  //     console.log("No chip is selected ");
  //   } else {
  //     setLabel((prevData) => ({
  //       ...prevData,
  //       labels: chip,
  //     }));
  //     setChips((prevChips) => [...prevChips, chip]);

  //     setLabel({
  //       labels: event.target.value,
  //     });
  //   }
  // };

  const handleInputlabel = (event) => {
    let name = event.target.name;
    let value = event.target.value;
 setLabel(value)
  };
  const apiData = {
    newLabel: label,
    courseRelation: filterData.courseRelation,
    labels: filterData.labels,
    semester: filterData.semester,
    college: filterData.college,
  };

  const handleAddlabel = () => {
    ContactModule.sendAddLabel(apiData, (response) => {
        if (response.status === "success") {
        Swal.fire({
          icon: "success",
          title: " Label updated Successfully.",
        });
        handleCloseAddlabel();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error Occured While  updating Label  !",
        });
      }
    });

  };

  return (
    <>
      <Dialog
        open={openLabel}
        onClose={() => handleCloseAddlabel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Add Label</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid>
              <Grid item xs={12} md={12}>
                 <Autocomplete
                  id="labels"
                  freeSolo
                  options={labelsList.map((option) => option.code)}
                  fullWidth
                  name="labels"
                  value={label}
                  // onChange={(event, value) =>
                  //   setLabel({ ...label, labels: value })
                  // }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="labels" // Set the label here
                      placeholder="labels"
                      onChange={handleInputlabel}
                      name="labels"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                  {chips.map((chip) => (
                    <Chip
                      variant="outlined"
                      sx={{ fontSize: "10px" }}
                      label={chip}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            autoFocus
            onClick={handleAddlabel}
          >
            Add Label
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default AddLabel;
