import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	TextField,
} from '@mui/material';
import AddSkillsApi from 'app/mmEsoft/module/AddSkillsApi';
import { useState, useEffect } from 'react';

const EditSkills = ({ item, getAllSkills, openEdit, setEditOpen }) => {
	const [skill, setSkill] = useState({
		skill: '',
		level: '',
		experience: '',
		candidateId: '',
	});

	useEffect(() => {
		setSkill({
			skill: item?.skill,
			experience: item?.experience,
			level: item?.level,
		});
	}, [item]);


	const handleChange = e => {
		const { name, value } = e.target;
		setSkill(prev => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSubmit = e => {
		e.preventDefault();
		AddSkillsApi.editSkill(item.id, skill, response => {
			if (response.status === 'success') {
				getAllSkills();
				setEditOpen(!openEdit);
			} else {
				console.error('Error saving skills');
			}
		});
	};

	return (
		<>
			<Dialog
				open={openEdit}
				onClose={() => setEditOpen(!openEdit)}
			>
				<DialogTitle>Edit Skills</DialogTitle>
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<TextField
							autoFocus
							id='skill'
							name='skill'
							label='Skill'
							onChange={handleChange}
							value={skill.skill}
							type='text'
							fullWidth
						/>
						<FormControl
							sx={{ marginTop: '10px' }}
							fullWidth
						>
							<InputLabel id='skillLevelLabel'>Skill Level</InputLabel>
							<Select
								id='skillLevel'
								name='level'
								value={skill.level || ''}
								onChange={handleChange}
								labelId='skillLevelLabel'
								label='Skill Level'
							>
								<MenuItem value='beginner'>Beginner</MenuItem>
								<MenuItem value='intermediate'>Intermediate</MenuItem>
								<MenuItem value='advanced'>Advanced</MenuItem>
							</Select>
						</FormControl>
						<TextField
							margin='dense'
							id='experience'
							name='experience'
							value={skill.experience}
							label='Experience'
							onChange={handleChange}
							type='number'
							fullWidth
						/>
					</DialogContent>
					<DialogActions>
						<Button
							variant='outlined'
							onClick={() => setEditOpen(!openEdit)}
						>
							Cancel
						</Button>
						<Button
							type='submit'
							variant='outlined'
						>
							Save
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default EditSkills;
