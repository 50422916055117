import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import Result_Show from './Result_Show';
import Certifictes from './Certificates';
import { useState } from 'react';

function Result_Tabs({ userId, candidate, batchId }) {
  const [value, setValue] = useState('1');
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChangeTab}
              aria-label='lab API tabs example'
            >
              <Tab label='Result' value='1' />
              <Tab label='Certifictes' value='2' />
            </TabList>
          </Box>
          <TabPanel value='1'>
            {' '}
            <Result_Show
              userId={userId}
              candidate={candidate}
              batchId={batchId}
            />{' '}
          </TabPanel>
          <TabPanel value='2'>
            {' '}
            <Certifictes candidate={candidate} />{' '}
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
export default Result_Tabs;
