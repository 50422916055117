import appApi from './appApi';
class Certificates {
  addCertificates = (data, callBack) => {
    return appApi({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data', // do not forget this
      },
      url: '/api/certificates/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while adding certificates', error);
        callBack({ status: 'error' });
      });
  };

  getAllCertificates = callBack => {
    return appApi({
      method: 'GET',
      url: `/api/certificates/all`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while getting  certificates', error);
        callBack({ status: 'error' });
      });
  };
  getAll = callBack => {
    return appApi({
      method: 'GET',
      url: `/api/certificates/all-without-pagination`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while getting  certificates', error);
        callBack({ status: 'error' });
      });
  };
  getCertificate = (name, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/certificates/image/${name}` ,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while getting  certificates', error);
        callBack({ status: 'error' });
      });
  };
  getById = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/certificates/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while getting  certificates', error);
        callBack({ status: 'error' });
      });
  };
  getImageById = (id, callBack) => {
    return appApi({
      method: 'GET',
      responseType: 'blob',
      url: '/api/certificates/image/'+ id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while getting  certificates', error);
        callBack({ status: 'error' });
      });
  };
  getLabelByCertificateId = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/labels/certificate/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while getting  certificates', error);
        callBack({ status: 'error' });
      });
  };
    uploadCertificate = (data, callBack) => {
      return appApi({
        method: "POST",
        headers: {
          "content-type": "multipart/form-data", // do not forget this
        },
        url: "/api/certificates/upload",
        data: data,
      })
        .then((response) => {
          callBack({ status: "success", data: response.data });
        })
        .catch((error) => {
          callBack({ status: "error" });
          console.log("error while getting  certificates", error);
        });
    };
}

export default new Certificates();
