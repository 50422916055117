import { InputAdornment, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListIcon from "@mui/icons-material/List";
import Batches from "app/mmEsoft/module/Batches";

const AdminBatchesDropDown = ({ onBatchSelect }) => {
  const [batchesList, setBatchesList] = useState([]);
  const [batch, setBatch] = useState();

  useEffect(() => {
    getAllBatches();
  }, []);

  const getAllBatches = () => {
    Batches.getAllBatches((response) => {
      if (response.status === "success") {
        setBatchesList(response?.data);
        setBatch(response?.data[0]?.id);
        onBatchSelect(response?.data[0]?.id);
      } else {
        console.log("error");
      }
    });
  };

  const handleBatch = (e) => {
    const selectedBatch = e.target.value;
    setBatch(selectedBatch);
    onBatchSelect(selectedBatch);
  };

  return (
    <div>
      <TextField
        size="small"
        required
        name="batch"
        variant="outlined"
        select
        value={batch || (batchesList.length > 0 ? batchesList[0]?.id : "")}
        onChange={(e) => handleBatch(e)}
        style={{ width: "170px" }}
        sx={{
          width: "100%",
          display: "flex",
          "& .MuiOutlinedInput-root": {
            // borderRadius: "20px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            // "& fieldset": {
            //   borderColor: "transparent",
            // },
            "&:hover fieldset": {
              borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ListIcon />
            </InputAdornment>
          ),
        }}
      >
        {batchesList?.length > 0 ? (
          batchesList.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.batchNumber}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="">No batches available</MenuItem>
        )}
      </TextField>
    </div>
  );
};

export default AdminBatchesDropDown;
