import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import phishingCampaign from 'modules/phishingCampaign';

const AddPhishingCampaign = props => {
  const Swal = useSwalWrapper();
  const [selectedImage, setSelectedImage] = useState();
  const [enableAttachment, setEnableAttachment] = useState(false);
  const contentRef = useRef(null);
  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required.'),
      }),
      title1: yup.string().when('title', {
        is: title => !title || title.length === 0,
        then: yup.string().required('At least one of the fields is required.'),
      }),
    },
    ['title', 'title1']
  );
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, []);
  const addPhishingCampaign = data => {
    if (enableAttachment == true) {
      let formData = new FormData();
      formData.append('file', selectedImage);
      formData.append('data', JSON.stringify(data));

      phishingCampaign.addPhishingCampaign(formData, response => {
        if (response.status === 'success') {
          props.getAllPhishingCampaign();
          props.close();
          Swal.fire({
            icon: 'success',
            title: 'Phishing Campaign has been added successfully.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'thsere is an issue While adding Phishing Campaign',
          });
        }
      });
      return;
    }

    let formData = new FormData();
    formData.append('data', JSON.stringify(data));
    phishingCampaign.addPhishingCampaign(formData, response => {
      if (response.status === 'success') {
        props.getAllPhishingCampaign();
        props.close();
        Swal.fire({
          icon: 'success',
          title: 'Phishing Campaign has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'thsere is an issue While adding Phishing Campaign',
        });
      }
    });
  };
  const handleCheckboxChange = event => {
    setEnableAttachment(event.target.checked);
  };
  const handleImageFileinputChange = event => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close} maxWidth="lg">
        <Formik
          validateOnChange={true}
          initialValues={{
            ...props.data,
            image: props?.data?.image
              ? props?.data?.image
              : selectedImage?.name,
            enableAttachment: enableAttachment,
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            data.image = props.data.image
              ? props?.data?.image
              : selectedImage?.name;
            data.enableAttachment = props.data.enableAttachment
              ? props.data.enableAttachment
              : false;
            addPhishingCampaign(data);
          }}
        >
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <DialogContent
              ref={contentRef}
              sx={{ overflowY: 'auto', maxHeight: '80vh' }}
            >
              <DialogTitle id="alert-dialog-title" textAlign="center">
                {props?.data?.id
                  ? 'Edit Phishing Campaign'
                  : 'Add Phishing Campaign'}
              </DialogTitle>
              <Box sx={{ width: '700px' }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} md={12} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="title"
                      label="Title(English)*"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="title1"
                      label="Title ( العربيه )"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField fullWidth name="page" label="Page " />
                  </Grid>

                  <Grid item xs={12} md={12} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      type="date"
                      name="startDate"
                      label="Start Date"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      type="date"
                      name="dueDate"
                      label="Due Date"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={6}
                    sx={{ mb: 2, mt: 1, display: 'flex', alignItems: 'center' }}
                  >
                    <Checkbox
                      value="checkedA"
                      inputProps={{
                        'aria-label': 'Checkbox A',
                      }}
                      checked={enableAttachment}
                      onChange={handleCheckboxChange}
                    />
                    <p>Enable Attachment</p>
                  </Grid>
                  {enableAttachment ? (
                    <>
                      <Grid item xs={12} md={12} sm={6} sx={{ mb: 2, mt: 1 }}>
                        <p>Attachment Name</p>
                        <JumboTextField
                          fullWidth
                          name="attachmentName"
                          label="Name"
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} sm={6} sx={{ mb: 2, mt: 1 }}>
                        <p>Attachment</p>
                        <TextField
                          id="image"
                          name="image"
                          accept=".png,.svg,.jpg,.exe,.docx,.pdf"
                          type="file"
                          fullWidth
                          onChange={handleImageFileinputChange}
                        />
                        {/* <JumboTextField
                          id="image"
                          name="image"
                          accept=".png,.svg, .jpg,.exe,.docx,.pdf"
                          type="file"
                          fullWidth
                          onChange={handleImageFileinputChange}
                        /> */}
                      </Grid>
                    </>
                  ) : (
                    false
                  )}
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type="submit" autoFocus>
                {props?.data?.id ? 'Update' : 'Save'}
              </Button>
              <Button variant="contained" onClick={props.close}>
                {' '}
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddPhishingCampaign;
