import React, { useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	Box,
	Typography,
	TableRow,
	TextField,
	Grid,
} from '@mui/material';
import code from '@mui/icons-material/Code';
import Div from '@jumbo/shared/Div';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { useSelector } from 'react-redux';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import project from 'app/mmEsoft/module/project';
import { Delete, Edit } from '@mui/icons-material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import EditProject from './EditProject';
import { DialogTitle } from '@material-ui/core';

const Project = () => {
	const [open, setOpen] = React.useState(false);
	const { user } = useSelector(({ login }) => login);
	const Swal = useSwalWrapper();
	const [openEdit, setOpenEdit] = React.useState(false);
	const [projectExperience, setProjectExperience] = React.useState();
	const [getProject, setGetProject] = React.useState([]);
	const { showDialog, hideDialog } = useJumboDialog();
	const [selectedProject, setSelectedProject] = React.useState([]);

	const [projectData, setProjectData] = React.useState({
		candidateId: user?.id,
		projectName: '',
		projectLink: '',
		companyName: '',
		startDate: null,
		endDate: null,
		description: '',
	});

	useEffect(() => {
		if (user.id) {
			getAllUSerProjects(user.id);
		}
	}, []);

	const handleChange = event => {
		setProjectData({
			...projectData,
			[event.target.id]: event.target.value,
		});
	};

	const addProject = data => {
		project.addProject(data, response => {
			if (response.status === 'success') {
				Swal.fire({
					icon: 'success',
					title: 'Project has been added successfully.',
				});
				getAllUSerProjects(user.id);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'There is an Error while adding Project.',
				});
			}
		});
		setOpen(false);
	};

	const handleConfirmationDelete = item => {
		showDialog({
			variant: 'confirm',
			title: 'Are you sure about deleting Project?',
			content: "You won't be able to recover this project later",
			onYes: () => handleDelete(item),
			onNo: hideDialog,
		});
	};

	const handleDelete = item => {
		project.deleteProject(item.id, response => {
			if (response.status === 'success') {
				getAllUSerProjects(user.id);
				Swal.fire('Deleted!', 'Project has been deleted.', 'success');
				hideDialog();
			} else {
				Swal.fire({
					icon: 'error',
					title: 'There is an Error while deleting the Project.',
				});
				hideDialog();
			}
		});
	};

	const handleEdit = e => {
		setSelectedProject(e);
		setOpenEdit(true);
	};

	const getAllUSerProjects = id => {
		project.getProjectData(id, response => {
			if (response.status === 'success') {
				setGetProject(response.data);
			} else {
				console.log('ERROR AT GET ALL USER PROJECTS');
			}
		});
	};

	return (
		<>
			<JumboCardQuick
				demoCode={code}
				wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
				style={{ marginTop: '10px' }}
			>
				<Div>
					<Box
						style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}
					>
						<Typography sx={{ fontSize: '20px', fontWeight: '400', marginTop: '10px' }}>
							Project Details
						</Typography>
						<Button
							variant='contained'
							onClick={() => setOpen(true)}
						>
							Add Project
						</Button>
					</Box>
					<Dialog
						open={open}
						onClose={() => setOpen(false)}
					>
						<DialogTitle>Add Project</DialogTitle>
						<DialogContent>
							<TextField
								autoFocus
								margin='dense'
								id='projectName'
								label='Project title'
								onChange={handleChange}
								type='text'
								fullWidth
							/>
							<TextField
								autoFocus
								margin='dense'
								id='projectLink'
								onChange={handleChange}
								label='Project link'
								type='text'
								fullWidth
							/>
							<TextField
								autoFocus
								margin='dense'
								id='companyName'
								onChange={handleChange}
								label='Company Name'
								type='text'
								fullWidth
							/>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									md={6}
								>
									<Typography>Start Date</Typography>
									<TextField
										margin='dense'
										id='startDate'
										type='date'
										fullWidth
										onChange={handleChange}
									/>
								</Grid>
								<Grid
									item
									md={6}
								>
									<Typography>End Date</Typography>
									<TextField
										margin='dense'
										id='endDate'
										type='date'
										fullWidth
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
							<TextField
								autoFocus
								margin='dense'
								id='description'
								label='Project Details'
								multiline
								rows={4}
								onChange={handleChange}
								fullWidth
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setOpen(false)}>Cancel</Button>
							<Button onClick={() => addProject(projectData)}>Save</Button>
						</DialogActions>
					</Dialog>
				</Div>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Project Name</TableCell>
							<TableCell>Project Link</TableCell>
							<TableCell>Company Name</TableCell>
							<TableCell>Start Date</TableCell>
							<TableCell>End Date</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{openEdit ? (
							<EditProject
								user={user}
								candidate={user.candidate}
								getProject={getProject}
								setOpen={setOpenEdit}
								open={openEdit}
								selectedProject={selectedProject}
								getAllUSerProjects={getAllUSerProjects}
							/>
						) : null}
						{getProject?.map(item => (
							<TableRow key={item?.id}>
								<TableCell>{item.projectName}</TableCell>
								<TableCell>{item.projectLink}</TableCell>
								<TableCell>{item.companyName}</TableCell>
								<TableCell>{item.startDate}</TableCell>
								<TableCell>{item.endDate}</TableCell>
								<TableCell>
									<Button sx={{ marginX: '2px' }}>
										<Edit onClick={() => handleEdit(item)} />
									</Button>
									<Button>
										<Delete
											sx={{ marginX: '2px' }}
											onClick={() => handleConfirmationDelete(item)}
										/>
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</JumboCardQuick>
		</>
	);
};

export default Project;
