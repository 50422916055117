import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Typography } from "@mui/material";
import ContactModule from "app/mmEsoft/module/ContactModule";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

function DeleteContact(props) {
  const Swal = useSwalWrapper();
  const handleSubmitDeleteModal=()=>{
     let deleteRowDataId = props?.deleteTableRowData?.id;
    ContactModule.deleteContact(deleteRowDataId, (response) => {
     if (response.status === "success") {
       Swal.fire({
         icon: "success",
         title: "Data deleted Successfully.",
       });
       props.getAllContacts();
     } else {
       Swal.fire({
         icon: "error",
         title: "Error Occured While deleting Data!",
       });
     }
   });
  }
  // // props.handleCloseDelete();

  return (
    <>
      <Dialog
        open={props.deleteMenuModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>Delete Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this?  
            <span style={{ fontWeight: 'bold', color: 'red' }}>  {props.deleteTableRowData.name}</span>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              props.handleCloseDeleteModal();
              props.handleCloseMenuModal();
            }}
          >
            Cancel
          </Button>

          <Button
          variant="contained"
            onClick={() => {
               handleSubmitDeleteModal()
              props.handleCloseMenuModal();
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default DeleteContact;
