import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { Form, Formik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; // Import the CSS for styling

import './ckEditor.css';

import * as yup from 'yup';
import company from 'modules/company';
let Editor = () => <React.Fragment />;
const TermsAndConditionsEditDialog = props => {
  const Swal = useSwalWrapper();
  const contentRef = useRef(null);

  const [open, setOpen] = useState(false);
  const validationSchema = yup.object().shape({
    termsAndConditions: props?.data.id
      ? yup.string()
      : yup.string().required('Terms and Conditions is required'),
  });
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const editorDataa = () => {
    Editor = require('react-draft-wysiwyg').Editor;
    if (props?.data?.termsAndConditions) {
      const blocksFromHTML = convertFromHTML(props?.data?.termsAndConditions);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(content));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  };
  React.useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
    editorDataa();
  }, []);
  const handleClickOpen = () => {
    editorDataa();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = data => {
    let id = 1;
    company.updateCompany(id, data, response => {
      if (response.status === 'success') {
        props.getTermsAndConditions();
        Swal.fire({
          icon: 'success',
          title: 'Terms And Conditions has been updated successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while adding Terms And Conditions.',
        });
      }
    });
    setOpen(false);
  };
  return (
    <Div>
      <Button
        sx={{ marginTop: '12px', marginRight: '30px' }}
        variant="contained"
        onClick={handleClickOpen}
      >
        Edit T&C
      </Button>
      <Dialog
        PaperProps={{
          style: { width: '100%', height: '100%' },
        }}
        open={open}
        maxWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            ...props.data,
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            handleSave(data);
            setOpen(false);
          }}
        >
          {({ setFieldValue }) => (
            <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
              <DialogContent
                ref={contentRef}
                sx={{ overflowY: 'auto', maxHeight: '80vh' }}
              >
                <DialogTitle fontSize={19} id="alert-dialog-title">
                  {'Edit Terms And Conditions'}
                </DialogTitle>
                <Box
                  sx={{
                    width: '100%',
                    position: 'sticky',
                  }}
                >
                  <Div
                    sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}
                  >
                    <Editor
                      editorStyle={{
                        width: '100%',
                        minHeight: 100,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'lightgray',
                      }}
                      toolbarClassName="horizontal-toolbar"
                      editorState={editorState}
                      name="termsAndConditions"
                      onEditorStateChange={editorState => {
                        setEditorState(editorState);
                        let text = draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                        );
                        setFieldValue('termsAndConditions', text);
                      }}
                    />
                  </Div>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" type="submit" autoFocus>
                  Update
                </Button>
                <Button variant="contained" onClick={handleClose}>
                  {' '}
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Div>
  );
};

export default TermsAndConditionsEditDialog;
