import { useState, useEffect } from "react";
import {
  Button,
  MenuItem,
  Select,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ContactModule from "app/mmEsoft/module/ContactModule";

function AddEmailCampagin({
  openEmailCampaign,
  handleCloseEmailCampaign,
  emailCampaigns,
   filterData
}) {
  const Swal = useSwalWrapper();

  //TODO : Send  Email  to all searched contacts
  const [emailCampaignId, setEmailCampaignId] = useState(null);

  const handleInputlabel = (event) => {
    setEmailCampaignId(event.target.value);
  };

  
  //  TODO   i have to create object in which i have to send payload like this 
  const apiData = {
    emailCampaignId: emailCampaignId,    
    courseRelation: filterData.courseRelation,
    labels: filterData.labels,
    semester: filterData.semester,    
    college: filterData.college,
  };

  const handleAddEmail = () => {
    ContactModule.sendEmailCampaign(apiData, (response) => {
   
      if (response.status === "success") {
        Swal.fire({
          icon: "success",
          title: " Email Send  Successfully.",
        });
        handleCloseEmailCampaign()
      } else {
        Swal.fire({
          icon: "error",
          title: "Error Occured While send  Email  !",
        });
      }

    });
  };

  return (
    <>
      <Dialog
        open={openEmailCampaign}
        onClose={() => handleCloseEmailCampaign()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Add Email Campaign</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <Grid sx={{ paddingX: "20px", width: "300px" }}> */}
            <Grid>
              <Grid item xs={12} md={12}>
                {" "}
                <Select
                  value={emailCampaignId}
                  onChange={handleInputlabel}
                  displayEmpty //set None as default by this
                  inputProps={{ "aria-label": "Without label" }}
                  fullWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {emailCampaigns.map((data, index) => {
                    return (
                      <MenuItem key={index} value={data.id}>
                        {data.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            autoFocus
            onClick={handleAddEmail}
          >
            Add Email Campaign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default AddEmailCampagin;
