import appApi from "./appApi";

class EmailCampaign {
  addEmailCampaign = (data, callBack) => {
    return appApi({
      method: "POST",
      url: "/api/email_campaigns/add",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while adding  email campaign", error);
      });
  };
  getAllEmailCampaigns = (callBack) => {
    return appApi({
      method: "GET",
      url: "/api/email_campaigns/all",
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while getting  email campaigns", error);
      });
  };

  updateEmailCampaign = (id, data, callBack) => {
    return appApi({
      method: "PUT",
      url: "/api/email_campaigns/" + id,
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while updating email campaign", error);
      });
  };
  deleteEmailCampaign = (id, callBack) => {
    return appApi({
      method: "DELETE",
      url: "/api/email_campaigns/" + id,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while deleting email campaign", error);
      });
  };
}

export default new EmailCampaign();
