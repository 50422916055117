import Page from '@jumbo/shared/Page';
import SmsTemplate from 'app/pages/sms-template/SmsTemplate';
import React from 'react';

const smsTemplateRoutes = [
  {
    path: '/settings/smstemplate',
    element: <Page component={SmsTemplate} />,
  },
];
export default smsTemplateRoutes;
