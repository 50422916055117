import './userAssignmentStyle.css';
import { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  TableHead,
  Grid,
  Typography,
  Paper,
} from '@mui/material';

import UserAssigments from 'app/mmEsoft/module/UserAssigments';
import { useNavigate } from 'react-router-dom';
import UserAssignmentsDetails from './userAssignmentsDetails';
import { useSelector } from 'react-redux';

function UserAssignmentssFun() {
  const navigate = useNavigate(); //step 3naviagte
  const { user } = useSelector(({ login }) => login);
  const { myBatch } = useSelector(({ batchesReducer }) => batchesReducer);
  const [UserAssignmentsList, setUserAssignmentsList] = useState([]);
  const [assignmentLoader, setAssignmentLoader] = useState(false);
  const getAssignmentByAssignmentId = () => {
    UserAssigments._getUserAssignmentByBatchIdAndUserId(
      myBatch,
      user.id,
      (response) => {
        setAssignmentLoader(true);
        if (response.status === 'success') {
          setUserAssignmentsList(response?.data);
          setAssignmentLoader(false);
        } else {
          console.log('error while getting the Assignments List ');
          setAssignmentLoader(false);
        }
      }
    );
  };
  useEffect(() => {
    getAssignmentByAssignmentId(myBatch);
  }, [myBatch]);

  return (
    <>
      <Typography variant={'h1'}>Assignments</Typography>

      {/* For Array of object  start*/}
      {assignmentLoader ? (
        <div className='loader' style={{ marginTop: '20%' }}>
          <svg className='circular-loader' viewBox='25 25 50 50'>
            <circle className='loader-path' cx='50' cy='50' r='20' />
          </svg>
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell> Name </TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell align='right'>Start Date</TableCell>
                    <TableCell align='right'>End Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {UserAssignmentsList.map((row) => (
                    <TableRow
                      key={row.id} // assuming 'id' is a unique identifier for each row
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => navigate(`/user-assignments/${row.id}`)}
                    >
                      <TableCell component='th' scope='row' id='titleCursor'>
                        {row.title}
                      </TableCell>
                      <TableCell> {row.score}</TableCell>
                      <TableCell align='right'>{row.startDate}</TableCell>
                      <TableCell align='right'>{row.endDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
}
export default UserAssignmentssFun;
