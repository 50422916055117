import Div from '@jumbo/shared/Div';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import BatchDetails from './BatchDetails';
import Students from './students/Students';
import Sessions from './sessions/Sessions';
import BatchCampaign from './campaign/BatchCampaign';
import Attendence from './Attendence/Attendence';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Div sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Div>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const BatchTabs = ({ batchId, Swal, getAllBatches }) => {
	const [value, setValue] = useState(0);

	return (
		<>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					aria-label='lab API tabs example'
					sx={{ width: '100%' }}
					textColor='secondary'
					value={value}
					indicatorColor='secondary'
					onChange={(event, newValue) => setValue(newValue)}
				>
					<Tab label='Batch Details' {...a11yProps(0)} />
					<Tab label='Sessions' {...a11yProps(1)} />
					<Tab label='Students' {...a11yProps(2)} />
					<Tab label='Attendance' {...a11yProps(3)} />
					<Tab label='Courses' {...a11yProps(4)} />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<BatchDetails
					batchId={batchId}
					Swal={Swal}
					getAllBatches={getAllBatches}
				/>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Sessions Swal={Swal} batchId={batchId} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Students Swal={Swal} batchId={batchId} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<Attendence batchId={batchId} />
			</TabPanel>
			<TabPanel value={value} index={4}>
				<BatchCampaign batchId={batchId} />
			</TabPanel>
		</>
	);
};

export default BatchTabs;
