import appApi from "./appApi";

class EmailTemplate {
  addEmailTemplate = (data, callBack) => {
    return appApi({
      method: "POST",
      url: "/api/email_templates/add",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while adding  email template", error);
      });
  };
  getAllEmailTemplates = (callBack) => {
    return appApi({
      method: "GET",
      url: "/api/email_templates/all",
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while getting  email templates", error);
      });
  };

  updateEmailTemplate = (id, data, callBack) => {
    return appApi({
      method: "PUT",
      url: "/api/email_templates/" + id,
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while updating email server", error);
      });
  };
  deleteEmailTemplate = (id, callBack) => {
    return appApi({
      method: "DELETE",
      url: "/api/email_templates/" + id,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while deleting email template", error);
      });
  };
}

export default new EmailTemplate();
