import React from 'react';
import RecentActivityItem from './RecentActivityItem';
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick';
import JumboScrollbar from '@jumbo/components/JumboScrollbar/JumboScrollbar';
import phishingCampaign from 'modules/phishingCampaign';
import { useTranslation } from 'react-i18next';



const RecentActivitiesList = props => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <JumboCardQuick>
        <JumboScrollbar
          autoHeight
          autoHeightMin={554}
          autoHide
          autoHideDuration={200}
          autoHideTimeout={500}
        >
          <RecentActivityItem
            key={'pcAll'}

            phishingCount={props.phishingCount}
            content="All the Phishing Campaigns present in this Training and Awareness Program"
            cardHeaderTitle={t("pages.title.allCampaigns")}

            usedInClient={true}
            pcType={'pcAll'}
          />
          <RecentActivityItem
            key={'pcActive'}

            phishingCount={props.phishingCount}
            content="Active Phishing campaigns that are already running"
            cardHeaderTitle={t("pages.title.activeCampaigns")}
            usedInClient={true}
            pcType={'pcActive'}
          />
          <RecentActivityItem
            key={'pcCompleted'}

            phishingCount={props.phishingCount}
            content="Phishing Campaigns that have been completed so far"
            cardHeaderTitle={t("pages.title.campaignsCompleted")}

            usedInClient={true}
            pcType={'pcCompleted'}
          />
          <RecentActivityItem
            key={'pcUpcoming'}

            phishingCount={props.phishingCount}
            content="Upcoming Phishing campaigns which will be starting "
            cardHeaderTitle={t("pages.title.upcomingCampaigns")}

            usedInClient={true}
            pcType={'pcUpcoming'}
          />
        </JumboScrollbar>
      </JumboCardQuick>
    </React.Fragment>
  );
};

export default RecentActivitiesList;
