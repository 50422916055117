import appApi from '../../../../src/modules/appApi';
class Email {

  getAllEmailCampaign = (callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/email_campaigns/all',

    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, "Error occurred in getting Email");
      });
  };
    
  addEmail = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: `/api/email_campaigns/add`,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, "Error occured while adding email");
      });
  };

  editEmail = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: "/api/email_campaigns/" +id,
      data: data,
    })
      .then(response => {
        callBack({ status: "success", data: response.data });
      })
      .catch(error => {
        callBack({ status: "error" });
        console.log(error, "Error occurred while editing email");
      })
  };

  emailLabel = (callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/label/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, "Error occurred while getting Labels");
      });
  }; 

}
export default new Email();