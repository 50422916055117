import { useState } from 'react';
import { Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterForm from './FilterForm';

const AddFilterDrawerContact = ({ labelsList, getAllLabels, setContacts }) => {
  // STATES
  const [openDrawer, toggleDrawer] = useState(false);

  return (
    <>
      <Button
        variant='outlined'
        startIcon={<FilterListIcon />}
        onClick={() => toggleDrawer(!openDrawer)}
      >
        Filter
      </Button>
      {/* // TODO - 0 : ADD DRAWER INSTEAD OF DIALOG */}
      <FilterForm
        setContacts={setContacts}
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
        labelsList={labelsList}
        getAllLabels={getAllLabels}
      />
    </>
  );
};

export default AddFilterDrawerContact;
