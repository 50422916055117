import appApi from "./appApi";

class EmailServer {
  addEmailServer = (data, callBack) => {
    return appApi({
      method: "POST",
      url: "/api/email_server/add",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while adding  email server", error);
      });
  };
  emailHistory = (campaignId, callBack) => {
    return appApi({
      method: "GET",
      url: "/api/email_history/"+campaignId,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while getting  email history", error);
      });
  };
  emailHistoryRetry = (id,callBack) => {
    return appApi({
      method: "GET",
      url: `/api/email_history/retry/${id}`,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while getting  email history", error);
      });
  };
  phishingSmsGateway = (data, callBack) => {
    return appApi({
      method: "POST",
      url: "/api/phishing_campaign/sms_gateways",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while adding  phising campaign Sms Gateway", error);
      });
  };
  phishingEmailServer = (data, callBack) => {
    return appApi({
      method: "POST",
      url: "/api/phishing_campaign/email_servers",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while adding  phising campaign Email Server", error);
      });
  };
  getAllEmailServers = (callBack) => {
    return appApi({
      method: "GET",
      url: "/api/email_server/all",
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while getting  email servers", error);
      });
  };

  updateEmailServer = (id, data, callBack) => {
    return appApi({
      method: "PUT",
      url: "/api/email_server/" + id,
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while updating email server", error);
      });
  };
  deleteEmailServer = (id, callBack) => {
    return appApi({
      method: "DELETE",
      url: "/api/email_server/" + id,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while deleting email server", error);
      });
  };
}

export default new EmailServer();
