import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GridWithHeader from 'app/CommonComponents/GridWithHeader';
import AddGateway from './AddGateway';
import smsGateway from 'modules/smsGateway';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

function SmsGateways() {
  const Swal = useSwalWrapper();
  const [isLoading, setIsLoading] = React.useState(true);
  const [serverDialog, setServerDialog] = React.useState({
    open: false,
  });
  const [smsGatewayList, setSmsGatewayList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { showDialog, hideDialog } = useJumboDialog();

  React.useEffect(() => {
    getSmsGateway();
  }, []);

  const headers = {
    main: 'Sms Gateways',
    addButton: 'Add Gateway',
    columns: [
      { name: 'title', label: 'Title' },
      { name: 'from', label: 'from' },
      { name: 'provider', label: 'Provider' },
    ],
  };

  const getSmsGateway = () => {
    smsGateway.getAllSmsGateways(response => {
      if (response.status === 'success') {
        setSmsGatewayList(response.data);
        setIsLoading(false);
      } else {
        setSmsGatewayList([]);
        setIsLoading(false);
      }
    });
  };
  const addSmsGateway = data => {
    serverDialog.data
      ? smsGateway.updateSmsGateway(serverDialog.data.id, data, response => {
          if (response.status === 'success') {
            getSmsGateway();

            Swal.fire({
              icon: 'success',
              title: 'SMS Gateway has been updated successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while updating SMS Gateway.',
            });
          }
        })
      : smsGateway.addSmsGateway(data, response => {
          if (response.status === 'success') {
            getSmsGateway();

            Swal.fire({
              icon: 'success',
              title: 'SMS Gateway has been added successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while adding SMS Gateway.',
            });
          }
        });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getSmsGateway();
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const setOpenServerDialog = data => {
    setServerDialog({ open: true, data: data });
  };

  const handleCloseDialog = () => {
    setServerDialog({ open: false });
  };
  const handleItemAction = (actionType, item) => {
    switch (actionType.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this SMS Gateway?',
          content: "You won't be able to recover this SMS Gateway later",
          onYes: () => handleDelete(item.id),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit(item);
        break;
    }
  };
  const handleDelete = id => {
    smsGateway.deleteSmsGateway(id, response => {
      if (response.status === 'success') {
        getSmsGateway();
        Swal.fire({
          icon: 'success',
          title: 'SMS Gateway has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting Sms Gateway.',
        });
      }
    });
  };
  const handleEdit = data => {
    setServerDialog({ ...serverDialog, open: true, data: data });
  };

  return (
    <>
      <GridWithHeader
        data={smsGatewayList}
        isLoading={isLoading}
        headers={headers}
        rowsPerPageOptions={[10]}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        actionsColumn={(item, index) => (
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={actionType => handleItemAction(actionType, item)}
          />
        )}
        openDialog={() =>
          setServerDialog({ ...serverDialog, open: true, data: '' })
        }
      />
      {serverDialog.open ? (
        <AddGateway
          open={serverDialog.open}
          close={handleCloseDialog}
          addSmsGateway={addSmsGateway}
        />
      ) : null}
      {serverDialog.data && serverDialog.open ? (
        <AddGateway
          open={serverDialog.open}
          close={handleCloseDialog}
          addSmsGateway={addSmsGateway}
          edit={true}
          data={serverDialog.data}
        />
      ) : null}
    </>
  );
}

export default SmsGateways;
const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
