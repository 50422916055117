import React from 'react';
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Facebook, Google, Twitter } from '@mui/icons-material';
import Div from '@jumbo/shared/Div';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Logo from 'app/shared/Logo';
import bgImg from '../../../Assets/esoft img (1).jpg';
import auth from 'modules/auth';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { Navigate, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { logoUrl } from 'modules/appApi';

const validationSchema = yup.object({
  otp: yup.string().matches(/^[0-9]{6}$/, 'Must be exactly 6 digits'),
});

const MasterAdminLogin = ({ disableSmLogin }) => {
  const navigate = useNavigate();
  const Swal = useSwalWrapper();
  const getOtp = () => {
    auth.getOtp(response => {
      if (response.status === 'success') {
        if (response.data.message == 'User not found') {
          Swal.fire({
            icon: 'error',
            title: 'Email Not Found, Please Enter Your Email Again',
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'otp has been sent successfully.',
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while resending otp',
        });
      }
    });
  };
  const postOtp = data => {
    auth.postOtp(data, response => {
      if (response.status === 'success') {
        navigate('/master-admin/dashboard');
      } else {
        console.log('Error while post otp');
      }
    });
  };
  React.useEffect(() => {
    getOtp();
  }, []);

  return (
    <Div
      sx={{
        width: 700,
        height: '1000px',
        maxWidth: '100%',
        margin: 'auto',
        padding: 4,
        position: 'fixed',
        // position: "relative",
        left: '50%',
        top: '100%',
        transform: 'translate(-50%, -50%)',
        overflow: 'auto',
        overflowY: 'hidden !important',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          minWidth: 0,
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <CardContent
          sx={{
            flex: '0 1 300px',
            position: 'relative',
            backgroundImage: `url("${bgImg}") !important; `,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
            },
          }}
        >
          <div
            style={{
              position: 'absolute',
              inset: 0,
              background: 'rgba(7, 149, 244, 0.5)',
            }}
          ></div>
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              color: 'common.white',
              position: 'relative',
              zIndex: 1,
              height: '100%',
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={'h3'}
                color={'white'}
                fontWeight={500}
                mb={3}
              >
                Sign In
              </Typography>
            </Div>

            <Div sx={{ mt: 'auto', position: 'relative', bottom: '-20px' }}>
              <Logo />
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              otp: null,
            }}
            validationSchema={validationSchema}
            onSubmit={data => {
              postOtp(data);
            }}
          >
            <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
              <Div sx={{ mb: 2 }}>
                <h2>Welcome Master Admin</h2>
              </Div>
              <Div sx={{ mt: 3, mb: 2 }}>
                <JumboTextField
                  fullWidth
                  name="otp"
                  label="OTP"
                  type="number"
                />
              </Div>

              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                // loading={isLoading}
                onClick={postOtp}
              >
                Sign In
              </LoadingButton>
              <a style={{ color: 'blue', cursor: 'pointer' }} onClick={getOtp}>
                Resend OTP?
              </a>

              {!disableSmLogin && (
                <React.Fragment>
                  <Typography variant={'body1'} mb={2}>
                    Or sign in with
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                    <IconButton
                      sx={{
                        bgcolor: '#385196',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),
                        '&:hover': {
                          backgroundColor: '#385196',
                        },
                      }}
                      aria-label="Facebook"
                    >
                      <Facebook fontSize="small" />
                    </IconButton>
                    <IconButton
                      sx={{
                        bgcolor: '#00a8ff',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                          backgroundColor: '#00a8ff',
                        },
                      }}
                      aria-label="Twitter"
                    >
                      <Twitter fontSize="small" />
                    </IconButton>
                    <IconButton
                      sx={{
                        bgcolor: '#23272b',
                        color: 'common.white',
                        p: theme => theme.spacing(1.25),

                        '&:hover': {
                          backgroundColor: '#23272b',
                        },
                      }}
                      aria-label="Twitter"
                    >
                      <Google fontSize="small" />
                    </IconButton>
                  </Stack>
                </React.Fragment>
              )}
            </Form>
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default MasterAdminLogin;
