import Batches from '../../mmEsoft/module/Batches';
export const GET_BATCHES = 'GET_BATCHES';
export const SELECT_BATCH = 'SELECT_BATCH';
export const GET_BATCHES_FAILURE = 'GET_BATCHES_FAILURE';

export const getbatchesSuccess = batchesList => ({
  type: GET_BATCHES,
  payload: batchesList,
});
export const getBatchesFailure = () => ({
  type: GET_BATCHES_FAILURE,
});
export const selectBatch = batchId => {
  return dispatch => {
    dispatch({ type: SELECT_BATCH, payload: batchId });
  };
};

export const getUserBatches = id => dispatch => {
  Batches.getBatchByUserId(id, response => {
    if (response.status === 'success') {
      dispatch({ type: GET_BATCHES, payload: response.data });
    } else {
      dispatch({ type: GET_BATCHES_FAILURE });
    }
  });
};
