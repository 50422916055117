import Batches from "app/mmEsoft/module/Batches";
import { useEffect, useState } from "react";

export const useGetBatchById = (id) => {
  const [batchDetails, setBatchDetails] = useState();

  useEffect(() => {
    Batches.getBatchById(id, (response) => {
      if (response.status == "success") setBatchDetails(response?.data);
      else console.log("error at get batch by id");
    });
  }, [id]);

  return { batchDetails };
};
