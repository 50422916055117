import { error } from 'react-json-pretty/dist/monikai';
import appApi from '../../../../src/modules/appApi';
class Assignment {
  constructor() {
    this.allAssignmentList = [];
  }
  addAssignment = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: `/api/assignment/add`,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred In Getting Add Assignment');
      });
  };

  getUserAssignmentByBatchId = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/assignment/batch/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getUserAssignmentByBatchId');
      });
  };
  editAssignment = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/assignment/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred While Editing Assignment');
      });
  };

  deleteAssignment = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: `api/assignment/${id}`,
    })
      .then(response => {
        callBack({ status: 'success' });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'Error Occurred While Deleting Assignment');
      });
  };
}
export default new Assignment();
