import appApi from './appApi';

class Campaigns {
  addCampaign = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/campaigns/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  campaign', error);
      });
  };
  saveSmsGateway = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/sms_gateways/sms_gateways',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding SMSgateway', error);
      });
  };

  getEmailServer = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/campaigns/email_servers/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Email server', error);
      });
  };

  getSmsServer = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/campaigns/sms_gateways/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Sms server', error);
      });
  };
  saveEmailDataServer = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/campaigns/email_servers',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding emailDataServer', error);
      });
  };
  getAllCampaigns = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/campaigns/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  campaigns', error);
      });
  };

  getLessonsByCampaign = callBack => {
    appApi({
      method: 'GET',
      url: '/api/campaign_lessons/all',
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting campaign  ', error.message);
        callBack({ status: 'error' });
      });
  };
  getSendSentEmail = (id, callBack) => {
    appApi({
      method: 'GET',
      url: 'api/email_history/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'error' });
        }
      })
      .catch(error => {
        console.log('Error occured while getting campaign  ', error.message);
        callBack({ status: 'error' });
      });
  };

  CampaignSmsRetry = (id, callBack) => {
    appApi({
      method: 'GET',
      url: '/api/sms_history/retry/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'error' });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting  PhishingSmsHistory ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  CampaignSmsHistory = (id, callBack) => {
    appApi({
      method: 'GET',
      url: '/api/sms_history/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'error' });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting  CampaignSmsHistory ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  addLessonsByCampaignId = (data, callBack) => {
    appApi({
      method: 'POST',
      url: '/api/campaign_lessons/add',
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while adding campaign  ', error.message);
        callBack({ status: 'error' });
      });
  };
  getLessonsByCampaignId = (id, callBack) => {
    appApi({
      method: 'GET',
      url: '/api/campaign_lessons/campaign/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'error' });
        }
      })
      .catch(error => {
        console.log('Error occure while getting lesson  ', error.message);
        callBack({ status: 'error' });
      });
  };
  getCountOfCampaigns = callBack => {
    appApi({
      method: 'GET',
      url: '/api/campaigns/get-count',
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'error' });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting campaigns count  ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getReportCounts = callBack => {
    appApi({
      method: 'GET',
      url: '/api/campaigns/get-reportcounts',
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'error' });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting campaigns count  ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getCountOfUserCampaigns = (id, callBack) => {
    appApi({
      method: 'GET',
      url: '/api/campaign_users/get-count/user/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'error' });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting campaigns count  ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  getCampaignsByUserId = (id, callBack) => {
    appApi({
      method: 'GET',
      url: '/api/campaign_users/user/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting lesson  ', error.message);
        callBack({ status: 'error' });
      });
  };

  updateCampignByLessonId = (id, data, callBack) => {
    appApi({
      method: 'PUT',
      url: '/api/campaign_lessons/' + id,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating campaign  ', error.message);
        callBack({ status: 'error' });
      });
  };

  updateCampaign = (id, data, callBack) => {
    appApi({
      method: 'PUT',
      url: '/api/campaigns/' + id,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating campaign  ', error.message);
        callBack({ status: 'error' });
      });
  };
  getCampaign = (id, callBack) => {
    appApi({
      method: 'GET',
      url: '/api/campaigns/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting campaign  ', error.message);
        callBack({ status: 'error' });
      });
  };

  deleteCampaignLessons = (id, callBack) => {
    appApi({
      method: 'DELETE',
      url: '/api/campaign_lessons/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting campaign ', error.message);
        callBack({ status: 'error' });
      });
  };
  deleteCampaign = (id, callBack) => {
    appApi({
      method: 'DELETE',
      url: '/api/campaigns/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting campaign ', error.message);
        callBack({ status: 'error' });
      });
  };
  
  addCampaignToBatch = (data,callBack) => {
    appApi({
      method: 'POST',
      url: '/api/batch_campaign/add',
      data:data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while fetching campaign Added in batch ' + error.message );
        callBack({ status: 'error' });
      });
  };
  getAllAddedCampaignOfBatch = (id,callBack) => {
    appApi({
      method: 'GET',
      url: `/api/batch_campaign/batch/${id}`,
     })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while getting  the Campaign List Added in Batch ', error.message);
        callBack({ status: 'error' });
      });
  };

  DeleteCampaignFromBatch = (id,callBack) => {
    appApi({
      method: 'DELETE',
      url: `/api/batch_campaign/${id}`,
     })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while  Delete the campaign  from  batch  ', error.message);
        callBack({ status: 'error' });
      });
  };

}

export default new Campaigns();
