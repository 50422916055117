import React from 'react';
import Avatar from '@mui/material/Avatar';
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import JumboDdPopover from '@jumbo/components/JumboDdPopover';
import Div from '@jumbo/shared/Div';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { Logout, authenticateUser } from 'app/redux/actions/login';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../redux/store/index';
import auth from '../../../../modules/auth';
import LanguageIcon from '@mui/icons-material/Language';
import LocalizationOptions from 'app/shared/JumboCustomizer/components/LocalizationOptions';
const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useSelector(({ login }) => login);
  const { theme } = useJumboTheme();
  const { isAuthenticated } = useSelector(({ login }) => login);

  React.useEffect(async () => {
    if (!isAuthenticated) {
      let username = searchParams.get('un');
      let secret = searchParams.get('secret');

      if (username && secret) {
        await onSignIn(username, secret);
      } else {
        // navigate('/user/login');
        // history.replace('/login');
      }
    }
  });

  const onSignIn = async (email, password) => {
    dispatch(authenticateUser({ email, password }));
  };

  const onLogout = () => {
    const logoutData = {
      userId: user?.id,
      userAgent: navigator.userAgent,
    };
    auth.logoutUser(logoutData, (response) => {
      dispatch(Logout());
      navigate('/user/login');
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={user?.profile_pic}
            sizes={'small'}
            sx={{
              boxShadow: 25,
              cursor: 'pointer',
              color: 'white',
              backgroundColor: theme.palette.primary.main,
            }}
          />
        }
      >
        <Div
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2.5),
          }}
        >
          <Avatar
            src={user?.profile_pic}
            alt={`${user?.username}`}
            sx={{
              width: 60,
              height: 60,
              mb: 2,
              color: 'white',
              backgroundColor: theme.palette.primary.main,
            }}
          />
          <Typography variant={'h5'}>{`${user?.username}`}</Typography>
          <Typography variant={'body1'} color='text.secondary'>
            {user?.email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText
                onClick={() => navigate('/profile')}
                primary='Profile'
                sx={{ my: 0 }}
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LanguageIcon />
              </ListItemIcon>
              <LocalizationOptions />
            </ListItemButton>
            {/* <ListItemButton>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <EditOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary='Edit Profile' sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <RepeatOutlinedIcon />
              </ListItemIcon>
              {/* <ListItemText
                onClick={() => navigate("/samples/content-layout")}
                primary="Switch User"
                sx={{ my: 0 }}
              /> */}
            {/* </ListItemButton> */}
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>

              <ListItemText primary='Logout' sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
