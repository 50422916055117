import MarvelMindsApi, { appServerURLJava } from "./MarvelMindsApi";
import appApi from "../../../modules/appApi";
import axios from "axios";

class Education {
  addEducation = (data, callBack) => {
    return appApi({
      method: "POST",
      url: `/api/qualification/add`,
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log(error, "Error Occurred In Getting Add Education");
      });
  };

  getAllEducationByCandidateId = (id, callBack) => {
    return appApi({
      method: "GET",
      url: `/api/qualification/${id}`,
    })
      .then((response) => {
        callBack({ status: "success", data: response?.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log(error, "Error Occurred In Getting All Education");
      });
  };

editEducation = (id, data, callBack) => {
  return appApi({
    method: 'PUT',
    url: `/api/qualification/${id}`,
    data: data,
  })
    .then(response => {
      callBack({ status: "success", data: response.data });
    })
    .catch(error => {
      callBack({ status: "error" });
      console.log(error, "Error Occurred While Editing Education");
    })
};
deleteEducation = (id, callBack) => {
  return appApi({
    method: 'DELETE',
    url: `/api/qualification/${id}`,
  })
    .then(response => {
      callBack({ status: 'success', data: response.data });
    })
    .catch(error => {
      callBack({ status: 'error' });
      console.log(error, 'Error Occurred while deleting Education');
    });
};
};
export default new Education();
