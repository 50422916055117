import React from 'react';
import Div from '@jumbo/shared/Div';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';
import UsersList from '../list-views/UsersList/UsersList';
import TrainingCampaignDetails from './TrainingCampaignDetails';
import LessonDetails from './LessonDetails';
import CampaignMailSettings from './CampaignMailSettings';
import SendSentEmail from './SendSentEmail';
import ScrollableTabsButtonAuto from '../components/mui/Tabs/ScrollableTabsButtonAuto';
import SmsSettings from './SmsSetting';
import SendSentSms from './SendSentSms';
import Certificates from 'modules/certificates';
import CampaignCertificate from './CampaignCertificate';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Div sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Div>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CampaignDetails = ({
  campaign,
  getAllCampaigns,
  expanded,
  setExpanded,
  Swal,
  examsData,
}) => {
  const [value, setValue] = React.useState(0);

  return (
    <Div sx={{ width: '100%', marginLeft: '-4vw' }}>
      <Div sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="scrollable"
          scrollButtons={<ScrollableTabsButtonAuto />}
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
        >
          <Tab label="Training Campaign Details" {...a11yProps(0)} />
          <Tab label="Users" {...a11yProps(1)} />
          <Tab label="lessons" {...a11yProps(2)} />
          <Tab label="certificates" {...a11yProps(3)} />
          <Tab label="mail setting" {...a11yProps(4)} />
          <Tab label="send/sent EMails" {...a11yProps(5)} />
          <Tab label="SMS Setting" {...a11yProps(6)} />
          <Tab label="send/sent SMS" {...a11yProps(7)} />
        </Tabs>
      </Div>

      <TabPanel value={value} index={0}>
        <TrainingCampaignDetails
          campaign={campaign}
          getAllCampaigns={getAllCampaigns}
          expanded={expanded}
          setExpanded={setExpanded}
          examsData={examsData}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UsersList campaignId={campaign?.id} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LessonDetails campaign={campaign} Swal={Swal} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CampaignCertificate campaign={campaign} Swal={Swal} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Typography variant="h4" align="center">
          <CampaignMailSettings setExpanded={setExpanded} campaign={campaign} />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Typography variant="h4" align="center">
          <SendSentEmail campaign={campaign} />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Typography variant="h4" align="center">
          <SmsSettings campaign={campaign} />
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Typography variant="h4" align="center">
          <SendSentSms campaign={campaign} />
        </Typography>
      </TabPanel>
    </Div>
  );
};

export default CampaignDetails;
