import Page from "@jumbo/shared/Page";

import ViewCertificates from "app/pages/certificates/viewCertificates";

const viewCertificatesRoutes = [
  {
    path: "/viewCertificates",
    element: <Page component={ViewCertificates} />,
  },
];
export default viewCertificatesRoutes;
