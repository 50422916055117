import appApi from './appApi';

class SsoServer {
  addSsoServer = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/sso_config/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding sso server', error);
      });
  };
  getAllSsoServers = (pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/sso_config/all/?page=${pageNum}&perPage=${pageSize}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting sso servers', error);
      });
  };
  getCount = callBack => {
    return appApi({
      method: 'GET',
      url: `/api/sso_config/get-count`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting sso servers', error);
      });
  };
  updateSsoServer = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/sso_config/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating sso server', error);
      });
  };
  deleteSsoServer = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: '/api/sso_config/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while deleting sso server', error);
      });
  };
}

export default new SsoServer();
