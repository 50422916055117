import ProjectsList from '../pages/list-views/ProjectsList';
import UsersList from '../pages/list-views/UsersList';
import Groups from '../pages/groups/Groups';
import Departments from '../pages/departments/Departments';

import Page from '@jumbo/shared/Page';

const listViewRoutes = [
  {
    path: '/list-views/projects',
    element: <Page component={ProjectsList} />,
  },
  {
    path: '/list-views/users',
    element: <Page component={UsersList} />,
  },
  {
    path: '/groups/groups',
    element: <Page component={Groups} />,
  },
  {
    path: '/departments/departments',
    element: <Page component={Departments} />,
  },
];
export default listViewRoutes;
