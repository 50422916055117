import '../SessionList.css';
import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SearchIcon from '@mui/icons-material/Search';
import SessionApi from '../../module/SessionApi';
import ClearIcon from '@mui/icons-material/Clear';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import AdminBatchesDropDown from 'app/layouts/shared/headers/Header/adminBatchesDropDown';
import { CleaningServices } from '@mui/icons-material';
import Utils from 'app/mmEsoft/Utils/Utils';
//TODO Clean imports
import { Dialog, Typography, DialogActions } from '@mui/material';
const SessionListAdmin = props => {
  // session classes start
  const [ongoing, setOngoing] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [complete, setComplete] = useState([]);
  const [originSessonList, setOriginSessonList] = useState([]);
  //TODO Remove unwanted state variables
  const [input, setInput] = useState(' ');
  const [value, setValue] = useState('1');
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [batchId, setBatchId] = useState();
  const [dialogg, setDialogg] = useState(false);

  const [recordingUrl, setRecordingUrl] = useState('');
  useEffect(() => {
    // DRY- Don't repeat your code
    let onGoingSessions = originSessonList?.filter(lv => {
      let currentDate = new Date();
      let startDate = new Date(lv.start + 'z');
      startDate.setMinutes(startDate.getMinutes() - 10);
      let endDate = new Date(lv.end + 'z');
      return startDate <= currentDate && endDate >= currentDate;
    });
    onGoingSessions.sort((a, b) => new Date(a.start) - new Date(b.start));
    setOngoing(onGoingSessions);

    let upComingSessions = originSessonList.filter(lv => {
      let currentDate = new Date();
      return new Date(lv.start + 'z') > currentDate;
    });
    upComingSessions.sort((a, b) => new Date(a.start) - new Date(b.start));
    setUpcoming(upComingSessions);

    let completeSessions = originSessonList.filter(lv => {
      let currentDate = new Date();
      return new Date(lv.end + 'z') < currentDate;
    });
    setComplete(completeSessions);
  }, [originSessonList]);

  useEffect(() => {
    getSessionAdminByBatchId();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = (event, newValue) => {
    setDialogg(false);
  };
  // TODO merge this useEffect
  useEffect(() => {
    if (complete) {
      complete.map(item => {
        let meetingId = item.id;
        SessionApi.getSessionViewUrlByBatchIdAndCampaignId(
          batchId,
          meetingId,
          response => {
            if (response.status === 'success') {
            } else {
              console.log('error');
            }
          }
        );
      });
    }
  }, [complete]);

  // TODO differenctiate between variable name and function name.
  // session search start
  const searchData = value => {
    if (!value) {
      setOriginSessonList([...originSessonList]);
      return;
    }
    let data = originSessonList?.filter(fetchData => {
      return (
        fetchData &&
        fetchData.description &&
        fetchData.description.toLowerCase().includes(value.toLowerCase())
      );
    });
    // TODO searchTerm is ideally the value to be searched, change searchTerm to searchData
    setSearchTerm(data);
    // Complete search
    if (searchTerm) {
      // TODO, ISSUE searchTerm won't be unpdated immediately because setSearchTerm is async.
      // Solution: change if(searchTerm) to if(data)
      let completedSearchData = originSessonList.filter(
        d =>
          new Date(d.end) < new Date() &&
          d.description.toLowerCase().includes(value.toLowerCase())
      );
      setComplete(completedSearchData);
    } else {
      // If no search term, show all completed sessions
      setComplete(originSessonList.filter(d => new Date(d.start) < new Date()));
    }

    // Upcoming search
    if (searchTerm) {
      let completedSearchData = originSessonList.filter(
        d =>
          new Date(d.start) > new Date() &&
          d.description.toLowerCase().includes(value.toLowerCase())
      );
      setUpcoming(completedSearchData);
    } else {
      // If no search term, show all upcoming sessions
      setUpcoming(originSessonList.filter(d => new Date(d.start) > new Date()));
    }

    // onGoing search
    if (searchTerm) {
      let completedSearchData = originSessonList.filter(
        d =>
          new Date(d.start) <= new Date() &&
          new Date(d.end) >= new Date() &&
          d.description.toLowerCase().includes(value.toLowerCase())
      );
      setOngoing(completedSearchData);
    } else {
      // If no search term, show all ongoing sessions
      setOngoing(
        originSessonList.filter(d => new Date(d.start) === new Date())
      );
    }
  };

  const handleChangeSearch = value => {
    setInput(value);
    searchData(value);
  };
  // session search end
  const handleBatchSelect = batch => {
    getSessionAdminByBatchId(batch);
    setBatchId(batch);
  };
  // Api call start

  const getSessionAdminByBatchId = batch => {
    SessionApi.getSessionAdminByBatchId(batch, response => {
      if (response.status === 'success') {
        setOriginSessonList(response?.data);
      } else {
        console.log('error');
      }
    });
    // }
  };

  const clearSearch = () => {
    document.getElementById('search').value = '';
  };
  // Converting Time To Indiand Standerd Strat
  const convertToIST = timestamp => {
    let utc = new Date(timestamp);
    var dateUTC = new Date(utc); // format should be like '2023-11-20T10:30:00'
    var dateUTC = dateUTC.getTime();
    var dateIST = new Date(dateUTC);
    //date shifting for IST timezone (+5 hours and 30 minutes)
    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);

    let dateTimeIST = new Date(dateIST);

    const year = dateTimeIST.getFullYear();
    const month = String(dateTimeIST.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(dateTimeIST.getDate()).padStart(2, '0');
    const hours = String(dateTimeIST.getHours()).padStart(2, '0');
    const minutes = String(dateTimeIST.getMinutes()).padStart(2, '0');
    const seconds = String(dateTimeIST.getSeconds()).padStart(2, '0');

    const formattedString = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedString;
  };

  const convertToISTDate = timestamp => {
    let utc = new Date(timestamp);
    var dateUTC = new Date(utc); // format should be like '2023-11-20T10:30:00'
    var dateUTC = dateUTC.getTime();
    var dateIST = new Date(dateUTC);
    // date shifting for IST timezone (+5 hours and 30 minutes)
    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);

    let dateTimeIST = new Date(dateIST);

    const year = dateTimeIST.getFullYear();
    const month = String(dateTimeIST.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(dateTimeIST.getDate()).padStart(2, '0');

    const formattedString = `${day}-${month}-${year}`;
    return formattedString;
  };
  const handleUpdate = lessonData => {
    updateMeeting(lessonData.id, recordingUrl);
  };
  const updateMeeting = (id, url) => {
    let data = { recordingUrl: url };
    SessionApi.updateMeeting(id, data, response => {
      if (response.status === 'success') {
        getSessionAdminByBatchId(batchId);
        setDialogg(false);
      } else {
        console.log('error');
      }
    });
  };
  return (
    <div>
      <Box sx={{ flexGrow: 1, marginTop: '2rem' }}>
        <Grid container sx={{ columnGap: '3rem' }}>
          <Grid
            id="session-container"
            item
            md={12}
            lg={7}
            sx={{
              backgroundColor: 'white',
              marginLeft: '1rem',
              borderRadius: '11px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1rem',
              }}
            >
              <div>
                <h3
                  style={{
                    color: 'black',
                    marginLeft: '10px',
                    marginBottom: '18px',
                  }}
                >
                  Sessions
                </h3>
              </div>
              <div>
                <AdminBatchesDropDown onBatchSelect={handleBatchSelect} />
              </div>
              <div>
                <TextField
                  size="small"
                  style={{
                    width: '220px',
                    borderRadius: '20%',
                    marginRight: '10px',
                  }}
                  id="search"
                  label="Search"
                  InputProps={{
                    endAdornment: isSearchFocused ? (
                      <ClearIcon
                        onClick={() => {
                          setIsSearchFocused(false);
                        }}
                      />
                    ) : (
                      <SearchIcon />
                    ),
                  }}
                  onChange={e => {
                    handleChangeSearch(e.target.value);
                    setIsSearchFocused(true);
                  }}
                  onBlur={() => {
                    setIsSearchFocused(false);
                    clearSearch();
                  }}
                />
              </div>
            </div>
            <item>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                  >
                    <Tab
                      label="Completed"
                      value="1"
                      sx={{ paddingLeft: '2rem', color: 'black' }}
                    />
                    <Tab label="ONGOING" value="2" sx={{ color: 'black' }} />
                    <Tab label="UPCOMING" value="3" sx={{ color: 'black' }} />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Box>
                    {complete.length <= 0 ? (
                      <JumboListNoDataPlaceholder>
                        <NoDataPlaceholder />
                      </JumboListNoDataPlaceholder>
                    ) : (
                      complete.map(lessonData => {
                        return (
                          <>
                            <Grid
                              id="complete-grid"
                              container
                              sx={{
                                columnGap: '3rem',
                                borderBottom: '1px solid gray',
                              }}
                              key={lessonData.id}
                            >
                              <Grid item sx={{ width: '100%' }}>
                                <div>
                                  <h3>Title: {lessonData.description}</h3>
                                  <p>
                                    Date: {convertToISTDate(lessonData.start)}{' '}
                                  </p>
                                  {lessonData.recordingUrl ? (
                                    <a
                                      href={lessonData.recordingUrl}
                                      target="_blank"
                                    >
                                      <Button sx={{ color: 'blue' }}>
                                        View Recording
                                      </Button>
                                    </a>
                                  ) : (
                                    <>
                                      <p style={{ color: 'red' }}>
                                        No Recording Found{' '}
                                      </p>
                                      <button
                                        onClick={e => {
                                          setDialogg(true);
                                        }}
                                      >
                                        {' '}
                                        Update Recording URL
                                      </button>
                                    </>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                            <Dialog
                              open={dialogg}
                              onClose={handleClose}
                              fullWidth
                            >
                              <br />
                              <br />
                              <Typography variant="h3" align="center">
                                Update URL
                              </Typography>
                              <Grid
                                container
                                spacing={2}
                                justifyContent="center"
                              >
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    id="recordingUrl"
                                    label="Recording Url"
                                    fullWidth
                                    variant="outlined"
                                    name="recordingUrl"
                                    onChange={event => {
                                      setRecordingUrl(event.target.value);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                justifyContent="center"
                                sx={{
                                  padding: '16px',
                                  gap: '16px',
                                  marginTop: '16px',
                                }}
                              >
                                <DialogActions>
                                  <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      handleUpdate(lessonData);
                                    }}
                                    autoFocus
                                  >
                                    Update
                                  </Button>
                                </DialogActions>
                              </Grid>
                            </Dialog>
                          </>
                        );
                      })
                    )}
                  </Box>
                </TabPanel>

                <TabPanel value="2">
                  <Box>
                    {!ongoing || ongoing.length === 0 ? (
                      <JumboListNoDataPlaceholder>
                        <NoDataPlaceholder />
                      </JumboListNoDataPlaceholder>
                    ) : (
                      ongoing.map(lessonData => {
                        return (
                          <Grid
                            id="ongoing-grid"
                            container
                            sx={{
                              columnGap: '3rem',
                              borderBottom: '1px solid gray',
                            }}
                            key={lessonData.id}
                          >
                            <Grid item sx={{ width: '100%' }}>
                              <div>
                                <h3>Title: {lessonData.description}</h3>
                                <p>
                                  Start Time: {convertToIST(lessonData.start)}{' '}
                                </p>
                                <p>End Time: {convertToIST(lessonData.end)} </p>
                                <a href={lessonData.meetingUrl} target="_blank">
                                  <Button sx={{ color: 'blue' }}>
                                    {' '}
                                    JOIN CLASS{' '}
                                  </Button>
                                </a>
                              </div>
                            </Grid>
                          </Grid>
                        );
                      })
                    )}
                  </Box>
                </TabPanel>
                <TabPanel value="3">
                  <Box>
                    {upcoming.length === 0 ? (
                      <JumboListNoDataPlaceholder>
                        <NoDataPlaceholder />
                      </JumboListNoDataPlaceholder>
                    ) : (
                      upcoming.map(lessonData => {
                        return (
                          <Grid
                            id="upcoming-grid"
                            container
                            sx={{
                              columnGap: '3rem',
                              borderBottom: '1px solid gray',
                            }}
                            key={lessonData.id}
                          >
                            <Grid item sx={{ width: '100%' }}>
                              <div>
                                <h3>Title: {lessonData.description}</h3>
                                <p>
                                  Start Time: {convertToIST(lessonData.start)}{' '}
                                </p>
                                <p>End Time: {convertToIST(lessonData.end)} </p>
                              </div>
                            </Grid>
                          </Grid>
                        );
                      })
                    )}
                  </Box>
                </TabPanel>
              </TabContext>
            </item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SessionListAdmin;
