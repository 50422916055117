import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { Box, Button, ListItem, Link } from '@mui/material';
import Div from '@jumbo/shared/Div';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import DndWrapper from 'app/pages/extensions/dropzone/components/DndWrapper';
import resume from '../../../../../../modules/resume';
import Swal from 'sweetalert2';

function UploadResume({ candidate }) {
	const [resumeUrl, setResumeUrl] = useState('');
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
	});

	function getResume() {
		resume.downloadResume(candidate?.id, response => {
			if (response.status === 'success') setResumeUrl(response.data.resumeUrl);
			else setResumeUrl(null);
		});
	}

	useEffect(() => {
		getResume();
	}, []);

	const uploadResume = () => {
		const file = acceptedFiles[0];
		const formData = new FormData();
		formData.append('resume', file);
		resume.uploadResume(candidate?.id, formData, response => {
			if (response.status === 'success') {
				Swal.fire({
					icon: 'success',
					title: 'Resume uploaded successfully',
				});
				getResume();
			} else {
				Swal.fire({
					icon: 'Error',
					title: 'Resume is not uploaded',
				});
			}
		});
	};

	const deleteResume = () => {
		resume.deleteResume(candidate?.id, response => {
			if (response.status === 'success') {
				Swal.fire({
					icon: 'success',
					title: 'Resume deleted successfully',
				});
				getResume();
			} else {
				Swal.fire({
					icon: 'Error',
					title: 'Resume is not deleted.',
				});
			}
		});
	};

	const files = acceptedFiles.map(file => (
		<ListItem
			selected
			key={file.path}
			sx={{ width: 'auto', mr: 1 }}
		>
			{file.path} - {file.size} bytes
		</ListItem>
	));

	return (
		<JumboCardQuick
			title={'Upload Resume'}
			wrapperSx={{ pt: 0, backgroundColor: 'background.paper' }}
			style={{ marginTop: '10px' }}
		>
			{resumeUrl ? (
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Typography>
						<Link
							target='_blank'
							href={resumeUrl}
							underline='none'
						>
							View Resume
						</Link>
					</Typography>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						sx={{ gap: '10px' }}
					>
						<Button variant='contained'>
							<Link
								target='_blank'
								href={resumeUrl}
								underline='none'
								sx={{ color: '#ffff' }}
							>
								download
							</Link>
						</Button>
						<Button
							variant='contained'
							onClick={deleteResume}
						>
							delete
						</Button>
					</Box>
				</Box>
			) : (
				<Div sx={{ flex: 1 }}>
					<DndWrapper>
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<Typography variant={'body1'}>
								Drag 'n' drop your resume here, or click to select files
							</Typography>
						</div>
					</DndWrapper>
					<List
						disablePadding
						sx={{ display: 'flex', marginBottom: '10px' }}
					>
						{files}
					</List>
					<Button
						variant='contained'
						onClick={uploadResume}
						marginTop={'10px'}
					>
						Upload
					</Button>
				</Div>
			)}
		</JumboCardQuick>
	);
}

export default UploadResume;
