import React from 'react';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GridWithHeader from 'app/CommonComponents/GridWithHeader';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import phishingTemplate from 'modules/phishingTemplate';
import AddPhishingTemplate from './AddPhishingTemplate';

function PhishingTemplate() {
  const Swal = useSwalWrapper();
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [phishingTemplateList, setPhishingTemplateList] = React.useState([]);
  const { showDialog, hideDialog } = useJumboDialog();
  const [serverDialog, setServerDialog] = React.useState({
    open: false,
  });
  const setOpenServerDialog = data => {
    setServerDialog({ open: true, data: data });
  };

  const handleCloseDialog = () => {
    setServerDialog({ open: false });
  };

  React.useEffect(() => {
    getAllPhishingTemplates();
  }, []);
  const headers = {
    main: 'Phishing Templates',
    addButton: 'Add Phishing Template',
    columns: [
      { name: 'title', label: 'Title' },
      { name: 'url', label: 'Url' },
    ],
  };
  const getAllPhishingTemplates = () => {
    phishingTemplate.getAllPhishingTemplate(response => {
      if (response.status === 'success') {
        setIsLoading(false);
        setPhishingTemplateList(response.data);
      } else {
        setIsLoading(false);

        setPhishingTemplateList([]);
      }
    });
  };

  const addPhishingTemplate = data => {
    serverDialog.data
      ? phishingTemplate.updatePhishingTemplate(
          serverDialog.data.id,
          data,
          response => {
            if (response.status === 'success') {
              getAllPhishingTemplates();

              Swal.fire({
                icon: 'success',
                title: 'Phishing Template has been updated successfully.',
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'There is an Error while updating phishing template.',
              });
            }
          }
        )
      : phishingTemplate.addPhishingTemplate(data, response => {
          if (response.status === 'success') {
            getAllPhishingTemplates();

            Swal.fire({
              icon: 'success',
              title: 'Phishing Template has been added successfully.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'There is an Error while adding Phishing template.',
            });
          }
        });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllPhishingTemplates();
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleItemAction = (actionType, item) => {
    switch (actionType.action) {
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this Phishing Template?',
          content: "You won't be able to recover Phishing Template",
          onYes: () => handleDelete(item.id),
          onNo: hideDialog,
        });

        break;
      case 'edit':
        handleEdit(item);
        break;
    }
  };
  const handleDelete = id => {
    phishingTemplate.deletePhishingTemplate(id, response => {
      if (response.status === 'success') {
        getAllPhishingTemplates();
        Swal.fire({
          icon: 'success',
          title: 'Phishing Template has been deleted successfully.',
        });
        hideDialog();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting Phishing template.',
        });
      }
    });
  };
  const handleEdit = data => {
    setServerDialog({ ...serverDialog, open: true, data: data });
  };

  return (
    <>
      <GridWithHeader
        data={phishingTemplateList}
        isLoading={isLoading}
        headers={headers}
        rowsPerPageOptions={[10]}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        actionsColumn={(item, index) => (
          <JumboDdMenu
            menuItems={menuItems}
            onClickCallback={actionType => handleItemAction(actionType, item)}
          />
        )}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        openDialog={() =>
          setServerDialog({ ...serverDialog, open: true, data: '' })
        }
      />
      {serverDialog.open ? (
        <AddPhishingTemplate
          open={serverDialog.open}
          close={handleCloseDialog}
          addTemplate={addPhishingTemplate}
        />
      ) : null}
      {serverDialog.data && serverDialog.open ? (
        <AddPhishingTemplate
          open={serverDialog.open}
          close={handleCloseDialog}
          addTemplate={addPhishingTemplate}
          edit={true}
          data={serverDialog.data}
        />
      ) : null}
    </>
  );
}

export default PhishingTemplate;
const menuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
];
