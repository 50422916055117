import { Typography, Box, CircularProgress, Button } from '@mui/material';
import useGetAllCandidates from '../hooks/useGetAllCanditates';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import AttendenceTable from './AttendenceTable';
import AddActivity from './AddActivity';
import useGetAttancence from '../hooks/useGetAttancence';

function Attendence({ batchId }) {
  const { studentList, studentLoading } = useGetAllCandidates(batchId);
  const { attendances, loading, getAttendance } = useGetAttancence(batchId);
  const [ids, setIds] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    studentList.forEach((e) => {
      setIds((prev) => [...prev, e.user_id]);
    });
  }, [studentList]);

  const handleActivity = () => {
    setOpen(!open);
  };

  return (
    <Box style={{ maxHeight: '400px', overflowY: 'auto' }}>
      <Typography variant='h1'>Attendance</Typography>
      {studentLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '10px',
            minWidth: '100vw',
            whiteSpace: 'nowrap',
          }}
        >
          <Box
            style={{
              width: '250px',
              border: '3px solid #c5cae9',
              borderRadius: '10px',
              flexShrink: 0,
            }}
          >
            <Typography
              component={'p'}
              textAlign={'center'}
              padding={'19px'}
              fontSize={'20px'}
            >
              Students
            </Typography>
            <Box>
              {studentList.length === 0 ? (
                <Typography
                  component={'p'}
                  padding={'5px'}
                  textAlign={'center'}
                  fontSize={'20px'}
                  fontWeight={'500'}
                >
                  No Data
                </Typography>
              ) : (
                studentList.map((student, index) => (
                  <Typography
                    key={student.id}
                    component={'div'}
                    style={{
                      height: '50px',
                      borderTop: 'solid 1px #c5cae9',
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px',
                      borderTop: 'solid 2px #c5cae9',
                    }}
                  >
                    <Typography
                      sx={{
                        height: '30px',
                        width: '30px',
                        background: '#7e8082',
                        borderRadius: '30px',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {index + 1}
                    </Typography>
                    <Typography sx={{ alignSelf: 'start' }}>
                      {student?.name}
                    </Typography>
                  </Typography>
                ))
              )}
            </Box>
          </Box>
          <AttendenceTable attendances={attendances} loading={loading} />
          <Button
            style={{
              alignSelf: 'start',
              marginX: '10px',
              minWidth: '150px',
              fontSize: '15px',
            }}
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={handleActivity}
          >
            Activity
          </Button>
        </Box>
      )}
      <AddActivity
        batchId={batchId}
        ids={ids}
        getAttendance={getAttendance}
        open={open}
        setOpen={setOpen}
      />
    </Box>
  );
}

export default Attendence;
