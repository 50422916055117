import { Box, Typography, Button } from '@mui/material';
import { useState } from 'react';
import StudentList from './StudentList';
import ContactList from './ContactList';
import useGetAllCanditates from '../hooks/useGetAllCanditates';
const Students = ({ Swal, batchId }) => {
  const [open, setOpen] = useState(false);
  const { studentList, studentLoading, fetchCandidates } =
    useGetAllCanditates(batchId);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h1" component="h2">
          Students
        </Typography>

        <Button onClick={() => setOpen(!open)} variant="contained" autoFocus>
          Add Student
        </Button>
        <ContactList
          Swal={Swal}
          open={open}
          setOpen={setOpen}
          batchId={batchId}
          fetchCandidates={fetchCandidates}
        />
      </Box>
      <StudentList
        studentList={studentList}
        studentLoading={studentLoading}
        fetchCandidates={fetchCandidates}
        batchId={batchId}
        Swal={Swal}
      />
    </>
  );
};

export default Students;
