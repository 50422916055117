import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import Div from '@jumbo/shared/Div';
import users from 'modules/users';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';

const AddMultipleUsers = props => {
  const { groupId, campaignId, departmentId, getAllUsers, Swal, phishingId } =
    props;
  const [userList, setUserList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [sizee, setSizee] = useState(0);
  const [searchText, setSearchText] = useState([]);
  const handleRowSelect = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = userList.map(row => row);
      setSelectedRows(newSelecteds);
    } else {
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    getUsers();
  }, [page, rowsPerPage, props.open]);

  const fetchUsers = (fetchFunction, fetchParams) => {
    fetchFunction(...fetchParams, response => {
      if (response.status === 'success') {
        setUserList(response.data);
        setTotalCount(page * 10 + 10);
        setSizee(response.data?.length);
      } else {
        setUserList([]);

        setTotalCount(0);
      }
    });
  };
  const getUsers = () => {
    if (props?.groupId) {
      fetchUsers(users.getUsersForGroup, [props.groupId, page, rowsPerPage]);
    } else if (props?.departmentId) {
      fetchUsers(users.getUsersForDepartmentId, [
        props.departmentId,
        page,
        rowsPerPage,
      ]);
    } else if (props?.campaignId) {
      fetchUsers(users.getUsersForCampaignId, [
        props.campaignId,
        page,
        rowsPerPage,
      ]);
    } else if (props?.phishingId) {
      fetchUsers(users.getUsersForPhishingId, [
        props.phishingId,
        page,
        rowsPerPage,
      ]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const AddUser = (AddFunction, addParams) => {
    AddFunction(addParams, response => {
      if (response.status === 'success') {
        getAllUsers();
        props.close();
        Swal.fire({
          icon: 'success',
          title: 'User has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'there is an error while adding user.',
        });
      }
    });
  };
  const addBulkUsersByIds = () => {
    if (selectedRows.length === 0) {
      return;
    }
    if (groupId || campaignId || departmentId || phishingId) {
      const idKey = groupId
        ? 'groupId'
        : campaignId
        ? 'campaignId'
        : departmentId
        ? 'departmentId'
        : 'phishingCampaignId';
      const data = {
        users: selectedRows,
        [idKey]: groupId || campaignId || departmentId || phishingId,
      };
      setSelectedRows([]);
      if (groupId) {
        AddUser(users.addBulkUserByGroupId, data);
      } else if (departmentId) {
        AddUser(users.addBulkUserByDepartMentId, data);
      } else if (campaignId) {
        AddUser(users.addBulkUserByCampaignId, data);
      } else if (phishingId) {
        AddUser(users.addBulkUserByPhishingCampaignId, data);
      }
    } else {
      console.log('No valid ID provided.');
    }
  };
  const handleSearch = e => {
    setSearchText(e.target.value);
    if (departmentId) {
      users.searchUsersForDepartmentId(
        departmentId,
        e.target.value,
        response => {
          if (response.status === 'success') {
            setUserList(response.data);
          } else {
            users.getUsersForDepartmentId(
              departmentId,
              page,
              rowsPerPage,
              response => {
                if (response.status === 'success') {
                  setUserList(response.data);
                } else {
                  setUserList();
                }
              }
            );
          }
        }
      );
    } else if (groupId) {
      users.searchUsersForGroup(groupId, e.target.value, response => {
        if (response.status === 'success') {
          setUserList(response.data);
        } else {
          users.getUsersForGroup(groupId, page, rowsPerPage, response => {
            if (response.status === 'success') {
              setUserList(response.data);
            } else {
              setUserList();
            }
          });
        }
      });
    } else if (campaignId) {
      users.searchUsersForCampaignId(campaignId, e.target.value, response => {
        if (response.status === 'success') {
          setUserList(response.data);
        } else {
          users.getUsersForCampaignId(
            campaignId,
            page,
            rowsPerPage,
            response => {
              if (response.status === 'success') {
                setUserList(response.data);
              } else {
                setUserList();
              }
            }
          );
        }
      });
    } else if (phishingId) {
      users.searchUsersForPhishing(phishingId, e.target.value, response => {
        if (response.status === 'success') {
          setUserList(response.data);
        } else {
          users.getUsersForPhishingId(
            phishingId,
            page,
            rowsPerPage,
            response => {
              if (response.status === 'success') {
                setUserList(response.data);
              } else {
                setUserList();
              }
            }
          );
        }
      });
    }
  };
  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close} width="500px">
        <DialogTitle id="alert-dialog-title" textAlign="center">
          Add Users
          {userList?.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                size="small"
                style={{
                  width: '250px',
                  margin: '0 auto',
                  marginTop: '1rem',
                }}
                label="Search Users"
                value={searchText}
                onChange={handleSearch}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
              />
            </Box>
          )}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              {!userList?.length <= 0 && (
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedRows.length === userList.length &&
                          userList.length > 0
                        }
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < userList.length
                        }
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {userList?.length <= 0 ? (
                  <JumboListNoDataPlaceholder>
                    <NoDataPlaceholder />
                  </JumboListNoDataPlaceholder>
                ) : (
                  userList.map(row => (
                    <TableRow
                      key={row.id}
                      hover
                      onClick={event => handleRowSelect(event, row)}
                      selected={selectedRows.indexOf(row) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={selectedRows.indexOf(row) !== -1} />
                      </TableCell>
                      <TableCell>
                        <Typography variant={'h6'} mb={0}>
                          {`${row.firstName} ${row.lastName}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'h6'} mb={0}>
                          {row.userName ? row.userName : '_'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'h6'} mb={0}>
                          {row.email ? row.email : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant={'h6'} mb={0}>
                          {row.phone ? row.phone : '-'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {sizee > 0 && (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                disabled: page === 0,
              }}
              nextIconButtonProps={{
                disabled: sizee < 10,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          {userList?.length > 0 && (
            <Button variant="contained" onClick={addBulkUsersByIds} autoFocus>
              Add Users
            </Button>
          )}
          <Button variant="contained" onClick={props.close}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Div>
  );
};

export default AddMultipleUsers;
