import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@material-ui/core';
import UserEmail from 'app/mmEsoft/module/UserEmail';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from '@mui/material';

import { Search, SearchIconWrapper, StyledInputBase } from './style';
import SearchIcon from '@mui/icons-material/Search';
import search from 'modules/search';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'First name', width: 130 },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    type: 'number',
    width: 190,
  },
];

export default function AddUser({ item, onSelectRows }) {
  const [getAddContact, setGetAddContact] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { setSearchReslts } = useJumboAuth();
  const { searchTerm, setSearchTerm } = useJumboAuth();
  setSearchTerm(searchTerm);
  const handleSearch = (source, searchTerm) => {

    search.getBySearch(source, searchTerm, response => {
      if (response.status === 'success') {
        if (response?.data?.results.length <= 0) {
          getAddContacts();
        } else {
          setGetAddContact(response.data.results);
        }
      } else {
        console.log('Error while searching');
      }
    });
  };
  useEffect(() => {
    handleSearch('contacts', searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    getAddContacts();
  }, []);

  const getAddContacts = () => {
    let id = item.id;
    UserEmail.getAddUserEmail(id, response => {
      if (response.status === 'success') {
        setGetAddContact(response.data);
      } else {
        setGetAddContact([]);
      }
    });
  };

  const handleRowSelect = (event, row) => {
    const selectedIndex = selectedRows.findIndex(
      selectedRow => selectedRow.id === row.id
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
    onSelectRows(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = getAddContact.map(row => row);
      setSelectedRows(newSelecteds);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <>
      <Search
        sx={{
          maxWidth: '200px',
          // marginLeft: '200px',
          margin: '0px 0px 20px 180px',
          border: '1 px solid',
          height: '100%',
          display: 'flex',

          '& .MuiInputBase-root': {
            flex: 1,
            borderRadius: 0,
            background: theme => theme.palette.background.default,
          },
          '& .MuiInputBase-input': {
            pr: 6,
          },
        }}
      >
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>

        <StyledInputBase
          placeholder="Search anything"
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </Search>
      <TableContainer component={Paper}>
        <Table>
          {!getAddContact?.length <= 0 && (
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      selectedRows.length === getAddContact.length &&
                      getAddContact.length > 0
                    }
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < getAddContact.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {getAddContact?.length <= 0 ? (
              <JumboListNoDataPlaceholder></JumboListNoDataPlaceholder>
            ) : (
              getAddContact?.map(row => (
                <TableRow
                  key={row.id}
                  hover
                  onClick={event => handleRowSelect(event, row)}
                  selected={selectedRows.indexOf(row) !== -1}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedRows.indexOf(row) !== -1} />
                  </TableCell>
                  <TableCell>
                    <Typography variant={'h6'} mb={0}>
                      {`${row.name}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'h6'} mb={0}>
                      {row.email ? row.email : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'h6'} mb={0}>
                      {row.phoneNumber ? row.phoneNumber : '_'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
