import Cookies from 'js-cookie';
import appApi from 'modules/appApi';
import MarvelMindsApi from '../module/MarvelMindsApi';

class Auth {
  // Authentication  Class //
  constructor() {
    const authData = Cookies.get('authData');
    this.user_token = authData
      ? JSON.parse(authData)
      : JSON.parse(localStorage.getItem('authData'));
    this.authDataLocalStorage = localStorage.getItem('authData');
    this.userTokenLocalStorage = this.authDataLocalStorage
      ? JSON.parse(this.authDataLocalStorage)
      : {};
    // this.UserDetails= json.parse(localStorage.getItem('userDetails'))||{};
  }

  setToken(tokenData) {
    Cookies.set('authData', JSON.stringify(tokenData));
    localStorage.setItem('authData', JSON.stringify(tokenData));
    this.user_token = tokenData;
  }

  getToken() {
    return this.user_token;
  }
  getUserDetails() {
    return this.user_token.UserDetails;
  }
  authenticate = (loginData, callBack) => {
    return appApi({
      //sm
      method: 'POST',
      url: '/public/login',
      data: loginData,
    })
      .then((response) => {
        const tokenData = response.data;
        this.setToken(tokenData);
        callBack({ status: 'success', data: tokenData });
      })
      .catch((error) => {
        console.log(error, 'errr');
        callBack({ status: 'error' });
        console.log('Authentication failed', error);
      });
  };

  getOtp = (callBack) => {
    return appApi({
      //sm
      method: 'GET',
      url: '/public/admin-otp',
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('error while getting otp', error);
      });
  };
  postOtp = (loginData, callBack) => {
    return appApi({
      //sm
      method: 'Post',
      url: '/public/admin-login',
      data: loginData,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('otp is not valid', error);
      });
  };

  removeLoginDetails = () => {
    localStorage.removeItem('authData');
    localStorage.removeItem('Auth');
  };
}

export default new Auth();
