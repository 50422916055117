import React from 'react';
import { Button } from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboAppTextField from '@jumbo/components/JumboAppTextField/JumboAppTextField';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import departments from 'modules/departments';
import users from 'modules/users';
import { useTranslation } from 'react-i18next';

const EditDepartmentDetails = ({
  depId,
  department,
  getAllDepartments,
  expanded,
  setExpanded,
}) => {
  const Swal = useSwalWrapper();
  const [userList, setUserList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const { t } = useTranslation();
  const validationSchema = yup.object().shape(
    {
      title1: yup.string().when('title2', {
        is: title2 => !title2 || title2.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title2: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
    },
    ['title1', 'title2']
  );
  React.useEffect(() => {
    getUsers();
  }, [expanded]);
  const editDepartment = data => {
    departments.updateDepartments(department.id, data, response => {
      if (response.status === 'success') {
        getAllDepartments();
        Swal.fire({
          icon: 'success',
          title: 'Department has been updated successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while updating Department.',
        });
      }
    });
  };
  const deleteDept = () => {
    if (userList.length <= 0) {
      departments.deleteDeptByDeptId(department.id, response => {
        if (response.status === 'success') {
          getAllDepartments();
          Swal.fire({
            icon: 'success',
            title: 'Department has been deleted successfully.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'There is an Error while deleting Department.',
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Cannot Delete The Department With Active Users.',
      });
    }
  };
  const getUsers = () => {
    users.getUsersByDepartmentId(depId, page, rowsPerPage, response => {
      if (response.status === 'success') {
        setUserList(response.data);
      } else {
        setUserList([]);
      }
    });
  };
  return (
    <div>
      <Formik
        validateOnChange={true}
        initialValues={{
          title1: department?.title1,
          title2: department?.title2,
          description: department?.description,
          description1: department?.description1,
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={data => {
          editDepartment(data);
        }}
      >
        {({ setFieldValue }) => (
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <Div sx={{ mb: 2, mt: 1 }}>
              <JumboAppTextField
                fullWidth
                name="title1"
                label="Title(English)"
              />
            </Div>
            <Div sx={{ mb: 2, mt: 1 }}>
              <JumboAppTextField
                fullWidth
                name="title2"
                label="Title ( العربيه )"
              />
            </Div>
            <Div sx={{ mb: 2, mt: 1 }}>
              <JumboAppTextField
                fullWidth
                name="description"
                label="Description(English)"
              />
            </Div>
            <Div sx={{ mb: 2, mt: 1 }}>
              <JumboAppTextField
                fullWidth
                name="description1"
                label="Description ( العربيه )"
              />
            </Div>
            <Div
              sx={{
                mb: 2,
                mt: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ mr: 2 }}
                autoFocus
              >
                {t('widgets.title.update')}
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={deleteDept}
                sx={{ mr: 2 }}
                autoFocus
              >
                {t('widgets.title.delete')}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setFieldValue('title1', '');
                  setFieldValue('title2', '');
                  setFieldValue('description', '');
                  setFieldValue('description1', '');
                  setExpanded(!expanded);
                }}
              >
                {' '}
                {t('widgets.title.cancel')}
              </Button>
            </Div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditDepartmentDetails;
