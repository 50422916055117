import { Button, InputLabel, TextField } from '@mui/material';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ResultData from 'app/mmEsoft/module/ResultData';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useState, useEffect } from 'react';
import Utils from 'app/mmEsoft/Utils/Utils';
import UserAssigments from 'app/mmEsoft/module/UserAssigments';

export default function Result_Show({ userId, candidate, batchId }) {
  // [TODO O] fetch data from user progress table
  const [userAssignment, setUserAssignment] = useState([]);
  const [batchAssignment, setBatchAssignment] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    remarks: '',
  });

  const Swal = useSwalWrapper();

  useEffect(() => {
    getAssignmentByUserId();
  }, [userId, candidate, batchId]);

  function getAssignmentByUserId() {
    ResultData.getAssignmentByBatchIdAndUserId(batchId, userId, (response) => {
      setLoader(true);
      if (response.status === 'success') {
        setUserAssignment(response.data);
        setLoader(false);
      } else {
        setLoader(false);
        console.log('ERROR AT GET ASSIGNMENT BY BATCH ID ', batchId);
      }
    });
  }

  let tot_score = 0;
  let max_marks = 0;
  let totalAssignment = 0;
  let submittedAssignments = 0;

  userAssignment?.forEach((e) => {
    if (e.score) {
      tot_score += Number(e.score);
    }
    if (e.isSubmitted) {
      submittedAssignments += 1;
    }
    max_marks += Number(e.maximumMarks);
    totalAssignment += 1;
  });

  const handleRemarks = (event) => {
    setData({
      [event.target.name]: event.target.value,
    });
  };

  const handleResult = () => {
    ResultData.issueCertificate(candidate?.batch_id, data, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Data Updated Successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error Occured While Updating Data!',
        });
      }
    });
  };

  // Calculate grade letter for different components
  const gradeLetter = Utils.calculateGradeLetter((tot_score / max_marks) * 100);
  const gradeLetterAttendance = Utils.calculateGradeLetter(
    candidate?.total_attendence
  );

  const gradeLetterQuiz = Utils.calculateGradeLetter(
    candidate?.quiz_percentage
  );

  // Define your grades array
  const grades = [gradeLetter, gradeLetterAttendance, gradeLetterQuiz];

  // Function to calculate final grade based on the provided rules
  const calculateFinalGrade = (grades) => {
    if (grades.includes('F')) {
      return 'F';
    }
    // Define your rules here
    const rules = {
      AAA: 'A+',
      AAB: 'A',
      AAC: 'A-',
      ABA: 'A',
      ABB: 'B+',
      ABC: 'B',
      ACA: 'A-',
      ACB: 'B',
      ACC: 'C+',
      BAA: 'B',
      BAB: 'B',
      BAC: 'C+',
      BBA: 'B',
      BBB: 'B-',
      BBC: 'C',
      BCA: 'C',
      BCB: 'C-',
      BCC: 'C',
      CAA: 'A-',
      CAB: 'B',
      CAC: 'C+',
      CBA: 'C',
      CBB: 'C',
      CBC: 'C-',
      CCA: 'C-',
      CCB: 'C',
      CCC: 'C-',
    };
    // Convert the input grades array to a string
    const gradeKey = grades.join('');

    // Check if the combination exists in the rules
    if (rules.hasOwnProperty(gradeKey)) {
      return rules[gradeKey];
    } else {
      return 'Not Available';
    }
  };

  // Final grade calculation
  const finalGrade = calculateFinalGrade(grades);

  return (
    <>
      {loader ? (
        <Typography
          sx={{ textAlign: 'center', fontSize: '20px', color: '#757575' }}
        >
          loading...
        </Typography>
      ) : (
        <Box sx={{ width: '100%' }}>
          {userAssignment.map((assignment, index) => {
            return (
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={'column'}
                sx={{
                  border: '2px solid #e0e0e0',
                  padding: '10px',
                  my: '10px',
                  width: '100%',
                }}
                key={assignment.id}
              >
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{
                    borderRadius: '4px',
                    padding: '10px',
                    my: '10px',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      border: '1px solid #e0e0e0',
                      padding: '5px',
                    }}
                  >
                    {assignment.batchId === batchId ? (
                      <Typography
                        sx={{
                          color: '#212121',
                        }}
                      >
                        Assignment Name : {assignment.title}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid #e0e0e0',
                      padding: '5px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#212121',
                      }}
                    >
                      Grade: {finalGrade}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid #e0e0e0',
                      padding: '5px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#212121',
                      }}
                    >
                      Quize Score :{' '}
                      {candidate?.attendenceLength > 0
                        ? (
                            (candidate?.attedanceCount /
                              candidate?.attendenceLength) *
                            100
                          ).toFixed(1)
                        : '0'}
                      %
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid #e0e0e0',
                      padding: '5px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#212121',
                      }}
                    >
                      Quize Score :{' '}
                      {candidate?.quiz_percentage
                        ? candidate?.quiz_percentage.toFixed(2)
                        : '0'}
                      %
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '3px',
                    flexDirection: 'column',
                    color: '#212121',
                    padding: '2px 10px',
                    borderRadius: '5px',
                  }}
                >
                  <Typography>Remarks</Typography>
                  <TextField
                    name='remarks'
                    fullWidth
                    rows={4}
                    margin='dense'
                    type='text'
                    variant='outlined'
                    value={data.remarks}
                    onChange={(event) => handleRemarks(event)}
                  />
                  <Stack
                    spacing={2}
                    direction='row'
                    justifyContent='flex-end'
                    sx={{ marginTop: '2%' }}
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleResult}
                    >
                      Save
                    </Button>
                  </Stack>
                </Box>
              </Box>
            );
          })}

          {totalAssignment === 0 ? (
            <Typography sx={{ fontSize: '18px', textAlign: 'center' }}>
              User haven't uploaded any assignment yet
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                boxShadow: '0px 0px 30px #e0e0e0',
                padding: '5px',
                border: '1px solid #e0e0e0',
              }}
            >
              <Typography style={{ color: '#212121', fontSize: '19px' }}>
                Total Assignments : {totalAssignment}
              </Typography>
              <Typography style={{ color: '#212121', fontSize: '19px' }}>
                Submited Assignments : {submittedAssignments}
              </Typography>
              <Typography style={{ color: '#212121', fontSize: '19px' }}>
                Total Assignment Score :{' '}
                {((tot_score / max_marks) * 100).toFixed(2)}
                {'%'}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
