import {
  TableContainer,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import DeleteSession from './DeleteSession';
import EditSession from './EditSession';
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
import Utils from 'app/mmEsoft/Utils/Utils';

const SessionList = ({
  batchId,
  sessionLoader,
  sessionList,
  getMeetingsByBatchId,
}) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [id, setId] = useState(null);
  const [editData, setEditData] = useState('');
	return (
		<TableContainer>
			{sessionLoader ? (
				<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
					<CircularProgress />
				</Box>
			) : (
				<Table
					sx={{ minWidth: 900 }}
					aria-label='simple table'
				>
					<TableHead>
						<TableRow>
							<TableCell>Session</TableCell>
							<TableCell>Start</TableCell>
							<TableCell>End</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sessionList.length === 0 ? (
							<JumboListNoDataPlaceHolder></JumboListNoDataPlaceHolder>
						) : (
							sessionList.map(session => {
								if (!session.isDeleted)
									return (
										<TableRow key={session.id}>
											<TableCell>{session.description}</TableCell>
											<TableCell>{Utils.convertToISTDate(session.start)}</TableCell>
											<TableCell>{Utils.convertToISTDate(session.end)}</TableCell>
											<TableCell>
												<IconButton
													disabled={!session.recordingUrl ? false : true}
													onClick={() => {
														setOpen(true);
														setId(session.id);
													}}
													aria-label='delete'
												>
													<DeleteIcon />
												</IconButton>
												<IconButton
													onClick={() => {
														setOpenEdit(true);
														setId(session.id);
														setEditData(session);
													}}
													aria-label='edit'
												>
													<EditIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									);
							})
						)}
					</TableBody>
				</Table>
			)}
			<DeleteSession
				open={open}
				setOpen={setOpen}
				id={id}
				batchId={batchId}
				getMeetingsByBatchId={getMeetingsByBatchId}
			/>
			<EditSession
				openEdit={openEdit}
				setOpenEdit={setOpenEdit}
				id={id}
				editData={editData}
				batchId={batchId}
				getMeetingsByBatchId={getMeetingsByBatchId}
			/>
		</TableContainer>
	); 
          
};

export default SessionList;
