import React, { useEffect, useState } from 'react';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import certificates from 'modules/certificates';
import { useSelector } from 'react-redux';
import { error } from 'react-json-pretty/dist/monikai';
import Candidate from 'app/mmEsoft/module/Candidate';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
const ViewUserCertificates = ({ certificateData, admin,data }) => {
	const { user } = useSelector(({ login }) => login);
	const [isLoading, setIsLoading] = React.useState(true);
	const [imageData, setImageData] = useState(null);
	const [fields, setFields] = React.useState([]);
	const [candidate, setCandidate] = React.useState({});
	const Swal = useSwalWrapper();
	useEffect(() => {
		if (!admin) {
			getCandidateByUserId(user.id);
			getImageById(certificateData.id);
		} else {
			getImageById(data.certificateId);
		}
	}, []);
	useEffect(() => {
		if (!admin) {
			getCertificateLabelsById(certificateData.id); // Call to fetch label data
		} else {
			getCertificateLabelsById(data.certificateId); // Call to fetch label data
		}
	}, [candidate]);
	const getCandidateByUserId = id => {
		Candidate.getCandidateByUserId(id, async response => {
			if (response.status === 'success') {
				setCandidate(response.data);
			} else {
				console.log(error);
			}
		});
	};
	const getImageById = id => {
		certificates.getImageById(id, async response => {
			setIsLoading(false);
			if (response.status === 'success') {
				setImageData(response.data);
			} else {
				console.log(error);
			}
		});
	};
	const getCertificateLabelsById = id => {
		certificates.getLabelByCertificateId(id, async response => {
			if (response.status === 'success') {
				let updatedFields = [];
				for (let i = 0; i < response?.data?.length; i++) {
					const input = response.data[i];
					if (!admin) {
						if (input.value == '{address}') {
							input.value = candidate.addressId;
						}
						if (input.value == '{Name}') {
							input.value = candidate.name;
						}
					} else {
						if (input.value == '{address}') {
							input.value = certificateData.addressId;
						}
						if (input.value == '{Name}') {
							input.value = certificateData.name;
						}
					}

					input.position = JSON.parse(input.position);
					updatedFields.push(input);
				}
				setFields(updatedFields);
			} else {
				console.log(error);
			}
		});
	};
	return (
		<>
			{isLoading ? (
				<div className='loader'>
					<svg
						className='circular-loader'
						viewBox='25 25 50 50'
					>
						<circle
							className='loader-path'
							cx='50'
							cy='50'
							r='20'
						/>
					</svg>
				</div>
			) : (
				<div>
					{imageData ? (
						<img
							style={{
								display: 'block',
								margin: '20px',
								width: '900px',
								height: '500px',
							}}
							src={URL.createObjectURL(new Blob([imageData]))}
							alt='Certificate Image'
						/>
					) : (
						<NoDataPlaceholder />
					)}
					<div style={{ display: 'flex' }}>
						{fields &&
							fields.map((field, index) => (
								<div
									style={{
										fontSize: field.style?.fontSize,
										color: field.style?.fontColor,
										fontFamily: field.style?.fontFamily,
										position: 'relative',
										left: field.position.x,
										top: field.position.y,
										margin: '10px',
									}}
								>
									{field.value}
								</div>
							))}
					</div>
				</div>
			)}
		</>
	);
};

export default ViewUserCertificates;
