import appApi from './appApi';
class userCertificates {
  getUserCertificatesById = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/user_certificates/user/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while getting  certificates', error);
        callBack({ status: 'error' });
      });
  };

  getUserCertificatesByUserId = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/certificates/user/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while getting  certificates', error);
        callBack({ status: 'error' });
      });
  };


  
  addUserCertificates = (data, callBack) => {
    return appApi({
      method: 'POST',

      url: '/api/user_certificates/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        console.log('error while adding certificate to user', error);
        callBack({ status: 'error' });
      });
  };
}
export default new userCertificates();
