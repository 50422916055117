import appApi from "./appApi";

class ExamLessons {
  addExamLesson = (data, callBack) => {
    return appApi({
      method: "POST",
      url: "/api/exam_lessons/add",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while adding  exam Lesson", error);
      });
  };
  getAllExamLessons = (callBack) => {
    return appApi({
      method: "GET",
      url: "/api/exam_lessons/all",
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while getting  exam lessons", error);
      });
  };

  updateExamLessons = (id, data, callBack) => {
    return appApi({
      method: "PUT",
      url: "/api/exam_lessons/" + id,
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while updating exam lessons", error);
      });
  };
  getLessonsByExamId = (id, callBack) => {
    appApi({
      method: "GET",
      url: "/api/exam_lessons/exam/" + id,
    })
      .then((response) => {
        if (response.data) {
          callBack({ status: "success", data: response.data });
        }
      })
      .catch((error) => {
        console.log("Error occured while getting lesson  ", error.message);
        callBack({ status: "error" });
      });
  };
  addBulkLessonsByIds = (data, callBack) => {
    return appApi({
      method: "POST",
      url: "/api/exam_lessons/bulkadd",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while adding  exam Lesson", error);
      });
  };
  deleteExamLesson = (id, callBack) => {
    return appApi({
      method: "DELETE",
      url: "/api/exam_lessons/lesson/" + id,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while deleting exam lessons", error);
      });
  };
}

export default new ExamLessons();
