import { useSelector } from 'react-redux';
import './userBatches.css';
import {
  Box,
  TextField,
  Typography,
  Grid,
  Stack,
  Tab,
  Chip,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CheckIcon from '@mui/icons-material/Check';
import React, { useEffect, useState } from 'react';
import campaigns from 'modules/campaigns';
import UserProgress from 'app/mmEsoft/module/UserProgress';
// import userProgress from "modules/userProgress";
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import DoneIcon from '@mui/icons-material/Done';

function UserBatches() {
  const { batchesList } = useSelector(({ batchesReducer }) => batchesReducer);
  const { myBatch } = useSelector(({ batchesReducer }) => batchesReducer);
  const { campaignList } = useSelector(
    ({ campaignsReducer }) => campaignsReducer
  );
  const { user } = useSelector(({ login }) => login);
  const [lessonData, setLessonData] = useState([]);
  const [userBatches, setUserBatches] = useState([]);
  const [userprogress, setUserprogress] = useState([]);
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // getLessonsByCampaignId
  const getCampaignLessons = (id) => {
    campaigns.getLessonsByCampaignId(id, (response) => {
      if (response.status === 'success') {
        setLessonData(response?.data);
      } else {
        console.log('error while loading the lessons ');
      }
    });
  };

  //user progress
  const getUserProgress = (id) => {
    UserProgress.getUserProgress(id, user.id, (response) => {
      if (response.status === 'success') {
        setUserprogress(response?.data);
      } else {
        console.log('error while getting the UserProgress  ');
      }
    });
  };
  const handleTabClick = (id) => {
    getCampaignLessons(id);
    getUserProgress(id);
  };

  return (
    <>
      {/* tabs for Capaingn   */}

      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            border: '1px solid divider',
            backgroundColor: 'white',
            borderRadius: '11px',
          }}
        >
          <Box>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label='lab API tabs example'
                  variant='scrollable'
                  scrollButtons='auto'
                >
                  {campaignList.map((item, index) => {
                    return (
                      <>
                        <Tab
                          label={item?.title}
                          // value="1"
                          value={value} // Make sure this matches the state
                          onClick={() => handleTabClick(item?.id)}
                          id='tabTextColor'
                          active
                        />
                      </>
                    );
                  })}
                </TabList>
              </Box>

              {lessonData?.map((campaignItem) => {
                return (
                  <>
                    <TabPanel
                      // value="1"
                      value={value} // Make sure this matches the state
                      id='styleText'
                      sx={{
                        borderBottom: '1px solid ',
                        borderColor: 'divider',
                      }}
                    >
                      <Box>
                        <h3
                          id='styleText'
                          style={{ fontWeight: '400', fontSize: '1.2rem' }}
                        >
                          {campaignItem?.lessonName}
                        </h3>
                        <p id='styleText'>{campaignItem?.lessonDescription}</p>
                        <Box>
                          <Stack direction='row' spacing={6}>
                            {userprogress.map((data, index) => {
                              if (data.lessonId === campaignItem.lessonId)
                                return (
                                  <>
                                    <Stack
                                      direction='row'
                                      justifyContent='end'
                                      alignItems='center'
                                      spacing={3}
                                      // sx={{ padding: "15px" }}
                                    >
                                      <Typography>
                                        Quiz{' '}
                                        <span
                                          style={{
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                          }}
                                        >
                                          {data?.quizScore}
                                        </span>
                                        <span
                                          style={{
                                            fontWeight: 'bold',
                                            fontSize: '25px',
                                            color: '#82b440',
                                          }}
                                        >
                                          /{campaignItem?.maxQuestions}
                                        </span>
                                      </Typography>
                                    </Stack>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        width: '100px',
                                        height: '40px',
                                        border: '1px solid #212121',
                                        borderRadius: '20px',
                                      }}
                                    >
                                      <Typography fontSize={18}>
                                        video
                                      </Typography>
                                      {!data.videoStatus ? (
                                        <DoneIcon />
                                      ) : (
                                        <DoneIcon color='success' />
                                      )}
                                    </Box>
                                  </>
                                );
                            })}
                          </Stack>
                        </Box>
                      </Box>
                    </TabPanel>
                  </>
                );
              })}
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default UserBatches;
