import Page from '@jumbo/shared/Page';
import PhishingCampaigns from 'app/pages/phishing-campaigns/PhishingCampaigns';
import React from 'react';

const phishingCampaignsRoutes = [
  {
    path: '/phishingcampaigns',
    element: <Page component={PhishingCampaigns} />,
  },
];
export default phishingCampaignsRoutes;
