import Candidate from 'app/mmEsoft/module/Candidate';
import users from 'modules/users';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Header from './Header';
import { Button, Grid } from '@mui/material';
import UserProfileSidebar from './UserProfileSidebar';
import About from './components/About';
import EditUser from './EditUser';
import Addskills from 'app/pages/users/user-profile/components/skills/AddSkills';
import AddQualification from './components/qualification/AddQualification';
import AddExperience from './components/experience/AddExperience';
import Project from 'app/pages/users/user-profile/components/Projects/Project';
import BasicTextFields from 'app/pages/components/mui/TextFields/BasicTextFields';
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ContextMenu from 'app/pages/components/mui/Menus/ContextMenu';
import UploadResume from './components/uploadResume/UploadResume';

const UserProfile = () => {
	const { user } = useSelector(({ login }) => login);
	const [userData, SetUserData] = useState({});
	const [openDialog, setOpenDialog] = React.useState(false);
	const [candidate, setCandidate] = useState();

	useEffect(() => {
		getUser();
		getCandidate();
	}, []);

	const handleToggleDialog = () => {
		setOpenDialog(!openDialog);
	};
	const handleClose = () => {
		setOpenDialog(false);
	};
	const getCandidate = () => {
		Candidate.getCandidateByUserId(user?.id, response => {
			if (response.status === 'success') {
				setCandidate(response.data);
			} else {
				console.log('error in get candidates');
			}
		});
	};
	const getUser = updated => {
		users.getUser(user?.id, response => {
			if (response.status === 'success') {
				SetUserData(response?.data);
			} else {
			}
		});
	};
	return (
		<>
			<Header user={user} />
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
					sm={4}
				>
					<UserProfileSidebar
						user={user}
						userData={userData}
					/>
				</Grid>

				<Grid
					item
					xs={12}
					sm={8}
				>
					<About user={user} />
				</Grid>
			</Grid>
			{candidate && candidate.id ? (
				<>
					<ContextMenu candidate={candidate} />
					<UploadResume candidate={candidate} />
					<Addskills candidate={candidate} />
					<AddQualification candidate={candidate} />
					<AddExperience candidate={candidate} />
					<Project candidate={candidate} />
					<BasicTextFields candidate={candidate} />
				</>
			) : null}
		</>
	);
};

export default UserProfile;
