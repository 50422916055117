import { Button } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import templateImg from '../../../../Assets/userTemplate/user_sample_tamplate.xlsx';
import users from 'modules/users';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useTranslation } from 'react-i18next';
import { Height } from '@mui/icons-material';
const AddBulkUsers = () => {
  const Swal = useSwalWrapper();
  const [user, setUser] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getAllUsers = pageNum => {
    users.getAllUsers(pageNum, 10, response => {
      if (response.status === 'success') {
        setUserList(response.data);
      } else {
        setUserList([]);
      }
    });
  };

  const handleUsersUpload = file => {
    const data = new FormData();
    data.append('file', file);
    users.uploadUserFile(data, response => {
      if (response.status === 'success') {
        handleClose();
        getAllUsers();
        Swal.fire({
          icon: 'success',
          title: 'Users File has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'thsere is an issue While adding Users File',
        });
      }
    });
  };

  return (
    <>
      <div>
        <Button variant="contained" onClick={handleClickOpen}>
          {t('widgets.title.addMultipleUsers')}
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <Button>
              <a
                href={templateImg}
                download="user_sample_template"
                target="_blank"
                rel="noreferrer"
              >
                Download Template
              </a>
            </Button>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <li>
                Please browse and select the Excel file from your computer to
                begin the bulk user upload process
              </li>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              aria-label="upload contact"
              component="label"
              size="small"
            >
              Select/Upload File
              <input
                hidden
                accept=".xls, .xlsx,.csv"
                type="file"
                onChange={event => {
                  const file = event.target.files[0];
                  handleUsersUpload(file);
                  setUser(file);
                }}
              />
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginLeft: '5px' }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AddBulkUsers;
