import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Slider,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import campaigns from 'modules/campaigns';

const TrainingCampaignDetails = ({
  campaign,
  getAllCampaigns,
  expanded,
  setExpanded,
  examsData,
}) => {
  const Swal = useSwalWrapper();
  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title1: yup.string().when('title', {
        is: title => !title || title.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      successPercentage: yup
        .number()
        .min(0, 'Success percentage must be a positive number')
        .max(100, 'Success percentage must be less than or equal to 100')
        .required('Success percentage is required'),
      startDate: yup.date().required('Start date is required'),
      dueDate: yup.date().required('Due date is required'),
      // examId: yup.string().required('Exam is required'),
      // quizType: yup.string().required('Quiz type is required'),
      // quizStyle: yup.string().required('Quiz style is required'),
      // seek: yup.string().required('Seek field is required'),
      // player: yup.string().required('Player field is required'),
      // randomQuestions: yup.boolean(),
    },
    ['title', 'title1']
  );

  const editCampaign = data => {
    campaigns.updateCampaign(campaign.id, data, response => {
           if (response.status === 'success') {
        getAllCampaigns();
        Swal.fire({
          icon: 'success',
          title: 'Campaign has been updated successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while updating Campaign.',
        });
      }
    });
  };
  const deleteCampaign = () => {
    campaigns.deleteCampaign(campaign.id, response => {
      if (response.status === 'success') {
        getAllCampaigns();
        Swal.fire({
          icon: 'success',
          title: 'Campaign has been deleted successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: ' Deletion Error: Campaign with Active Users and Lessons.',
        });
      }
    });
  };

  const seek = [
    { name: 'No Seek', value: 'noseek' },
    { name: 'Seek Backwards Only', value: 'seekbackwardsonly' },
    { name: 'Seek Backwards And Forwards', value: 'seekbackwardsandforwards' },
  ];

  return (
    <div>
      <Formik
        validateOnChange={true}
        initialValues={{
          ...campaign,
          startDate: campaign.startDate
            ? campaign.startDate
            : new Date().toLocaleDateString('en-CA'),
          dueDate: campaign.dueDate
            ? campaign.dueDate
            : new Date().toLocaleDateString('en-CA'),
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={data => {
          editCampaign(data);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={6} sm={6}>
                <JumboTextField fullWidth name="title" label="Title(English)" />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <JumboTextField
                  fullWidth
                  name="title1"
                  label="Title ( العربيه )"
                />
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <JumboTextField
                  fullWidth
                  type="date"
                  name="startDate"
                  label="Start Date"
                  value={values.startDate}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <JumboTextField
                  fullWidth
                  type="date"
                  name="dueDate"
                  label="Due Date"
                  value={values.dueDate}
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12} p={1}>
                <Typography>Success Percentage</Typography>
                <Slider
                  value={values.successPercentage}
                  onChange={(event, newValue) =>
                    setFieldValue('successPercentage', newValue)
                  }
                  aria-labelledby="percentage-slider"
                  valueLabelDisplay="auto"
                  min={0}
                  max={100}
                />
              </Grid>

              {/* <Grid item xs={12} md={6} sm={6}>
                <JumboTextField
                  fullWidth
                  select
                  name="examId"
                  label="Exam"
                  value={values?.examId}
                >
                  {examsData.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </JumboTextField>
              </Grid> */}

              {/* <Grid item xs={12} md={6} sm={6}>
                <JumboTextField
                  fullWidth
                  select
                  name="quizType"
                  label="Quiz Type"
                >
                  <MenuItem key="text" value="text">
                    Text
                  </MenuItem>
                  <MenuItem key="interactive" value="interactive">
                    Interactive
                  </MenuItem>
                </JumboTextField>
              </Grid> */}

              {/* <Grid item xs={12} md={6} sm={6}>
                <JumboTextField
                  fullWidth
                  select
                  name="quizStyle"
                  label="Quiz Style"
                >
                  <MenuItem key="once" value="once">
                    Once
                  </MenuItem>
                  <MenuItem key="repeat" value="repeat">
                    Repeat
                  </MenuItem>
                </JumboTextField>
              </Grid> */}

              {/* <Grid item xs={12} md={6} sm={6}>
                <JumboTextField fullWidth select name="seek" label="Seek">
                  {seek.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </JumboTextField>
              </Grid> */}
              {/* <Grid item xs={12} md={6} sm={6}>
                <JumboTextField fullWidth select name="player" label="Player">
                  <MenuItem key="WebGL" value="WebGL">
                    WebGL
                  </MenuItem>
                  <MenuItem key="HTML5" value="HTML5">
                    HTML5
                  </MenuItem>
                </JumboTextField>
              </Grid> */}
              {/* <Grid item xs={12} md={6} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={event =>
                        setFieldValue('randomQuestions', event.target.checked)
                      }
                      checked={values.randomQuestions}
                    />
                  }
                  label="Random Questions"
                  name="randomQuestions"
                />
              </Grid> */}
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',

                mt: 2,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ mr: 2 }}
                autoFocus
              >
                Update
              </Button>
              <Button
                variant="contained"
                onClick={deleteCampaign}
                sx={{ mr: 2 }}
                autoFocus
              >
                Delete
              </Button>
              <Button
                variant="contained"
                onClick={() => setExpanded(!expanded)}
              >
                {' '}
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TrainingCampaignDetails;
