import React from 'react';
import { enUS, arEG } from '@mui/material/locale';
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { ListItemText, Typography } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';


const LocalizationOptions = () => {
    const { i18n } = useTranslation();
    const { theme, setMuiLocale, setTheme } = useJumboTheme();

    const toggleLanguage = () => {
        // Check the current locale and switch to the other one
        const currentLocale = i18n.language;
        const newLocale = currentLocale === 'ar-EG' ? 'en-US' : 'ar-EG';

        i18n.changeLanguage(newLocale).then(() => {
            setMuiLocale(newLocale === 'ar-EG' ? arEG : enUS);

            // Set the direction to 'rtl' for Arabic and 'ltr' for English
            const newDirection = newLocale === 'ar-EG' ? 'rtl' : 'ltr';
            setTheme({ direction: newDirection });
            document.querySelector("body").setAttribute("dir", newDirection);
        });
    };

    return (
        <ListItemText
            onClick={toggleLanguage}
            primary={i18n.language === 'ar-EG' ? 'English' : 'عربي'}
            sx={{ my: 0 }}
        />
    );
};

export default LocalizationOptions;
