import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';

const MasterAdminMenu = [
  {
    label: 'sidebar.menu.home',
    type: 'section',
    children: [
      {
        uri: '/master-admin/dashboard',
        label: 'My Licenses',
        type: 'nav-item',
        icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },

  {
    uri: '/list-views/users',
    label: 'sidebar.menuItem.users',
    type: 'nav-item',
    icon: <ViewListOutlinedIcon sx={{ fontSize: 20 }} />,
  },
];
export default MasterAdminMenu;
