import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Div from '@jumbo/shared/Div';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import departments from 'modules/departments';
import AddDepartment from './AddDepartment';
import DepartmentDetails from './DepartmentDetails';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import dataAndTimeFormatter from 'modules/dataAndTimeFormatter';
import { useTranslation } from 'react-i18next';
const Departments = () => {
  const { t } = useTranslation();
  const Swal = useSwalWrapper();
  const [expanded, setExpanded] = React.useState(false);
  const [openGroupDialog, setOpenGroupDialog] = React.useState(false);
  const [departmentData, setDepartmentData] = React.useState([]);
  const [isLoading, setIsloading] = React.useState(true);
  const [depId, setDepId] = React.useState({});

  React.useEffect(() => {
    getAllDepartments();
  }, []);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseDialog = () => {
    setOpenGroupDialog(false);
  };
  const addDepartment = data => {
    departments.addDepartments(data, response => {
      if (response.status === 'success') {
        getAllDepartments();
        Swal.fire({
          icon: 'success',
          title: 'Department has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while adding Department.',
        });
      }
    });
  };
  const getAllDepartments = () => {
    departments.getAllDepartments(response => {
      if (response.status === 'success') {
        setIsloading(false);
        setDepartmentData(response.data);
      } else {
        setDepartmentData([]);
      }
    });
  };
  const handleAccordionClick = item => {
    setDepId(item.id);
  };

  return (
    <Div>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'} mt={1}>
          {t("pages.title.departments")}
        </Typography>
        <Box>
          {' '}
          <Button variant="contained" onClick={() => setOpenGroupDialog(true)}>
            {t("pages.title.addDepartment")}
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <div className="loader" style={{ marginTop: '15%' }}>
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : (
        <>
          {departmentData.map((item, index) => (
            <Card sx={{ mb: 1, boxShadow: 1 }}>
              <Accordion
                onClick={() => handleAccordionClick(item)}
                expanded={expanded === 'panel' + index}
                onChange={handleChange('panel' + index)}
                key={index}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel' + index ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    {' '}
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      {item?.title1 ? item?.title1 : '-'}
                    </Typography>
                  </Div>

                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    {' '}
                    <Typography sx={{ color: 'text.secondary' }}>
                      {item?.description ? item?.description : '-'}
                    </Typography>
                  </Div>

                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    {' '}
                    <Typography sx={{ color: 'text.secondary' }}>
                      {dataAndTimeFormatter.formatDateToCustomFormat(
                        item?.createdAt
                      )}{' '}
                    </Typography>
                  </Div>

                  <Div
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    {' '}
                    <Typography sx={{ color: 'text.secondary' }}>
                      {dataAndTimeFormatter.formatDateToCustomFormat(
                        item?.updatedAt
                      )}{' '}
                    </Typography>
                  </Div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    borderTop: 1,
                    borderColor: 'divider',
                    p: theme => theme.spacing(2, 2, 2, 8.25),
                    marginLeft: -8,
                  }}
                >
                  <DepartmentDetails
                    depId={depId}
                    department={item}
                    getAllDepartments={getAllDepartments}
                    expanded={expanded}
                    setExpanded={setExpanded}
                  />
                </AccordionDetails>
              </Accordion>
            </Card>
          ))}
        </>
      )}
      <AddDepartment
        open={openGroupDialog}
        close={handleCloseDialog}
        addDepartment={addDepartment}
      />
    </Div>
  );
};

export default Departments;
