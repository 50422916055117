import { Grid, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import * as yup from 'yup';
import { useGetBatchById } from './hooks/useGetBatchById';
import Batches from '../../module/Batches';
import { useState } from 'react';
const BatchDetails = ({ batchId, Swal, getAllBatches }) => {
	const { batchDetails } = useGetBatchById(batchId);

	const validationSchema = yup.object().shape({
		batchNumber: yup.string().required('Batch number is required'),
		description: yup.string().required('description is required'),
	});

	return !batchDetails ? null : (
		<Formik
			initialValues={{
				batchNumber: batchDetails?.batchNumber,
				startDate: batchDetails.startDate
					? new Date(batchDetails.startDate).toISOString().split('T')[0]
					: '',
				endDate: batchDetails.endDate
					? new Date(batchDetails.endDate).toISOString().split('T')[0]
					: '',
				description: batchDetails?.description,
			}}
			validateOnChange={true}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting }) => {
				Batches.updateBatchById(batchId, values, (response) => {
					if (response.status === 'success') {
						getAllBatches();
						Swal.fire({
							icon: 'success',
							title: 'Batch has been deleted successfully.',
						});
					} else {
						Swal.fire({
							icon: 'error',
							title: ' Deletion Error: Batch is not updated.',
						});
					}
				});
				setSubmitting(false);
			}}
		>
			{({ isSubmitting }) => (
				<Form noValidate autoComplete="off">
					<Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={12} md={4} sm={4} sx={{ mb: 2, mt: 1 }}>
							<JumboTextField
								fullWidth
								name="batchNumber"
								label="Batch Name"
								// value={batcbatchNumber}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={4} sx={{ mb: 2, mt: 1 }}>
							<JumboTextField
								fullWidth
								type="date"
								name="startDate"
								label="Start Date"
								value={batchDetails?.startDate}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={4} sx={{ mb: 2, mt: 1 }}>
							<JumboTextField
								fullWidth
								type="date"
								name="endDate"
								label="End Date"
								value={batchDetails?.endDate}
							/>
						</Grid>
						<Grid item xs={12} md={12} sm={12} sx={{ mb: 2, mt: 1 }}>
							<JumboTextField
								fullWidth
								name="description"
								label="descriptoin"
							/>
						</Grid>
					</Grid>
					<Button variant="contained" type="submit" autoFocus>
						update
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default BatchDetails;
