import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
  Box,
  Skeleton,
  TableCell,
  TableRow,
  TableHead,
  Table,
  Tooltip,
} from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import certificates from 'modules/certificates';
import { useNavigate } from 'react-router-dom';

const Certificates = () => {
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [viewingTemplate, setViewingTemplate] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [templateName, setTemplateName] = useState('');
  const [imageToUpload, setImageToUpload] = useState(null);
  const [certificateData, setCertificateData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState();
  const [skeleton, setSkeleton] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);

  const navigate = useNavigate();
  const [lazyData, setLazyData] = useState({
    orginalData: [],
    certificateData: [],
    visibleData: [],
    loader: false,
    chunkSize: 8, // Number of items to load per scroll
  });
  const Swal = useSwalWrapper();
  useEffect(() => {
    allCertificates();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page]);

  const addCertificates = data => {
    const formData = new FormData();
    formData.append('file', imageToUpload);
    formData.append('data', JSON.stringify(data));

    certificates.addCertificates(formData, response => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Template has been added successfully.',
        });

        allCertificates();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There was an error while adding the template.',
        });
      }
    });
  };

  const allCertificates = () => {
    certificates.getAllCertificates(response => {
      if (response.status === 'success') {
        setIsLoading(false);
        setLazyData({
          orginalData: [...response.data],
          certificateData: response.data.slice(0, lazyData.chunkSize),
          chunkSize: 8,
          loader: false,
        });
      } else {
        setLazyData(prevData => ({
          ...prevData,
          orginalData: [],
          certificateData: [],
        }));
      }
    });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedImage(null);
    setTemplateName('');
  };

  const handleFileInputChange = e => {
    const selectedFile = e.target.files[0];
    setImageToUpload(selectedFile);
    const reader = new FileReader();
    reader.onload = e => {
      setSelectedImage(URL.createObjectURL(selectedFile));
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleAddTemplate = async () => {
    if (!selectedImage || !templateName) {
      return;
    }

    const newTemplate = {
      image: selectedImage,
      labels: [],
    };

    setTemplateList(prevTemplates => [...prevTemplates, newTemplate]);
    handleDialogClose();

    try {
      const dataToSend = {
        certificateName: templateName,
      };

      // Now send the data to the API
      addCertificates(dataToSend);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'There was an error while adding the template.',
      });
    }
  };
  const handleViewTemplate = (templateId, certificateName) => {
    navigate(`/viewCertificates`, {
      state: { id: templateId, certificateName: certificateName },
    });

    setViewingTemplate(true);
  };
  const handleScroll = () => {
    let element = containerRef.current; // Get the element to check for scrolling
    if (
      Math.abs(
        element.scrollHeight - (element.scrollTop + element.clientHeight) <= 1
      )
    ) {
      loadMoreCertificates();
      setSkeleton(true);
    }
  };

  const loadMoreCertificates = () => {
    const { orginalData, chunkSize, certificateData } = lazyData;
    if (certificateData.length == orginalData.length) {
      return;
    }
    const currentChunk = Math.floor(certificateData?.length / chunkSize);
    const start = currentChunk * chunkSize;
    const end = (currentChunk + 1) * chunkSize;
    const newVisibleData = orginalData.slice(start, end);
    setLazyData(prevState => ({
      ...prevState,
      certificateData: [...prevState.certificateData, ...newVisibleData],
      loader: false,
    }));
  };
  useEffect(() => {
    allCertificates();
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant={'h2'}>Certificates</Typography>
        <Button
          variant="contained"
          onClick={handleDialogOpen}
          style={{ width: '200px', height: '40px' }}
        >
          New Template
        </Button>
      </Box>

      <Dialog
        style={{ fontSize: '16px' }}
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle style={{ fontSize: '16px' }}>
          Create a New Template
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: '16px' }}>
            Enter the template name and select an image:
          </DialogContentText>
          <TextField
            fullWidth
            label="Template Name"
            value={templateName}
            onChange={e => setTemplateName(e.target.value)}
            margin="normal"
            variant="outlined"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleFileInputChange}
            />
            <Button
              variant="outlined"
              onClick={() => fileInputRef.current.click()}
            >
              Choose File
            </Button>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected"
                style={{ maxWidth: '100%', marginTop: '10px' }}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleAddTemplate}
            disabled={!selectedImage || !templateName}
          >
            Add Template
          </Button>
        </DialogActions>
      </Dialog>

      <div
        ref={containerRef}
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: '10px',
          maxHeight: '400px', // Set your desired max height
          overflowY: 'auto',
        }}
        onScroll={handleScroll}
      >
        {isLoading ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'200px'}
                    width={'200px'}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'200px'}
                    width={'200px'}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'200px'}
                    width={'200px'}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'200px'}
                    width={'200px'}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'200px'}
                    width={'200px'}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'200px'}
                    width={'200px'}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'200px'}
                    width={'200px'}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'200px'}
                    width={'200px'}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        ) : (
          <React.Fragment>
            {lazyData?.certificateData?.map((template, index) => (
              <div
                key={index}
                style={{
                  display:
                    viewingTemplate && selectedTemplate === index
                      ? 'none'
                      : 'block',
                }}
              >
                <Card style={{ padding: '10px' }}>
                  <Typography
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <WorkspacePremiumIcon
                      style={{
                        color: '#173f5b',
                        width: '100px',
                        height: '100px',
                      }}
                    />
                  </Typography>
                  <Tooltip title={template?.certificateName}>
                    <Typography
                      style={{
                        fontWeight: '800',
                        textAlign: 'center',
                      }}
                    >
                      {template?.certificateName.substring(0, 16)}
                      {template?.certificateName?.length > 16 ? '...' : ''}
                    </Typography>
                  </Tooltip>

                  <Button
                    onClick={() => {
                      handleViewTemplate(template.id, template.certificateName);
                    }}
                    style={{ display: 'block', margin: 'auto' }}
                  >
                    View Template
                  </Button>
                </Card>
              </div>
            ))}
          </React.Fragment>
        )}
        {skeleton &&
        lazyData?.orginalData?.length !== lazyData?.certificateData?.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'180px'}
                    width={'200px'}
                    mb={1}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'180px'}
                    width={'200px'}
                    mb={1}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'180px'}
                    width={'200px'}
                    mb={1}
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    height={'180px'}
                    width={'200px'}
                    mb={1}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        ) : null}
      </div>
    </div>
  );
};

export default Certificates;
