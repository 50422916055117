import React, { useState } from 'react';
import {
  Grid,
  DialogTitle,
  InputLabel,
  DialogActions,
  MenuItem,
  Select,
  DialogContent,
  Button,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useGetAllCampaigns } from '../hooks/useGetAllCampaigns';
import SessionModule from 'app/mmEsoft/module/SessionModule';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const CreateDialogSession = ({
  open,
  setOpen,
  batchId,
  Swal,
  setSessionLoader,
  getMeetingsByBatchId,
}) => {
  const [camp_id, setCampId] = useState(0);
  const [meeting, setMeeting] = useState({
    subject: '',
    start: {
      dateTime: '',
      timeZone: 'Indian Standard Time',
    },
    end: {
      dateTime: '',
      timeZone: 'Indian Standard Time',
    },
    location: {
      displayName: 'Conference Room A',
    },
    isOnlineMeeting: true,
    onlineMeetingProvider: 'teamsForBusiness',
  });
  const { campaignList } = useGetAllCampaigns();

  const [dayNames, setDayNames] = useState([
    { id: '7', name: 'SUN', selected: false, value: 'Sunday' },
    { id: '1', name: 'MON', selected: false, value: 'Monday' },
    { id: '2', name: 'TUE', selected: false, value: 'Tuesday' },
    { id: '3', name: 'WED', selected: false, value: 'Wednesday' },
    { id: '4', name: 'THU', selected: false, value: 'Thursday' },
    { id: '5', name: 'FRI', selected: false, value: 'Friday' },
    { id: '6', name: 'SAT', selected: false, value: 'Saturday' },
  ]);

  const [isDaily, setIsDaily] = useState(false);
  const [repeat, setRepeat] = useState(false);

  const handleRepeat = () => {
    setRepeat(!repeat);
    if (repeat === true) {
      setMeeting({
        ...meeting,
        ['recurrence']: {
          pattern: {
            type: '',
            daysOfWeek: [],
            interval: 1,
          },
          range: {
            startDate: '',
            endDate: '',
            recurrenceTimeZone: 'UTC',
          },
        },
      });
    }
  };

  const setDayName = value => {
    setDayNames(prevDayNames =>
      prevDayNames.map(day =>
        day.value === value ? { ...day, selected: !day.selected } : day
      )
    );

    setMeeting({
      ...meeting,
      recurrence: {
        ...meeting.recurrence,
        pattern: {
          type: 'weekly',
          daysOfWeek:
            meeting?.recurrence?.pattern?.daysOfWeek &&
            Array.isArray(meeting.recurrence.pattern.daysOfWeek)
              ? meeting.recurrence.pattern.daysOfWeek.includes(value)
                ? meeting.recurrence.pattern.daysOfWeek.filter(
                    day => day !== value
                  )
                : [...meeting.recurrence.pattern.daysOfWeek, value]
              : [value], // If daysOfWeek is undefined or not an array, initialize it with [value]
          interval: 1,
        },
      },
    });
  };

  const handleCampaignChange = e => {
    setCampId(e.target.value);
  };

  const handleDailyChange = () => {
    setIsDaily(!isDaily);
    setDayNames(prevDayNames =>
      prevDayNames.map(day => ({ ...day, selected: !isDaily }))
    );
    setMeeting({
      ...meeting,
      recurrence: {
        ...meeting.recurrence,
        pattern: {
          type: 'daily',
        },
      },
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    setSessionLoader(true);
    SessionModule.createMeeting(batchId, camp_id, meeting, response => {
      if (response?.status === 'success') {
        setSessionLoader(false);
        Swal.fire({
          icon: 'success',
          title: 'Session is added',
        });
        getMeetingsByBatchId(batchId);
      } else {
        setSessionLoader(false);
        Swal.fire({
          icon: '',
          title: 'Session is not added',
        });
        console.log('error while adding session');
      }
    });
    setOpen(!open);
  };
  return (
    <>
      <DialogTitle sx={{ textAlign: 'center', fontSize: '20px' }}>
        Add Session
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
          <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} md={6} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div>
                  <DateTimePicker
                    value={meeting.start.dateTime}
                    onChange={newValue => {
                      const formattedDateTime = dayjs(newValue)
                        .utc()
                        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                      setMeeting(prevMeeting => {
                        const newMeeting = {
                          ...prevMeeting,
                          start: {
                            dateTime: formattedDateTime,
                            timeZone: 'Indian Standard Time',
                          },
                          end: {
                            dateTime: dayjs(newValue)
                              .add(1, 'hour') // Add one hour to the end.dateTime
                              .utc()
                              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                            timeZone: 'Indian Standard Time',
                          },
                        };
                        return newMeeting;
                      });
                    }}
                    renderInput={props => (
                      <TextField
                        {...props}
                        fullWidth
                        label="Start Date and Time"
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div>
                  <DateTimePicker
                    value={meeting.end.dateTime}
                    onChange={newDate => {
                      const formattedDateTime = dayjs(newDate)
                        .utc()
                        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
                      setMeeting(prevMeeting => {
                        const newMeeting = {
                          ...prevMeeting,
                          end: {
                            dateTime: formattedDateTime,
                            timeZone: 'Indian Standard Time',
                          },
                        };
                        return newMeeting;
                      });
                    }}
                    renderInput={props => (
                      <TextField
                        {...props}
                        fullWidth
                        label="End Date and Time"
                      />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                fullWidth
                label="Subject"
                name="subject"
                type="text"
                required
                onChange={e => {
                  setMeeting({ ...meeting, subject: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel required id="demo-simple-select-label">
                  Campaigns
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Campaigns"
                  name="campaign"
                  fullWidth
                  value={meeting.campaign}
                  onChange={handleCampaignChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  <MenuItem value={0}>--NONE-- </MenuItem>
                  {campaignList.map(camp => (
                    <MenuItem
                      key={camp?.id}
                      // onClick={() => setCampId(camp?.id)}
                      value={camp?.id}
                    >
                      {camp?.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControlLabel
                control={<Checkbox />}
                label="Repeat"
                onChange={handleRepeat}
              />
            </Grid>
            {!repeat ? null : (
              <>
                <Grid item xs={12} md={6} sm={12}>
                  <InputLabel>Start Repeat Date</InputLabel>
                  <TextField
                    fullWidth
                    name="startDate"
                    type="date"
                    onChange={e =>
                      setMeeting({
                        ...meeting,
                        recurrence: {
                          ...meeting.recurrence,
                          range: {
                            ...meeting.recurrence?.range,
                            startDate: e.target.value,
                          },
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <InputLabel>End Repeat Date</InputLabel>
                  <TextField
                    fullWidth
                    name="endDate"
                    type="date"
                    onChange={e =>
                      setMeeting({
                        ...meeting,
                        recurrence: {
                          ...meeting.recurrence,
                          range: {
                            ...meeting.recurrence?.range,
                            endDate: e.target.value,
                            recurrenceTimeZone: 'UTC',
                          },
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item sm={12} sx={{ gap: '8px' }}>
                  <FormControlLabel
                    label="Daily"
                    control={
                      <Checkbox
                        checked={isDaily}
                        onChange={handleDailyChange}
                      />
                    }
                  />
                </Grid>
                <Grid item sm={12} sx={{ gap: '8px' }}>
                  {dayNames.map(day => (
                    <FormControlLabel
                      key={day.id}
                      label={day.name}
                      control={
                        <Checkbox
                          checked={day.selected}
                          onChange={() => setDayName(day.value)}
                        />
                      }
                    />
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" autoFocus>
            Save
          </Button>
          <Button variant="contained" onClick={() => setOpen(!open)}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default CreateDialogSession;
