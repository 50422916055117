import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import {
	Dialog,
	DialogActions,
	DialogTitle,
	Button,
	DialogContent,
	Typography,
} from '@mui/material';
import SessionModule from 'app/mmEsoft/module/SessionModule';
const DeleteSession = ({
	open,
	setOpen,
	id,
	batchId,
	getMeetingsByBatchId,
}) => {
	const Swal = useSwalWrapper();
	const handleDelete = () => {
		SessionModule.deleteMeetingById(id, response => {
			if (response.status === 'success') {
				Swal.fire({
					icon: 'success',
					title: 'Session deleted successfully',
				});
				getMeetingsByBatchId(batchId);
				setOpen(!open);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Session is not deleted',
				});
				getMeetingsByBatchId(batchId);
			}
		});
	};
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(!open)}
		>
			<DialogTitle color='red'>Delete confirmation</DialogTitle>
			<DialogContent>
				<Typography
					marginY='10px'
					variant='h1'
					color='#212121'
					textAlign='center'
				>
					Are you sure you want to delete this session?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					onClick={() => setOpen(!open)}
				>
					Cancel
				</Button>
				<Button
					variant='contained'
					onClick={handleDelete}
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteSession;
