import certificates from 'modules/certificates';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useLocation } from 'react-router-dom';

import { Box, Button, Card, Grid, Typography } from '@mui/material';
import Label from '../../../modules/labels.js';
import ViewCertificatesData from './ViewCertificatesData.js';
import ColorPicker from './ColorPicker';
// import StylePicker from './StylePicker';
function ViewCertificates() {
	const location = useLocation();
	const templateId = location?.state?.id;
	const certificateName = location?.state?.certificateName;

	useEffect(() => {
		getImageById();
		getLabelsById();
		document.fonts.ready.then(function () {
			// Get the list of available fonts
			var fontNames = [
				'Arial',
				'Arial Black',
				'Bahnschrift',
				'Calibri',
				'Cambria',
				'Cambria Math',
				'Candara',
				'Comic Sans MS',
				'Consolas',
				'Constantia',
				'Corbel',
				'Courier New',
				'Ebrima',
				'Franklin Gothic Medium',
				'Gabriola',
				'Gadugi',
				'Georgia',
				'HoloLens MDL2 Assets',
				'Impact',
				'Ink Free',
				'Javanese Text',
				'Leelawadee UI',
				'Lucida Console',
				'Lucida Sans Unicode',
				'Malgun Gothic',
				'Marlett',
				'Microsoft Himalaya',
				'Microsoft JhengHei',
				'Microsoft New Tai Lue',
				'Microsoft PhagsPa',
				'Microsoft Sans Serif',
				'Microsoft Tai Le',
				'Microsoft YaHei',
				'Microsoft Yi Baiti',
				'MingLiU-ExtB',
				'Mongolian Baiti',
				'MS Gothic',
				'MV Boli',
				'Myanmar Text',
				'Nirmala UI',
				'Palatino Linotype',
				'Segoe MDL2 Assets',
				'Segoe Print',
				'Segoe Script',
				'Segoe UI',
				'Segoe UI Historic',
				'Segoe UI Emoji',
				'Segoe UI Symbol',
				'SimSun',
				'Sitka',
				'Sylfaen',
				'Symbol',
				'Tahoma',
				'Times New Roman',
				'Trebuchet MS',
				'Verdana',
				'Webdings',
				'Wingdings',
				'Yu Gothic',
			];

			setFontList(fontNames);
		});
	}, []);

	const [imageData, setImageData] = useState(null);
	const Swal = useSwalWrapper();
	const [isLoading, setIsLoading] = React.useState(true);
	const [fontSize, setFontSize] = useState('14px');
	const [fontColor, setFontColor] = useState('#C0C0C0');
	const [fontFamily, setFontFamily] = useState('Open Sans');
	const [fontList, setFontList] = useState([]);

	const getImageById = () => {
		certificates.getImageById(templateId, async response => {
			if (response.status === 'success') {
				setImageData(response.data);
				setIsLoading(false);
			} else {
				Swal.fire({
					icon: 'error',
					title: 'There was an error while viewing the template.',
				});
			}
		});
	};

	const getLabelsById = () => {
		certificates.getLabelByCertificateId(templateId, async response => {
			if (response.status === 'success') {
				// Extract labels from the backend response
				if (response.data.length > 0) {
					let updatedInputFields = [];
					let labels = initInputFields();
					for (let i = 0; i < labels.length; i++) {
						let inputField = labels[i];
						let label = response.data.find(item => item.value === inputField.value);

						if (label) {
							let styles = JSON.parse(label.style);
							setFontColor(styles.fontColor);
							setFontFamily(styles.fontFamily);
							setFontSize(styles.fontSize);
							updatedInputFields[i] = { ...label };
							updatedInputFields[i].position = JSON.parse(label.position);
							updatedInputFields[i].style = JSON.parse(label?.style);
						}
					}
					setInputFields(updatedInputFields);
				} else {
					setInputFields(initInputFields());
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'There was an error while viewing the template.',
				});
			}
		});
	};

	const [inputFields, setInputFields] = useState([]);

	const initInputFields = () => {
		return [
			{
				placeholder: '{Name}',
				position: { x: 0, y: 0 },
				style: {
					fontSize: fontSize,
					fontColor: fontColor,
					fontFamily: fontFamily,
				},
				value: '{Name}',
			},

			{
				placeholder: '{address}',
				position: { x: 0, y: 0 },
				style: {
					fontSize: fontSize,
					fontColor: fontColor,
					fontFamily: fontFamily,
				},
				value: '{address}',
			},
			{
				placeholder: '{serial Number}',
				position: { x: 0, y: 0 },
				style: {
					fontSize: fontSize,
					fontColor: fontColor,
					fontFamily: fontFamily,
				},
				value: '{serialNumber}',
			},
			{
				placeholder: '{date}',
				position: { x: 0, y: 0 },
				style: {
					fontSize: fontSize,
					fontColor: fontColor,
					fontFamily: fontFamily,
				},
				value: '{issueDate}',
			},
		];
	};

	const handleFontSizeChange = event => {
		setFontSize(event.target.value);
		updateLabelStyles();
	};

	const handleFontColorChange = event => {
		setFontColor(event.target.value);
		updateLabelStyles();
	};

	const handleFontFamilyChange = event => {
		setFontFamily(event.target.value);
		updateLabelStyles();
	};

	const [newInputField, setNewInputField] = useState({
		placeholder: 'Enter a label',
		position: { x: 0, y: 0 },
		style: { fontSize: fontSize, fontColor: fontColor, fontFamily: fontFamily },
		value: '',
		isEditing: false,
	});
	const handleChange = e => {
		setFontFamily(e.target.value);
		updateLabelStyles();
	};
	const handleStop = (e, data, index) => {
		const updatedInputFields = [...inputFields];
		updatedInputFields[index].position = { x: data.x, y: data.y };
		updatedInputFields[index].style = {
			fontSize: fontSize,
			fontColor: fontColor,
			fontFamily: fontFamily,
		};
		setInputFields(updatedInputFields);
	};

	const handleNewInputStop = (e, data) => {
		setNewInputField({
			...newInputField,
			position: { x: data.x, y: data.y },
			style: {
				fontSize: data.fontSize,
				fontColor: data.fontColor,
				fontFamily: data.fontFamily,
			},
		});
	};

	const handleNewInputClick = () => {
		setNewInputField({ ...newInputField, isEditing: true });
	};

	const handleNewInputBlur = () => {
		setNewInputField({ ...newInputField, isEditing: false });
	};

	const updateLabelStyles = () => {
		const labelElements = document.querySelectorAll('.draggable-label');
		labelElements.forEach(label => {
			label.style.fontSize = fontSize;
			label.style.color = fontColor;
			label.style.fontFamily = fontFamily;
		});
	};

	const sendDataToBackend = () => {
		// Prepare the data to send to the backend
		let style1 = {
			fontSize: fontSize,
			fontColor: fontColor,
			fontFamily: fontFamily,
		};
		const labelsWithStyles = inputFields.map((inputField, index) => ({
			id: inputField.id,
			position: JSON.stringify(inputField.position),
			value: inputField.value,
			style: JSON.stringify(style1),
		}));

		const inputData = {
			labels: labelsWithStyles,
			certificateId: templateId,
		};

		// Make the API call to save the label positions
		Label.addBulkLabels(inputData, response => {
			if (response.status === 'success') {
				Swal.fire({
					icon: 'success',
					title: 'Label positions have been saved successfully.',
				});
			} else {
				Swal.fire({
					icon: 'error',
					title: 'There was an error while saving the Label positions.',
				});
			}
		});
	};

	return (
		<div>
			<Box sx={{ display: 'flex' }}>
				<Typography variant={'h2'}>{certificateName}</Typography>
			</Box>
			<div>
				{isLoading ? (
					<div className='loader'>
						<svg
							className='circular-loader'
							viewBox='25 25 50 50'
						>
							<circle
								className='loader-path'
								cx='50'
								cy='50'
								r='20'
							/>
						</svg>
					</div>
				) : (
					<Box>
						<img
							style={{
								width: '100%',
								height: '100%',
							}}
							src={URL.createObjectURL(new Blob([imageData]))}
							alt='Certificate'
						/>

						{inputFields &&
							inputFields.map((inputField, index) => (
								<Draggable
									key={index} // Use the index as the key
									defaultPosition={inputField.position}
									onStop={(e, data) => handleStop(e, data, index)}
								>
									<input
										className='draggable-label'
										style={{
											margin: '10px',
											width: '100px',
											height: '20px',
											position: 'relative',
											fontSize: fontSize,
											color: fontColor,
											fontFamily: fontFamily,
										}}
										type='text'
										placeholder={inputField.placeholder}
										readOnly={true}
										value={inputField.value}
									/>
								</Draggable>
							))}
					</Box>
				)}

				<div
					variant='h4'
					style={{ margin: '10px', marginBottom: '30px' }}
				>
					Note: Fields are draggable, place them at appropriate positions.
				</div>

				{/* <Draggable
                defaultPosition={newInputField.position}
                onStop={handleNewInputStop}
              >
                <TextField
                  size='small'
                  sx={{ marginLeft: '10px', display: 'none' }}
                  type='text'
                  placeholder={newInputField.placeholder}
                  readOnly={!newInputField.isEditing}
                  value={newInputField.value}
                  onChange={e => {
                    console.log(e.target.value, ' Draggable e.target.value');
                    setNewInputField({
                      ...newInputField,
                      value: e.target.value,
                    });
                  }}
                  onClick={handleNewInputClick}
                  onBlur={handleNewInputBlur}
                />
              </Draggable> */}

				<Grid
					container
					columnSpacing={3}
					gap={1}
				>
					<Grid
						item
						xs={4}
						md={1.5}
						sm={8}
						sx={{ mb: 2, mt: 1 }}
					>
						<ColorPicker
							color={fontColor}
							setColor={setFontColor}
						/>
					</Grid>
					<Grid
						item
						xs={4}
						md={2}
						sm={8}
						sx={{ mb: 2, mt: 1 }}
					>
						<div>
							<label htmlFor='fontSelect'>Select Font</label>
							<select
								style={{ width: '100%' }}
								id='fontSelect'
								onChange={handleChange}
								value={fontFamily}
							>
								{fontList.map((font, index) => (
									<option
										key={index}
										value={font}
									>
										{font}
									</option>
								))}
							</select>
						</div>
					</Grid>
					<Grid
						item
						xs={4}
						md={2}
						sm={8}
						sx={{ mb: 2, mt: 1 }}
					>
						<div>
							<label htmlFor='sizeSelect'>Select Size</label>
							<select
								style={{ width: '100%' }}
								id='sizeSelect'
								onChange={handleFontSizeChange}
								value={fontSize}
							>
								{Array.from({ length: 17 }, (_, index) => (
									<option
										key={index}
										value={`${index + 6}px`}
									>
										{`${index + 6}px`}
									</option>
								))}
							</select>
						</div>
					</Grid>
				</Grid>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'end',
					alignItems: 'start',
					position: 'relative',
					// bottom: '30px',
					right: '160px',
				}}
			>
				<Button
					variant='contained'
					size='small'
					onClick={sendDataToBackend}
				>
					Submit
				</Button>
				{imageData ? (
					<ViewCertificatesData
						imageData={imageData}
						inputFields={inputFields}
						labelStyles={{ fontSize, fontColor, fontFamily }}
					/>
				) : null}
			</div>
		</div>
	);
}

export default ViewCertificates;
