import { Button } from '@mui/material';
import React, { useState, useRef } from 'react';
import { ChromePicker } from 'react-color';

function ColorPicker({ color, setColor }) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef();

  const handleColorClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleColorChange = newColor => {
    setColor(newColor.hex);
  };

  // Close the color picker when clicking outside of it
  const handleClickOutside = event => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target)
    ) {
      setShowColorPicker(false);
    }
  };

  // Attach a click event listener to the document
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="color-picker">
      <div className="color-field" onClick={handleColorClick}>
        <label>Select color</label>
        <br />
        <button
          style={{
            width: '80px',
            height: '15px',
            backgroundColor: color,
          }}
          variant="contained"
        ></button>
      </div>
      {showColorPicker && (
        <div ref={colorPickerRef} className="color-picker-popover">
          <ChromePicker color={color} onChange={handleColorChange} />
        </div>
      )}
    </div>
  );
}

export default ColorPicker;
