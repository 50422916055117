import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import campaigns from "modules/campaigns";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

function DeleteCampaign({ openDeleteDiolog,  handleClickOpenDeleteDiolog,  handleCloseDeleteDiolog,
    deleteData,getAllCampaigns
  }) 
  {
    const Swal = useSwalWrapper();
  const handleDeleteData = () => {
    const DeleteDataId = deleteData.id;
     campaigns.DeleteCampaignFromBatch(DeleteDataId, (response)=>{
        if(response.status==="success"){
          Swal.fire({
            icon: "success",
            title: "Data deleted Successfully.",
          });
          getAllCampaigns();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error Occured While deleting Data!",
          });
        }
     })
    getAllCampaigns();
    handleCloseDeleteDiolog()
  };

  return (
    <>
      <Dialog
        open={openDeleteDiolog}
        onClose={handleCloseDeleteDiolog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Confirmation ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure You want to delete this <span>{deleteData?.title}</span> From  Batch
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDeleteDiolog}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDeleteData} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default DeleteCampaign;
