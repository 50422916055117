import SessionModule from 'app/mmEsoft/module/SessionModule';
import { useEffect, useState } from 'react';

const useValidateSessions = () => {
	const [isSessionValid, setIsSessonValid] = useState([]);
	const [validLoader, setValidLoader] = useState(false);

	useEffect(() => {
		SessionModule.sessionValidation((response) => {
			setValidLoader(true);
			if (response.status === 'success') {
				setIsSessonValid(response?.data);
				setValidLoader(false);
			} else {
				setValidLoader(false);
				console.log('error while getting valid session');
			}
		});
	}, []);

	return { isSessionValid, validLoader };
};

export default useValidateSessions;
