import "./EmailCampaign.css"
import { Box } from "@mui/material";
import { Button, Stack } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { useState } from "react";
import EmailList from "./EmailList";
import AddEmailCampaign from "./AddEmailCamapign";

function EmailCampaign() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <item id="email-heading">Email Campaign</item>
          <item> <Button variant="contained" onClick={handleClickOpen}> Add Email </Button> </item>
        </Stack>
        <Dialog open={open} onClose={handleClose}>
         <AddEmailCampaign handleClose={handleClose}/>
      </Dialog>
       <EmailList/>
      </Box>
    </>
  );
}
export default EmailCampaign;
