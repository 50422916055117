import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';

const AddDepartment = props => {
  const validationSchema = yup.object().shape(
    {
      title1: yup.string().when('title2', {
        is: title2 => !title2 || title2.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title2: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
    },
    ['title1', 'title2']
  );

  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close}>
        <DialogTitle id='alert-dialog-title' textAlign='center'>
          Add Department
        </DialogTitle>
        <Formik
          validateOnChange={true}
          initialValues={{
            title1: '',
            title2: '',
            description: '',
            description1: '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            props.addDepartment(data);
            props.close();
          }}
        >
          <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
            <DialogContent>
              <Box
                sx={{
                  width: '500px',
                }}
              >
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name='title1'
                    label='Title(English)*'
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name='title2'
                    label='Title ( العربيه )'
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name='description'
                    label='Description(English)'
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name='description1'
                    label='Description ( العربيه )'
                  />
                </Div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' type='submit' autoFocus>
                Save
              </Button>
              <Button variant='contained' onClick={props.close}>
                {' '}
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddDepartment;
