import Page from '@jumbo/shared/Page';
// import Attendence from 'app/mmEsoft/components/Batches/Attendence/Attendence';

const attendenceRoutes = [
	{
		path: '/attendence',
		// element: <Page component={Attendence} />,
	},
];
export default attendenceRoutes;
