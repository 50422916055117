import appApi from '../../../modules/appApi';
class Attendence {
  createAttendance = async (data, cb) => {
    try {
      const response = await appApi({
        method: 'POST',
        url: `/api/attendence/add`,
        data,
      });
      cb({ status: 'success', data: response.data });
    } catch (error) {
      console.log('ERROR ON CREATING ATTENDENCE :: ' + error);
      cb({ status: 'error' });
    }
  };

  getAttendance = async (batchId, cb) => {
    try {
      const response = await appApi({
        method: 'GET',
        url: `/api/attendence/batch/${batchId}`,
      });
      cb({ status: 'success', data: response.data });
    } catch (error) {
      console.log('ERROR ON GETTING ATTENDENCE :: ' + error);
      cb({ status: 'error' });
    }
  };

  setPresent = async (id, data, cb) => {
    try {
      const response = await appApi({
        method: 'PUT',
        url: `/api/attendence/edit/${id}`,
        data,
      });
      cb({ status: 'success', data: response.data });
    } catch (error) {
      console.log('ERROR ON GETTING ATTENDENCE :: ' + error);
      cb({ status: 'error' });
    }
  };
}

export default new Attendence();
