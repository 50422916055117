import appApi from './appApi';

class Groups {
  addGroup = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/groups/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  group', error);
      });
  };
  getAllGroups = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/groups/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  group', error);
      });
  };
  updateGroup = (id, data, callBack) => {
    appApi({
      method: 'PUT',
      url: '/api/groups/' + id,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating group  ', error.message);
        callBack({ status: 'error' });
      });
  };
  deleteGroupbyGroupId = (id, callBack) => {
    appApi({
      method: 'DELETE',
      url: '/api/groups/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting group  ', error.message);
        callBack({ status: 'error' });
      });
  };
}
export default new Groups();
