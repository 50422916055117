import labels from 'modules/labels';
import { useEffect, useState } from 'react';

export const useGetAllLabels = () => {
  const [labelsList, setLables] = useState([]);
  function getAllLabels() {
    labels.getAllLabels((response) => {
      if (response.status === 'success') {
        setLables(response.data);
      } else {
        console.log('Error at getting labels');
      }
    });
  }
  useEffect(() => {
    getAllLabels();
  }, []);

  return { labelsList, getAllLabels };
};
