import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
export default function ViewCertificatesData(props) {
  const [open, setOpen] = React.useState(false);

  const initInputFields = () => {
    return [
      {
        placeholder: '{firstname}',
        position: { x: 0, y: 0 },
        value: '{firstname}',
      },
      {
        placeholder: '{lastName}',
        position: { x: 0, y: 0 },
        value: '{lastname}',
      },
      {
        placeholder: '{address}',
        position: { x: 0, y: 0 },
        value: '{address}',
      },
      {
        placeholder: '{department}',
        position: { x: 0, y: 0 },
        value: '{department}',
      },
    ];
  };

  const [fields, setFields] = React.useState(initInputFields());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setTimeout(() => {
      let updatedFields = [...props?.inputFields];
      // for (let i = 0; i < updatedFields.length; i++) {
      //   const input = updatedFields[i];
      //   if (input.value == '{firstname}') {
      //     input.value = 'John';
      //   }
      //   if (input.value == '{lastname}') {
      //     input.value = 'Doe';
      //   }
      //   if (input.value == '{department}') {
      //     input.value = 'Marketing';
      //   }
      //   if (input.value == '{address}') {
      //     input.value = 'Singapore';
      //   }
      // }
      setFields(updatedFields);
    }, 500);
  }, [props?.inputFields]);

  return (
    <div>
      <Button
        variant='contained'
        size='small'
        sx={{ ml: 2 }}
        onClick={handleClickOpen}
      >
        View Template
      </Button>
      <Dialog
        maxWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogContent>
          <img
            style={{
              display: 'block',
              margin: '20px',
              width: '900px',
              height: '500px',
            }}
            src={URL.createObjectURL(new Blob([props.imageData]))}
            alt='API Image'
          />
          <div style={{ display: 'flex' }}>
            {fields &&
              fields.map((field, index) => (
                <div
                  style={{
                    fontSize: field.style?.fontSize,
                    color: field.style?.fontColor,
                    fontFamily: field.style?.fontFamily,
                    position: 'relative',
                    left: field.position.x,
                    top: field.position.y,
                    margin: '10px',
                  }}
                >
                  {field.value}
                </div>
              ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
