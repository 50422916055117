import appApi from './appApi';

class Licenses {
  addLicense = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/licenses/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  licenses', error);
      });
  };
  LicenseToUser = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: 'api/licenses/license_to_user',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  licensesToUser', error);
      });
  };
  getAllLicenses = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/licenses/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  lesson', error);
      });
  };
  getAllLicenseByCompany = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/licenses/all/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  licenses', error);
      });
  };
  getLicenseById = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/licenses/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  licenses', error);
      });
  };

  updateLicense = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/licenses/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating  licenses', error);
      });
  };
  deleteLicense = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: '/api/licenses/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while deleting  license', error);
      });
  };
}

export default new Licenses();
