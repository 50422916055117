import appApi from './appApi';

class Lessons {
  addLesson = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/lessons/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  lesson', error);
      });
  };
  getAllLessons = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/lessons/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  lesson', error);
      });
  };
  getAllLessonsByExam = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/lessons/all/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  lesson', error);
      });
  };
  ongoingLessons = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/user-progress/ongoing/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  lesson', error);
      });
  };
  upcomingLessons = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/user-progress/upcoming/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  lesson', error);
      });
  };
  completedLessons = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/user-progress/completed/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  lesson', error);
      });
  };
  updateLesson = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/lessons/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating  lesson', error);
      });
  };
  deleteLesson = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: '/api/lessons/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while deleting  lesson', error);
      });
  };
}

export default new Lessons();
