import campaigns from 'modules/campaigns';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGNS_FAILURE = 'GET_CAMPAIGNS_FAILURE';

export const getCampaignsSuccess = campaignList => ({
  type: GET_CAMPAIGNS,
  payload: campaignList,
});
export const getCampaignsFailure = () => ({
  type: GET_CAMPAIGNS_FAILURE,
});
export const getUserCampaigns = id => dispatch => {

  campaigns.getCampaignsByUserId(id, response => {
    if (response.status === 'success') {
      dispatch({ type: GET_CAMPAIGNS, payload: response.data });
    } else {
      dispatch({ type: GET_CAMPAIGNS_FAILURE });
    }
  });
};

