import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	Typography,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import Education from 'app/mmEsoft/module/Education';
import Swal from 'sweetalert2';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import Div from '@jumbo/shared/Div';

const EditQualification = ({
	candidate,
	getEducation,
	setOpen,
	open,
	selectedEducation,
}) => {
	const [qualification, setQualification] = React.useState({
		qualification: selectedEducation.qualification,
		institute: selectedEducation.institute,
		candidateId: candidate?.id,
		courseName: selectedEducation.courseName,
		courseBranch: selectedEducation.courseBranch,
		yearOfJoining: selectedEducation.yearOfJoining,
		yearOfPassing: selectedEducation.yearOfPassing,
		board: selectedEducation.board,
		schoolMedium: selectedEducation.schoolMedium,
		marks: selectedEducation.marks,
		cgpa: selectedEducation.cgpa,
	});

	const handleSubmit = e => {
		e.preventDefault();
		Education.editEducation(selectedEducation.id, qualification, response => {
			if (response.status === 'success') {
				setQualification(response?.data);
				getEducation(candidate.id);
				Swal.fire({
					icon: 'success',
					title: 'Education has been updated successfully',
				});
			} else {
				console.log('error in education');
				Swal.fire({
					icon: 'error',
					title: 'There was an error while updating the Education',
				});
			}
		});
		setOpen(false);
	};

	const handleChange = event => {
		setQualification({
			...qualification,
			[event.target.name]: event.target.value,
		});
	};
	return (
		<div>
			<JumboCardQuick
				title={'Education'}
				wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
				style={{ marginTop: '10px' }}
			>
				<Div>
					<Button
						style={{ marginLeft: '78%', marginTop: '-9%' }}
						variant='outlined'
						onClick={() => setOpen(true)}
					>
						Edit Qualification
					</Button>
					<Dialog
						open={open}
						onClose={() => setOpen(false)}
						maxWidth='md'
					>
						<DialogTitle>Edit Qualification</DialogTitle>
						<form onSubmit={handleSubmit}>
							<DialogContent>
								<FormControl
									variant='outlined'
									style={{ marginTop: '5px' }}
									fullWidth
								>
									<InputLabel htmlFor='qualification'>Education</InputLabel>
									<Select
										value={qualification?.qualification}
										onChange={handleChange}
										label='Education'
										inputProps={{
											name: 'qualification',
											id: 'qualification',
										}}
									>
										<MenuItem value='Doctorate/PhD'>Doctorate/PhD</MenuItem>
										<MenuItem value='Masters/Post-Graduation'>Masters/Post-Graduation</MenuItem>
										<MenuItem value='Graduation/Diploma'>Graduation/Diploma</MenuItem>
										<MenuItem value='12th'>12th</MenuItem>
										<MenuItem value='10th'>10th</MenuItem>
										{/* Add more education options as needed */}
									</Select>
								</FormControl>
								{/* ... (other input fields) ... */}
								{qualification?.qualification === '12th' ||
								qualification?.qualification === '10th' ? (
									<>
										<TextField
											margin='dense'
											id='marks'
											label='Marks (% of 100)'
											type='text'
											fullWidth
											name='marks'
											value={qualification.marks}
											onChange={handleChange}
										/>
										<TextField
											margin='dense'
											id='board'
											name='board'
											label='Board'
											type='text'
											fullWidth
											value={qualification.board}
											onChange={handleChange}
										/>
									</>
								) : (
									<>
										<TextField
											margin='dense'
											id='institute'
											label='University/Institute'
											type='text'
											name='institute'
											fullWidth
											value={qualification?.institute}
											onChange={handleChange}
										/>
										<TextField
											margin='dense'
											id='courseName'
											label='Course'
											name='courseName'
											type='text'
											fullWidth
											value={qualification?.courseName}
											onChange={handleChange}
										/>
										<TextField
											margin='dense'
											id='courseBranch'
											label='Specialization'
											name='courseBranch'
											type='text'
											fullWidth
											value={qualification?.courseBranch}
											onChange={handleChange}
										/>
										<TextField
											margin='dense'
											id='cgpa'
											label='Percentage/CGPA'
											name='cgpa'
											type='text'
											fullWidth
											value={qualification?.cgpa}
											onChange={handleChange}
										/>{' '}
									</>
								)}

								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										md={6}
									>
										<Typography sx={{ fontSize: '14px' }}>Joining Year</Typography>
										<TextField
											margin='dense'
											id='yearOfJoining'
											name='yearOfJoining'
											type='date'
											fullWidth
											value={qualification?.yearOfJoining}
											onChange={handleChange}
										/>
									</Grid>
									<Grid
										item
										md={6}
									>
										<Typography sx={{ fontSize: '14px' }}>last Year</Typography>
										<TextField
											margin='dense'
											id='yearOfPassing'
											type='date'
											name='yearOfPassing'
											fullWidth
											value={qualification?.yearOfPassing}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button
									variant='outlined'
									onClick={() => setOpen(false)}
								>
									Cancel
								</Button>
								<Button
									variant='outlined'
									type='submit'
								>
									Update
								</Button>
							</DialogActions>
						</form>
					</Dialog>
				</Div>
			</JumboCardQuick>
		</div>
	);
};

export default EditQualification;
