import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import lessons from 'modules/lessons';
import exams from 'modules/exams';

const Exam = ({ exam, setExpanded, getAllExams }) => {
  const Swal = useSwalWrapper();

  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title1: yup.string().when('title', {
        is: title => !title || title.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
    },
    ['title', 'title1']
  );

  const updateExam = (id, data) => {
    exams.updateExam(id, data, response => {
      if (response.status === 'success') {
        getAllExams();
        Swal.fire({
          icon: 'success',
          title: 'Exam has been updated successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while updating exam.',
        });
      }
    });
  };
  const deleteExam = () => {
    exams.deleteExam(exam.id, response => {
      if (response.status === 'success') {
        getAllExams();
        Swal.fire({
          icon: 'success',
          title: 'Exam has been deleted successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting exam.',
        });
      }
    });
  };
  const handleDelete = () => {
    setOpenConfirmation(false);
    setExpanded(false);
    deleteExam();
  };

  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Formik
        validateOnChange={true}
        initialValues={exam}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={data => {
          updateExam(exam.id, data);
          setExpanded(false);
        }}
      >
        <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
          <Div sx={{ mb: 2, mt: 1 }}>
            <JumboTextField fullWidth name="title" label="Title(English)" />
          </Div>
          <Div sx={{ mb: 2, mt: 1 }}>
            <JumboTextField fullWidth name="title1" label="Title ( العربيه )" />
          </Div>

          <Div
            sx={{ mb: 2, mt: 1, display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button variant="contained" type="submit" autoFocus>
              Update
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant="contained"
              onClick={() => setOpenConfirmation(true)}
            >
              Delete
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button variant="contained" onClick={() => setExpanded(false)}>
              {' '}
              Cancel
            </Button>
          </Div>
          <Dialog
            open={openConfirmation}
            onClose={() => setOpenConfirmation(false)}
          >
            <DialogTitle>Delete Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this lesson?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenConfirmation(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      </Formik>
    </Div>
  );
};

export default Exam;
