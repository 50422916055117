import appApi from './appApi';

class Videos {
  addVideo = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/videos/add',
      data: data,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('error while adding  video', error);
      });
  };
  getVideosByCampaignIds = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/videos/get_videos_by_campaignids',
      data: data,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('error while getting videos by campaignids', error);
      });
  };
  getAllVideos = (callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/videos/all',
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('error while getting  video', error);
      });
  };
  getAllVideosByLesson = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/videos/all/' + id,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('error while getting  video', error);
      });
  };

  getVideoCountByLesson = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/videos/get-count-by-lesson/' + id,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('error while getting  video', error);
      });
  };
  updateVideo = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/videos/' + id,
      data: data,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('error while updating  video', error);
      });
  };
  deleteVideo = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: '/api/videos/' + id,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('error while deleting  video', error);
      });
  };
  uploadVideo = (formdata, callBack) => {
    return appApi({
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data', // do not forget this
      },
      url: '/api/videos/upload',
      data: formdata,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        console.log('error while uploading video', error);
        callBack({ status: 'error' });
      });
  };

  getAllVideosFromCloudOrFile = (callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/videos/videos',
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('error while getting  videos', error);
      });
  };

  getVideoURL = (videoName, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/video/' + videoName,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('error while getting  videos', error);
      });
  };

  //END PONT :: /api/videos/get-video-duration/:userId/:lessonId
  getVideoDuration = (userId, lessonId, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/videos/get-video-duration/${userId}/${lessonId}`,
    })
      .then((response) => {
        callBack({ status: 'success', data: response.data });
      })
      .catch((error) => {
        callBack({ status: 'error' });
        console.log('ERROR AT GET VIDEO DURATION :: ', error);
      });
  };
}

export default new Videos();
