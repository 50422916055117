import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Email from "app/mmEsoft/module/Email";
import { Box } from "@mui/system";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoDataPlaceholder from "app/shared/NoDataPlaceholder";
import JumboListNoDataPlaceholder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder";
import EmailCampaignDetails from "./EmailCampaignDetails";
import EditIcon from '@mui/icons-material/Edit';
import { Dialog } from "@mui/material";
import EditEmailCampaign from "./EditEmail";
import AddUser from "./AddContacts";
import Utils from "app/mmEsoft/Utils/Utils";

function EmailList() {

  const [expanded, setExpanded] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const[emailSendData,setEmailSendData] =useState();

  const [open, setOpen] = useState(false);

  const handleClickOpen = (event, item) => {
    event.stopPropagation();
    setEmailSendData(item)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    // Check if the click target is the EditIcon
    const isEditIconClicked = event.target.closest('.MuiSvgIcon-root') !== null;
    setExpanded(isEditIconClicked && isExpanded ? panel : false);
  };
  

  useEffect(() => {
    getAllEmailCampaign();
  }, []);

  const getAllEmailCampaign = () => {
    Email.getAllEmailCampaign(response => {
      if (response.status === 'success') {
        setCampaignList(response.data);
      } else {
        setCampaignList([]);
      }
    });
  };

  return (
    <>
      <Box sx={{ marginTop: '3%' }}>
        {campaignList.length > 0 ? (
          campaignList.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{ marginBottom: '1%', borderRadius: 2 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  {item?.title ? item?.title : '-'}
                </Typography>
                <Typography sx={{ width: '33%', color: 'text.secondary' }}>
                  {Utils.convertToISTDate(item?.startDate ? item?.startDate : '--') }
                </Typography>
                <Typography sx={{ width: '31%', color: 'text.secondary' }}>
                  { item?.dueDate ? item?.dueDate : '--'}
                </Typography>
                <span onClick={(event) => handleClickOpen(event, item)}><EditIcon /> </span>  
                <Dialog open={open} onClose={handleClose}>
                   <EditEmailCampaign 
                    handleClose={handleClose} 
                    item={emailSendData}
                    getAllEmailCampaign={getAllEmailCampaign}
                    />
                </Dialog>
              </AccordionSummary>
              <AccordionDetails>
                <EmailCampaignDetails item={item}/>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <JumboListNoDataPlaceholder>
            <NoDataPlaceholder />
          </JumboListNoDataPlaceholder>
        )}
      </Box>

    </>
  );
}

export default EmailList;
