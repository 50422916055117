import Page from '@jumbo/shared/Page';

import Certificates from 'app/pages/certificates/Certificates';

const certificatesRoutes = [
  {
    path: '/certificates',
    element: <Page component={Certificates} />,
  },
];
export default certificatesRoutes;
