import appApi from './appApi';
class ForgotPassword {
  sendEmail = async (data, callback) => {
    await appApi({
      method: 'POST',
      url: '/public/forgot-password',
      data: data,
    })
      .then(response => {
        if (response.data) {
          callback({ status: 'success', data: response.data });
        } else {
          callback({ status: 'error', data: 'No Data Found' });
        }
      })
      .catch(error => {
        callback({ status: 'error' });
        console.log('error while adding  campaign', error);
      });
  };
}
export default new ForgotPassword();
