import GenerateToken from './GenerateToken';
const SessionDialog = ({
	open,
	setOpen,
	Swal,
	batchId,
	setSessionLoader,
	getMeetingsByBatchId,
}) => {
	return (
		<>
			<GenerateToken
				open={open}
				setOpen={setOpen}
				Swal={Swal}
				batchId={batchId}
				setSessionLoader={setSessionLoader}
				getMeetingsByBatchId={getMeetingsByBatchId}
			/>
		</>
	);
};

export default SessionDialog;
