import { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Autocomplete,
  InputAdornment,
  Stack,
  SwipeableDrawer,
  Chip,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import ContactModule from 'app/mmEsoft/module/ContactModule';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useGetAllLabels } from './hooks/useGetAllLabels';
import { useGetAllCollages } from './hooks/useGetAllCollages';
import { useGetAllCourseRtln } from './hooks/useGetAllCourseRtln';
import CloseIcon from '@mui/icons-material/Close';
import AddLabel from '../addLabel';
import AddEmailCampagin from '../addEmailCampagin';
import DeleteIcon from '@mui/icons-material/Delete';
import labels from 'modules/labels';

//  TODO - 0 : FILTER
//  TODO - 1 : GET COLLAGES API
//  TODO - 2 : ADD EMAIL CAMPAIGN TO FILTERED USERS
//  TODO - 3 : ADD LABEL TO ALL FILTERED USERS

const FilterForm = ({
  setContacts,
  openDrawer,
  toggleDrawer,
  labelsList,
  getAllLabels,
  loader,
}) => {
  // STATE FOR OPEN ADD CAMPAIGN DIALOG
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);

  // GET CONTACTS
  const { collageList, collageLoader } = useGetAllCollages();
  // GET COURSES
  const { courseList, courseLoder } = useGetAllCourseRtln();

  // CHIPS
  const [chips, setChips] = useState([]);

  //none-- state
  const [nullData, setNullData] = useState('none');
  const [emailCampaigns, setEmailCampaigns] = useState([]);
  const [contactIdList, setContactIdList] = useState([]);

  // DATA FOR SEARCH PARAMS LIST
  const [filterData, setFilterData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    courseRelation: 'null',
    labels: 'null',
    semester: 'null',
    college: 'null',
  });

  //LABEL PROPS
  const labelProps = {
    options: labelsList,
    getOptionLabel: (option) => option?.code,
  };

  // CHIP DATA HANDLING
  const handleLabel = (_, newValue) => {
    const chip = newValue?.code;
    if (!chip);
    else {
      setFilterData((prevData) => ({
        ...prevData,
        labels: chip,
      }));
      setChips((chips) => [...chips, chip]);
      //get result by batches
      const updatedFilterData = {
        ...filterData,
        labels: chip,
      };
      setFilterData(updatedFilterData);
      ContactModule.filterContact(updatedFilterData, (response) => {
        if (response.status === 'success') {
          setContacts(response?.data);
          response?.data.forEach((element) => {
            setContactIdList([...contactIdList, element.candidate_id]);
          });
        } else {
          console.log('Error Occurred while searching contact');
        }
      });
      // filter(updatedFilterData);
    }
  };

  // HANDLE INPUT
  const handleInput = (e) => {
    setFilterData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    // FORM DATA HANDLING
    ContactModule.filterContact(filterData, (response) => {
      if (response.status === 'success') {
        setContacts(response?.data);
        response?.data.forEach((element) => {
          setContactIdList([...contactIdList, element.candidate_id]);
        });
      } else {
        console.log('Error Occurred while searching contact');
      }
    });
  };

  // Todo handle Add label Dialog
  const [openLabel, setOpenLabel] = useState(false);

  const handleClickOpenAddLabel = () => {
    setOpenLabel(true);
  };

  const handleCloseAddlabel = () => {
    setOpenLabel(false);
  };

  // Todo handle Add label Dialog
  const [openEmailCampaign, setOpenEmailCampaign] = useState(false);

  const handleOpenEmailCampaign = () => {
    setOpenEmailCampaign(true);
  };

  const handleCloseEmailCampaign = () => {
    setOpenEmailCampaign(false);
  };

  //todo api/email_campaigns/all to get All comapaign

  const getAllEmailCampagin = () => {
    ContactModule.getAllEmailCampagin((response) => {
      if (response.status === 'success') {
        setEmailCampaigns(response?.data);
      } else {
        console.log('ERROR While  getting all Email  campaigns');
      }
    });
  };
  useEffect(() => {
    getAllEmailCampagin();
  }, []);

  const handleDeleteLabel = () => {
    labels.deleteLabel(id, (response) => {
      getAllLabels();
      setOpen(!open);
    });
  };

  return (
    <>
      <SwipeableDrawer
        anchor='right'
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            paddingX: '10px',
          }}
        >
          <IconButton
            variant='outlined'
            onClick={() => {
              toggleDrawer(!openDrawer);
              setFilterData({
                name: '',
                email: '',
                phoneNumber: '',
                courseRelation: 'null',
                labels: 'null',
                semester: 'null',
                college: 'null',
              });
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant='h3' marginTop='10px' textAlign='center'>
            Filter Contacts
          </Typography>
        </Box>

        <Grid sx={{ paddingX: '20px', width: '300px' }}>
          <Grid marginY='10px' item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Course</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Courses'
                name='courseRelation'
                value={filterData?.courseRelation}
                onChange={handleInput}
                fullWidth
                displayEmpty //set None as default by this
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value='none'>--NONE-- </MenuItem>
                {courseList.map((course, index) => {
                  return (
                    <MenuItem key={index} value={course.course_relation}>
                      {course.course_relation}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid marginY='10px' item xs={12} md={12}>
            <Autocomplete
              onChange={handleLabel}
              name='label'
              autoHighlight
              startAdornment={
                <InputAdornment position='start'>
                  <AccountCircle />
                </InputAdornment>
              }
              {...labelProps}
              id='auto-complete-search'
              autoComplete
              includeInputInList
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Labels'
                  variant='outlined'
                  size='small'
                />
              )}
              renderOption={(props, option) => (
                <li
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  {...props}
                >
                  {option.code.split('@')[0]}
                  <IconButton
                    onClick={(e) => {
                      setId(option.id);
                      setOpen(!open);
                      e.stopPropagation();
                    }}
                    size='small'
                  >
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </li>
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction='row' spacing={2}>
              {chips.map((chip) => (
                <Chip
                  variant='outlined'
                  sx={{ fontSize: '10px' }}
                  label={chip}
                />
              ))}
            </Stack>
          </Grid>
          {/* semesters */}
          <Grid marginY='10px' item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Semester</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Semester'
                value={filterData.semester}
                onChange={handleInput}
                displayEmpty //set None as default by this
                fullWidth
                name='semester'
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value='none'>--NONE-- </MenuItem>
                <MenuItem value={1}>1st</MenuItem>
                <MenuItem value={2}>2nd</MenuItem>
                <MenuItem value={3}>3rd</MenuItem>
                <MenuItem value={4}>4th</MenuItem>
                <MenuItem value={5}>5th</MenuItem>
                <MenuItem value={6}>6th</MenuItem>
                <MenuItem value={7}>7th</MenuItem>
                <MenuItem value={8}>8th</MenuItem>
                <MenuItem value={9}>9th</MenuItem>
                <MenuItem value={10}>10th</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* collages */}
          <Grid marginY='10px' item xs={12} md={12}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Collages</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='collages'
                name='college'
                value={filterData.college}
                onChange={handleInput}
                displayEmpty //set None as default by this
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value='none'>--NONE-- </MenuItem>
                {collageList.map((clg, index) => {
                  return (
                    <MenuItem key={index} value={clg.college}>
                      {clg.college}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid marginY='10px' item xs={12} md={12}>
            <Button
              variant='contained'
              fullWidth
              onClick={handleOpenEmailCampaign}
            >
              Add Email Campaign
            </Button>
          </Grid>

          <AddEmailCampagin
            handleCloseEmailCampaign={handleCloseEmailCampaign}
            openEmailCampaign={openEmailCampaign}
            emailCampaigns={emailCampaigns}
            contactIdList={contactIdList}
            filterData={filterData}
          />

          <Grid marginY='10px' item xs={12} md={12}>
            <Button
              variant='contained'
              fullWidth
              onClick={() => handleClickOpenAddLabel()}
            >
              Add label
            </Button>
          </Grid>

          <AddLabel
            handleCloseAddlabel={handleCloseAddlabel}
            openLabel={openLabel}
            filterData={filterData}
          />
        </Grid>
      </SwipeableDrawer>
      <Dialog
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description' variant='h3'>
            Do you want to delete this label.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size='small'
            variant='outlined'
            onClick={() => setOpen(!open)}
          >
            cencel
          </Button>
          <Button
            size='small'
            variant='outlined'
            color='error'
            onClick={handleDeleteLabel}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FilterForm;
