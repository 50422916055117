import appApi from '../../../../src/modules/appApi';

class UserAssignments {
  getAssignmentByBatchId = (id, page, limit, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/assignment/batch/${id}?page=${page}&limit=${limit}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getAssignmentByBatchId');
      });
  };

  getAssignmentByAssignmentId = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/user_assignment/all/assignment/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getAssignmentByAssignmentId');
      });
  };

  //update user Assigments  field

  updateUserAssignments = (assignmentId, userId, data, callBack) => {
    appApi({
      method: 'PUT',
      url: `/api/user_assignment/${assignmentId}/${userId}`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in updateUserAssignments');
      });
  };

  //user Assignments List clientSide
  getUserAssignmentByBatchId = (id, userId, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/assignment/batch/${id}/${userId}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getUserAssignmentByBatchId');
      });
  };

  _getUserAssignmentByBatchIdAndUserId = (batchId, userId, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/assignment/batch/${batchId}/${userId}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in _getUserAssignmentByBatchId');
      });
  };

  //userAssignment update clientside
  //update user Assigments  field

  updateAssignmentOfUser = (data, callBack) => {
    appApi({
      method: 'PUT',
      url: `/api/user_assignment/add`,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in updateAssignmentOfUser');
      });
  };

  getUserAssignmentByUserId = (userId, assignmentId, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/user_assignment/user/${userId}/assignment/${assignmentId}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in getting feedback of  Assignment');
      });
  };
  //admin side
  downloadAssignmentByAssignmentId = (userAssignmentId, filename, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/assignment/download/${userAssignmentId}/${filename}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(error, 'error occurred in while downloading  Assignment');
      });
  };
  //userSide
  downloadGivenAssignmentByAssignmentId = (userAssignmentId, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/user_assignment/download/${userAssignmentId}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log(
          error,
          'error occurred in while downloading Admin given  Assignment'
        );
      });
  };
}
export default new UserAssignments();
