import AppApi from './appApi';

class Candidate {
	updateCandidate = (id, data, callBack) => {
		return AppApi({
			method: 'PUT',
			url: `/api/candidate/${id}`,
			data: data,
		})
			.then(response => {
				callBack({ status: 'success', data: response.data });
			})
			.catch(error => {
				console.log('error while getting all lables', error);
				callBack({ status: 'error' });
			});
	};
}

export default new Candidate();
