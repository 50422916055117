import React from 'react';
import Div from '@jumbo/shared/Div';
import { Typography } from '@mui/material';
import ASSET_IMAGES from '../../../Assets/empty_list.png';

const NoDataPlaceholder = ({ children }) => {
  if (children) return children;

  return (
    <Div sx={{ textAlign: 'center', p: 3, alignContent: 'center', m: 'auto' }}>
      <img
        alt={'Not Found'}
        src={`${ASSET_IMAGES}`}
        width='500'
        style={{ verticalAlign: 'middle', horizotalAlign: 'middle' }}
      />
      <Typography variant={'h3'} color={'text.secondary'} mt={2}>
        No data available
      </Typography>
    </Div>
  );
};

export default NoDataPlaceholder;
