import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { useTranslation } from 'react-i18next';

const AddLesson = props => {
  useEffect(() => {
    console.log(' Calling Add Lessons ');
  }, []);
  const { t } = useTranslation();
  const validationSchema = yup.object().shape(
    {
      title: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title1: yup.string().when('title', {
        is: title => !title || title.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
    },
    ['title', 'title1']
  );

  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close}>
        <DialogTitle id="alert-dialog-title" textAlign="center">
          {/* Add Lesson */}
          {t('pages.title.addLesson')}
        </DialogTitle>
        <Formik
          validateOnChange={true}
          initialValues={{
            title: props?.data?.title ? props?.data?.title : '',
            title1: props?.data?.title1 ? props?.data?.title1 : '',
            description: props?.data?.description
              ? props?.data?.description
              : '',
            description1: props?.data?.description1
              ? props?.data?.description1
              : '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            data.examId = props.id;
            props.addLesson(data);
            props.close();
          }}
        >
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <DialogContent>
              <Box
                sx={{
                  width: '500px',
                }}
              >
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="title"
                    label="Title(English)*"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="title1"
                    label="Title ( العربيه )"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="description"
                    label="Description (English)"
                  />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="description1"
                    label="Description ( العربيه )"
                  />
                </Div>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type="submit" autoFocus>
                Save
              </Button>
              <Button variant="contained" onClick={props.close}>
                {' '}
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddLesson;
