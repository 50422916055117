import React, { useEffect, useRef } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik, useField } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import Slider from '@mui/material/Slider';

import campaigns from 'modules/campaigns';

const AddCampaignDialog = props => {
  const contentRef = useRef(null);
  const Swal = useSwalWrapper();
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, []);
  const validationSchema = yup.object().shape(
    {
      title1: yup.string().when('title2', {
        is: title2 => !title2 || title2.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title2: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      successPercentage: yup
        .number()
        .min(0, 'Success percentage must be a positive number')
        .max(100, 'Success percentage must be less than or equal to 100')
        .required('Success percentage is required'),
      startDate: yup.date().required('Start date is required'),
      dueDate: yup.date().required('Due date is required'),
      // exam: yup.string().required('Exam is required'),
      // quizType: yup.string().required('Quiz type is required'),
      // quizStyle: yup.string().required('Quiz style is required'),
      // seek: yup.string().required('Seek field is required'),
      // player: yup.string().required('Player field is required'),
      // randomQuestions: yup.boolean(),
    },
    ['title1', 'title2']
  );

  const seek = [
    { name: 'No Seek', value: 'noseek' },
    { name: 'Seek Backwards Only', value: 'seekbackwardsonly' },
    { name: 'Seek Backwards And Forwards', value: 'seekbackwardsandforwards' },
  ];

  const addCampaign = data => {
    campaigns.addCampaign(data, response => {
      if (response.status === 'success') {
        props.getAllCampaigns();
        props.close();
        Swal.fire({
          icon: 'success',
          title: 'Campaign has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'there is an issue While adding Campaign',
        });
      }
    });
  };

  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close} maxWidth="lg">
        <Formik
          validateOnChange={true}
          initialValues={{
            ...props.data,
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={data => {
            addCampaign(data);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
              <DialogContent
                ref={contentRef}
                sx={{ overflowY: 'auto', maxHeight: '80vh' }}
              >
                <DialogTitle id="alert-dialog-title" textAlign="center">
                  {props.data.id ? 'Edit Course' : 'Add Course'}
                
                </DialogTitle>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="title1"
                      label="Title(English)*"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="title2"
                      label="Title ( العربيه )"
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      type="date"
                      name="startDate"
                      label="Start Date"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      type="date"
                      name="dueDate"
                      label="Due Date"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12} p={1}>
                    <Typography>Success Percentage *</Typography>
                    <Slider
                      value={values.successPercentage}
                      onChange={(event, newValue) =>
                        setFieldValue('successPercentage', newValue)
                      }
                      aria-labelledby="percentage-slider"
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      select
                      name="exam"
                      value={values.exam}
                      label="Exam*"
                    >
                      {props?.examsData?.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </JumboTextField>
                  </Grid> */}

                  {/* <Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      select
                      name="quizType"
                      label="Quiz Type*"
                    >
                      <MenuItem key="text" value="text">
                        Text
                      </MenuItem>
                      <MenuItem key="interactive" value="interactive">
                        Interactive
                      </MenuItem>
                    </JumboTextField>
                  </Grid> */}

                  {/* <Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      select
                      name="quizStyle"
                      label="Quiz Style*"
                    >
                      <MenuItem key="once" value="once">
                        Once
                      </MenuItem>
                      <MenuItem key="repeat" value="repeat">
                        Repeat
                      </MenuItem>
                    </JumboTextField>
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField fullWidth select name="seek" label="Seek*">
                      {seek.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </JumboTextField>
                  </Grid> */}
                  {/* <Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      select
                      name="player"
                      label="Player*"
                    >
                      <MenuItem key="WebGL" value="WebGL">
                        WebGL
                      </MenuItem>
                      <MenuItem key="HTML5" value="HTML5">
                        HTML5
                      </MenuItem>
                    </JumboTextField>
                  </Grid> */}
                  {/* <Grid item xs={12} md={12} sm={12} sx={{ mb: 2, mt: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event =>
                            setFieldValue(
                              'randomQuestions',
                              event.target.checked
                            )
                          }
                          checked={values.randomQuestions}
                        />
                      }
                      label="Random Questions"
                      name="randomQuestions"
                    />
                  </Grid> */}
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button variant="contained" type="submit" autoFocus>
                  {props.data.id ? 'Update' : 'Save'}
                </Button>
                <Button variant="contained" onClick={props.close}>
                  {' '}
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Div>
  );
};

export default AddCampaignDialog;
