import appApi from './appApi';

class Exams {
  addExam = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/exams/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  exam', error);
      });
  };
  getAllExams = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/exams/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  exams', error);
      });
  };
  getAllExamsByUserId = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/user_exam/user/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  exams', error);
      });
  };
  updateExam = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/exams/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating  exam', error);
      });
  };
  getAllLessonsNotInExams = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/exams/not_in_exam/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  exams', error);
      });
  };
  deleteExam = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: '/api/exams/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while deleting  exams', error);
      });
  };
}

export default new Exams();
