import appApi from './appApi';

class Questions {
  addQuestion = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/questions/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  question', error);
      });
  };
  getAllQuestions = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/questions/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Question', error);
      });
  };

  getAllQuestionsByLesson = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/questions/all/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Question', error);
      });
  };

  getQuestionCountByLesson = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/questions/get-count-by-lesson/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Question', error);
      });
  };
  getAllQuestionsAndOptionsByLesson = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/questions/questions_options/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Question', error);
      });
  };
  getAnswersByLessonId = (userId,lessonId, callBack) => {
    return appApi({
      method: 'GET',
      url: "/api/user_answer/get-answers/"+userId+"/"+lessonId,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Question', error);
      });
  };

  updateQuestion = (id, data, callBack) => {
    return appApi({
      method: 'PUT',
      url: '/api/questions/' + id,
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while updating  question', error);
      });
  };
  deleteQuestion = (id, callBack) => {
    return appApi({
      method: 'DELETE',
      url: '/api/questions/' + id,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while deleting  question', error);
      });
  };
}

export default new Questions();
