import { Height, Margin } from '@mui/icons-material';
import { Button, Grid, List, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { getUserCampaigns } from 'app/redux/actions/userCampaigns';
import videos from 'modules/videos';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Video from 'app/pages/videos/Video';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { mt } from 'date-fns/locale';
import { useParams } from 'react-router-dom';
import JumboListContext from '@jumbo/components/JumboList/JumboListContext';
import { footerTheme } from 'app/themes/footer/default';
import JumboList from '@jumbo/components/JumboList';
import VideoItem1 from 'app/shared/widgets/PropertiesList1/VideoItem1'
import { alpha } from '@mui/material';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
const LibraryDashboard = () => {
  const dispatch = useDispatch();
  const [libraryList, setLibraryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [video, setVideo] = useState(null);
  const { user } = useSelector(({ login }) => login);
  const { campaignList } = useSelector(
    ({ campaignsReducer }) => campaignsReducer
  );
  const { theme } = useJumboTheme();
  const [flag, setFlag] = useState(true);
  useEffect(() => {
    dispatch(getUserCampaigns(user?.id));
  }, []);
  useEffect(() => {
    // When campaignList changes, fetch videos for all campaigns
    if (campaignList.length > 0) {
      getVideosByCampaignIds();
    }
  }, [campaignList]);

  const getVideosByCampaignIds = () => {
    const campaignIds = campaignList.map(campaign => campaign.id);
    const data = {
      campaignIds: campaignIds,
    };

    videos.getVideosByCampaignIds(data, response => {
      if (response.status === 'success') {
        setLibraryList(response.data.result);
        setVideo(response.data.result[0]);
        setIsLoading(false);
      } else {
        setLibraryList([]);
        setIsLoading(false);
      }
    });
  };

   const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  const handleClick = video => {
    setVideo(video);
  };
  const renderPropertyItem = React.useCallback(videoItem => {
    return  <VideoItem1   item={videoItem} onVideoSelect={handleClick} />; 
  });

  return (
    <>
          <Typography ml={1} mb={2} variant="h1">
          My Library
      </Typography>
      <Grid container spacing={3.75}>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Item>
            <Video video={video} flag={flag} controls={true} />
          </Item>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
            <JumboList
              data={libraryList?.length > 0 ? libraryList : []}
              primaryKey={'id'}
              renderItem={renderPropertyItem}
              componentElement={'div'}
              wrapperSx={{ pb: 1 }}
            />
        </Grid>

        </Grid>
  
    </>
  );
};

export default LibraryDashboard;
