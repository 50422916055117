import appApi from './appApi';

class PhishingCampaign {
  addPhishingCampaign = (data, callBack) => {
    return appApi({
      method: 'POST',
      Headers: {
        'content-type': 'multipart/form-data',
      },
      url: '/api/phishing_campaign/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  Phishing Campaign', error);
      });
  };
  addBulkPhishingCampaign = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/phishing_campaign/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  Phishing Campaign', error);
      });
  };
  getAllPhishingCampaign = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/phishing_campaign/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting phishing campaign', error);
      });
  };
  updatePhishingCampaign = (id, data, callBack) => {
    appApi({
      method: 'PUT',
      url: '/api/phishing_campaign/update/' + id,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while updating phishing campaign',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
  PhishingSmsHistory = (id, callBack) => {
    appApi({
      method: 'GET',
      url: '/api/sms_history/phishing/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'error' });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting  PhishingSmsHistory ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  PhishingEmailHistory = (id, callBack) => {
    appApi({
      method: 'GET',
      url: 'api/email_history/phishing/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        } else {
          callBack({ status: 'error' });
        }
      })
      .catch(error => {
        console.log(
          'Error occured while getting  Phishing Email History ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };

  SmsHistoryRetry = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/sms_history/phishing/retry/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  phising sms history', error);
      });
  };

  getPhisingEmailServer = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/phishing_campaign/email_servers/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting PhisingEmail  server', error);
      });
  };

  getPhisingEmailRetry = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/email_history/phishing/retry/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting PhisingEmailRetry ', error);
      });
  };

  getPhisingSmsGateway = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/phishing_campaign/sms_gateways/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Sms Gateway', error);
      });
  };
  getCount = callBack => {
    return appApi({
      method: 'GET',
      url: `/api/phishing_campaign/get-count`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Count', error);
      });
  };
  getUserCount = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/phishing_campaign_user/get-count/user/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  Count', error);
      });
  };
  deletePhishingCampaign = (id, callBack) => {
    appApi({
      method: 'DELETE',
      url: '/api/phishing_campaign/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log(
          'Error occure while deleting Phishing campaign ',
          error.message
        );
        callBack({ status: 'error' });
      });
  };
}

export default new PhishingCampaign();
