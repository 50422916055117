import { InputAdornment, MenuItem, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ListIcon from '@mui/icons-material/List';
import { selectBatch } from 'app/redux/actions/userBatches';

const BatchesDropDown = () => {
  const dispatch = useDispatch();
  const { batchesList } = useSelector(({ batchesReducer }) => batchesReducer);
  const { myBatch } = useSelector(({ batchesReducer }) => batchesReducer);
  const { user } = useSelector(({ login }) => login);

  useEffect(() => {
    if (batchesList?.length > 0) {
      dispatch(selectBatch(batchesList[0].batchId));
    }
  }, [batchesList, dispatch]);

  const handleChange = (batchId) => {
    let id = batchId.target.value;
    dispatch(selectBatch(id));
  };
  return (
    <div>
      <TextField
        size='small'
        required
        name='batch'
        variant='outlined'
        select
        onChange={(e) => {
          handleChange(e);
        }}
        value={myBatch}
        style={{ width: '170px' }}
        sx={{
          width: '100%',
          display: 'flex',
          '& .MuiOutlinedInput-root': {
            borderRadius: '20px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <ListIcon />
            </InputAdornment>
          ),
        }}
      >
        {batchesList?.map((batch) => (
          <MenuItem key={batch?.batchId} value={batch?.batchId}>
            {batch?.batchNumber}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default BatchesDropDown;
