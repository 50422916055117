import React from "react";
import Div from "@jumbo/shared/Div";
import { Tab, Tabs, Typography } from "@mui/material";
import EmailCampaigns from "./EmailCampaigns";
import EmailTemplates from "./EmailTemplates";
import EmailServers from "./EmailServers";
import EmailCampaign from "app/mmEsoft/components/Email/EmailCampaign";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Div sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Div>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const EmailConfig = (props) => {
  const [value, setValue] = React.useState(0);
  return (
    <Div>
      <Div sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
          <Tab label="Email Campaign" {...a11yProps(0)} />
          {/* <Tab label="Email Campaigns" {...a11yProps(1)} /> */}
          <Tab label="Email Templates" {...a11yProps(1)} />
        </Tabs>
      </Div>
      <TabPanel value={value} index={0}>
        {/* <EmailServers /> */}
        <EmailCampaign/>
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <EmailCampaigns />
      </TabPanel> */}
      <TabPanel value={value} index={1}>
        <EmailTemplates />
      </TabPanel>
    </Div>
  );
};

export default EmailConfig;
