import "./EditEmailCampaign.css";
import {
  Box,
  DialogContent,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Button, DialogActions } from "@mui/material";
import { useEffect, useState } from "react";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Email from "app/mmEsoft/module/Email";
import emailTemplate from "modules/emailTemplate";

function EditEmailCampaign({ handleClose, item,getAllEmailCampaign }) {
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    emailTemplate: "",
    startDate: "",
    endDate: "",
    repeat: "",
    days: "",
    labels: "",
  });

 
  useEffect(() => {
    getAllEmailTemplates();
    
    // Format the dates to "yyyy-MM-dd" before setting them in the state
    const formattedStartDate = new Date(item?.startDate).toLocaleDateString('en-CA');
    const formattedEndDate = new Date(item?.endDate).toLocaleDateString('en-CA');
  
    setFormData({
      title: item?.title,
      emailTemplate: item?.emailTemplate,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      repeat: item?.repeat,
      days: item?.days,
      labels: item?.labels,
    });
  }, []);
  

  const Swal = useSwalWrapper();

  // Email template dropdown API
  const getAllEmailTemplates = () => {
    emailTemplate.getAllEmailTemplates((response) => {
      if (response.status === "success") {
        setEmailTemplateList(response.data);
      } else {
        setEmailTemplateList([]);
      }
    });
  };
  // Email template dropdown API End

  const handleEditEmail = () => {
    let id = item.id;
    Email.editEmail(id, formData, (response) => {
      if (response.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Data Updated Successfully.",
        });
        getAllEmailCampaign();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error Occured While Updating Data!",
        });
      }
    });
    handleClose();
  };

  const handleEditChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Box>
        <DialogContent>
          <Grid container columnSpacing={10}>
            <Grid item xs={12}>
              <Typography variant="h2" style={{textAlign:'center'}}> Edit Email Campaign </Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ marginTop: "4%", marginBottom: "1%" }}>
                Title
              </InputLabel>
              <TextField
                fullWidth
                className="title"
                autoFocus
                margin="dense"
                id="title"
                type="text"
                name="title"
                variant="outlined"
                value={formData.title}
                onChange={(event) => handleEditChange(event)}
              />
              <Typography id="startDateError" color="error"></Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                id="demo-simple-select-label"
                fullWidth
                sx={{ marginTop: "2%", marginBottom: "2%" }}
              >
                Email Templates
              </InputLabel>
              <TextField
                fullWidth
                required
                id="emailTemplate"
                name="emailTemplate"
                size="small"
                variant="outlined"
                select
                value={formData.emailTemplate}
                onChange={(event) => handleEditChange(event)}
              >
                {emailTemplateList.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>

              <Typography id="titleError" color="error"></Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel sx={{ marginTop: "4%", marginBottom: "1%" }}>
                Start Date
              </InputLabel>
              <TextField
                fullWidth
                className="startDateAdd"
                autoFocus
                margin="dense"
                id="startDate"
                type="Date"
                name="startDate"
                variant="outlined"
                value={formData.startDate}
                onChange={(event) => handleEditChange(event)}
              />
            </Grid>
            <Grid item xs={12} md={6} id="endDateGrid">
              <InputLabel sx={{ marginTop: "4%", marginBottom: "1%" }}>
                End Date
              </InputLabel>
              <TextField
                fullWidth
                className="endDateAdd"
                autoFocus
                margin="dense"
                id="endDate"
                type="Date"
                name="endDate"
                variant="outlined"
                value={formData.endDate}
                onChange={(event) => handleEditChange(event)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              handleEditEmail();
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Box>
    </>
  );
}
export default EditEmailCampaign;
