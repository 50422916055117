import appApi from '../../../../src/modules/appApi';

class SessionModule {
	//  /api/sessions/session-validation (GET)
	sessionValidation = async callBack => {
		try {
			const response = await appApi({
				method: 'GET',
				url: `/api/sessions/session-validation`,
			});
			callBack({ status: 'success', data: response.data });
		} catch (error) {
			callBack({ status: 'error' });
			console.log(error, 'Error Occurred while validating session ');
		}
	};

	// /api/sessions/approve-session(GET)
	approveSession = async callBack => {
		try {
			const response = await appApi({
				method: 'GET',
				url: '/api/sessions/approve-session',
			});
			callBack({ status: 'success', data: response.data });
		} catch (error) {
			callBack({ status: 'error' });
			console.log(error, 'Error Occurred while approve session ');
		}
	};

	// /api/getCodeCall
	getCode = async callBack => {
		try {
			const response = await appApi({
				method: 'GET',
				url: '/api/sessions/get-code',
			});
			callBack({ status: 'success', data: response.data });
		} catch (error) {
			callBack({ status: 'error' });
			console.log(error, 'Error Occurred while approve session ');
		}
	};

	// /api/sessions/11/create-meeting?campaignId=0 (POST)
	createMeeting = async (b_id, camp_id, data, callBack) => {
		try {
			const response = await appApi({
				method: 'POST',
				url: `/api/sessions/${b_id}/create-meeting?campaignId=${camp_id}`,
				data,
			});
			callBack({ status: 'success', data: response.data });
		} catch (error) {
			callBack({ status: 'error' });
			console.log(error, 'Error Occurred while creating meeting ');
		}
	};

	// /api/sessions/all (GET)
	getAllSessions = async callBack => {
		try {
			const response = await appApi({
				method: 'GET',
				url: `/api/sessions/all`,
			});
			callBack({ status: 'success', data: response.data });
		} catch (error) {
			callBack({ status: 'error' });
			console.log(error, 'Error Occurred while creating meeting ');
		}
	};

	// /api/candidate/addContactToCandidate/:batchId
	addContactToCandidate = async (batchId, data, callBack) => {
		try {
			const response = await appApi({
				method: 'POST',
				url: `/api/candidate_batch/addContactToCandidate/${batchId}`,
				data,
			});
			callBack({ status: 'success', data: response.data });
		} catch (error) {
			callBack({ status: 'error' });
			console.log(error, 'Error Occurred while creating meeting ');
		}
	};

	//* /api/meeting/all/:id
	getMeetingsByBatchId = async (batchId, callBack) => {
		try {
			const response = await appApi({
				method: 'GET',
				url: `/api/meeting/all/${batchId}`,
			});
			callBack({ status: 'success', data: response.data });
		} catch (error) {
			callBack({ status: 'error' });
			console.log(error, 'Error Occurred while getting meeting by batchId');
		}
	};

	//* /api/meeting/id
	editMeetingById = async (id, data, callBack) => {
		try {
			const response = await appApi({
				method: 'PUT',
				url: `/api/meeting/${id}`,
				data,
			});
			callBack({ status: 'success', data: response.data });
		} catch (error) {
			callBack({ status: 'error' });
			console.log(error, 'Error Occurred while edit meeting by id');
		}
	};

	//* /api/meeting/id
	deleteMeetingById = async (id, callBack) => {
		try {
			const response = await appApi({
				method: 'DELETE',
				url: `/api/meeting/${id}`,
			});
			callBack({ status: 'success', data: response.data });
		} catch (error) {
			callBack({ status: 'error' });
			console.log(error, 'Error Occurred while edit meeting by id');
		}
	};
}

export default new SessionModule();
