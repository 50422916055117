// Date field problem
// TextAreas field problem
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	DialogActions,
	Button,
	Typography,
} from '@mui/material';
import Batches from 'app/mmEsoft/module/Batches';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const AddBatchDialog = ({ open, setOpen,getAllBatches }) => {
	const validationSchema = yup.object().shape({
		batchNumber: yup.string().required('Batch number is required'),
		description: yup.string().required('description is required'),
	});
	const Swal = useSwalWrapper();

	return (
		<Dialog open={open} onClose={() => setOpen(!open)}>
			<DialogTitle sx={{ textAlign: 'center', fontSize: '20px' }}>
				Add Batch
			</DialogTitle>
			<Formik
				initialValues={{ batchNumber: '', description: '' }}
				validateOnChange={true}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					Batches.addBatch(values, (response) => {
						if (response.status === "success") {
							Swal.fire({
							  icon: "success",
							  title: "Batch Added Successfully.",
							});
						  } else {
							Swal.fire({
							  icon: "error",
							  title: "Error Occured While Adding Batch",
							});
						  }
					});
					setOpen(!open);
					setSubmitting(false);
					getAllBatches();
				}}
			>
				{({ isSubmitting }) => (
					<Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
						<DialogContent sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
							<Grid
								container
								// rowSpacing={1}
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							>
								<Grid item xs={12} md={12} sm={12} sx={{ mb: 2 }}>
									<JumboTextField
										fullWidth
										name='batchNumber'
										label='Batch number'
									/>
								</Grid>
								<Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
									<Typography component='label'>Start Date</Typography>
									<JumboTextField fullWidth type='date' name='startDate' />
								</Grid>
								<Grid item xs={12} md={6} sm={6} sx={{ mb: 2, mt: 1 }}>
									<Typography component='label'>End Date</Typography>

									<JumboTextField fullWidth type='date' name='endDate' />
								</Grid>
								<Grid item xs={12} md={12} sm={12} sx={{ mb: 2, mt: 1 }}>
									<JumboTextField
										fullWidth
										type='textarea'
										name='description'
										label='Description'
										maxRows={4}
									/>
								</Grid>
							</Grid>

							<DialogActions>
								<Button variant='contained' type='submit' autoFocus>
									Save
								</Button>
								<Button variant='contained' onClick={() => setOpen(!open)}>
									Cancel
								</Button>
							</DialogActions>
						</DialogContent>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default AddBatchDialog;
