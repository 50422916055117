import './addAssignment.css';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import {
  Grid,
  ButtonGroup,
  IconButton,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import Assignment from 'app/mmEsoft/module/Assignment';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useEffect } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditAssignment from './editAssignment';
import DeleteAssignment from './deleteAssignment';
import { Label } from 'recharts';
import { Form, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Batches from 'app/mmEsoft/module/Batches';
import UserAssigments from 'app/mmEsoft/module/UserAssigments';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import Batches from "app/mmEsoft/module/Batches";

export default function AddAssignment() {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [batches, setBatches] = useState('');
  const [editAssignmentData, setEditAssignmentData] = useState({});
  const [deleteAssignmentData, setDeleteAssignmentData] = useState({});
  const [assignments, setAssignments] = useState([]);
  const [allBatches, setAllBatches] = useState([]);
  const [batch, setBatch] = useState();
  const [assignmentOfUser, setAssignmentOfUser] = useState([]);
  const [fileUpload, setFileUpload] = useState({ file: '' });
  const [assignmnetLoader, setAssignmentLoader] = useState(true);
  const navigate = useNavigate();
  // TODO rename. FormData is keyWord pending
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    startDate: null,
    endDate: null,
    batchId: null,
    maximumMarks: null,
  });

  const Swal = useSwalWrapper();
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleChange = (event, index) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeFile = (event) => {
    const uploadFile = event.target.files[0];
    setFileUpload({
      file: uploadFile,
    });
  };

  const handleSubmit = () => {
    // Perform validation
    if (!handleValidation()) {
      // If validation fails, don't submit the form
      // TODO add toasters
      return;
    }
    // if (fileUpload.file == "") {
    //   Swal.fire({
    //     icon: "error",
    //     title: "File has not been Added .",
    //   });
    // } else {
    //   Swal.fire({
    //     icon: "success",
    //     title: "File has been Added Successfully.",
    //   });
    // }
    //TODO name variable properly
    let formDataIs = new FormData();
    formDataIs.append('file', fileUpload.file); // Access the 'file' property of fileUpload
    formDataIs.append('data', JSON.stringify(formData));

    Assignment.addAssignment(formDataIs, (response) => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Assignment Added Successfully.',
        });
        handleAllAssignment(); // Reset the form data after a successful submission
        setFormData({
          title: '',
          description: '',
          startDate: null,
          endDate: null,
          batchId: null,
          maximumMarks: null,
        });
        setFileUpload({
          file: '',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error Occured While Adding Assignment!',
        });
      }
    });
    handleClose();
  };

  const handleClickEditOpen = (data) => {
    setOpenEdit(true);
    setEditAssignmentData(data);
    setOpenEdit(true);
  };

  const handleClickOpenDelete = (data) => {
    setOpenDelete(true);
    setDeleteAssignmentData(data);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleValidation = () => {
    let isValid = true;
    // Check title

    //TODO handle by adding an error class and apply style
    let titleError = document.getElementById('titleError');
    const titleField = document.getElementById('title');
    if (!formData.title) {
      titleField.style.border = '2px solid red';
      titleError.innerText = 'please fill this field';
      titleError.style.fontSize = '11px';

      isValid = false;
    }

    // Check start date
    let startError = document.getElementById('startDateError');
    const startDateField = document.getElementById('startDate');
    if (!formData.startDate) {
      startDateField.style.border = '2px solid red';
      startError.innerText = 'please fill this field';
      startError.style.fontSize = '11px';
      isValid = false;
    }

    // Check end date
    let endError = document.getElementById('endDateError');
    const endDateField = document.getElementById('endDate');
    if (!formData.endDate) {
      endDateField.style.border = '2px solid red';
      endError.innerText = 'please fill this field';
      endError.style.fontSize = '11px';
      endError.style.marginLeft = '20%';
      isValid = false;
    }

    return isValid;
  };

  const handleItemClick = (id) => {
    if (id && batch) {
      navigate('/assignment/viewAssignment', {
        state: {
          assignmentId: id,
          batchId: batch, // Pass batchId along with assignmentId
        },
      });
    } else {
      console.error('Error: ID or batchId is undefined');
    }
  };

  //TODO keep it at top, after state variable and other hooks
  useEffect(() => {
    getAllBatches();
  }, []);

  const getAllBatches = () => {
    Batches.getAllBatches((response) => {
      if (response.status === 'success') {
        setAllBatches(response?.data);
        setBatch(response?.data[0].id);
      } else {
        console.log('error while getting the Batches Assignments ');
      }
    });
  };

  const handleBatch = (e) => {
    setBatch(e.target.value);
  };

  // get user Assignment start
  const handleAllAssignment = () => {
    let batchId = batch;
    setAssignmentLoader(false);
    UserAssigments.getAssignmentByBatchId(batchId, 0, 10, (response) => {
      if (response.status === 'success') {
        setAssignmentOfUser(response?.data);
        setAssignmentLoader(true);
      } else {
        console.log('error while getting the user  Assignments ');
        setAssignmentLoader(true);
      }
    });
  };

  useEffect(() => {
    handleAllAssignment();
  }, [batch]);

  // Handle pagination incre
  const pageIncre = () => {
    setPage(page + 1);
    let batchId = batch;
    UserAssigments.getAssignmentByBatchId(batchId, page + 1, 10, (response) => {
      if (response.status === 'success') {
        setAssignmentOfUser(response?.data);
      } else {
        console.log('error while getting the user  Assignments ');
      }
    });
  };

  // Handle pagination decre
  const pageDecre = () => {
    setPage(page - 1);
    let batchId = batch;
    UserAssigments.getAssignmentByBatchId(batchId, page - 1, 10, (response) => {
      if (response.status === 'success') {
        setAssignmentOfUser(response?.data);
      } else {
        console.log('error while getting the user  Assignments ');
      }
    });
  };

  return (
    <>
      <Box>
        <Box>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography id='assignmentStyle'>Assignments</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                direction='row'
                justifyContent='end'
                alignItems='center'
                spacing={2}
              >
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={'10px'}
                  sx={{
                    height: '45px',
                    borderRadius: '5px',
                    border: '2px solid #bdbdbd',
                  }}
                >
                  <Typography sx={{ fontSize: '15px', padding: '5px' }}>
                    Batch
                  </Typography>
                  <TextField
                    sx={{
                      display: 'flex',
                      padding: '5px',
                      '& .MuiOutlinedInput-root': {
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                        },
                      },
                    }}
                    required
                    id='degree'
                    size='small'
                    variant='outlined'
                    select
                    value={
                      batch || (allBatches.length > 0 ? allBatches[0]?.id : '')
                    }
                    onChange={(e) => handleBatch(e)}
                    style={{ width: '160px' }}
                  >
                    {allBatches.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.batchNumber}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                <Button
                  id='addAssignmentBtn'
                  variant='contained'
                  onClick={handleClickOpen}
                >
                  <AddIcon sx={{ marginRight: '5%' }} />
                  Add
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            // maxWidth
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
              Create Assignment
            </DialogTitle>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Grid container columnSpacing={10}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    className='titleAdd'
                    margin='dense'
                    id='title'
                    label='Title'
                    type='text'
                    name='title'
                    fullWidth
                    variant='outlined'
                    onChange={handleChange}
                  />
                  <Typography id='titleError' color='error'></Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin='dense'
                    id='maximumMarks'
                    label='Maximum Marks'
                    type='number'
                    fullWidth
                    name='maximumMarks'
                    variant='outlined'
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel id='demo-simple-select-label' fullWidth>
                    Batches
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    // id="batchId"
                    name='batchId'
                    fullWidth
                    label='Batches'
                    onChange={handleChange}
                  >
                    {allBatches.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.batchNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Start Date</InputLabel>
                  <TextField
                    fullWidth
                    className='startDateAdd'
                    autoFocus
                    margin='dense'
                    id='startDate'
                    type='Date'
                    name='startDate'
                    variant='outlined'
                    onChange={handleChange}
                  />
                  <Typography id='startDateError' color='error'></Typography>
                </Grid>

                <Grid item xs={12} md={6} id='endDateGrid'>
                  <InputLabel>End Date</InputLabel>
                  <TextField
                    fullWidth
                    className='endDateAdd'
                    autoFocus
                    margin='dense'
                    id='endDate'
                    type='Date'
                    name='endDate'
                    variant='outlined'
                    onChange={handleChange}
                  />
                  <Typography id='endDateError' color='error'></Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id='description'
                    label='Description'
                    fullWidth
                    multiline
                    name='description'
                    rows={4}
                    variant='outlined'
                    onChange={handleChange}
                    sx={{ marginTop: '2%', marginBottom: '2%' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    component='label'
                    fullWidth
                    name='file'
                    id='file'
                    sx={{
                      backgroundColor: fileUpload.file
                        ? 'green'
                        : 'rgb(225, 223, 223)', // Change button color when file is selected
                      paddingTop: '4%',
                      paddingBottom: '4%',
                      color: fileUpload.file ? 'white' : 'gray', // Change text color when file is selected
                    }}
                  >
                    {fileUpload.file
                      ? `File selected: ${fileUpload.file.name}`
                      : 'Upload files'}
                    <VisuallyHiddenInput
                      accept='txt,pdf,docx,jpeg,jpg,png'
                      type='file'
                      onChange={handleChangeFile}
                    />
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' fullWidth onClick={handleSubmit}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Box>

        {/*Start Get Data of Assignment */}

        <Box
          sx={{
            flexGrow: 1,
            marginTop: '3%',
            padding: '.8%',
          }}
        >
          {assignmnetLoader ? (
            <Box>
              {assignmentOfUser?.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Subject Name</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {assignmentOfUser.map((data) => {
                        return (
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: 600, cursor: 'pointer' }}
                              onClick={() => handleItemClick(data.id)}
                            >
                              {data.title}
                            </TableCell>
                            <TableCell>{data.startDate}</TableCell>
                            <TableCell>{data.endDate}</TableCell>
                            <TableCell>
                              <item
                                id='edit-btn-hover'
                                class='grid-item-Assignment'
                              >
                                <EditIcon
                                  onClick={() => handleClickEditOpen(data)}
                                />
                                <span id='deleteBtn'>
                                  <DeleteIcon
                                    onClick={() => handleClickOpenDelete(data)}
                                  />
                                </span>
                              </item>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <JumboListNoDataPlaceholder>
                  <NoDataPlaceholder />
                </JumboListNoDataPlaceholder>
              )}
            </Box>
          ) : (
            <div className='loader' style={{ marginTop: '20%' }}>
              <svg className='circular-loader' viewBox='25 25 50 50'>
                <circle className='loader-path' cx='50' cy='50' r='20' />
              </svg>
            </div>
          )}
        </Box>
        {/* Edit 1*/}
        {openEdit ? (
          <EditAssignment
            editAssignmentData={editAssignmentData}
            handleAllAssignment={handleAllAssignment}
            openEdit={openEdit}
            handleCloseEdit={handleCloseEdit}
          />
        ) : null}

        {/* Delete */}
        {openDelete ? (
          <DeleteAssignment
            openDelete={openDelete}
            handleCloseDelete={handleCloseDelete}
            handleAllAssignment={handleAllAssignment}
            deleteAssignmentData={deleteAssignmentData}
          />
        ) : null}
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: 2, width: '100%' }}
      >
        {page > 0 ? (
          <IconButton onClick={pageDecre}>
            <NavigateBeforeIcon />
          </IconButton>
        ) : null}
        <Typography
          sx={{
            fontSize: '18px',
            borderRadius: '100px',
          }}
        >
          {page + 1}
        </Typography>
        {assignmentOfUser.length < 10 ? null : (
          <IconButton onClick={pageIncre}>
            <NavigateNextIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
}
