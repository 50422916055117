import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import RecentActivitiesList from './RecentActivitiesList';
import { useTranslation } from 'react-i18next';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import exams from 'modules/exams';
import { useSelector } from 'react-redux';
import phishingCampaign from 'modules/phishingCampaign';

const RecentActivities1 = ({ scrollHeight }) => {
  const { user } = useSelector(({ login }) => login);

  const [phishingCount, setPhishingCount] = React.useState();

  const { t } = useTranslation();
  React.useEffect(() => {
    if (user.roleId == 2 || user.roleId == 5) {
      getUserPhishingCount(user.id);
    } else getPhishingCount();
  }, []);
  const getPhishingCount = () => {
    phishingCampaign.getCount(response => {
      if (response.status === 'success') {
        setPhishingCount(response.data);
      } else {
        setPhishingCount();
      }
    });
  };
  const getUserPhishingCount = id => {
    phishingCampaign.getUserCount(id, response => {
      if (response.status === 'success') {
        setPhishingCount(response.data);
      } else {
        setPhishingCount();
      }
    });
  };
  return (
    <JumboCardQuick
      title="Phishing Campaigns"
      wrapperSx={{ p: 0 }}
      width={'100%'}
    >
      <JumboScrollbar
        autoHeight
        autoHeightMin={scrollHeight ? scrollHeight : 480}
        autoHide
        autoHideDuration={200}
        autoHideTimeout={500}
      >
        <RecentActivitiesList phishingCount={phishingCount} />
      </JumboScrollbar>
    </JumboCardQuick>
  );
};
/* Todo scrollHeight prop define */
export default RecentActivities1;
