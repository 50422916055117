import React, { useState } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import {
  routesForAdmin,
  routesForNotAuthenticatedOnly,
} from '../../../app/routes';
import useRoutePathMatch from '@jumbo/hooks/useRoutePathMatch';
import { removeToken, storeToken } from './authHelpers';
import { config } from '../../../app/config/main';
import { AuthContext } from '@jumbo/components/JumboAuthProvider/JumboAuthContext';
import Cookies from 'js-cookie';
import { buildRoutes } from '@jumbo/utils';

const authData = Cookies.get('authData');
const storedToken = authData
  ? JSON.parse(authData)
  : JSON.parse(localStorage.getItem('authData'));
let firstTimePageLoad = true;

const init = () => {
  let authUser = null;

  if (!config?.authSetting) {
    throw Error(
      `You are using JumboAuthProvider but you haven't setup authSetting inside /src/app/config/main.js's config object`
    );
  }

  if (storedToken) {
    storeToken(storedToken?.token); // also sets axios header
  }
  return {
    authToken: storedToken?.token ?? null,
    authUser: storedToken?.user,
    isLoading: false,
    fallbackPath: config.authSetting.fallbackPath,
  };
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'set-auth-data':
      return {
        ...state,
        ...action.payload,
      };

    case 'start-loading':
      return {
        ...state,
        isLoading: true,
      };

    case 'stop-loading':
      return {
        ...state,
        isLoading: false,
      };
  }
};

const JumboAuthProvider = ({ children, ...restProps }) => {
  const [authOptions, setAuthOptions] = React.useReducer(
    authReducer,
    restProps,
    init
  );
  const location = useLocation();
  const [searchReslts, setSearchReslts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [logout, setLogout] = React.useState(false);
  const navigate = useNavigate();
  const [isAuthenticatedPath, setIsAuthenticatedPath] = React.useState(false);
  // const isAuthenticatedRouteOnly = useRoutePathMatch(routesForAdmin);
  // const isNotAuthenticatedRouteOnly = useRoutePathMatch(
  //   routesForNotAuthenticatedOnly
  // );

  React.useEffect(() => {
    if (logout) {
      removeToken();
      setAuthOptions({
        type: 'set-auth-data',
        payload: { authToken: null, authUser: null, isLoading: false },
      });
      setLogout(false);
    }
  }, [logout]);

  const setAuthToken = React.useCallback(async (token) => {
    setAuthOptions({ type: 'start-loading' });
    if (!token) {
      setLogout(true);
      return;
    }

    storeToken(token);
    try {
      const authUser = await config?.authSetting?.getAuthUserService();
      if (authUser) {
        setAuthOptions({
          type: 'set-auth-data',
          payload: { authToken: token, authUser: authUser, isLoading: false },
        });
        return;
      }
      setLogout(true);
    } catch (error) {
      setLogout(true);
      console.error(error);
    }
  }, []);

  //todo: maybe in next version
  const setRedirectPath = React.useCallback((redirectPath) => {
    setAuthOptions({ type: 'set-redirect-path', payload: { redirectPath } });
  }, []);

  const setAuthData = React.useCallback((data) => {
    setAuthOptions({ type: 'set-auth-data', payload: data });
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      ...authOptions,
      setAuthData,
      setRedirectPath,
      setAuthToken,
      setAuthOptions,
      setSearchReslts,
      searchReslts,
      setSearchTerm,
      searchTerm,
    };
  }, [authOptions, searchReslts, searchTerm]);

  React.useEffect(async () => {
    let routes = routesForAdmin;

    if (Array.isArray(routes) && routes.length > 0 && location?.pathname) {
      const generatedRoutes = await buildRoutes(routes);
      const matchedRoutes = matchRoutes(generatedRoutes, location.pathname);
      if (matchedRoutes && matchedRoutes.length > 0) {
        setIsAuthenticatedPath(true);
      }
    }
  }, []);

  React.useEffect(async () => {
    if (!authOptions.authToken) {
      if (isAuthenticatedPath) {
        navigate(authOptions?.fallbackPath);
      }
    } else if (!authOptions.authUser) {
      setAuthToken(authOptions.authToken);
    }
  }, [authOptions.authUser, isAuthenticatedPath]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default JumboAuthProvider;
