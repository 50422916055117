import { enUS, frFR, arEG, zhCN, esES, itIT } from '@mui/material/locale';

const languages = [
  {
    label: 'English',
    locale: 'en-US',
    muiLocale: enUS,
  },
  {
    label: 'French',
    locale: 'fr-FR',
    muiLocale: frFR,
  },
  {
    label: 'Arabic',
    locale: 'ar-EG',
    muiLocale: arEG,
  },
  {
    label: 'Chinese',
    locale: 'zh-CN',
    muiLocale: zhCN,
  },
  {
    label: 'Spanish',
    locale: 'es-ES',
    muiLocale: esES,
  },
  {
    label: 'Italian',
    locale: 'it-IT',
    muiLocale: itIT,
  },
];

const applicationConfig = {
  language: languages[0],
  direction: 'ltr', //rtl
};

export default applicationConfig;
