import ContactModule from "app/mmEsoft/module/ContactModule";
import { useEffect, useState } from "react";

export const useGetAllCourseRtln = () => {
  const [courseList, setCourses] = useState([]);
  const [courseLoader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    ContactModule.getAllCourseRelations((response) => {
      if (response.status === "success") {
        setCourses(response.data);
      } else console.log("error while getting collages");
    });
    setLoader(false);
  }, []);

  return { courseList, courseLoader };
};
