import appApi from "./appApi";

class LdapServer {
  addLdapServer = (data, callBack) => {
    return appApi({
      method: "POST",
      url: "/api/ldap_servers/add",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while adding  ldap server", error);
      });
  };
  getAllLdapServers = (callBack) => {
    return appApi({
      method: "GET",
      url: "/api/ldap_servers/all",
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while getting  ldap servers", error);
      });
  };

  updateLdapServer = (id, data, callBack) => {
    return appApi({
      method: "PUT",
      url: "/api/ldap_servers/" + id,
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while updating ldap server", error);
      });
  };
  deleteLdapServer = (id, callBack) => {
    return appApi({
      method: "DELETE",
      url: "/api/ldap_servers/" + id,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while deleting ldap server", error);
      });
  };
}

export default new LdapServer();
