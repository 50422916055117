import appApi from "./appApi";

class SmsGateway {
  addSmsGateway = (data, callBack) => {
    return appApi({
      method: "POST",
      url: "/api/sms_gateways/add",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while adding  sms gateway", error);
      });
  };
  getAllSmsGateways = (callBack) => {
    return appApi({
      method: "GET",
      url: "/api/sms_gateways/all",
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while getting  sms gateways", error);
      });
  };
  getAllSmsTemplates = (callBack) => {
    return appApi({
      method: "GET",
      url: "/api/sms_template/all",
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while getting  sms_template", error);
      });
  };
  updateSmsGateway = (id, data, callBack) => {
    return appApi({
      method: "PUT",
      url: "/api/sms_gateways/" + id,
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while updating sms gateways", error);
      });
  };
  deleteSmsGateway = (id, callBack) => {
    return appApi({
      method: "DELETE",
      url: "/api/sms_gateways/" + id,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log("error while deleting sms gateways", error);
      });
  };
}

export default new SmsGateway();
