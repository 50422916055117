import appApi from './appApi';

class Users {
  addUser = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/users/add',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  user', error);
      });
  };
  addBulkUserByGroupId = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/group_users/bulkadd',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  user', error);
      });
  };
  addBulkUserByPhishingCampaignId = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/phishing_campaign_user/bulkadd',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  user', error);
      });
  };
  addBulkUserByCampaignId = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/campaign_users/bulkadd',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  user', error);
      });
  };
  addBulkUserByDepartMentId = (data, callBack) => {
    return appApi({
      method: 'POST',
      url: '/api/department_users/bulkadd',
      data: data,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while adding  user', error);
      });
  };
  getAllUsers = (pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/all/?page=${pageNum}&perPage=${pageSize}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  getDistinctUser = (userName, callBack) => {
    return appApi({
      method: 'GET',
      url: `/public/check_username/` + userName,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  getAllUsersByLicenseId = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `api/users/get_users_by_license_id/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };

  getUsersByGroupId = (id, pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/group_users/group/${id}?page=${pageNum}&perPage=${pageSize}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  searchUsersByGroupId = (id, search, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/group_users/search/' + id + '/' + search,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  searchUsersByDepartmentId = (id, search, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/department_users/search/' + id + '/' + search,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  getUsersByDepartmentId = (id, pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/department_users/department/${id}?page=${pageNum}&perPage=${pageSize}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  getUsersByCampaignId = (id, pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/campaign_users/campaign/${id}?page=${pageNum}&perPage=${pageSize}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  searchUsersByCampaignId = (id, search, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/campaign_users/search/' + id + '/' + search,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  getUsersByPhishingCampaignId = (id, pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/phishing_campaign_user/phishing-campaign/${id}?page=${pageNum}&perPage=${pageSize}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  searchUsersByPhishingCampaignId = (id, search, callBack) => {
    return appApi({
      method: 'GET',
      url: '/api/phishing_campaign_user/search/' + id + '/' + search,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  getUsersForGroup = (id, pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/not_in_group/${id}?page=${pageNum}&perPage=${pageSize}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  searchUsersForGroup = (id, search, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/search_user_not_in_group/${id}/${search}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  searchUsersForPhishing = (id, search, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/search_user_not_in_phishing/${id}/${search}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  getUsersForDepartmentId = (id, pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/not_in_department/${id}?page=${pageNum}&perPage=${pageSize}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  searchUsersForDepartmentId = (id, search, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/search_user_not_in_department/${id}/${search}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  getUsersForCampaignId = (id, pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/not_in_campaign/${id}?page=${pageNum}&perPage=${pageSize}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  searchUsersForCampaignId = (id, search, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/search_user_not_in_campaign/${id}/${search}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  getUsersForPhishingId = (id, pageNum, pageSize, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/not_in_phishing/${id}?page=${pageNum}&perPage=${pageSize}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };

  getUsersById = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
    getUser = (id, callBack) => {
    return appApi({
      method: 'GET',
      url: `/api/users/user/${id}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
  getUserCards = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/user-progress/recent-users',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  sms template', error);
      });
  };

  updateUser = (id, data, callBack) => {
    appApi({
      method: 'PUT',
      url: '/api/users/user_and_candidate/' + id,
      data: data,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while updating user  ', error.message);
        callBack({ status: 'error' });
      });
  };

  deleteUser = (id, callBack) => {
    appApi({
      method: 'DELETE',
      url: '/api/users/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting user ', error.message);
        callBack({ status: 'error' });
      });
  };
  enableUser = (id, callBack) => {
    appApi({
      method: 'GET',
      url: '/api/users/enable_user/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while enabling user ', error.message);
        callBack({ status: 'error' });
      });
  };
  deleteUserByGroupId = (id, callBack) => {
    appApi({
      method: 'DELETE',
      url: '/api/group_users/user/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting user ', error.message);
        callBack({ status: 'error' });
      });
  };
  deleteUserByCampaignId = (id, callBack) => {
    appApi({
      method: 'DELETE',
      url: '/api/campaign_users/user/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting user ', error.message);
        callBack({ status: 'error' });
      });
  };
  deleteUserDepartmentId = (id, callBack) => {
    appApi({
      method: 'DELETE',
      url: '/api/department_users/user/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting user ', error.message);
        callBack({ status: 'error' });
      });
  };
  deleteUserByPhishingId = (id, callBack) => {
    appApi({
      method: 'DELETE',
      url: '/api/phishing_campaign_user/user/' + id,
    })
      .then(response => {
        if (response.data) {
          callBack({ status: 'success', data: response.data });
        }
      })
      .catch(error => {
        console.log('Error occure while deleting user ', error.message);
        callBack({ status: 'error' });
      });
  };
  uploadUserFile = (data, callback) => {
    appApi({
      method: 'POST',
      Headers: {
        'content-type': 'multipart/form-data',
      },
      url: `/api/users/upload`,
      data: data,
    })
      .then(response => {
        callback({ status: 'success', data: response.data });
      })
      .catch(err => {
        console.log(err, 'error occurred while uploading Users');
        callback({ status: 'error' });
      });
  };
  searchByNameEmailOrDomain = (search, callBack) => {
    return appApi({
      method: 'GET',
      url: `api/users/search-by-name-or-email/${search}`,
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting  users', error);
      });
  };
}

export default new Users();
