import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { Form, Formik, Field } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import users from "modules/users";
import { onEditUser } from "app/redux/actions/login";
import { useDispatch } from "react-redux";
import Departments from "../../../../modules/departments";

const EditUser = (props) => {
  const dispatch = useDispatch();
  const Swal = useSwalWrapper();

  const validationSchema = yup.object({
    name: yup.string("Enter your name").required("Name is required"),
    username: yup
      .string("Enter your username")
      .required("Username is required"),
    contact: yup.string("Enter your phone number"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const handleGetUserById = (updated) => {
    users.getUsersById(props?.user?.id, (response) => {
      if (response.status === "success") {
        if (updated) {
          dispatch(onEditUser(response.data));
        }
      }
    });
  };

  const handleAddEditUser = (data) => {
    users.updateUser(props?.user?.id, data, (response) => {
      if (response.status === "success") {
        handleGetUserById(true);
        Swal.fire({
          icon: "success",
          title: "User has been updated successfully.",
        });
        props.close();
      } else {
        Swal.fire({
          icon: "error",
          title: "There is an Error while updating user.",
        });
      }
    });
  };

  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} onClose={props.close} maxWidth="lg">
        <DialogTitle sx={{ textAlign: "center" }}>{"Edit User"}</DialogTitle>
        <Formik
          validateOnChange={true}
          initialValues={{
            name: props.user?.name || "",
            username: props.user?.username || "",
            contact: props.user?.contact || "",
            email: props.user?.email || "",
            address: props.user?.address || "",
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(data) => {
            handleAddEditUser(data);
            props.setOpenDialog(false);
          }}
        >
          {({ handleSubmit }) => (
            <Form
              style={{ textAlign: "left" }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="name"
                      label="Name*"
                      id="name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      disabled
                      name="username"
                      label="Username*"
                      id="username"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="contact"
                      label="Phone Number"
                      id="contact"
                    />
                  </Grid>
				  <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="address"
                      label="Address"
                      id="address"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} sx={{ mb: 1, mt: 1 }}>
                    <JumboTextField
                      fullWidth
                      name="email"
                      label="Email*"
                      id="email"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" type="submit" autoFocus>
                  {"Update"}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => props.setOpenDialog(false)}
                >
                  {"Cancel"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Div>
  );
};

export default EditUser;
