import { Button, Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ContactModule from "app/mmEsoft/module/ContactModule";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import templateImg from "../../assets/template/contact_sample_template.xlsx";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddBulkContact() {
  const Swal = useSwalWrapper();
  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContactFile = (event) => {
    const file = event.target.files[0];
    handleContactUpload(file);
    setContact(file);
  };

  const handleContactUpload = (selectedFile) => {
    if (selectedFile) {
      Swal.fire({
        icon: "success",
        title: "Contact File has been Selected successfully.",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "There is an issue While selected Contact File",
      });
    }
    const data = new FormData();
    data.append("file", selectedFile);

    // let ext = selectedFile.name.substring(
    //   selectedFile.name.lastIndexOf(".") + 1
    // );

    ContactModule.uploadBulkContact(data, (response) => {
      if (response.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Contact File has been added successfully.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "There is an issue While adding Contact File",
        });
      }
      setOpen(false);
    });
  };
  return (
    <>
      <Button
        variant="contained"
        startIcon={<FileUploadIcon />}
        onClick={handleClickOpen}
        size="large"
      ></Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Button variant="outlined">
            <a
              href={templateImg}
              download="contact_sample_template"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "red" }}
            >
              Download Excel Template
            </a>
          </Button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h4" style={{ color: "red" }}>
              NOTE:-
            </Typography>
            <Typography variant="body1">
              <li>
                Cannot not use special characters in labels except hyphens ( - )
                and underscore ( _ )
              </li>
              <li>
                Write all in uppercase in labels and cannot use space in labels
                also
              </li>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            aria-label="upload contact"
            component="label"
          >
            Select/Upload File
            <input
              hidden
              accept=".xls, .xlsx"
              type="file"
              onChange={handleContactFile}
            />
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ marginLeft: "5px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <a href={templateImg} 
          download="contact_sample_template"
          target="_blank"
          rel="noreferrer">
          <Button variant="outlined"> Download Excel Template</Button>
          </a>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6">
            <li>
                Please browse and select the Excel file from your computer to
                begin the bulk user upload process
              </li>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        
          <Button onClick={handleClose} autoFocus>
            <Box>
            <Button
              variant="contained"
              color="primary"
              aria-label="upload contact"
              component="label"
              size="small"
            >
              Select/Upload File
              <input
                hidden
                accept=".xls, .xlsx"
                type="file"
                onChange={handleContactFile}
              />
            </Button>
            <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
            </Box>
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
export default AddBulkContact;
