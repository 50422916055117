import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import UserEmail from 'app/mmEsoft/module/UserEmail';
import AddUser from './AddContacts';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

function UserList({ item }) {
  
  const [emailDataList, setEmailDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const Swal = useSwalWrapper();

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      headerClassName: 'header-cell',
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      headerClassName: 'header-cell',
    },
    {
      field: 'labels',
      headerName: 'Label',
      width: 250,
      headerClassName: 'header-cell',
      renderCell: (params) => {
        let labelArray = params.value.split(',')
        return(

          <div style={{
            overflow:"scroll",

          }}>
            {labelArray.map((label, index) => (
              <Chip
                key={index}
                label={label}
                style={{ marginRight: 4 }}
              />
            ))}
         </div>
        )
        
},
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 250,
      headerClassName: 'header-cell',
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone-Number',
      width: 250,
      headerClassName: 'header-cell',
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAllAddedEmailCampaign();
  }, []);
  
  const getAllAddedEmailCampaign = () => {
    let id = item.id;
    UserEmail.getAllaAddedEmailCampaign(id, response => {
      if (response.status == 'success') {
        
        setEmailDataList(response.data);
        setLoading(false);
      } else {
        setEmailDataList([]);
        setLoading(false);
      }
    });
  };
  
  const addContacts = async () => {
    const userIds = selectedRows.map(user => user.id);
    
    let Ids = {
      emailCampaignId: item.id,
      contactId: userIds,
    };
    
    await UserEmail.addUserEmail(Ids, response => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Users Added Successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error Occurred While Adding Users!',
        });
      }
    });
     getAllAddedEmailCampaign();
    handleClose();
  };

  const handleSelectRows = selectedRows => {
    setSelectedRows(selectedRows);
  };

  return (
    <>
      <Box id="main-container">
        <Stack direction="row" justifyContent="space-between">
          <item id="email-heading">Contact</item>
          <item>
            {' '}
            <Button variant="contained" onClick={handleClickOpen}>
              {' '}
              Add Contact{' '}
            </Button>{' '}
          </item>
        </Stack>
        {loading ? (
          <JumboListNoDataPlaceholder>
            <NoDataPlaceholder />
          </JumboListNoDataPlaceholder>
        ) : (
          <>
            {emailDataList.length === 0 ? (
              <JumboListNoDataPlaceholder>
                <NoDataPlaceholder />
              </JumboListNoDataPlaceholder>
            ) : (
              <div style={{ height: 400, width: '100%', fontWeight: 'bold' }}>
                <DataGrid
                  rows={emailDataList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                />
              </div>
            )}
          </>
        )}
        <Dialog open={open} onClose={handleClose}>
          <AddUser item={item} onSelectRows={handleSelectRows} />
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="outlined" onClick={addContacts}>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default UserList;
