import React from 'react';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Link from '@mui/material/Link';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import styled from '@emotion/styled';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 36,
  color: theme.palette.primary.main,
  fontSize: 24,
  height: 48,
  width: 48,
  borderRadius: '50%',
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  border: `solid 1px ${theme.palette.divider}`,
}));

const Contacts = ({ user,userData }) => {
  return (
    <JumboCardQuick title={'Contact'} noWrapper>
      <Divider />

      <List disablePadding sx={{ mb: 2 }}>
        <ListItem
          alignItems='flex-start'
          sx={{ p: theme => theme.spacing(0.5, 3),marginTop:"15px",marginBottom:"10px" }}
        >
          <StyledListItemIcon>
            <EmailOutlinedIcon />
          </StyledListItemIcon>
          <ListItemText
            primary={
              <Typography variant='body1' color='text.secondary'>
                Email
              </Typography>

            }
            secondary={
              <Typography variant='body1' color='text.secondary'>
                {user?.email}
              </Typography>
            }
          />
        </ListItem>
        {/* <ListItem
          alignItems='flex-start'
          sx={{ p: theme => theme.spacing(0.5, 3) }}
        >
          <StyledListItemIcon>
            <InsertLinkOutlinedIcon />
          </StyledListItemIcon>
          <ListItemText
            primary={
              <Typography variant='body1' color='text.secondary'>
                Address
              </Typography>
            }
            secondary={
              <Link variant='body1' href='#' underline='none'>
                {user?.address}
              </Link>
            }
          />
        </ListItem> */}
        <ListItem
          alignItems='flex-start'
          sx={{ p: theme => theme.spacing(0.5, 3), marginBottom:"20px" }}
        >
          <StyledListItemIcon>
          <LocalPhoneOutlinedIcon />
          </StyledListItemIcon>
          <ListItemText
            primary={
              <Typography variant='body1' color='text.secondary'>
                Phone
              </Typography>
            }
            secondary={
              <Typography variant='body1' color='text.primary'>
                {userData[0]?.contact}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </JumboCardQuick>
  );
};

export default Contacts;
