import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import SessionModule from 'app/mmEsoft/module/SessionModule';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const EditSession = ({
  openEdit,
  setOpenEdit,
  editData,
  batchId,
  getMeetingsByBatchId,
}) => {
  const Swal = useSwalWrapper();
  const [updateSession, setUpdateSession] = useState({
    start: null,
    end: null,
    description: '',
  });

  useEffect(() => {
    if (editData) {
      setUpdateSession({
        description: editData?.description || '',
        start: convertToISTDatee(editData?.start),
        end: convertToISTDatee(editData?.end),
      });
    }
  }, [editData]);
  const convertToISTDatee = timestamp => {
    // Parse the timestamp string
    if (timestamp == null) {
      return null;
    }
    let utc = new Date(timestamp);
    var dateUTC = new Date(utc); // format should be like '2023-11-20T10:30:00'
    var dateUTC = dateUTC.getTime();
    var dateIST = new Date(dateUTC);
    //date shifting for IST timezone (+5 hours and 30 minutes)
    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);

    let dateTimeIST = new Date(dateIST);

    const year = dateTimeIST.getFullYear();
    const month = String(dateTimeIST.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
    const day = String(dateTimeIST.getDate()).padStart(2, '0');
    const hours = String(dateTimeIST.getHours()).padStart(2, '0');
    const minutes = String(dateTimeIST.getMinutes()).padStart(2, '0');
    const seconds = String(dateTimeIST.getSeconds()).padStart(2, '0');

    const formattedString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedString;
  };
  const handleUpdate = e => {
    e.preventDefault();
    // Perform update operation here, for example, you can call an API to update the session
    SessionModule.editMeetingById(editData.id, updateSession, response => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Session updated successfully',
        });
        getMeetingsByBatchId(batchId);
        setOpenEdit(false); // Move this line here
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Session is not successfully',
        });
      }
    });
  };

  const handleChange = (key, value) => {
    setUpdateSession(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Dialog open={openEdit} maxWidth="md" onClose={() => setOpenEdit(false)}>
      <DialogTitle>Edit Session</DialogTitle>
      <DialogContent>
        <form onSubmit={handleUpdate}>
          <Grid container spacing={1}>
            <Grid item marginTop={'12px'} xs={12} md={12}>
              <TextField
                fullWidth
                label="Subject"
                name="subject"
                type="text"
                value={updateSession?.description}
                onChange={e => handleChange('description', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  renderInput={props => <TextField fullWidth {...props} />}
                  label="Start Time"
                  inputFormat="YYYY/MM/DD HH:mm:ss"
                  onChange={newValue => {
                    // Calculate end time with one hour gap
                    const endValue = newValue && newValue.add(1, 'hour');

                    handleChange('start', newValue);
                    handleChange('end', endValue);
                  }}
                  value={updateSession.start}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  renderInput={props => <TextField fullWidth {...props} />}
                  label="End Time"
                  inputFormat="DD/MM/YYYY HH:mm:ss"
                  onChange={newValue => handleChange('end', newValue)}
                  value={updateSession.end}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpenEdit(false)}>
              Close
            </Button>
            <Button variant="contained" type="submit">
              Update
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditSession;
