import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";

import Div from "@jumbo/shared/Div";
import users from "modules/users";
import exams from "modules/exams";
import examLessons from "modules/examLessons";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const AddMultipleLesson = (props) => {
  const Swal = useSwalWrapper();
  const [lessonList, setLessonList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handleRowSelect = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lessonList.map((row) => row);
      setSelectedRows(newSelecteds);
    } else {
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    getLessonsNotInExams(props.examId);
  }, [page, rowsPerPage, props.open]);

  const getLessonsNotInExams = (id) => {
    exams.getAllLessonsNotInExams(id, (response) => {
      if (response.status === "success") {
        setLessonList(response.data);
        setTotalCount(response.data.length);
      } else {
        setLessonList([]);
        setTotalCount(0);
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addBulkLessonsByIds = () => {
    const data = {
      lessons: selectedRows,
      examId: props.examId,
    };
    examLessons.addBulkLessonsByIds(data, (response) => {
      if (response.status === "success") {
        getLessonsNotInExams(props.examId);
        props.getAllExamLessonsByExam(props.examId);
        Swal.fire({
          icon: "success",
          title: "Lessons has been added successfully.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "There is an Error while adding lessons.",
        });
      }
    });
  };
  return (
    <Div sx={{ py: { lg: 2 } }}>
      <Dialog open={props.open} close={props.close} maxWidth="lg">
        <DialogTitle id="alert-dialog-title" textAlign="center">
          Add Lessons
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedRows.length === lessonList.length &&
                        lessonList.length > 0
                      }
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < lessonList.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell>Title </TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lessonList.map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={(event) => handleRowSelect(event, row)}
                    selected={selectedRows.indexOf(row) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={selectedRows.indexOf(row) !== -1} />
                    </TableCell>
                    <TableCell>
                      <Typography variant={"h6"} mb={0}>
                        {row.title ? row.title : row.title1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={"h6"} mb={0}>
                        {row.description ? row.description : ""}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            backIconButtonProps={{
              disabled: page === 0,
            }}
            nextIconButtonProps={{
              disabled: totalCount < 10,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => addBulkLessonsByIds()}
            autoFocus
          >
            Add Lessons
          </Button>
          <Button variant="contained" onClick={props.close}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Div>
  );
};

export default AddMultipleLesson;
