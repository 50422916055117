// http://localhost:8083/api/candidate/download-resume/348 // 386 -> candidate id
// http://localhost:8083/api/candidate/delete-resume/348 // 348-> candidate id
// http://localhost:8083/api/candidate/uploadResume/348

import appApi from './appApi';

class Resume {
	uploadResume = (id, data, callBack) => {
		return appApi({
			method: 'POST',
			url: `/api/candidate/uploadResume/${id}`,
			data,
		})
			.then(response => {
				callBack({ status: 'success', data: response.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log('error while getting  Option', error);
			});
	};

	deleteResume = (id, callBack) => {
		return appApi({
			method: 'DELETE',
			url: `/api/candidate/delete-resume/${id}`,
		})
			.then(response => {
				callBack({ status: 'success', data: response.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log('error while getting  Option', error);
			});
	};

	downloadResume = (id, callBack) => {
		return appApi({
			method: 'GET',
			url: `/api/candidate/download-resume/${id}`,
		})
			.then(response => {
				callBack({ status: 'success', data: response.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log('error while getting  Option', error);
			});
	};
}

export default new Resume();
