import { Box, IconButton, TextField, CircularProgress } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const CopyToken = ({ loading, genrateToken }) => {
	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(genrateToken)
			.then(() => {
				// console.log('Text copied to clipboard:', genrateToken);
			})
			.catch((error) => {
				console.error('Failed to copy:', error);
			});
	};

	return (
		<Box display="flex" alignItems="center" bgcolor="background.paper">
			<TextField
				value={genrateToken || ''}
				placeholder="Click to genrate code"
				style={{ width: '100%' }}
				InputProps={{
					readOnly: true,
				}}
				sx={{
					marginY: '10px',
				}}
			/>
			{loading ? (
				<CircularProgress sx={{ marginX: '10px' }} />
			) : (
				<IconButton aria-label="copy" onClick={copyToClipboard}>
					<FileCopyIcon />
				</IconButton>
			)}
		</Box>
	);
};

export default CopyToken;
