import React from 'react';
import {
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Div from '@jumbo/shared/Div';
import bgImg from '../../../../Assets/esoft img (1).jpg';
import forgetPassword from 'modules/forgetPassword';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import logo from '../../../../Assets/logo-esoft.png';
import { alpha } from '@mui/material';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Invalid Email Format')
    .required('Email is Required'),
});

const ForgotPassword = () => {
  const Swal = useSwalWrapper();

  const sendEmail = (values, { setSubmitting }) => {
    forgetPassword.sendEmail(values, response => {
      setSubmitting(false); // Reset submitting state
      if (response.status === 'success') {
        if (response.data.message == 'User not found') {
          Swal.fire({
            icon: 'error',
            title: 'Email Not Found, Please Enter Your Email Again',
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Email has been sent successfully.',
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while resetting Password',
        });
      }
    });
  };

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: theme => theme.spacing(4),
      }}
    >
      <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
        <Div sx={{ position: 'relative', height: '200px' }}>
          <CardMedia
            sx={{
              backgroundImage: `url("${bgImg}") !important; `,
              backgroundSize: 'cover',
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              position: 'relative',
            }}
            component="img"
            alt=""
            height="200"
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme => alpha(theme.palette.common.black, 0.5),
              p: theme => theme.spacing(3),
            }}
          >
            <Typography
              variant={'h2'}
              sx={{
                color: 'common.white',
                fontSize: '1.5rem',
                mb: 0,
              }}
            >
              Forgot password
            </Typography>
          </Div>
        </Div>
        <CardContent>
          <Formik
            validationSchema={validationSchema}
            onSubmit={sendEmail}
            initialValues={{ email: '' }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Div sx={{ mb: 3, mt: 1 }}>
                  <Field
                    as={TextField}
                    name="email"
                    fullWidth
                    id="email"
                    label="Email"
                    type="email"
                    placeholder="demo@example.com"
                    // error={Boolean(<ErrorMessage name='email' />)}
                    // helperText={<ErrorMessage name='email' />}
                  />
                </Div>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                >
                  Forget Password
                </Button>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default ForgotPassword;
