import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  TextField,
  Button,
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useEffect } from 'react';
import attendanceModule from 'app/mmEsoft/module/attendanceModule';
import useGetAttancence from '../hooks/useGetAttancence';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const AddActivity = ({ batchId, ids, open, setOpen, getAttendance }) => {
  const Swal = useSwalWrapper();

  const [data, setData] = useState({
    batchId,
    ids: [],
    activityName: '',
    activityDate: '',
  });

  useEffect(() => {
    if (ids && ids.length > 0) {
      setData((prevData) => ({
        ...prevData,
        ids: ids,
      }));
    }
  }, [ids]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleDate = (newValue) => {
    const dateStr = newValue?.$d?.toString()?.split(' ');
    const date = `${dateStr[1]} ${dateStr[2]}  ${dateStr[3]}`;
    setData({ ...data, activityDate: date });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    attendanceModule.createAttendance(data, (response) => {
      if (response.status === 'success') {
        getAttendance(batchId);
        Swal.fire({
          icon: 'success',
          title: 'activity created',
        });
      } else {
        console.log('ERROR OCCURED AT HANDLE SUBMIT :: CREATE ATTENDENCE');
        Swal.fire({
          icon: 'error',
          title: 'Failed to create attendance',
        });
      }
    });
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(!open)}>
        <DialogTitle>Add Activity</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container marginY={1} spacing={1}>
              <Grid item sx={12} md={12}>
                <TextField
                  type='text'
                  name='activityName'
                  value={data?.activityName}
                  label='Active Name'
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sx={12} md={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    value={data?.activityDate}
                    label='Activity Date'
                    onChange={handleDate}
                    name='activityDate'
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Box display={'flex'} gap={'10px'} justifyContent={'end'}>
              <Button
                variant='contained'
                type='submit'
                onClick={() => setOpen(!open)}
              >
                Add
              </Button>
              <Button variant='contained' onClick={() => setOpen(!open)}>
                close
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddActivity;
