import {
  CardContent,
  Divider,
  Typography,
  Card,
  Grid,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import './style.css';
import logo from '../../../Assets/logo-esoft.png';
import TermsAndConditionsEditDialog from './termsAndConditionsEditDialog';
import company from 'modules/company';
import './ckEditor.css';

import { EditorState } from 'draft-js';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
export const TermsAndConditions = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsloading] = React.useState(true);
  const [data, setData] = useState({});
  const containerStyle = {
    position: 'relative',
    minHeight: '100vh',
    overflow: 'hidden',
  };
  const footerStyle = {
    flexShrink: 0,
    textAlign: 'center',
    backgroundColor: '#e1daf2',
    padding: '10px',
  };
  React.useEffect(() => {
    getTermsAndConditions();
  }, []);
  const getTermsAndConditions = id => {
    let Companyid = 1;
    company.getcompanyById(Companyid, response => {
      if (response.status === 'success') {
        setData(response.data);
        setIsloading(false);
      } else {
        setIsloading(false);
      }
    });
  };
  return (
    <>
      <style>{`
        /* Hide both horizontal and vertical scrollbars */
        ::-webkit-scrollbar {
          width: 0px;
          height: 0px;
          background: transparent;
        }
      `}</style>
      {isLoading ? (
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : setData?.length <= 0 && !isLoading ? (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      ) : (
        <div style={containerStyle}>
          <Card
            maxWidth
            sx={{ overflow: 'hidden' }}
            style={{
              borderRadius: '5px',
              maxHeight: '500px',
              overflowY: 'auto',
            }}
          >
            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography ml={3} mt={2} mb={2} variant="h1" align="center">
                Terms and Conditions
              </Typography>
              <TermsAndConditionsEditDialog
                data={data}
                getTermsAndConditions={getTermsAndConditions}
              />
            </Grid>

            <Divider></Divider>
            <CardContent>
              <div
                dangerouslySetInnerHTML={{ __html: data.termsAndConditions }}
              />
              <Divider></Divider>
            </CardContent>
          </Card>

          <div style={footerStyle}>
            <Grid container justifyContent="space-between">
              <Grid item>
                Powered By &nbsp;
                <img
                  style={{
                    width: 'auto',
                    height: '12px',
                  }}
                  src={logo}
                  alt="Jumbo React"
                />
                <h2
                  style={{
                    fontSize: '10px',
                    color: 'white',
                    position: 'relative',
                    top: '-2px',
                    display: 'inline-block',
                    fontWeight: '600',
                  }}
                >
                  oft
                </h2>
              </Grid>
              <Grid item>Release No:</Grid>
            </Grid>
          </div>
          {open && <TermsAndConditionsEditDialog open={open} />}
        </div>
      )}
    </>
  );
};
