import React from 'react';
import { Box, Button } from '@mui/material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboAppTextField from '@jumbo/components/JumboAppTextField/JumboAppTextField';
import groups from 'modules/groups';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useTranslation } from 'react-i18next';

const EditGroupDetails = ({
  groupData,
  getAllGroup,
  expanded,
  setExpanded,
}) => {
  const Swal = useSwalWrapper();
  const { t } = useTranslation();
  const validationSchema = yup.object().shape(
    {
      title1: yup.string().when('title2', {
        is: title2 => !title2 || title2.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
      title2: yup.string().when('title1', {
        is: title1 => !title1 || title1.length === 0,
        then: yup.string().required('At least one of the fields is required'),
      }),
    },
    ['title1', 'title2']
  );
  const editGroup = data => {
    groups.updateGroup(groupData.id, data, response => {
      if (response.status === 'success') {
        getAllGroup();
        Swal.fire({
          icon: 'success',
          title: 'Group has been updated successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while updating group.',
        });
      }
    });
  };
  const deleteGroup = () => {
    groups.deleteGroupbyGroupId(groupData.id, response => {
      if (response.status === 'success') {
        getAllGroup();
        Swal.fire({
          icon: 'success',
          title: 'group has been deleted successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting Group.',
        });
      }
    });
  };
  return (
    <div>
      <Formik
        validateOnChange={true}
        initialValues={{
          title1: groupData?.title1,
          title2: groupData?.title2,
          description: groupData?.description,
          description1: groupData?.description1,
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={data => {
          editGroup(data);
        }}
      >
        {({ setFieldValue }) => (
          <Form style={{ textAlign: 'left' }} noValidate autoComplete="off">
            <Div sx={{ mb: 2, mt: 1 }}>
              <JumboAppTextField
                fullWidth
                name="title1"
                label="Title(English)"
              />
            </Div>
            <Div sx={{ mb: 2, mt: 1 }}>
              <JumboAppTextField
                fullWidth
                name="title2"
                label="Title ( العربيه )"
              />
            </Div>
            <Div sx={{ mb: 2, mt: 1 }}>
              <JumboAppTextField
                fullWidth
                name="description"
                label="Description(English)"
              />
            </Div>
            <Div sx={{ mb: 2, mt: 1 }}>
              <JumboAppTextField
                fullWidth
                name="description1"
                label="Description ( العربيه )"
              />
            </Div>
            <Div
              sx={{
                mb: 2,
                mt: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ mr: 2 }}
                autoFocus
              >
                {t("widgets.title.update")}
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={deleteGroup}
                sx={{ mr: 2 }}
                autoFocus
              >
                {t("widgets.title.delete")}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setFieldValue('title1', '');
                  setFieldValue('title2', '');
                  setFieldValue('description', '');
                  setFieldValue('description1', '');
                  setExpanded(!expanded);
                }}
              >
                {t("pages.title.cancel")}
              </Button>
            </Div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditGroupDetails;
