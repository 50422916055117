import appApi from "./appApi";
class Label {
  addLables = (data, callBack) => {
    return appApi({
      method: "POST",
      headers: {
        "content-type": "multipart/form-data", // do not forget this
      },
      url: "/api/labels/add",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        console.log("error while adding lables", error);
        callBack({ status: "error" });
      });
  };
  addBulkLabels = (data, callBack) => {
    return appApi({
      method: "POST",
      url: "/api/labels/bulkadd",
      data: data,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        console.log("error while adding lables", error);
        callBack({ status: "error" });
      });
  };

  getAllLabels = (callBack) => {
    return appApi({
      method: "GET",
      url: "api/label/all",
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        console.log("error while getting all lables", error);
        callBack({ status: "error" });
      });
  };

  deleteLabel = (id, callBack) => {
    return appApi({
      method: "DELETE",
      url: `api/contacts/deleteLabel/${id}`,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        console.log("error while getting all lables", error);
        callBack({ status: "error" });
      });
  };
}

export default new Label();
