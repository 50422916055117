import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import {
  Facebook,
  Google,
  Twitter,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import Div from '@jumbo/shared/Div';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser } from 'app/redux/actions/login';
import logo from '../../../../Assets/logo-esoft.png';
import bgImg from '../../../../Assets/esoft img (1).jpg';
import LocalizationOptions from 'app/shared/JumboCustomizer/components/LocalizationOptions';
import LanguageIcon from '@mui/icons-material/Language';
import { logoUrl } from 'modules/appApi';
import Logo from 'app/shared/Logo';

const validationSchema = yup.object({
  // email: yup
  //   .string('Enter your email')
  //   .email('Enter a valid email')
  //   .required('Email is required'),
  password: yup.string('Enter your password').required('Password is required'),
});

const Login2 = ({ disableSmLogin }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [userAgent, setUserAgent] = useState('');
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading, user } = useSelector(
    ({ login }) => login
  );
  const navigate = useNavigate();
  React.useEffect(() => {
    if (isAuthenticated && user?.roleId === 1) {
      navigate('/dashboards/dashboard');
    } else if (isAuthenticated && (user?.roleId === 3 || user?.roleId === 5)) {
      navigate('/dashboards/user');
    } else if (isAuthenticated && user?.roleId === 0) {
      navigate('/master-admin/otp');
    }
  }, [isAuthenticated]);
  useEffect(() => {
    const useragent = navigator.userAgent;
    setUserAgent(useragent);
  }, []);

  const onSignIn = async (email, password) => {
    dispatch(authenticateUser({ email, password, userAgent }));
  };

  // for password toggling
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Div
      sx={{
        width: 750,
        height: '500px',
        maxWidth: '100%',
        margin: 'auto',
        padding: 4,
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'auto',
        overflowY: 'hidden !important',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          minWidth: 0,
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <CardContent
          sx={{
            flex: '0 1 300px',
            position: 'relative',
            backgroundImage: `url("${bgImg}") !important; `,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
            },
          }}
        >
          <div
            style={{
              position: 'absolute',
              inset: 0,
              background: 'rgba(7, 149, 244, 0.5)',
            }}
          ></div>
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              color: 'common.white',
              position: 'relative',
              zIndex: 1,
              height: '100%',
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={'h3'}
                color={'white'}
                fontWeight={500}
                mb={3}
              >
                Sign In
              </Typography>
            </Div>

            <Div sx={{ mt: 'auto', position: 'relative', bottom: '-20px' }}>
              <Link href='#' underline='none' sx={{ display: 'inline-flex' }}>
                <Logo />
              </Link>
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              email: user?.email,
              password: user?.password,
            }}
            validationSchema={validationSchema}
            onSubmit={(data) => {
              onSignIn(data.email, data.password);
            }}
          >
            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
              <Div sx={{ mt: 1, mb: 3 }}>
                <JumboTextField fullWidth name='email' label='Email/Username' />
              </Div>
              <Div sx={{ mt: 3, mb: 2 }}>
                <JumboTextField
                  fullWidth
                  name='password'
                  label='Password '
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Div>
              <Div sx={{ mb: 2 }}>
                <FormControlLabel control={<Checkbox />} label='Remember me' />
              </Div>
              <LoadingButton
                fullWidth
                type='submit'
                variant='contained'
                size='large'
                sx={{ mb: 3 }}
                loading={isLoading}
              >
                Sign In
              </LoadingButton>
              <a
                style={{ color: 'blue' }}
                onClick={() => navigate('/user/forgot-password')}
              >
                Forgot Password?
              </a>
              {/* <a onClick={() => navigate("/user/reset-password")}>
                Reset Password
              </a> */}
              <ListItemButton
                style={{
                  float: 'right',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '-1vw',
                }}
              >
                <LanguageIcon style={{ fontSize: '15px' }} />

                <LocalizationOptions />
              </ListItemButton>
              {!disableSmLogin && (
                <React.Fragment>
                  <Typography variant={'body1'} mb={2}>
                    Or sign in with
                  </Typography>
                  <Stack direction='row' alignItems='center' spacing={1} mb={1}>
                    <IconButton
                      sx={{
                        bgcolor: '#385196',
                        color: 'common.white',
                        p: (theme) => theme.spacing(1.25),

                        '&:hover': {
                          backgroundColor: '#385196',
                        },
                      }}
                      aria-label='Facebook'
                    >
                      <Facebook fontSize='small' />
                    </IconButton>
                    <IconButton
                      sx={{
                        bgcolor: '#00a8ff',
                        color: 'common.white',
                        p: (theme) => theme.spacing(1.25),

                        '&:hover': {
                          backgroundColor: '#00a8ff',
                        },
                      }}
                      aria-label='Twitter'
                    >
                      <Twitter fontSize='small' />
                    </IconButton>
                    <IconButton
                      sx={{
                        bgcolor: '#23272b',
                        color: 'common.white',
                        p: (theme) => theme.spacing(1.25),

                        '&:hover': {
                          backgroundColor: '#23272b',
                        },
                      }}
                      aria-label='Twitter'
                    >
                      <Google fontSize='small' />
                    </IconButton>
                  </Stack>
                </React.Fragment>
              )}
            </Form>
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default Login2;
