import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Button, InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import ResultData from 'app/mmEsoft/module/ResultData';

export default function Assignment({ userId, batchId }) {
  const [loading, setLoading] = useState(true);
  const [userAssignment, setUserAssignment] = useState([]);

  useEffect(() => {
    getAssignmentByUserId();
  }, [userId, batchId]);

  function getAssignmentByUserId() {
    ResultData.getAssignmentByBatchIdAndUserId(batchId, userId, (response) => {
      setLoading(true);
      if (response.status === 'success') {
        setUserAssignment(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        console.log('ERROR AT GET ASSIGNMENT BY BATCH ID ', batchId);
      }
    });
  }

  let totalScore = 0;
  let maxScore = 0;
  let totalAssignment = 0;
  let submittedAssignments = 0;

  userAssignment?.forEach((e) => {
    if (e.score) {
      totalScore += Number(e.score);
    }
    if (e.isSubmitted) {
      submittedAssignments += 1;
    }
    maxScore += Number(e.maximumMarks);
    totalAssignment += 1;
  });

  return (
    <>
      {loading ? (
        <Typography
          sx={{ textAlign: 'center', fontSize: '20px', color: '#75757' }}
        >
          loading...
        </Typography>
      ) : (
        <Box sx={{ width: '100%' }}>
          {userAssignment.map((assignment, index) => {
            return (
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                border={'2px solid #e0e0e0'}
                flexDirection={'column'}
                sx={{
                  padding: '10px',
                  my: '10px',
                  width: '100%',
                }}
              >
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{
                    borderRadius: '4px',
                    padding: '10px',
                    my: '10px',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      border: '1px solid #e0e0e0',
                      padding: '5px',
                      borderRadius: '1px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#212121',
                      }}
                    >
                      Assignment Name : {assignment.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid #e0e0e0',
                      padding: '5px',
                      borderRadius: '1px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#212121',
                      }}
                    >
                      Start Date : {assignment.startDate}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid #e0e0e0',
                      padding: '5px',
                      borderRadius: '1px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#212121',
                      }}
                    >
                      End Date : {assignment.endDate}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid #e0e0e0',
                      padding: '5px',
                      borderRadius: '1px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#212121',
                      }}
                    >
                      {assignment?.score && assignment?.isSubmitted
                        ? `Score : ${assignment?.score}`
                        : 'score is not assigned'}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '3px',
                    flexDirection: 'column',
                    color: '#212121',
                    padding: '2px 10px',
                    borderRadius: '2px',
                    border: '1px solid #757575',
                  }}
                >
                  <Typography>Remarks</Typography>
                  <Typography>{assignment.feedback}</Typography>
                </Box>
              </Box>
            );
          })}

          {totalAssignment === 0 ? (
            <Typography sx={{ fontSize: '18px', textAlign: 'center' }}>
              User haven't uploaded any assignment yet
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '5px',
                border: '1px solid #e0e0e0',
              }}
            >
              <Typography style={{ color: '#212121', fontSize: '19px' }}>
                {' '}
                Total Assignments : {totalAssignment}
              </Typography>
              <Typography style={{ color: '#212121', fontSize: '19px' }}>
                Submited Assignments : {submittedAssignments}
              </Typography>
              <Typography style={{ color: '#212121', fontSize: '19px' }}>
                {' '}
                Total Score : {(100 * (totalScore / maxScore)).toFixed(2)}
                {'%'}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
