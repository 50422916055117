import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Div from '@jumbo/shared/Div';
import AddVideo from './AddVideos';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import videos from 'modules/videos';
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Video from './Video';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';

const Videos = props => {
  const Swal = useSwalWrapper();
  const [openVideoDialog, setOpenVideoDialog] = React.useState(false);
  const [isLoading, setIsloading] = React.useState(true);
  const [videoData, setVideoData] = React.useState([]);
  const [editData, setEditData] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [view, setView] = useState('list');
  React.useEffect(() => {
    getAllVideosByLesson(props.id);
  }, [props.id]);

  const handleCloseDialog = () => {
    setOpenVideoDialog(false);
  };
  const addVideo = data => {
    videos.addVideo(data, response => {
      if (response.status === 'success') {
        getAllVideosByLesson(props.id);
        Swal.fire({
          icon: 'success',
          title: 'Video has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while adding Video.',
        });
      }
    });
  };
  const getAllVideosByLesson = id => {
    videos.getAllVideosByLesson(id, response => {
      if (response.status === 'success') {
        setVideoData(response.data);
        setIsloading(false);
      } else {
        setVideoData([]);
        setIsloading(false);
      }
    });
  };
  const toggleView = prevView => {
    setView(prevView);
  };
  const handleChange = panel => (event, isExpanded) => {
    console.log(isExpanded, 'is', panel, 'panell');
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant={'h2'} mb={3}>
          Videos
        </Typography>
        <Div>
          <Button color="primary" onClick={() => toggleView()}></Button>
          <Button variant="contained" onClick={() => setOpenVideoDialog(true)}>
            Add Video
          </Button>
        </Div>
      </Box>
      {isLoading ? (
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" />
          </svg>
        </div>
      ) : videoData?.length <= 0 && !isLoading ? (
        <JumboListNoDataPlaceholder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceholder>
      ) : (
        videoData.map((video, index) => (
          <Accordion
            expanded={expanded === 'panel' + index}
            onChange={handleChange('panel' + index)}
          >
            <AccordionSummary
              // expandIcon={
              //   expanded === "panel" + index ? <RemoveIcon /> : <AddIcon />
              // }
              expandIcon={<ExpandMoreIcon />}
              // aria-controls="panel1bh-content"
              // id="panel1bh-header"
            >
              <Div
                sx={{
                  width: { xs: 'auto', lg: '26%' },
                  flexShrink: 0,
                  px: 1,
                  flex: { xs: '1', lg: '0 1 auto' },
                }}
              >
                <Typography>
                  {video.title ? video.title : video.title1}
                </Typography>
              </Div>
              <Div
                sx={{
                  width: { xs: 'auto', lg: '76%' },
                  flexShrink: 0,
                  px: 1,
                  flex: { xs: '1', lg: '0 1 auto' },
                }}
              >
                <Typography sx={{ color: 'text.secondary' }}>
                  {video.url ? video.url : ''}
                </Typography>
              </Div>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === 'panel' + index && (
                <Video
                  key={index}
                  index={index}
                  video={video}
                  lessonId={props.id}
                  getAllVideosByLesson={getAllVideosByLesson}
                  view={view}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))
      )}

      <AddVideo
        open={openVideoDialog}
        close={handleCloseDialog}
        getAllVideosByLesson={getAllVideosByLesson}
        addVideo={addVideo}
        lessonId={props.id}
      />
    </Div>
  );
};

export default Videos;
