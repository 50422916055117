import appApi from "../../../../src/modules/appApi";

class UserProgress {
  getUserProgress = (campaignId, userId, callBack) => {
    return appApi({
      method: "GET",

      url: `/api/user-progress/campaigns/${campaignId}/users/${userId}`,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log(error, "error occurred in getting User progress");
      });
  };

  getAllCampaigns = (userId, batchId, callBack) => {
    return appApi({
      method: "GET",
      url: `api/campaign_users/all/user/${userId}/batch/${batchId}`,
    })
      .then((response) => {
        callBack({ status: "success", data: response.data });
      })
      .catch((error) => {
        callBack({ status: "error" });
        console.log(error, "error occurred in getting Campaign List ");
      });
  };
}
export default new UserProgress();
