import React, { useEffect, useState } from 'react';
import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import AddUser from './AddUser';
import users from 'modules/users';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useSelector } from 'react-redux';
import UsersList from './UsersList';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const UserCell = props => {
  const Swal = useSwalWrapper();
  const { showDialog, hideDialog } = useJumboDialog();
  const { isAuthenticated, user } = useSelector(({ login }) => login);
  const [userDialog, setUserDialog] = React.useState({
    open: false,
    data: {
      userName: '',
      name: '',
      email: '',
      phoneNumber: '',
      department: '',
      role: '',
      status: '',
      language: '',
      isDeleted: false,
    },
  });

  const { contact, getAllUsers, selectedRows, setSelectedRows } = props;

  const { id, name, userName, email, phone, isDeleted } = contact;

  const [menuItems, setMenuItems] = useState(initialMenuItems);

  const handleRowSelect = (event, id) => {
    const selectedIndex = props.selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedRows.slice(0, selectedIndex),
        props.selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  useEffect(() => {
    if (contact.isDeleted === true) {
      setMenuItems([
        { icon: <EditIcon />, title: 'Edit', action: 'edit' },
        { icon: <CheckCircleIcon />, title: 'Enable', action: 'enable' },
      ]);
    } else {
      if (
        props?.groupId ||
        props?.departmentId ||
        props?.campaignId ||
        props?.phishingId
      ) {
        setMenuItems([
          { icon: <DeleteIcon />, title: 'Delete', action: 'delete' },
        ]);
      } else {
        setMenuItems([
          { icon: <EditIcon />, title: 'Edit', action: 'edit' },
          { icon: <BlockIcon />, title: 'Disable', action: 'disable' },
        ]);
      }
    }
  }, [contact.isDeleted]);

  const deleteUsers = (deleteFunction, fetchParams) => {
    deleteFunction(fetchParams, response => {
      if (response.status === 'success') {
        getAllUsers();
        Swal.fire({
          icon: 'success',
          title: 'User has been deleted successfully.',
        });
        hideDialog();
        // setMenuItems([
        //   { icon: <EditIcon />, title: 'Edit', action: 'edit' },
        //   { icon: <CheckCircleIcon />, title: 'Enable', action: 'enable' }, // Change to 'Enable'
        // ]);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while deleting user.',
        });
      }
    });
  };
  const enableUser = (enableFunction, fetchParams) => {
    enableFunction(fetchParams, response => {
      if (response.status === 'success') {
        getAllUsers();
        Swal.fire({
          icon: 'success',
          title: 'User has been enable successfully.',
        });
        hideDialog();
        setMenuItems([
          { icon: <EditIcon />, title: 'Edit', action: 'edit' },
          { icon: <BlockIcon />, title: 'Disable', action: 'disable' }, // Change to 'Disable'
        ]);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There is an Error while enable user.',
        });
      }
    });
  };
  const handleDelete = () => {
    if (props?.groupId) {
      deleteUsers(users.deleteUserByGroupId, id);
    } else if (props?.departmentId) {
      deleteUsers(users.deleteUserDepartmentId, id);
    } else if (props?.campaignId) {
      deleteUsers(users.deleteUserByCampaignId, id);
    } else if (props?.phishingId) {
      deleteUsers(users.deleteUserByPhishingId, id);
    } else {
      deleteUsers(users.deleteUser, id);
    }
  };
  const handleEnable = () => {
    enableUser(users.enableUser, id);
  };

  const handleEdit = () => {
    setUserDialog({ ...userDialog, data: contact, open: true });
  };

  const closeDialog = () => {
    setUserDialog({ ...userDialog, open: false });
  };
  const handleItemAction = menuItem => {
    switch (menuItem.action) {
      case 'disable':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about disabling this User?',
          content: 'You will be able to enable this User later',
          onYes: () => handleDelete(),
          onNo: hideDialog,
        });

        break;
      case 'delete':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about deleting this User?',
          content: 'You will not be able to recover this User later',
          onYes: () => handleDelete(),
          onNo: hideDialog,
        });

        break;
      case 'enable':
        showDialog({
          variant: 'confirm',
          title: 'Are you sure about enabling this User?',
          content: 'This will re-enable the User.',
          onYes: () => handleEnable(),
          onNo: hideDialog,
        });
        break;
      case 'edit':
        handleEdit();
        break;
    }
  };

  return (
    <TableRow
      sx={{
        padding: '2px 2px 2px 10px',
        boxShadow: 'rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem',
        borderRadius: '12px',
        // backgroundColor: ' rgb(255, 255, 255)',
        backgroundColor:
          contact.isDeleted === true ? '#f2f2f2' : 'rgb(255, 255, 255)',
      }}
    >
      {user?.roleId === 0 && (
        <TableRow
          key={contact.id}
          hover
          onClick={event => handleRowSelect(event, contact)}
          selected={selectedRows.indexOf(contact) !== -1}
          style={{ marginTop: '2' }}
        >
          <TableCell padding="checkbox" sx={{ mt: '2px' }}>
            <Checkbox checked={selectedRows.indexOf(contact) !== -1} />
          </TableCell>
        </TableRow>
      )}
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
        <Typography
          variant={'h6'}
          mb={0}
          sx={{
            color: contact.isDeleted === true ? 'grey' : 'inherit',
          }}
        >
          {`${name} `}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
        <Typography
          variant={'h6'}
          mb={0}
          sx={{
            color: contact.isDeleted === true ? 'grey' : 'inherit',
          }}
        >
          {userName ? userName : '_'}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
        <Typography
          variant={'h6'}
          mb={0}
          sx={{
            color: contact.isDeleted === true ? 'grey' : 'inherit',
          }}
        >
          {email ? email : '-'}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
        <Typography
          variant={'h6'}
          mb={0}
          sx={{
            color: contact.isDeleted === true ? 'grey' : 'inherit',
          }}
        >
          {phone ? phone : '-'}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          padding: '2px 2px 2px 20px !important',
        }}
      >
        <JumboDdMenu menuItems={menuItems} onClickCallback={handleItemAction} />
      </TableCell>

      {userDialog.open && (
        <AddUser
          open={userDialog.open}
          close={closeDialog}
          data={userDialog.data}
          getAllUsers={props.getAllUsers}
        />
      )}
    </TableRow>
  );
};

export default UserCell;

const initialMenuItems = [
  { icon: <EditIcon />, title: 'Edit', action: 'edit' },
  { icon: <BlockIcon />, title: 'Disabled', action: 'disable' },
];
