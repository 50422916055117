import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import emailServer from 'modules/emailServer';
import emailTemplate from 'modules/emailTemplate';
import campaigns from 'modules/campaigns';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import TypesTypography from '../components/mui/Typography/TypesTypography';

const CampaignMailSettings = props => {
  const Swal = useSwalWrapper();
  const [emailServerList, setEmailServerList] = useState([]);
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const [fieldValue, setFieldValue] = useState('');
  let emailData;
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
const [selectedEmailServer, setSelectedEmailServer] = useState(null);
  useEffect(() => {
    // Fetch data for email templates
    emailTemplate.getAllEmailTemplates(response => {
      if (response.status === 'success') {
        setEmailTemplateList(response.data);
      } else {
        setEmailTemplateList([]);
      }
    });

    // Fetch data for email servers
    emailServer.getAllEmailServers(response => {
      if (response.status === 'success') {
        setEmailServerList(response.data);
      } else {
        setEmailServerList([]);
      }
    });
 
    getEmailServerById(props?.campaign?.id);
  }, [props?.campaign?.id]); 

 
  const addEmailServerData = emailData => {
    campaigns.saveEmailDataServer(emailData, response => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Template has been added successfully.',
        });

      } else {
        Swal.fire({
          icon: 'error',
          title: 'There was an error while adding the template.',
        });
      }
    });
  };



   const getEmailServerById= (id)=>{
    campaigns.getEmailServer(id, response => {
       if (response.status === 'success') {
        setSelectedEmailTemplate(response.data.emailTemplateId);
        setSelectedEmailServer(response.data.emailServerId);
       } else {
        setSelectedEmailTemplate('');
        setSelectedEmailServer('');
       }
     });
   }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={{
          emailTemplate: selectedEmailTemplate,
          emailServer: selectedEmailServer,
        }}
        onSubmit={data => {
          emailData = {
            emailTemplateId: data.emailTemplate,
            emailServerId: data.emailServer,
            campaignId: props?.campaign?.id,
          };
          addEmailServerData(emailData);
          getEmailServerById(props?.campaign?.id);
          
        }}
      >
        <Form>
          <Grid>
            <Grid item xs={12} md={6} sm={6} mb={2}>
              <JumboTextField
                style={{ textAlign: 'start' }}
                fullWidth
                select
                name='emailTemplate'
                label={selectedEmailTemplate ? '' : 'Email Template'}
                onChange={e => {
                  // Set the email template value and clear the language value
                  setFieldValue('emailTemplate', e.target.value);
                  setFieldValue('language', ''); // Set language to an empty string
                }}
                
              >
                {emailTemplateList?.map(option => (
                  <MenuItem key={option?.id} value={option?.id}>
                    <div style={{ width: '90%' }}>{option?.title.trim()} </div>
                      <span style={{ width: '10%' }}>
                      {option?.language.trim()}
                    </span>
                  </MenuItem>
                ))}
              </JumboTextField>
            </Grid>
            
            <Grid item xs={12} md={6} sm={6}>
              <JumboTextField
                style={{ textAlign: 'start' }}
                fullWidth
                select
                name='emailServer'
                label={selectedEmailServer ? '' : 'Email Server'}
                onChange={e => {
                  setFieldValue('emailServer', e.target.value);
                }}
              >
                {emailServerList?.map(option => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.title.trim()}
                  </MenuItem>
                ))}
              </JumboTextField>
            </Grid>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '2vw',
                gap: '15px',
              }}
            >
              <Button variant='contained' type='submit' autoFocus>
                Save
              </Button>
              <Button variant='contained' onClick={props.setExpanded}>
                Cancel
              </Button>
            </div>
          </Grid>
        </Form>
      </Formik>
    </div>
  );
};
export default CampaignMailSettings;
