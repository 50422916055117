import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	TableContainer,
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Button,
	TableBody,
} from '@mui/material';
import campaigns from 'modules/campaigns';
import React, { useEffect, useState } from 'react';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
const AddCampaignDialog = ({ open, setOpen, batchId, getAllCampaigns }) => {
	const Swal = useSwalWrapper();
	const [isExist, setIsExist] = useState(false);
	const [isAdded, setIsAdded] = useState(false);
	//   PENDENING TODOS
	// TODO - 0 : GET BATCH ID (Done)
	// TODO - 1 : GET BATCH NAME (Done)

	// Campaign state
	const [campaignsData, setCampaigns] = useState([]);

	// FETCH @GET ALL CAMPAIGNS
	useEffect(() => {
		const getAllCampaigns = async () => {
			campaigns.getAllCampaigns(response => {
				if (response.status === 'success') setCampaigns(response?.data);
				else console.log('ERROR AT get all campaigns');
			});
		};
		getAllCampaigns();
	}, []);

	const removeToaster = () => {
		const timeListener = setTimeout(() => {
			setIsAdded(!isAdded);
			setIsExist(!isExist);
		}, 300);

		clearTimeout(timeListener);
	};

	//   ADD TO BATCH CAMPAIGN FUN -  DEF
	const addToBatchCamp = id => {
		// TODO - 0 ADD TO BATCH CAMPAIGN
		const data = {
			batchId: batchId,
			campaignId: id,
		};
		campaigns.addCampaignToBatch(data, response => {
			if (response.status === 'success') {
				getAllCampaigns();
				setIsAdded(true);
				setIsExist(false);
				removeToaster();
			} else {
				setIsExist(true);
				setIsAdded(false);
				removeToaster();
			}
		});
	};
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(!open)}
			maxWidth='lg'
		>
			<DialogTitle
				textAlign={'center'}
				fontWeight={'semibold'}
			>
				Add Course to batch
			</DialogTitle>
			{isExist ? (
				<Typography
					component={'p'}
					sx={{
						color: '#b71c1c',
						fontSize: '20px',
						textAlign: 'center',
						paddingX: '25px',
						paddingY: '5px',
						backgroundColor: '#ffcdd2',
					}}
				>
					Course already exists in batch!
				</Typography>
			) : null}

			{isAdded ? (
				<Typography
					component={'p'}
					sx={{
						color: '#1565c0',
						fontSize: '20px',
						textAlign: 'center',
						paddingX: '25px',
						paddingY: '5px',
						backgroundColor: '#bbdefb',
					}}
				>
					Course added successfully.
				</Typography>
			) : null}
			<DialogContent>
				<TableContainer>
					<Table
						sx={{ minWidth: 700 }}
						aria-label='simple table'
					>
						<TableHead>
							<TableRow>
								<TableCell>Title</TableCell>
								<TableCell>Description</TableCell>
								<TableCell>Start Date</TableCell>
								<TableCell>End Date</TableCell>
								<TableCell>Action</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{campaignsData.map(camp => {
								return (
									<TableRow>
										<TableCell>{camp?.title}</TableCell>
										<TableCell>{camp?.description}</TableCell>
										<TableCell>{camp?.startDate}</TableCell>
										<TableCell>{camp?.dueDate}</TableCell>
										<TableCell>
											<Button
												onClick={() => addToBatchCamp(camp?.id)}
												variant='contained'
												color='success'
											>
												Add
											</Button>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					onClick={() => setOpen(!open)}
				>
					close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddCampaignDialog;
