import appApi from './appApi';

class Roles {
  getAllRoles = callBack => {
    return appApi({
      method: 'GET',
      url: '/api/roles/all',
    })
      .then(response => {
        callBack({ status: 'success', data: response.data });
      })
      .catch(error => {
        callBack({ status: 'error' });
        console.log('error while getting roles', error);
      });
  };
}
export default new Roles();
