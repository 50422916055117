import React, { useEffect } from 'react';
import config from '@jumbo/config';
import JumboThemeContext from '@jumbo/components/JumboTheme/JumboThemeContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import JumboThemeHeader from './JumboThemeHeader';
import JumboThemeSidebar from './JumboThemeSidebar';
import JumboThemeFooter from './JumboThemeFooter';
import applicationConfig from 'app/config/applicationConfig';
import ThemeModule from '../../../modules/theme';

const JumboTheme = ({ children, init }) => {
  const [theme, setTheme] = React.useState(init.main);
  const [muiLocale, setMuiLocale] = React.useState({
    locale: applicationConfig.language.locale,
  });

  // useEffect(() => {
  //   loadDBTheme();
  // }, [init]);

  const loadDBTheme = async () => {
    await ThemeModule.loadTheme();
    let newDbTheme = init.main;
    if (ThemeModule.color) {
      newDbTheme.palette.primary = ThemeModule.color.primary;
      newDbTheme.palette.secondary = ThemeModule.color.secondary;
      newDbTheme.typography.fontFamily = ThemeModule.color.fontStyle.fontFamily;
      newDbTheme.typography.fontSize = ThemeModule.color.fontStyle.fontSize;
      newDbTheme.typography.h1.color = ThemeModule.color.fontStyle.fontColor;
      newDbTheme.typography.h2.color = ThemeModule.color.fontStyle.fontColor;
      newDbTheme.typography.h3.color = ThemeModule.color.fontStyle.fontColor;
      newDbTheme.typography.h4.color = ThemeModule.color.fontStyle.fontColor;
      newDbTheme.typography.h5.color = ThemeModule.color.fontStyle.fontColor;
    }
    setTheme(newDbTheme);
  };

  const updateTheme = React.useCallback(
    options => {
      const newTheme = createTheme({ ...theme, ...options }, muiLocale);
      setTheme(newTheme);
    },
    [theme, setTheme]
  );

  const themeContextValue = React.useMemo(
    () => ({
      theme: createTheme(theme, muiLocale),
      setTheme: updateTheme,
      setMuiLocale: setMuiLocale,
    }),
    [theme, muiLocale]
  );

  return (
    <JumboThemeContext.Provider value={themeContextValue}>
      <ThemeProvider theme={themeContextValue.theme}>
        <JumboThemeHeader init={init.header}>
          <JumboThemeSidebar init={init.sidebar}>
            <JumboThemeFooter init={init.footer}>{children}</JumboThemeFooter>
          </JumboThemeSidebar>
        </JumboThemeHeader>
      </ThemeProvider>
    </JumboThemeContext.Provider>
  );
};

export default JumboTheme;
