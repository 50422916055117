import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import emailServer from 'modules/emailServer';
import emailTemplate from 'modules/emailTemplate';
import campaigns from 'modules/campaigns';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import TypesTypography from '../components/mui/Typography/TypesTypography';
import smsGateway from 'modules/smsGateway';

const SmsSettings = props => {
  const Swal = useSwalWrapper();
  const [emailServerList, setEmailServerList] = useState([]);
  const [emailTemplateList, setEmailTemplateList] = useState([]);
  const [fieldValue, setFieldValue] = useState('');
  let emailData;

  useEffect(() => {
    // Fetch data for email templates
    smsGateway.getAllSmsTemplates(response => {
      if (response.status === 'success') {
        setEmailTemplateList(response.data);
      } else {
        setEmailTemplateList([]);
      }
    });

    smsGateway.getAllSmsGateways(response => {
      if (response.status === 'success') {
        setEmailServerList(response.data);
      } else {
        setEmailServerList([]);
      }
    });
    getSmsServerById(props?.campaign?.id);
  }, [props?.campaign?.id]); 

  const addSmsGateway= emailData => {
    campaigns.saveSmsGateway(emailData, response => {
      if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Template has been added successfully.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'There was an error while adding the template.',
        });
      }
    });
  };
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
  const [selectedEmailServer, setSelectedEmailServer] = useState(null);

  const getSmsServerById= (id)=>{
    campaigns.getSmsServer(id, response => {
       if (response.status === 'success') {
        setSelectedEmailTemplate(response.data.smsTemplateId);
        setSelectedEmailServer(response.data.smsGatewayId);
       } else {
        setSelectedEmailTemplate('');
        setSelectedEmailServer('');
       }
     });
   }
  return (
    <div>
      <Formik
        enableReinitialize={true}
        validateOnChange={true}
        initialValues={{
          smsTemplate: selectedEmailTemplate,
          smsGateway: selectedEmailServer,
        }}
        onSubmit={data => {
          emailData = {
            smsTemplateId: data.smsTemplate,
            smsGatewayId: data.smsGateway,
            campaignId: props?.campaign?.id,
          };
          addSmsGateway(emailData);
          getSmsServerById(props?.campaign?.id);
        }}
      >
        <Form>
          <Grid>
            <Grid item xs={12} md={6} sm={6} mb={2}>
              <JumboTextField
                style={{ textAlign: 'start' }}
                fullWidth
                select
                name='smsTemplate'
                label={selectedEmailTemplate ? '' : 'SMS Template'}
                onChange={e => {
                  // Set the sms template value and clear the language value
                  setFieldValue('smsTemplate', e.target.value);
                  setFieldValue('language', ''); // Set language to an empty string
                }}
              >
                {emailTemplateList?.map(option => (
                  <MenuItem key={option?.id} value={option?.id}>
                    <div style={{ width: '90%' }}>{option?.title}</div>
                  <span style={{ width: '10%' }}>
                   </span>
                  </MenuItem>
                ))}
                
              </JumboTextField>
            </Grid>
           
            <Grid item xs={12} md={6} sm={6}>
              <JumboTextField
                style={{ textAlign: 'start' }}
                fullWidth
                select
                name='smsGateway'
                label={selectedEmailServer ? '' : 'SMS Gateway'}
              >
                {emailServerList?.map(option => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.title.trim()}
                  </MenuItem>
                ))}
              </JumboTextField>
            </Grid>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '2vw',
                gap: '15px',
              }}
            >
              <Button variant='contained' type='submit' autoFocus>
                Save
              </Button>
              <Button variant='contained' onClick={props.setExpanded}>
                Cancel
              </Button>
            </div>
          </Grid>
        </Form>
      </Formik>
    </div>
  );
};
export default SmsSettings;
