import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from '@mui/material';
import ViewUserCertificates from 'app/clientSide/certificates/viewUserCertificates';
import React from 'react';

const ViewCertificate = ({ setOpen, open, certificateData,admin, data }) => {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  console.log({certificateData})
  return (
    <div>
      <Dialog
        maxWidth
        TransitionComponent={Transition}
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
            <Typography variant={'h3'}>View Certificate</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* <div ref={targetRef}> */}
          <ViewUserCertificates
            admin={true}
            certificateData={certificateData}
            data={data}
          />
          {/* </div> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewCertificate;
