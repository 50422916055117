import MarvelMindsApi, { appServerURLJava } from './MarvelMindsApi';
import appApi from '../../../../src/modules/appApi';
import axios from 'axios';
class Project {
	addProject = (data, callBack) => {
		return appApi({
			method: 'POST',
			url: `/api/project/add`,
			data: data,
		})
			.then(response => {
				callBack({ status: 'success', data: response.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log(error, 'Error Occurred In Getting Add Project');
			});
	};
	getProjectData = (id, callBack) => {
		return appApi({
			method: 'GET',
			url: `/api/project/all/${id}`,
		})
			.then(response => {
				callBack({ status: 'success', data: response?.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log(error, 'Error Occurred In Getting Project Details');
			});
	};

	editProjectData = (id, data, callBack) => {
		return appApi({
			method: 'PUT',
			url: `/api/project/${id}`,
			data: data,
		})
			.then(response => {
				callBack({ status: 'success', data: response.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log(error, 'Error Occurred while updating Project');
			});
	};

	deleteProject = (id, callBack) => {
		return appApi({
			method: 'DELETE',
			url: `/api/project/${id}`,
		})
			.then(response => {
				callBack({ status: 'success', data: response.data });
			})
			.catch(error => {
				callBack({ status: 'error' });
				console.log(error, 'Error Occurred while deleting Project');
			});
	};
}
export default new Project();
