import Page from '@jumbo/shared/Page';

import Reports from 'app/pages/reports/reports';

const reportsRoutes = [
  {
    path: '/reports',
    element: <Page component={Reports} />,
  },
];
export default reportsRoutes;
