import { Box, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import SessionDialog from './SessionDialog';
import SessionList from './SessionList';
import SessionModule from 'app/mmEsoft/module/SessionModule';

const Sessions = ({ batchId, Swal }) => {
	const [open, setOpen] = useState(false);
	const [sessionList, setSessionList] = useState([]);
	const [sessionLoader, setSessionLoader] = useState(false);
	const getMeetingsByBatchId = async batchId => {
		setSessionLoader(true);
		SessionModule.getMeetingsByBatchId(batchId, response => {
			if (response.status === 'success') {
				setSessionList(response.data);
				setSessionLoader(false);
			} else {
				setSessionLoader(false);
				console.log('error while getting all the sessions');
			}
		});
	};

	useEffect(() => {
		getMeetingsByBatchId(batchId);
	}, [batchId]);
	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography
					variant='h1'
					component='h2'
				>
					Sessions
				</Typography>

				<Button
					onClick={() => setOpen(!open)}
					variant='contained'
					autoFocus
				>
					Add Sessions
				</Button>
				<SessionDialog
					open={open}
					setOpen={setOpen}
					Swal={Swal}
					batchId={batchId}
					getMeetingsByBatchId={getMeetingsByBatchId}
					setSessionLoader={setSessionLoader}
				/>
			</Box>
			<SessionList
				batchId={batchId}
				sessionList={sessionList}
				sessionLoader={sessionLoader}
				getMeetingsByBatchId={getMeetingsByBatchId}
			/>
		</>
	);
};

export default Sessions;
