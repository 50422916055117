import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import contactsApp from './contactsApp';
import login from './login';
import campaignsReducer from './campaignsReducer';
import batchesReducer from './batchesReducer';

const exportReducers = history => {
  return combineReducers({
    router: connectRouter(history),
    contactsApp: contactsApp,
    login: login,
    campaignsReducer: campaignsReducer,
    batchesReducer: batchesReducer,
  });
};

export default exportReducers;
