import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import AddBatchDialog from './AddBatchDialog';
import BatchTabs from './BatchTabs';
import Batches from 'app/mmEsoft/module/Batches';
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder';
import NoDataPlaceholder from 'app/shared/NoDataPlaceholder';
import campaigns from '../../../../modules/campaigns';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

const Batch = () => {
  const Swal = useSwalWrapper();
  const [open, setOpen] = useState(false);
  const [batches, setBatches] = useState([]);

  const getAllBatches = () => {
    Batches.getAllBatches((response) => {
      if (response.status === 'success') {
        // Sort batches by creation date in descending order (newest first)
        const sortedBatches = response?.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setBatches(sortedBatches);
      } else console.log('ERROR at get all batchs');
    });
  };
  // getAllBatchs
  useEffect(() => {
    getAllBatches();
  }, [open]);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant={'h4'}>Batches</Typography>
        <Button onClick={() => setOpen(true)} variant='contained'>
          Add Batches
        </Button>
        {/* dialog */}
        <AddBatchDialog
          open={open}
          setOpen={setOpen}
          getAllBatches={getAllBatches}
        />
      </Box>
      {batches.length == 0 ? (
        <JumboListNoDataPlaceHolder>
          <NoDataPlaceholder />
        </JumboListNoDataPlaceHolder>
      ) : (
        batches.map((batch) => {
          return (
            <Card key={batch.id} sx={{ marginY: '5px', boxShadow: 1 }}>
              <Accordion square sx={{ borderRadius: 2 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    {batch?.batchNumber}
                  </Typography>
                  <Typography
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    Start Date {batch?.startDate?.split('T')[0]}
                  </Typography>
                  <Typography
                    sx={{
                      width: { xs: 'auto', lg: '26%' },
                      flexShrink: 0,
                      px: 1,
                      flex: { xs: '1', lg: '0 1 auto' },
                    }}
                  >
                    End Date {batch?.endDate?.split('T')[0]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <BatchTabs
                    batchId={batch.id}
                    Swal={Swal}
                    getAllBatches={getAllBatches}
                  />
                </AccordionDetails>
              </Accordion>
            </Card>
          );
        })
      )}

      {/* Add Batch model */}
    </>
  );
};

export default Batch;
